import RouterFrame from '../../../components/share/RouterFrame.vue'
import getProperServiceIcon from '../../utils/getProperServiceIcon'
import clients from './clients'
import role from './role'
import person from './person'
import mainVehicle from './main-vehicle'
import mainProperty from './main-property'
import appraisal from './appraisal'

export default function (service, name) {
  return {
    path: `administracja-procesami-${name}`,
    component: RouterFrame,
    meta: {
      title: name,
      icon: getProperServiceIcon(service),
      menuTree: true,
      acl: {
        service: service,
        action: '==ACL=='
      }
    },
    children: [
      clients(service, name),
      role(service, name),
      person(service, name),
      mainVehicle(service, name),
      mainProperty(service, name),
      appraisal(service, name),
    ]
  }
}
