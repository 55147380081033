// import imageAssertions from '../utils/IsImage'
import fileUtil from '../utils/file'
import errorReporter from '../utils/errorReporter'
import api from '../../../api'
import i18n from '../../../translations/internationalisation'

export default {
  sendAttachmentFiles: ({dispatch, commit, state}, {files, service, filePath, filePaths, canEditAttachments, $notify, taskId, fileRef}) => {
    commit('SET_SENDING_COUNT', 0)
    commit('CLEAR_SENT_SUMMARIES')
    commit('SET_UPLOADING_ATTACHMENTS', true)
    let allFilesArray = Object.keys(files)
    commit('SET_IMAGES_TO_PROCESS', allFilesArray.length)
    commit('CLEAR_PROCESSING_IMAGES_PROGRESS')

    let reader = null

    for (let file of files) {
      let extensionsString = '.doc, .docx, .rtf, .pdf, .ods, .odt, .xls, .xlsx, .csv, .txt, .eml, .msg, .xlsm, .7z, .zip, .rar, .gz, .tar, .bmp, .jpeg, .jpg, .tif, .tiff, .png, .PNG, .gif, xml, .signPro, .SIGNPR, .DWG, .crw, .ath, .szf, .axx, .xades, .GPG, .pgp, .sig, .sko, .eie, .sie, .mp3'
      let extensions = extensionsString.split(', ')
      let splittedFileName = file.name.split('.')
      let extension = splittedFileName[splittedFileName.length - 1]
      if ( !extensions.some(ext => ext.toLocaleLowerCase() === `.${extension.toLowerCase()}`)) {
        $notify({
          type: 'error',
          duration: -1,
          text: i18n.t('pages.taskDetails.attachments.fileUploadNotAllowedExtension', {extension: extension})
        })
         commit('SET_UPLOADING_ATTACHMENTS', false)
        let error = new Error('wrong extension')
        commit('ADD_PROCESSING_IMAGES_PROGRESS', error)
        errorReporter.reportError(error)

        return
      }
    }
    for (let file of files) {
      fileUtil.readFile({reader, commit, dispatch, file, files, service, filePath, filePaths, canEditAttachments, $notify, taskId, fileRef, allFilesArray})
    }
  },
  rotate ({dispatch, commit, state}, { uuid, event, file, service, filePath, filePaths, canEditAttachments, $notify, taskId, fileRef, rotateMode }) {
    fileUtil.processImage (event, file, {uuid, commit, dispatch, service,
      filePath,
      filePaths,
      canEditAttachments,
      $notify,
      taskId,
      fileRef,
      rotateMode,
      edit: true
    })
  },
  sendSingleToBackend:  ({dispatch, commit, state}, {uuid, data, service, filePath, $notify, taskId, categoryId, edit, ...params}) => {
      let content
      let form = new FormData()
      form.append('path', filePath)
      let name = uuid + '_' + data.filename
      try {
        content = fileUtil.b64toBlob(data.value.split(',')[1], data.type)
      } catch (e) {
        commit('INCREASE_SENDING_COUNT')
        commit('ADD_SENT_FAILED', data.filename)
        errorReporter.reportError(e)
        console.error(e)
        $notify({
          type: 'error',
          duration: -1,
          text: i18n.t('pages.taskDetails.attachments.fileBroken', {filename: data.filename})
        })
        throw e
      }
      form.append(`file[0]`, content, name)

      let requestUrl = edit ? '/upload/edit/' : '/upload/'
      api.request('storage', 'post', requestUrl + service, form)
      .then((response) => {
        commit('INCREASE_SENDING_COUNT')
        commit('ADD_SENT_SUCCESFULLY', data.filename)

        let upload = true
        if (categoryId)  {
          upload = false
        }
        if (!edit) {
          dispatch('sendAttachmentCategory', {data, service, $notify, categoryId: categoryId, task: taskId, upload: upload})
        } else {
          dispatch('fullImageDownloaded',{id: uuid, value: data.value})
          commit('UPDATE_ATTACHMENT_FULLSIZE',{id: uuid, value: data.value, fullSize: true})
        }
        if (response.data && response.data.message) {
          $notify({
            type: 'success',
            text: response.data.message
          })
      }
      })
      .catch((error) => {
        commit('INCREASE_SENDING_COUNT')
        commit('ADD_SENT_FAILED', data.filename)
        if (error.response.data.error &&  error.response.data.error.message) {
            $notify({
              type: 'error',
              duration: -1,
              text:  error.response.data.error.message
            })
        }
        errorReporter.reportError(error)
        console.error(error)
    })
  }
}
