<template>
  <form>
    <input-type
      v-model="value['label']"
      v-validate="{ rules: { required: true }}"
      name="label"
      label="Nazwa"
      :required="true"
      data-vv-as="nazwa"
      :error-message="vErrors.first('label')"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'

export default {
  components: {
    InputType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  }
}
</script>
