<script>
import BaseLisitng from '../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseLisitng
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Stany procesu', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_state',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'label', title: 'Opis' },
        { id: 1, data: 'name', title: 'Nazwa' },
        { id: 2, data: 'initial', title: 'Stan początkowy', searchable: false },
        { id: 3, data: 'final', title: 'Stan końcowy', searchable: false },
        { id: 4, data: 'roles', title: 'Role odpowiedzialego', searchable: false, sortable: false }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_${this.$route.meta.workflow.split('-')[1]}_state_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:main:addState`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/workflows/${this.$route.meta.workflow}/states` }
    }
  }
}
</script>
