<template>
  <div
    class="form-group"
    :class="[{ 'has-error': errorMessage, 'has-success' : !errorMessage && value }, { 'required-field': required }]"
  >
    <label :for="name">
      {{ label }}
    </label>
    <textarea
      :id="name"
      class="form-control"
      :name="name"
      :cols="cols"
      :rows="rows"
      :placeholder="placeholder"
      :value="value"
      @input="updateValue($event.target)"
      @change="changeValue($event.target)"
      :maxlength="maxlength"
    />
    <span
      v-show="errorMessage"
      class="help-block"
    >{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  props: {
    /** Komunikat wyświetlana podczas błędów wykrytych przez walidator */
    errorMessage: { type: null, required: true },
    /** Etykieta pola wyświetlana w widoku */
    label: { type: String, required: true },
    /** Etykieta pola wyświetlana w widoku */
    placeholder: { type: String },
    /** Maksymalna ilość wpisywanych znaków */
    maxlength: { type: String },
    /** Określa atrybuty 'id' oraz 'name' tagu html: <input id="" name=""> */
    name: { type: String, required: true },
    /** Ustawia atrybut 'required-field' dla walidatora */
    required: { type: Boolean, default: false },
    /** Określa ilość kolumn */
    cols: { type: String, default: '60' },
    /** Określa ilość wierszy */
    rows: { type: String, default: '6' },
    /** Przechowuje wartość pola formularza */
    value: null
  },
  methods: {
    /** Aktualizuje wartość parametru 'value' i emituje zdarzenie 'input' */
    updateValue (target) {
      this.$emit('input', target.value)
    },
    changeValue (target) {
      this.$emit('change', target.value)
    }
  }
}
</script>
