<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body table-responsive">
              <grid
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :default-order="[[1, 'asc']]"
                :actions="gridActions"
                @show="actionShow"
                @activate="actionActivate"
                @passwordReset="actionPasswordReset"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <new-user-modal :show="newUserVisible" @close="newUserVisible = false" />
  </div>
</template>

<script>
import api from "../../../../../api";
import Grid from "../../../share/grid/Grid";
import Page from "../../../../share/content/Page";
import NewUserModal from "./modal/NewUser";
import moment from "moment";

export default {
  components: {
    Grid,
    NewUserModal
  },
  mixins: [Page],
  data() {
    return {
      events: {
        addUser: "core:user:addUser"
      },
      headerTitle: { title: "Użytkownicy", description: "Lista" },
      newUserVisible: false,
      gridDataSource: { service: "core", url: "/users" },
      gridName: "grid_user",
      gridColumns: [
        {
          id: 0,
          data: "loggedIn",
          searchable: false,
          orderable: false,
          render(data) {
            return data !== 0
              ? '<i class="fa fa-sign-in text-green"><i>'
              : '<i class="fa fa-sign-out text-red"></i>';
          }
        },
        { id: 1, data: "forename", title: this.$t("pages.users.listing.name") },
        {
          id: 2,
          data: "surname",
          title: this.$t("pages.users.listing.surname")
        },
        {
          id: 3,
          data: "email",
          title: "E-mail",
          render(data) {
            return `<a href="mailto://${data}">${data}</a>`;
          }
        },
        {
          id: 4,
          data: "createdAt",
          title: this.$t("pages.users.listing.creationDate"),
          render(data) {
            return moment(data)
              .locale("pl")
              .format("YYYY-MM-DD kk:mm:ss");
          }
        }
      ],
      gridActions: [
        {
          id: 0,
          event: "show",
          title: "Szczegóły",
          icon: "eye",
          acl: { service: "core", action: "get_user" },
          params: ["uuid"]
        }
        // {id: 1, event: 'activate', title: 'Aktywuj', icon: 'check', acl: {service: 'core', action: 'put_user_activation'}, params: ['uuid']},
        // {id: 1, event: 'passwordReset', title: 'Resetuj hasło', icon: 'unlock-alt', acl: {service: 'core', action: 'post_password_reset_link'}, params: ['uuid']}
      ]
    };
  },
  mounted() {
    this.$events.on(this.events.addUser, this.addUser);
  },
  methods: {
    addUser() {
      this.newUserVisible = true;
    },
    actionShow(id, params) {
      if (params && params.uuid) {
        return this.$router.push({
          name: "admin_user_details",
          params: { uuid: params.uuid }
        });
      }
    },
    actionActivate(id, params) {
      if (params && params.uuid) {
        api
          .request("core", "put", `users/${params.uuid}/activation`)
          .then(() => {
            this.toggleLoading();
            this.$notify({
              type: "success",
              text: "Link aktywacyjny wysłany na adres mailowy"
            });
          })
          .catch(() => {
            this.toggleLoading();
            this.$notify({
              type: "error",
              title: "Wystąpił błąd",
              text: "Nie można wysłać linka atywacyjnego"
            });
          });
      }
    },
    actionPasswordReset(id, params) {
      if (params && params.uuid) {
        api
          .request("core", "post", "security/password-reset-link", {
            uuid: params.uuid
          })
          .then(() => {
            this.toggleLoading();
            this.$notify({
              type: "success",
              text: "Przypomnienie hasła wysłane na adres mailowy"
            });
          })
          .catch(() => {
            this.toggleLoading();
            this.$notify({
              type: "error",
              title: "Wystąpił błąd",
              text: "Nie można wysłać resetującego linka atywacyjnego"
            });
          });
      }
    }
  }
};
</script>
