<script>
import BaseLisitng from '../../../share/ListingWithEditMode'
import clientObjectMixin from '../../share/mixins/clientObjectMixin'

export default {
  mixins: [
    BaseLisitng,
    clientObjectMixin
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Stany procesu', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_state',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'label', title: 'Opis' },
        { id: 1, data: 'name', title: 'Nazwa' },
        { id: 2, data: 'initial', title: 'Stan początkowy', searchable: false, render (data) {
            return data ? 'Tak' : 'Nie'
          }
        },
        { id: 3, data: 'final', title: 'Stan końcowy', searchable: false, render (data) {
            return data ? 'Tak' : 'Nie'
          }
        }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_state_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:main:addState`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/workflows/1/states` }
    }
  }
}
</script>
