<template>
  <div
    class="form-group"
    :class="[{ 'has-error': errorMessage, 'has-success' : !errorMessage && value }, { 'required-field': required }] "
  >
    <label :for="id">
      {{ label }}
    </label>
    <select
      :id="id"
      class="form-control"
      :class="identifierClass"
      :multiple="isMultiple"
      :name="name"
      :value="value"
      :disabled="disabled"
      @change="updateValue($event.target.value)"
    >
      <!-- <option v-if="emptyFirst" disabled value=""></option> -->
      <option
        v-if="emptyFirst"
        value=""
      />
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :selected="option.value == value"
      >
        {{ option.label }}
      </option>
    </select>
    <span
      v-show="errorMessage"
      class="help-block"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    /** Komunikat wyświetlana podczas błędów wykrytych przez walidator */
    errorMessage: { type: null },
    /** Etykieta pola wyświetlana w widoku */
    label: { type: String, required: true },
    /** Określa atrybuty 'id' oraz 'name' tagu html: <input id="" name=""> */
    name: { type: String, required: true },
    /** Ustawia atrybut 'required-field' dla walidatora */
    required: { type: Boolean, default: false },
    /** Określa typ pola */
    type: { type: String, default: 'text' },
    /** Przechowuje wartość pola formularza */
    value: null,
    /** Lista opcji do wyświetlenia przez select */
    options: { type: Array, required: true },
    /** Określa typ pola select: wybór pojedynczy / wybór wielokrotny */
    isMultiple: { type: Boolean, default: false },
    /** Określa identyfikator dla wyszukiwania z zewnątrz */
    identifier: { type: String, required: false },
    /** Określa dostępność pola */
    disabled: { type: Boolean, default: false },
    emptyFirst: { type: Boolean, default: false }
  },
  computed: {
    id: function () {
      return `${this.name}-${this._uid}`
    },
    identifierClass () {
      return this.identifier || ''
    }
  },
  methods: {
    /** Aktualizuje wartość parametru 'value' i emituje zdarzenie 'input' */
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style scoped>
.form-group select.form-control {
  -moz-appearance: auto;
 -webkit-appearance: auto;
}
</style>
