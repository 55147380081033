import api from '@/api/index.js'
import { setupTotalCount } from '../setupTotalCount'

export default {
  data() {
    return {
      blsClosedTaskKey: 0,
      blsOpenTasks: {
        intgen: {
          title: 'Otwarte zlecenia',
          total: '',
          data: {
            x: 'x',
            columns: [],
            groups: []
          },
          axis: {
            x: {
              type: 'category',
              tick: {
                rotate: 60
              }
            }
          }
        },
        extavi: {
          title: 'Wszystkie zlecenia',
          total: '',
          data: {
            x: 'x',
            columns: [],
            groups: []
          },
          axis: {
            x: {
              type: 'category',
              tick: {
                rotate: 60
              }
            }
          }
        },
        extuni: {
          title: 'Wszystkie zlecenia',
          total: '',
          data: {
            x: 'x',
            columns: [],
            groups: []
          },
          axis: {
            x: {
              type: 'category',
              tick: {
                rotate: 60
              }
            }
          }
        }
      },
    }
  },
  methods: {
    loadblsOpenTasks (service, action, workflows) {
      let workflowMap = {
        main_vehicle: [this.trans[this.workflows[0]]],
        main_property: [this.trans[this.workflows[1]]]
      }
      let chartData = {
        columns: {
          categories: ['x']
        },
        groups: []
      }
      workflows.forEach(workflow => {
        chartData.columns[workflow] = workflowMap[workflow]
      })
      if (!this.isAllowed([service], action)) {
        return
      }
      workflows.forEach(w => {
        chartData.groups.push(this.trans[w])
      })

      api.request(service, 'get', `statistics/tasks/open`)
        .then((response) => {
          let data = response.data.data
          let sumValues = Object.values(data)
          .reduce((acc, curr) => {
            if (typeof curr === 'undefined') {
              return acc += 0
            } else if (typeof curr === 'number') {
              return acc += curr
            } else if ( typeof curr === 'string') {
              return acc += Number(curr)
            }
          }, 0)
          chartData.columns.categories.push(this.contractorApps[service] + ' ' + `(${sumValues})`)

          workflows.forEach(w => {
            if (data.hasOwnProperty(w)) {
              chartData.columns[w].push(data[w])
            } else {
              chartData.columns[w].push(0)
            }
          })
          this.addOpenTaskToCharts(chartData, service, action, workflows)
        })
        .catch((e) => {
          console.error(e)
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować danych do wykresu Otwarte zlecenia'
          })
        })
    },
    addOpenTaskToCharts (chartData, service, action, workflows) {
      this.blsOpenTasks.intgen.total = setupTotalCount(chartData.columns);

      this.blsOpenTasks[service].data.columns.push(chartData.columns.categories)
      workflows.forEach(workflow => this.blsOpenTasks[service].data.columns.push(chartData.columns[workflow]))
      if (this.isAllowed([service], action)) {
        let refStr = `blsOpenTasks${service}`
        this.$refs[refStr].chart.groups([chartData.groups])
      }
    },
  },
}
