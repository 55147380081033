export default {
  showCalendar: ({ commit }, payload) => {
    commit('SET_CALENDAR_VISIBILITY', payload)
  },
  getCalendarEvents: ({ commit }, { token }) => {
    let events = [
      { title: 'event 1', start: '2019-09-19 12:05', end: '2019-09-19 12:40' },
      { title: 'event 2', start: '2019-09-19 08:05', end: '2019-09-19 08:40' }
    ]
    commit('SET_CALENDAR_EVENTS', events)
  },
  handleDate: ({ commit }, { event }) => {
  },
  getStatuses ({ commit }, { token }) {
    let statuses = [
      { title: 'Rejestracja', active: false, description: 'testowy opis1' },
      { title: 'Przekazane do realizacji', active: true, description: 'testowy opis2' },
      { title: 'Umówienie oględzin', active: false, description: 'testowy opis3' },
      { title: 'Przydzielanie rzeczoznawcy', active: false, description: 'testowy opis4' }
      ]
    commit('SET_INFORMATION_STATUSES', statuses)
  },
  nextState ({commit, state}, payload) {
    let newStatuses = []
    if (payload + 1 >= state.statuses.length) {
      newStatuses = state.statuses.map((status, index) => {
        if (index === 0) {
          status.active = true
          return status
        } else {
          status.active = false
          return status
        }
      })
    } else {
      newStatuses = state.statuses.map((status, index) => {
        if (index === payload + 1) {
          status.active = true
          return status
        } else {
          status.active = false
          return status
        }
      })
    }
    commit('SET_INFORMATION_STATUSES', newStatuses)
  }
}
