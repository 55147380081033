import imageAssertions from './IsImage'
// import { image } from 'vee-validate/dist/rules.esm'
 export default {
  getThumbnails () {
    return  [
        '7z',
        'ath',
        'axx',
        'crw',
        'csv',
        'doc',
        'docx',
        'dwg',
        'eml',
        'gpg',
        'gz',
        'msg',
        'ods',
        'odt',
        'pdf',
        'pgp',
        'ppt',
        'rar',
        'rtf',
        'sig',
        'signpr',
        'signpro',
        'sko',
        'szf',
        'tar',
        'txt',
        'xades',
        'xls',
        'xlsm',
        'xlsx',
        'xml',
        'zip'
      ]
    },
   getNewSrc (data) {
    let ext = {}
      switch (data.mimeType) {
        // Documents
        case 'application/pdf':
          return '/static/img/filetypes/pdf.svg'
        case 'text/csv':
          return '/static/img/filetypes/csv.svg'
        case 'application/rtf':
        case 'application/x-rtf':
        case 'text/richtext':
          return '/static/img/filetypes/rtf.svg'
        case 'application/plain':
        case 'text/plain':
          return '/static/img/filetypes/txt.svg'
        case 'message/rfc822':
          return '/static/img/filetypes/eml.svg'
        case 'application/vnd.ms-outlook':
          return '/static/img/filetypes/msg.svg'
        case 'application/vnd.oasis.opendocument.text':
          return '/static/img/filetypes/odt.svg'
        case 'application/vnd.oasis.opendocument.spreadsheet':
          return '/static/img/filetypes/ods.svg'
        case 'application/msword':
          return '/static/img/filetypes/doc.svg'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return '/static/img/filetypes/docx.svg'
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-office':
        case 'application/excel':
        case 'application/x-excel':
        case 'application/x-msexcel':
          return '/static/img/filetypes/xls.svg'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return '/static/img/filetypes/xlsx.svg'
        case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          return '/static/img/filetypes/xlsm.svg'

        // Archives
        case 'application/x-7z-compressed':
          return '/static/img/filetypes/7z.svg'
        case 'application/zip':
        case 'application/x-zip':
        case 'application/x-zip-compressed':
        case 'application/octet-stream':
        case 'application/x-compress':
        case 'application/x-compressed':
        case 'multipart/x-zip':
          return '/static/img/filetypes/zip.svg'
        case 'application/x-rar-compressed':
          return '/static/img/filetypes/rar.svg'
        case 'application/gzip':
        case 'application/x-gzip':
        case 'application/x-gunzip':
        case 'application/gzipped':
        case 'application/gzip-compressed':
        case 'gzip/document':
          return '/static/img/filetypes/gz.svg'
        case 'application/tar':
        case 'application/x-tar':
        case 'applicaton/x-gtar':
        case 'multipart/x-tar':
          return '/static/img/filetypes/tar.svg'

        // TIFF
        case 'image/tif':
        case 'image/x-tif':
        case 'image/tiff':
        case 'image/x-tiff':
        case 'application/tif':
        case 'application/x-tif':
        case 'application/tiff':
        case 'application/x-tiff':
          return '/static/img/filetypes/tiff.svg'

        // Images
        case 'image/bmp':
        case 'image/x-bmp':
        case 'image/x-bitmap':
        case 'image/x-xbitmap':
        case 'image/x-win-bitmap':
        case 'image/x-windows-bmp':
        case 'image/ms-bmp':
        case 'image/x-ms-bmp':
        case 'application/bmp':
        case 'application/x-bmp':
        case 'application/x-win-bitmap':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/pjpeg':
        case 'image/vnd.swiftview-jpeg':
        case 'image/png':
        case 'application/png':
        case 'application/x-png':
        case 'image/gif':
          return `data:${data.mimeType};base64,${data.value}`

        // Other
        case 'application/xml':
        case 'text/xml':
          return '/static/img/filetypes/xml.svg'
        case 'application/acad':
        case 'application/x-acad':
        case 'application/autocad_dwg':
        case 'image/x-dwg':
        case 'application/dwg':
        case 'application/x-dwg':
        case 'application/x-autocad':
        case 'image/vnd.dwg':
        case 'drawing/dwg':
          return '/static/img/filetypes/dwg.svg'
        case 'image/x-canon-crw ':
          return '/static/img/filetypes/crw.svg'
        case 'application/pgp':
        case 'application/pgp-keys':
        case 'application/pgp-signature':
        case 'application/x-pgp-plugin':
        case 'application/pgp-encrypted':
          return '/static/img/filetypes/pgp.svg'

        default:
          if (data.filename.indexOf('.') === -1) {
            return '/static/img/filetypes/default.svg'
          }

          ext = data.filename.substring(data.filename.lastIndexOf('.'))

          if (imageAssertions.isImageByExtension(ext)) {
            return `data:${data.mimeType};base64,${data.value}`
          }

          if (this.getThumbnails().includes(ext)) {
            return `/static/img/filetypes/${ext}.svg`
          }

          return '/static/img/filetypes/default.svg'
      }
    },
    src (data) {
      let ext = {}
      switch (data.mimeType) {
        // Documents
        case 'application/pdf':
          return '/static/img/filetypes/pdf.svg'
        case 'text/csv':
          return '/static/img/filetypes/csv.svg'
        case 'application/rtf':
        case 'application/x-rtf':
        case 'text/richtext':
          return '/static/img/filetypes/rtf.svg'
        case 'application/plain':
        case 'text/plain':
          return '/static/img/filetypes/txt.svg'
        case 'message/rfc822':
          return '/static/img/filetypes/eml.svg'
        case 'application/vnd.ms-outlook':
          return '/static/img/filetypes/msg.svg'
        case 'application/vnd.oasis.opendocument.text':
          return '/static/img/filetypes/odt.svg'
        case 'application/vnd.oasis.opendocument.spreadsheet':
          return '/static/img/filetypes/ods.svg'
        case 'application/msword':
          return '/static/img/filetypes/doc.svg'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return '/static/img/filetypes/docx.svg'
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-office':
        case 'application/excel':
        case 'application/x-excel':
        case 'application/x-msexcel':
          return '/static/img/filetypes/xls.svg'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return '/static/img/filetypes/xlsx.svg'
        case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          return '/static/img/filetypes/xlsm.svg'

        // Archives
        case 'application/x-7z-compressed':
          return '/static/img/filetypes/7z.svg'
        case 'application/zip':
        case 'application/x-zip':
        case 'application/x-zip-compressed':
        case 'application/octet-stream':
        case 'application/x-compress':
        case 'application/x-compressed':
        case 'multipart/x-zip':
          return '/static/img/filetypes/zip.svg'
        case 'application/x-rar-compressed':
          return '/static/img/filetypes/rar.svg'
        case 'application/gzip':
        case 'application/x-gzip':
        case 'application/x-gunzip':
        case 'application/gzipped':
        case 'application/gzip-compressed':
        case 'gzip/document':
          return '/static/img/filetypes/gz.svg'
        case 'application/tar':
        case 'application/x-tar':
        case 'applicaton/x-gtar':
        case 'multipart/x-tar':
          return '/static/img/filetypes/tar.svg'

        // TIFF
        case 'image/tif':
        case 'image/x-tif':
        case 'image/tiff':
        case 'image/x-tiff':
        case 'application/tif':
        case 'application/x-tif':
        case 'application/tiff':
        case 'application/x-tiff':
          return '/static/img/filetypes/tiff.svg'

        // Images
        case 'image/bmp':
        case 'image/x-bmp':
        case 'image/x-bitmap':
        case 'image/x-xbitmap':
        case 'image/x-win-bitmap':
        case 'image/x-windows-bmp':
        case 'image/ms-bmp':
        case 'image/x-ms-bmp':
        case 'application/bmp':
        case 'application/x-bmp':
        case 'application/x-win-bitmap':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/pjpeg':
        case 'image/vnd.swiftview-jpeg':
        case 'image/png':
        case 'application/png':
        case 'application/x-png':
        case 'image/gif':
          return `data:${data.mimeType};base64,${data.value}`

        // Other
        case 'application/xml':
        case 'text/xml':
          return '/static/img/filetypes/xml.svg'
        case 'application/acad':
        case 'application/x-acad':
        case 'application/autocad_dwg':
        case 'image/x-dwg':
        case 'application/dwg':
        case 'application/x-dwg':
        case 'application/x-autocad':
        case 'image/vnd.dwg':
        case 'drawing/dwg':
          return '/static/img/filetypes/dwg.svg'
        case 'image/x-canon-crw ':
          return '/static/img/filetypes/crw.svg'
        case 'application/pgp':
        case 'application/pgp-keys':
        case 'application/pgp-signature':
        case 'application/x-pgp-plugin':
        case 'application/pgp-encrypted':
          return '/static/img/filetypes/pgp.svg'

        default:
          if (data.filename.indexOf('.') === -1) {
            return '/static/img/filetypes/default.svg'
          }

          ext = data.filename.substring(data.filename.lastIndexOf('.'))

          if (imageAssertions.isImageByExtension(ext)) {
            return `data:${data.mimeType};base64,${data.value}`
          }
          if (this.getThumbnails().includes(ext)) {
            return `/static/img/filetypes/${ext}.svg`
          }
          return '/static/img/filetypes/default.svg'
      }
    },
    fileName (data) {
      if (data.filename.length > 20) {
        return data.filename.slice(0, 20) + '...'
      } else {
        return data.filename
      }
    }
}
