<script>
export default {
  data () {
    return {
      events: {
        reload: 'dashboard:submenu:reload'
      },
      items: [],
      temporaryItems: []
    }
  },
  watch: {
    $route: function (newRoute) {
      this.items = []
      this.items = this.getItems(newRoute)
      if (newRoute.name.includes('_task_details')) {
        this.items = this.items.map(el => {
          el.visibility = false
          return el
        })
        return
      }
      return
    }
  },
  created () {
    this.items = []
    this.items = this.getItems(this.$route)

    if (this.$route.name.includes('_task_details')) {
      this.items = this.items.map(el => {
        el.visibility = false
        return el
      })
    }
  },
  methods: {
    emitEvent (itemEvent) {
      if (itemEvent.hasOwnProperty('value')) {
        this.$events.$emit(itemEvent.name, itemEvent.value)
      } else {
        this.$events.$emit(itemEvent.name)
      }
    },
    getAllowedMenuItems (menuItems) {
      if (!Array.isArray(menuItems)) {
        return []
      }

      return menuItems.filter(menuItem => {
        return (!menuItem.hasOwnProperty('acl') || this.$auth.isAllowed(menuItem.acl, {params: this.$route.params}))
      })
    }
  }
}
</script>
