<script>
import Details from '../../../share/admin/person/Details.js'

export default {
  mixins: [
    Details
  ],
  data () {
    return {
      events: null,
      service: this.$route.meta.acl.service
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.$nextTick(() => {
      this.loadContent()
    })

    this.$events.on(this.events.editPerson, this.editPerson)
  },
  methods: {
    getInitEvents () {
      this.events = {
        editPerson: `${this.service}:person:editPerson`
      }
    }
  }
}
</script>

<style scoped>
.label {
    display: inline-block;
    margin-bottom: 0.3em;
    vertical-align: middle;
}
</style>
