<template>
  <div>
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div class="row">
                  <select-type
                    class="col-sm-4 col-md-3"
                    name="role"
                    :label="'Rola'"
                    :required="false"
                    :options="roles"
                    :disabled="!rolesLoaded"
                    :empty-first="true"
                    :value="model.roleId"
                    @input="changeRole"
                  />
                </div>
                <div
                  v-if="model.roleId !== null"
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Poziomy dostępności do pól</h2>
                    <list
                      :items="model.fieldVisibilities"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <field-access-type
                          v-model="listItems.item"
                          :states="states"
                          :schema="schema"
                          :visibilities="visibilities"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />

    <login-modal
      :show="loginVisible"
      :back-url-name="`${service}_appraisal_field_access_listing`"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../../../share/content/Page'
import api from '../../../../../../api'
import ErrorNotify from '../../../../../share/mixins/ErrorNotify'
import List from '../../../../../share/form/List'
import SelectType from '../../../../../share/form/type/SelectType'
import LoginModal from '../../../../share/modal/LoginModal'
import FieldAccessType from '../../../../share/form/type/FieldAccessType'
import SaveDataInformation from '../../../../share/modal/SaveDataInformation'

export default {
  components: {
    FieldAccessType,
    SelectType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page,
    ErrorNotify
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      events: null,
      headerTitle: { title: 'Dostęp do pól: Ford', description: 'Tryb edycji' },
      schemaName: 'appraisal',
      workflowName: 'appraisal',
      model: {
        roleId: null,
        fieldVisibilities: []
      },
      isSaved: true,
      saveDataInformationVisible: false,
      previouslySelectedRole: null,
      schema: {
        groups: []
      },
      roles: [],
      rolesLoaded: false,
      states: [],
      statesLoaded: false,
      visibilities: [],
      loginVisible: false,
      allowedAccess: false
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.fieldVisibilitySubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      this.events = {
        fieldVisibilitySubmit: `${this.service}:appraisal:fieldVisibilitySubmit`
      }
    },
    init () {
      this.allowedAccess = true
      this.loadRoles()
      this.loadStates()
      this.loadVisibilities()
      this.loadSchema()
    },
    loadRoles () {
      api.request(this.service, 'get', '/roles')
        .then((response) => {
          const roles = response.data
          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({ label: roles[c].label, value: roles[c].id })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    loadStates () {
      api.request(this.service, 'get', `/workflows/${this.workflowName}/states`)
        .then((response) => {
          const states = response.data
          for (let c = 0; c < states.length; ++c) {
            this.states.push({ label: states[c].label, value: states[c].id })
          }
          this.statesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    },
    loadVisibilities () {
      this.visibilities = [
        { label: 'niewidoczne', value: 0 },
        { label: 'widoczne', value: 1 },
        { label: 'edytowalne', value: 2 },
        { label: 'obligatoryjne', value: 4 }
      ]
    },
    loadSchema () {
      api.request(this.service, 'get', `/schemas/${this.schemaName}`)
        .then((response) => {
          this.buildSchema(response.data)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Błąd ładowania listy pól formularza'
          })
        })
    },
    buildSchema (schema) {
      for (let g = 0; g < schema.groups.length; g++) {
        const group = schema.groups[g]
        let fields = []
        for (let f = 0; f < group.fields.length; f++) {
          fields.push(this.createField(group.fields[f]))
        }
        this.schema.groups.push(this.createGroup(group, fields))
      }
    },
    createGroup (group, fields) {
      let result = { fields }
      if (group.label) {
        result.label = group.label
      }
      return result
    },
    createField (fieldItem) {
      let fieldParams = {
        id: fieldItem.id,
        label: fieldItem.field.label
      }
      return fieldParams
    },
    forceToSave () {
      this.isSaved = false
    },
    changeRole (optionValue) {
      if (!this.isSaved && this.model.fieldVisibilities.length > 0) {
        document.getElementById('role').value = this.previouslySelectedRole
        this.saveDataInformationVisible = true
        return
      }

      this.model.roleId = this.previouslySelectedRole = optionValue

      this.loadFieldsVisibilities()
    },
    loadFieldsVisibilities () {
      api.request(this.service, 'get', `/schemas/${this.schemaName}/roles/${this.model.roleId}/field-visibility`)
        .then((response) => {
          this.model.fieldVisibilities = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować widoczności pól'
          })
        })
    },
    createEmptyElement () {
      return {
        id: null,
        fieldItem: { id: null },
        state: { id: null },
        visibility: null
      }
    },
    submitData () {
      api.request(this.service, 'put', `/schemas/${this.schemaName}/field-visibility`, this.createDataToSend())
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      let submitData = { fieldsVisibilities: [], role: this.model.roleId }

      for (let c = 0; c < this.model.fieldVisibilities.length; ++c) {
        submitData.fieldsVisibilities.push({
          id: this.model.fieldVisibilities[c].id,
          role: this.model.roleId,
          state: this.model.fieldVisibilities[c].state.id,
          fieldItem: this.model.fieldVisibilities[c].fieldItem.id,
          visibility: this.model.fieldVisibilities[c].visibility
        })
      }

      return submitData
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 18px
}
</style>
