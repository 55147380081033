<script>
import BaseListing from '../../../share/ListingWithEditMode'
import clientObjectMixin from '../../share/mixins/clientObjectMixin'

export default {
  mixins: [
    BaseListing,
    clientObjectMixin
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Widoczność zleceń', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_settlements_visibility',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'label', title: 'Stan' },
        { id: 1, data: 'role', title: 'Rola' },
        { id: 2, data: 'visibility', title: 'Poziom widoczności',
          render (data) {
            if(data === 0) {
              return 'Brak'
            } else if(data === 4) {
              return 'Wszystkie'
            }else if(data === 1) {
              return 'Swoje lub swojego autorstwa'
            }
          } 
        }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_task_visibility_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:main:taskVisibilityEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/settlements-categories/1/settlements-visibilities` }
    }
  }
}
</script>
