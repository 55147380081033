import api from '@/api/index.js'

export default {
  data() {
    return {
      analystVerificationLongerThanKey: 0,
      analystVerificationLongerThan:{
        title: 'Weryfikacja analityka dłużej niż',
        total: '',
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        data: {
          json: [],
          keys: {
            x: 'label',
            value: []
          },
          type: 'bar',
          groups: [],
          selection: {},
          onselected: (part, node) => {
          },
          onunselected: (slice, node) => {
          }
        },
        downloadLinkData: {
          service: 'intmil',
          type: 'analyst_verification_after_deadline',
          withDate: false,
          withAssistants: true,
          withOrWithoutRelated: true,
        },
      },
    }
  },
  methods: {
    loadAnalystVerificationLongerThan () {
      api.request('intmil', 'get', `/statistics/tasks/task-analyst-verification-after-deadline`)
        .then((response) => {
          let data = response.data

          const preparedData = this.getTasksAnalystVerificationLongerThan(data)
          const values = this.getTasksAnalystVerificationLongerThanValues(preparedData)

          this.analystVerificationLongerThanKey = Math.random() * 100

          this.analystVerificationLongerThan.data.json = preparedData
          this.analystVerificationLongerThan.data.keys.value = values
          this.analystVerificationLongerThan.data.groups = [values]

          const totalCount = Object.values(data).reduce((acc, curr) => acc + Number(curr.count), 0)
          this.analystVerificationLongerThan.total = `Łącznie: ${totalCount}`

          let assistants = preparedData.map(user => ({label: user.label, value: user.id} ))
          this.sentAnalystVeryficationLongerThanOptions = assistants
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
    getTasksAnalystVerificationLongerThan (backendData) {
      return Object.entries(backendData).map(([user, value]) => {
        return {
          label: `${user} (${value.count})`,
          id: value.value,
          ...value.assignees
        }
      })
    },
    getTasksAnalystVerificationLongerThanValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label' && key !== 'id').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
  },
}
