export default {
  loadingFinanceImport: false,
  downloadingAttachments: false,
  loadingAttachments: false,
  uploadingAttachments: false,
  changingCategory: false,
  attachments: {},
  attachmentsCache: {},
  categories: [],
  requiredCategories: [],
  numberOfFilesToDownload: 0,
  downloadedFiles: [],
  currentlyDownloadedFile: [],
  currentlyDownloadedFileAmount: 0,
  sendingToAudanet: false,
  processingImages: false,
  processingImagesCount: [],
  imagesToProcess: 0,
  sendingCount: 0,
  sentSuccesfully: [],
  sentFailed: [],
  movingAttachments: false,
  movedAttachments: []
}
