export default function (service) {
  let icons = {
    intgen: 'generali',
    extgen: 'generali',
    demofokus: 'list',
    extpko: 'list',
    extavi: 'list',
    intbls: 'list',
    extgcz: 'list',
    extshp: 'list',
    extuni: 'list',
    intmil: 'list',
    intvig: 'list',
    intvhs: 'list'
  }
  return icons.hasOwnProperty(service) ? icons[service] : 'list'
}
