<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/Model'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    mode: { type: String, required: true },
    service: { type: String, required: true }
  },
  data () {
    return {
      title: 'Dodanie modelu',
      method: { new: 'post' },
      action: { new: 'vehicles/models' }
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then((response) => {
          this.$events.$emit('reloadGrid')
          // this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Dodano model'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można dodać modelu'
          })
        })
    },
    createDataToSend () {
      return {
        label: this.model.modelLabel,
        make: this.model.make,
        vehicleType: this.model.vehicleType
      }
    }
  }
}
</script>
