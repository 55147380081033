<template>
  <modal
    v-model="isVisible"
    :title="$t(title)"
    :size="size"
    :backdrop="false"
    @show="open"
    @validation="submit($event)"
    @hide="close"
  >
    <slot />
    <div slot="footer">
      <button
        v-if="showCancelButton"
        type="button"
        class="zord-btn btn btn-default zord-btn-default"
        @click="close"
      >
        <span>{{ $t(cancelText) }}</span>
      </button>
      <button
        type="button"
        class="zord-btn btn zord-btn-primary btn-primary"
        data-action="auto-focus"
        :disabled="btnDisabled === 0 ? true : false"
        @click="beforeSubmit(true)"
      >
        <span>{{ $t(okText) }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import BaseModal from './BaseModal'

export default {
  mixins: [
    BaseModal
  ],
  props: {
    rememberModelAfterClose: { type: Boolean, default: false },
    title: { type: String, default: 'Tytuł' },
    okText: { type: String, default: 'zModal.okText.default' },
    cancelText: { type: String, default: 'zModal.cancel.default' },
    size: {type: String, default: 'md'},
    btnDisabled: {type: Number, require: false}
  },
  computed: {
    showCancelButton () {
      return this.cancelText.length !== 0
    }
  },
  methods: {
    beforeSubmit (bool) {
      this.$emit('beforeSubmit', bool)
    },
    clear () {
      if (this.rememberModelAfterClose === false) {
        this.$parent.model = { ...this.$parent.$props.formData }
        this.clearForm()
      }
    },
    clearForm () {
      // clear validation errors
      this.$parent.$refs.form.vErrors.clear()

      // injects in form data dynamic trigger to potentially clear some form data or compoments
      if (this.$parent.$refs.form) {
        this.$parent.$refs.form.$data.clear = true
        this.$nextTick(() => {
          if (this.$parent.$refs.form) {
            this.$parent.$refs.form.$data.clear = false
          }
        })
      }

    },
    open () {
      this.$emit('show')
    },
    close () {
      this.clear()
      this.beforeSubmit(false)
      this.$parent.$emit('close')
    },
    submit (validated) {
      if (validated === true) {
        this.toggleLoading()
        this.$parent.submitForm()
      }

      // needed for the next validation process to work after form being invalid
      this.beforeSubmit(false)
    }
  }
}
</script>
