import RouterFrame from '../../../../../components/share/RouterFrame.vue'
import AdminCalendarInspectionVisibilitiesListing from '../../../../../components/bundles/intgen/admin/inspection-visibility/Listing.vue'

export default function (service, name) {
  return {
    path: 'kalendarz-widocznosc',
    component: RouterFrame,
    meta: {
      title: 'Ustawienia widoczności',
      icon: 'calendar',
      menuLink: true,
      acl: {
        service: service,
        action: 'get_inspection_visibilities'
      }
    },
    children: [
      {
        path: '',
        component: AdminCalendarInspectionVisibilitiesListing,
        name: `${service}_calendar_inspection_visibilities_listing`,
        meta: {
          title: 'Ustawienia widoczności',
          acl: {
            service: service,
            action: 'get_inspection_visibilities'
          }
        }
      }
    ]
  }
}
