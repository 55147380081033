import api from '../../../api'
import errorReporter from '../utils/errorReporter'
import urlsConfig from './urlsConfig'
import i18n from '../../../translations/internationalisation'

export default {
  actionDelete: ({dispatch, commit, state}, {uuids, service, $notify}) => {
    let joinedUuids = ''
    if (Array.isArray(uuids)) {
      joinedUuids = uuids.join()
    }

    api.request('storage', 'delete', `/upload/${service}/${joinedUuids}`)
    .then((response) => {
      dispatch('deleteCategories', {joinedUuids, uuids, service, $notify})
    })
    .catch((error) => {
      errorReporter.reportError(error)
      $notify({
        type: 'error',
        title: i18n.t('error.default'),
        text: i18n.t('pages.taskDetails.attachments.fileDeleteError')
      })
    })
  },
  deleteCategories: ({dispatch, commit, state}, {joinedUuids, uuids, service, $notify}) => {
    const method = urlsConfig.getDeleteCategoriesRequest(service, joinedUuids).method
    const url = urlsConfig.getDeleteCategoriesRequest(service, joinedUuids).url
    api.request(service, method, url)
    .then((response) => {
      commit('REMOVE_ATTACHMENTS', {uuids})
      $notify({
        type: 'success',
        title: i18n.t('success.default'),
        text: response.data.message
      })
    })
    .catch((error) => {
      errorReporter.reportError(error)
      $notify({
        type: 'error',
        title: i18n.t('error.default'),
        text: i18n.t('pages.taskDetails.attachments.fileDeleteError')
      })
    })
  },
}
