import api from '@/api/index.js'

export default {
  data() {
    return {
      bwnNewTaskStatisticKey: 0,
      bwnNewTaskStatistic:{
        title: 'Nowe zlecenia',
        total: '',
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        data: {
          json: [],
          keys: {
            x: 'label',
            value: []
          },
        },
        type: 'donut',
        otherArgs: {
          donut: {
            label: {
              format: function (value, ratio, id) {
                return value;
              }
            }
          },
          tooltip: {
            format: {
              value: function (value, ratio, id, index) {
                return value;
              }
            }
          }
        },
        downloadLinkData: {
          service: 'intmil',
          type: 'bwn_new',
          withDate: false,
          withAssistants: true
        }
      },
    }
  },
  methods: {
    loadbwnNewTaskStatistic () {
      api.request('intmil', 'get', `statistics/tasks/new`)
        .then((response) => {
          let data = response.data.data

          const preparedData = this.getNewTaskStatistics(data)
          const values = this.getNewTaskValues(preparedData)

          this.bwnNewTaskStatisticKey = Math.random() * 100

          this.bwnNewTaskStatistic.data.json = preparedData
          this.bwnNewTaskStatistic.data.keys.value = values
          this.bwnNewTaskStatistic.data.groups = [values]

          const totalCount = data.reduce((acc, curr) => acc + Number(curr.count), 0)
          this.bwnNewTaskStatistic.total = `Łącznie: ${totalCount}`

          let assistants = preparedData.map(user => ({label: user.label, value: user.id} ))
          this.bwnNewTaskSelectOptions = assistants
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
    getNewTaskStatistics (backendData) {
      return backendData.map(({label, count, uuid}) => ({
        label: `${label} (${count})`,
        id: uuid,
        [label]: count
      }))
    },
    getNewTaskValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label' && key !== 'id').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
  }
}
