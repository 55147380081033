<template>
  <div v-if="showGrid">
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body table-responsive listing-grid-container">
              <grid
                v-if="client"
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                :default-order="[3, 'desc']"
                :language="$i18n.locale"
                :data-source-method="gridDataSourceMethod"
                :additional-params="$additionalParams"
                @show="actionShow"
              />
              <grid
                v-else
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                :language="$i18n.locale"
                :default-order="[3, 'desc']"
                :data-source-method="gridDataSourceMethod"
                @show="actionShow"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Modals-->
    <spreadsheet-import
      :service="gridDataSource.service"
      :show="financeImportVisible"
      @close="financeImportVisible = false"
    />
  </div>
</template>

<script>
import Grid from '../grid/tasksListGrid/TasksListGrid.vue'
import Page from '../../../share/content/Page'
import api from '../../../../api'
import SpreadsheetImport from '../finance-import/modal/SpreadsheetImport'
import moment from 'moment/moment'
import ListingSchemaMixin from './mixins/ListingSchemaMixin'
import AdditionalParamsMixin from './mixins/AdditionalParamsMixin'
import ErrorNotify from '../../../share/mixins/ErrorNotify'

export default {
  name: 'TaskListingComponent',
  components: {
    Grid,
    SpreadsheetImport
  },
  mixins: [
    ErrorNotify,
    Page,
    ListingSchemaMixin,
    AdditionalParamsMixin
  ],
  props: {
    filter: { type: Object, default () { return null } }
  },
  computed: {
    client () {
      return this.$isWithClients(this.service) ? this.$route.meta.client : false
    }
  },
  data () {
    return {
      showGrid: false,
      service: this.$route.meta.acl.service,
      events: {},
      financeImportVisible: false,
      headerTitle: { title: 'Zlecenia', description: 'Lista' },
      gridName: 'grid_task_all_common',
      gridDataSource: {},
      gridColumns: [],
      gridActions: [],
      gridDataSourceMethod: 'get'
    }
  },
  created () {
    this.getGridColumns()
  },
  mounted () {
    this.service = this.$route.meta.acl.service
    this.getEvents()
    this.getGridActions()
    this.getGridDataSource(this.service)
    this.gridDataSourceMethod = this.getGridDataSourceMethod()
    this.showGrid = true
    this.$events.on(this.events.addTask, this.addTask)
    this.$events.on(this.events.financeImport, this.showFinanceImportModal)
  },
  methods: {
    getGridDataSourceMethod () {
      return this.$isWithClients(this.service) ? 'post' : 'get'
    },
    getGridDataSource (service) {
      let urls = {
        withClients: { service: service, url: `/tasks/datatables` },
        defaultUrl: { service: service, url: `/all-tasks/${this.$route.meta.workflowName.split('_')[1]}` }
      }
      this.gridDataSource = this.$isWithClients(service) ? urls['withClients'] : urls['defaultUrl']
    },
    getGridActions () {
      this.gridActions = this.$isWithClients(this.service) ? [
        {
          id: 0,
          event: 'show',
          title: 'Szczegóły',
          icon: 'eye',
          params: ['parentId', 'supportedClass'],
          acl: {
            service: this.service,
            action: [`get_task__${this.$route.meta.workflowName}__${this.$route.meta.client}`, `get_task__appraisal__${this.$route.meta.client}`], allowOneOfActions: true
          },
          routeToItems: {
            appraisal: {
              name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_appraisal_task_details`,
              params: {id: 'parentId', appraisalTaskId: 'id', appraisalTaskCategory: 'categoryId'}
            },
            main: {
              name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_task_details`,
              params: {id: 'id'}
            }
          },
          router: () => this.$router
        }]
        : [
          {
            id: 0,
            event: 'show',
            title: 'Szczegóły',
            icon: 'eye',
            params: ['parentId', 'id', 'supportedClass'],
            acl: {
              service: this.service,
              action: [`get_${this.$route.meta.workflowName}_task`, 'get_appraisal_task'],
              allowOneOfActions: true
            },
            routeToItems: {
              appraisal: {
                name: `${this.service}_${this.$route.meta.workflowName}_appraisal_task_details`,
                params: { id: 'parentId', appraisalTaskId: 'id' }
              },
              main: {
                name: `${this.service}_${this.$route.meta.workflowName}_task_details`,
                params: { id: 'id' }
              }
            },
          router: () => this.$router
          }
        ]
    },
    getEvents () {
      this.events = {
        financeImport: `${this.service}:finance-import:spreadsheet`,
        addTask: `${this.service}:main:addTask`
      }
    },
    addTaskToServiceWithClients () {
      let data = {
        type: this.$route.meta.taskType,
        clientUuid: this.$store.state.base.user.serviceClients[this.$route.meta.acl.service].find(client => client.id === this.$route.meta.client).uuid,
        client: this.$route.meta.client
      }
      data[this.$route.meta.taskType] = true
      api.request(this.service, 'post', `/tasks`, data)
        .then((response) => {
          let location = response.headers.location.split('/')
          this.toggleLoading()
          this.actionShow(location[location.length - 1])
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można dodać zlecenia'
          })
        })
    },
    addTaskToBaseService () {
      api.request(this.service, 'post', `/tasks/${this.$route.meta.taskType}`)
        .then((response) => {
          this.toggleLoading()
          this.actionShow(response.data.id)
        })
        .catch((e) => {
          if (this.service === 'extshp') {
            this.errorNotify(e)
            return
          }
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można dodać zlecenia'
          })
        })
    },
    addTask () {
      this.$isWithClients(this.service) ? this.addTaskToServiceWithClients() : this.addTaskToBaseService()
    },
    showFinanceImportModal () {
      this.financeImportVisible = true
    },
    actionShow (id, params) {
      if (this.$isWithClients(this.service)) {
        if (params && params.parentId) {
          return this.$router.push({ name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_appraisal_task_details`, params: { id: params.parentId, appraisalTaskId: id } })
        }
        this.$router.push({ name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_task_details`, params: { id } })
      } else {
        if (params && params.parentId) {
          return this.$router.push({ name: `${this.service}_${this.$route.meta.workflowName}_appraisal_task_details`, params: { id: params.parentId, appraisalTaskId: id } })
        }
        this.$router.push({ name: `${this.service}_${this.$route.meta.workflowName}_task_details`, params: { id } })
      }
    },
    formatDate (data) {
      return moment(data).locale('pl').format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
<style>
.listing-grid-container {
  transform:rotateX(180deg)
}

.listing-grid-container > * {
  transform:rotateX(180deg)
}
</style>
