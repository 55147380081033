export default {
  data () {
    return {
      // object containing props: (routeName: label) of specific route to be dynamic
      dynamicBreadcrumbLabels: {}
    }
  },
  methods: {
    // use this function to add your custom label for given routeName
    addDynamicBreadcrumbLabel (routeName, label) {
      this.dynamicBreadcrumbLabels[`${routeName}`] = label
      this.setDynamicBreadcrumbLabels()
    },
    setDynamicBreadcrumbLabels () {
      this.$store.commit('SET_BREADCRUMB_LABEL', this.dynamicBreadcrumbLabels)
    },
    removeDynamicBreadcrumbLabels () {
      this.$store.commit('REMOVE_BREADCRUMB_LABEL', Object.keys(this.dynamicBreadcrumbLabels))
    }
  },
  beforeDestroy () {
    this.removeDynamicBreadcrumbLabels()
  }
}
