export default {
  methods: {
    getInitialChartData (workflows, trans) {
      let chartData = {
        columns: {
          categories: ['x'],
          main_vehicle: [trans[workflows[0]]],
          main_property: [trans[workflows[1]]]
        },
        groups: []
      }
      workflows.forEach(w => {
        chartData.groups.push(trans[w])
      })
      return chartData
    },
    prepareChartWithOtions(responseData, chartData, chartOptions) {
      let categories = chartOptions.categories.slice(1)
      Object.entries(responseData).forEach(item => {
        let service = item[0]
        let data = item[1]

        if (this.$isWithClients(service) === false) {
          data = this.convertToTwoDimension(service, data, categories)
        }

        this.prepareCategoryColumnsAxisLabels(chartData, chartOptions.services[service].categoryColumns)
        this.processChartByCategories(data, chartData, categories, chartOptions.services[service].categoryColumns)
      })
    },
    prepareChart (responseData, chartData, workflows, contractorApps) {
      Object.entries(responseData).forEach(item => {
        let service = item[0]
        let data = item[1]

        if (this.$isWithClients(service) === false) {
          this.processChartByContractorAppsAndWorkflows(service, data, chartData, contractorApps, workflows)
        } else {
          let clients = this.getClients(service)
          this.processChartByClients(service, data, chartData, workflows, clients)
        }
      })
    },
    prepareChartByDate (responseData, chartData, workflows, contractorApps, selectedDate) {
      Object.entries(responseData).forEach(item => {
        let service = item[0]
        let data = item[1]

        if (this.$isWithClients(service) === false) {
          this.processChartByContractorAppsAndWorkflowsAndDate(service, data, chartData, contractorApps, workflows, selectedDate)
        } else {
          let clients = this.getClients(service)
          this.processChartByClientsAndDate(service, data, chartData, workflows, clients, selectedDate)
        }
      })
    },
    prepareChartByUser (responseData, chartData, contractorApps, selectedOption) {
      Object.entries(responseData).forEach(item => {
        let service = item[0]
        let data = item[1]

        if (this.$isWithClients(service) === false) {
          this.processChartSingleClientByUser(service, data, chartData, contractorApps, selectedOption)
        } else {
          this.processChartMultiClientsByUser(service, data, chartData, selectedOption)
        }
      })
    },
    processChartByContractorAppsAndWorkflows (service, data, chartData, contractorApps, workflows) {
      let sumValues = this.sumValues(data)
      chartData.columns.categories.push(contractorApps[service] + ' ' + `(${sumValues})`)
      this.groupChartDataByWorkflow(data, chartData, workflows)
    },
    processChartByContractorAppsAndWorkflowsAndDate (service, data, chartData, contractorApps, workflows, selectedDate) {
      let sumValues = this.sumValuesByDate(data, selectedDate)
      chartData.columns.categories.push(contractorApps[service] + ' ' + `(${sumValues})`)
      this.groupChartDataByWorkflowAndProperty(data, chartData, workflows, selectedDate)
    },
    processChartByClients (service, data, chartData, workflows, clients) {
      clients.forEach(c => {
        let sumValues = this.sumValuesByKey(data, c)
        chartData.columns.categories.push(this.getClientLabel(c) + ' ' + `(${sumValues})`)
      })
      this.groupChartDataByWorkflowAndClient(data, chartData, workflows, clients)
    },
    processChartByClientsAndDate (service, data, chartData, workflows, clients, selectedDate) {
      clients.forEach(c => {
        let sumValues = this.sumValuesByKeyAndDate(data, c, selectedDate)
        chartData.columns.categories.push(this.getClientLabel(c) + ' ' + `(${sumValues})`)
      })
      this.groupChartDataByWorkflowAndClientAndProperty(data, chartData, workflows, clients, selectedDate)
    },
    processChartSingleClientByUser(service, data, chartData, contractorApps, selectedOption) {
      let client = contractorApps[service]
      Object.entries(data).forEach(([user, value], index) => {
        let userObject = {}
        userObject.name = user
        this.filterUserObject(userObject, client, value, selectedOption)
        chartData.columns.data.push(userObject)
      })
    },
    processChartMultiClientsByUser(service, data, chartData, selectedOption) {
      Object.entries(data).forEach(([user, userValue], index) => {
        Object.entries(userValue).forEach(([client, dates], clientIndex) => {
          let existingUser = chartData.columns.data.find(userObj => userObj.name === user)
          if (existingUser) {
            this.filterUserObject(existingUser, client, dates, selectedOption)
          } else {
            let existingUser = {}
            existingUser.name = user
            this.filterUserObject(existingUser, client, dates, selectedOption)
            chartData.columns.data.push(existingUser)
          }
        })
      })
    },
    processChartSingleClientByCategory (service, data, chartData, contractorApps, selectedOption) {
      let client = contractorApps[service]
      chartData.columns[service] = [client]
      Object.keys(data).forEach(category => {
        chartData.columns.categories.push(category)
      })
      Object.values(data).forEach(record => {
        chartData.columns[service].push(record)
      })
      return chartData
    },
    processChartMultiClientsByCategory(clie, chartData, data, intgenCategories) {
      Object.keys(data).forEach(category => {
        if (chartData.columns.hasOwnProperty('intgen') && !chartData.columns.categories.includes(category)) {
          chartData.columns.intgen.push(0)
          chartData.columns.categories.push(category)
        }
      })
      clie.forEach(c => {
        chartData.columns[c] = [this.getClientLabel(c)]
        intgenCategories.forEach(cat => {
          chartData.columns[c].push(0)
        })
        Object.entries(data).forEach(record => {
          if (!intgenCategories.includes(record[0])) {
            if (record[1].hasOwnProperty(c)) {
              chartData.columns[c].push(record[1][c])
            } else {
              chartData.columns[c].push(0)
            }
          } else {
            let indexOfAppraiser
            chartData.columns.categories.forEach((element, index) => {
              if (element === record[0]) {
                indexOfAppraiser = index
              }
            })
            if (record[1][c] === undefined) {
              record[1][c] = 0
            }
            chartData.columns[c].splice(indexOfAppraiser, 1, record[1][c])
          }
        })
      })
      return chartData
    },
    processChartByCategories(data, chartData, categories, categoryColumns) {
      categories.forEach(category => {
        Object.keys(categoryColumns).forEach(column => {
          if (data.hasOwnProperty(category) && data[category].hasOwnProperty(column)) {
            chartData.columns[column].push(data[category][column])
          } else {
            chartData.columns[column].push(0)
          }
        })
      })
    },
    filterUserObject(userObject, client, dates, selectedOption) {
      if (selectedOption !== 'all') {
        userObject[client] = dates[selectedOption] || 0
      } else {
        userObject[client] = Object.entries(dates).map(([key, value], index) => value).reduce((a, b) => a + b, 0)
      }
    },
    groupChartDataByWorkflow (data, chartData, workflows) {
      workflows.forEach(w => {
        if (data.hasOwnProperty(w)) {
          chartData.columns[w].push(data[w])
        } else {
          chartData.columns[w].push(0)
        }
      })
    },
    groupChartDataByWorkflowAndProperty (data, chartData, workflows, property) {
      workflows.forEach(w => {
        if (data.hasOwnProperty(w) && data[w].hasOwnProperty(property)) {
          chartData.columns[w].push(data[w][property].length !== 0 ? data[w][property] : 0)
        } else {
          chartData.columns[w].push(0)
        }
      })
    },
    groupChartDataByWorkflowAndClient (data, chartData, workflows, clients) {
      workflows.forEach(w => {
        clients.forEach(c => {
          if (data.hasOwnProperty(w) && data[w].hasOwnProperty(c)) {
            chartData.columns[w].push(data[w][c])
          } else {
            chartData.columns[w].push(0)
          }
        })
      })
    },
    groupChartDataByWorkflowAndClientAndProperty (data, chartData, workflows, clients, property) {
      workflows.forEach(w => {
        clients.forEach(c => {
          if (data.hasOwnProperty(w) && data[w].hasOwnProperty(property) && data[w][property].length !== 0 && data[w][property].hasOwnProperty(c)) {
            chartData.columns[w].push(data[w][property][c])
          } else {
            chartData.columns[w].push(0)
          }
        })
      })
    },
    sumValues (data) {
      let sumValues = Object.values(data)
        .reduce((acc, curr) => {
          if (typeof curr === 'undefined') {
            return acc += 0
          } else if (typeof curr === 'number') {
            return acc += curr
          } else if ( typeof curr === 'string') {
            return acc += Number(curr)
          }
        }, 0)
      return sumValues
    },
    sumValuesByDate (data, selectedDate) {
      let sumValues = Object.values(data)
        .map(el => el[selectedDate ?? new Date().toISOString().split('T')[0]] ?? 0)
        .reduce((acc, curr) => {
          if (typeof curr === 'undefined') {
            return acc += 0
          } else if (typeof curr === 'number') {
            return acc += curr
          } else if ( typeof curr === 'string') {
            return acc += Number(curr)
          }
        }, 0)
      return sumValues
    },
    sumValuesByKey (data, key) {
      let sumValues = Object.values(data)
        .reduce((acc, curr) => {
          if (typeof curr[key] === 'undefined') {
            return acc += 0
          } else if (typeof curr[key] === 'number') {
            return acc += curr[key]
          } else if ( typeof curr[key] === 'string') {
            return acc += Number(curr[key])
          }
        }, 0)
      return sumValues
    },
    sumValuesByKeyAndDate (data, key, selectedDate) {
      let sumValues = Object.values(data)
        .map(el => el[selectedDate ?? new Date().toISOString().split('T')[0]] ?? 0)
        .reduce((acc, curr) => {
          if (typeof curr[key] === 'undefined') {
            return acc += 0
          } else if (typeof curr[key] === 'number') {
            return acc += curr[key]
          } else if ( typeof curr[key] === 'string') {
            return acc += Number(curr[key])
          }
        }, 0)
      return sumValues
    },
    getRebuildedChartData (chartData, data) {
      let clients = {}
      let users = {}
      chartData.columns.data.forEach(el => {
        Object.entries(el).forEach(([key, value]) => {
          if (key === 'name') {
            users[value] = value
          } else {
            clients[key] = key
          }
        })
      })

      let tempStats = data
      tempStats.data.json = chartData.columns.data.map(el => {
        const values = Object.values(el).filter(item => typeof item !== 'string')
        const sumValues = values.reduce((acc,curr) => acc + curr, 0)

        return {
          ...el,
          name: `${el.name} (${sumValues})`
        }
      })
      tempStats.data.groups = [Object.keys(clients)]
      tempStats.data.keys.value = Object.keys(clients)
      tempStats.axis.x.categories = Object.keys(users)

      return tempStats
    },
    prepareCategoryColumnsAxisLabels(chartData, categoryColumns) {
      Object.entries(categoryColumns).forEach(([key, value]) => {
        chartData.columns[key] = []
        chartData.columns[key].push(value)
      })
    },
    convertToTwoDimension(service, data, categories) {
      let result = {}
      categories.forEach(category => {
        result[category] = {}
        if (data.hasOwnProperty(category)) {
          result[category][service] = data[category]
        } else {
          result[category][service] = 0
        }
      })
      return result
    },
    prepareChartOptions(responseData, clients, contractorApps) {
      let chartOptions = {'services': {}}
      Object.entries(responseData).forEach(item => {
        let service = item[0]
        let categories = {}
        let categoryColumns = {'categoryColumns': {}}
        if (this.$isWithClients(service) === false) {
          categories[service] = contractorApps[service]
        } else {
          clients[service].forEach(element => {
            categories[element.name] = element.label
          })
        }
        chartOptions.services[service] = categoryColumns
        chartOptions.services[service].categoryColumns = categories
      })
      return chartOptions
    },
    addGroup(chartData, chartOptions) {
      chartData['groups'] = []
      Object.entries(chartOptions.services).forEach(([service, serviceParam]) => {
        Object.entries(serviceParam.categoryColumns).forEach(([key, value]) => { chartData.groups.push(value) })
      })
    }
  }
}

// const reduceSum = (inputData) => {
//   inputData.reduce((acc, curr) => {
//     if (typeof curr === 'undefined') {
//       return acc += 0
//     } else if (typeof curr === 'number') {
//       return acc += curr
//     } else if ( typeof curr === 'string') {
//       return acc += Number(curr)
//     }
//   }, 0)
// }

// const reduceSumByIndex = (inputData, index) => {
//   inputData.reduce((acc, curr) => {
//     if (typeof curr[index] === 'undefined') {
//       return acc += 0
//     } else if (typeof curr[index] === 'number') {
//       return acc += curr[index]
//     } else if ( typeof curr[index] === 'string') {
//       return acc += Number(curr[index])
//     }
//   }, 0)
// }
