<template>
  <div id="app">
    <router-view class="dashboard-wrapper"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    this.$i18n.locale = 'pl'
    return {locale: 'pl'}
  },
  computed: {
    userLogged () {
      return this.$store.state.base.user !== null && this.$store.state.base.user.hasOwnProperty('uuid')
    }
  },
  mounted () {
    const lang = this.$store.state.base.user !== null && this.$store.state.base.user.locale
    this.locale = lang ? lang : navigator.language
  },
  watch: {
    locale (val) {
      this.$i18n.locale = val
    }
  }
}
</script>

<style lang="scss">
@import '../node_modules/vue-virtual-scroller/dist/vue-virtual-scroller.css';
</style>
