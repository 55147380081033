import { Modal } from 'uiv'
import Loader from '../Loader'

export default {
  components: {
    Modal
  },
  mixins: [
    Loader
  ],
  props: {
    show: { type: Boolean, required: true }
  },
  computed: {
    isVisible: {
      get: function () { return this.show },
      set: function (value) { this.emitClose() }
    }
  },
  methods: {
    open () {
      this.$emit('open')
    },
    emitClose (value) {
      if (!value) {
        this.$emit('close')
      }
    }
  }
}
