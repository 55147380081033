import Grid from './grid/Grid.vue'
import Page from '../../share/content/Page'

export default {
  template: `
    <div>
          <section class="content">
              <div class="row">
                  <div class="col-sm-12">
                      <div class="box box-default box-solid">
                          <div class="box-body table-responsive">
                              <grid :source="gridDataSource"
                                    :name="gridName"
                                    :columns="gridColumns">
                              </grid>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>`,
  components: {
    Grid
  },
  mixins: [
    Page
  ],
  mounted () {
    this.$events.on(this.editEventName, this.editMode)
  },
  beforeDestroy () {
    this.$events.off(this.editEventName, this.editMode)
  },
  methods: {
    editMode () {
      const vm = this
      this.$router.push({ name: vm.editModeUrlName, params: vm.$route.params })
    }
  }
}
