export default {
  isCallingApi: false,
  pending: false,
  isValidCurrentPassword: false,
  user: {},
  token: null,
  refreshToken: null,
  securityActions: null,
  substitutes: [],
  loadedVoivodeships: false,
  voivodeships: [],
  documentData: {},
  currentTaskSchema: {},
  currentTaskModel: {},
  breadcrumbLabels: {},
  attachmentsCache: {},
  inspectionCategories: [],
  workflow: {
    transitions: []
  }
}
