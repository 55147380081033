export default {
  TOGGLE_LOADING (state) {
    state.isCallingApi = !state.isCallingApi
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_PENDING (state, value) {
    state.pending = value
  },
  SET_VALID_CURRENT_PASSWORD (state, value) {
    state.isValidCurrentPassword = value
  },
  SET_TOKEN (state, token) {
    state.token = token
  },
  SET_REFRESHTOKEN (state, refreshToken) {
    state.refreshToken = refreshToken
  },
  SET_SECURITY_ACTIONS (state, securityActions) {
    state.securityActions = securityActions
  },
  SET_SUBSTITUTES (state, substitutes) {
    state.substitutes = substitutes
  },
  SET_LOADED_VOIVODESHIPS (state, loaded) {
    state.loadedVoivodeships = loaded
  },
  SET_VOIVODESHIPS (state, voivodeships) {
    state.voivodeships = voivodeships
  },
  SET_DOCUMENT_GENERATOR_DATA (state, documentData) {
    state.documentData = documentData
  },
  SET_CURRENT_TASK_SCHEMA (state, currentTaskSchema) {
    state.currentTaskSchema = currentTaskSchema
  },
  SET_CURRENT_TASK_MODEL (state, currentTaskModel) {
    state.currentTaskModel = currentTaskModel
  },
  SET_BREADCRUMB_LABEL (state, label) {
    state.breadcrumbLabels = Object.assign({}, state.breadcrumbLabels, label)
  },
  REMOVE_BREADCRUMB_LABEL (state, routeNames) {
    if (typeof routeNames === 'string') {
      routeNames = [routeNames]
    }

    let newState = state.breadcrumbLabels
    routeNames.forEach(routeName => {
      delete newState[routeName]
    })

    state.breadcrumbLabels = Object.assign({}, newState)
  },
  SET_ATTACHMENTS_CACHE (state, attachment) {
    if (state.attachmentsCache[attachment[0]]) {
      return
    }
    attachment[1]['timestamp'] = Date.now()
    state.attachmentsCache[attachment[0]] = attachment[1]
  },
  SET_ATTACHMENT_FULLSIZE (state, attachment) {
    let att = state.attachmentsCache
    att[attachment.id] = attachment
    att[attachment.id].value = attachment.value
    att[attachment.id]['fullSize'] = attachment.fullSize
    att[attachment.id]['timestamp'] = Date.now()
    state.attachmentsCache = att
  },
  REMOVE_OLD_CACHED_ATTACHMENTS (state) {
    let filteredCache = {}
    Object.entries(state.attachmentsCache).map(element => {
      if (Date.now() - element[1].timestamp < 7200000) {
        filteredCache[element[0]] = element[1]
      }
    })
    state.attachmentsCache = filteredCache
  },
  SET_INSPECTION_CATEGORIES (state, payload) {
    state.inspectionCategories = payload
  },
  SET_WORKFLOW_TRANSITIONS (state, payload) {
    state.workflow.transitions = payload
  }
}
