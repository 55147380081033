<template>
  <div
    class="form-group"
    :class="[{ 'has-error': errorMessage, 'has-success' : !errorMessage && value }, { 'required-field': required }]"
  >
    <div class="field-wrap">
      <label :for="id">
        {{ label }}
      </label>
      <uiv-dropdown>
        <div class="input-group date">
          <input
            class="form-control"
            type="text"
            :value="selected"
            @input="updateValue($event.target)"
          >
          <span class="input-group-addon">
            <span class="glyphicon glyphicon-calendar" />
          </span>
        </div>
        <template slot="dropdown">
          <div class="datepicker">
            <uiv-date-picker
              v-model="date"
              :today-btn="false"
              :locale="localePL"
              :week-starts-with="1"
              :width="width"
              :clear-btn="clearBtn"
              :limit-from="minDate"
              :limit-to="maxDate"
            />
          </div>
        </template>
      </uiv-dropdown>
      <span
        v-show="errorMessage"
        class="help-block"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import * as uiv from 'uiv'

Vue.use(uiv, { prefix: 'uiv' })

export default {
  props: {
    /** Komunikat wyświetlana podczas błędów wykrytych przez walidator */
    errorMessage: { type: null, required: true },
    /** Etykieta pola wyświetlana w widoku */
    label: { type: String, required: true },
    /** Określa atrybuty 'id' oraz 'name' tagu html: <input id="" name=""> */
    name: { type: String, required: true },
    /** Ustawia atrybut 'required-field' dla walidatora */
    required: { type: Boolean, default: false },
    /** Przechowuje wartość pola formularza */
    value: {type: String, default: () => ''},
    /** Pierwszy dzień tygodnia */
    weekStartsWith: { type: String, default: '1' },
    /** Szerokość modala datepickera **/
    width: { type: Number, default: 270 },
    /** Przycisk czyszczenia daty **/
    clearBtn: { type: Boolean, default: false },
    /** minDate i maxDate ograniczają klikalne daty na datepickerze **/
    minDate: {type: String, default: () => ''},
    maxDate: {type: String, default: () => ''}
  },
  data () {
    return {
      date: null,
      localePL: {
        uiv: {
          datePicker: {
            clear: 'Wyczyść',
            today: 'Dzisiaj',
            month: 'Miesiąc',
            month1: 'Styczeń',
            month2: 'Luty',
            month3: 'Marzec',
            month4: 'Kwiecień',
            month5: 'Maj',
            month6: 'Czerwiec',
            month7: 'Lipiec',
            month8: 'Sierpień',
            month9: 'Wrzesień',
            month10: 'Październik',
            month11: 'Listopad',
            month12: 'Grudzień',
            year: 'Rok',
            week1: 'Pon',
            week2: 'Wt',
            week3: 'Śr',
            week4: 'Czw',
            week5: 'Pt',
            week6: 'Sob',
            week7: 'Niedz'
          },
          timePicker: {
            am: 'AM',
            pm: 'PM'
          },
          modal: {
            cancel: 'Anuluj',
            ok: 'OK'
          },
          multiSelect: {
            placeholder: 'Wybierz...',
            filterPlaceholder: 'Wyszukaj...'
          }
        }
      }
    }
  },
  computed: {
    id: function () {
      return `${this.name}-${this._uid}`
    },
    selected: {
      get: function () {
        return this.value
      },
      set: function (value) {
        if (value !== undefined) {
          this.$emit('input', value)
        }
        this.date = value
      }
    }
  },
  watch: {
    date: function (val) {
      this.selected = val
    },
    value: function (val) {
      this.selected = val
    }
  },
  methods: {
    /** Aktualizuje wartość parametru 'value' i emituje zdarzenie 'input' */
    updateValue (target) {
      this.date = target.value
      this.$emit('input', target.value)
    }
  }
}
</script>
