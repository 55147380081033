import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-vigcrls/zlecenie-rzeczoznawcze/`
  const customBreadcrumbs = {}
  const workflow = 'appraisal'

  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  customBreadcrumbs.appraisalTask = {
    meta: {
      icon: 'settings',
      title: 'Zlecenie rzeczoznawcze'
    }
  }

  return [
    {
      path: `${pathPrefix}stany-procesu`,
      name: `${service}_appraisal_state_listing`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/state/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_states'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: 'disc',
        title: 'Lista stanów',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:addState` },
            icon: 'plus',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_state' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_appraisal_state_edit`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/state/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_state'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_state_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: `${service}:appraisal:stateSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_appraisal_state_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_states' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu`,
      name: `${service}_appraisal_workflow_listing`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/transition/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: 'git-commit',
        title: 'Lista przejść',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:transitionEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
      name: `${service}_appraisal_workflow_edit`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/transition/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_role_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_workflow_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          },
          {
            event: { name: `${service}:appraisal:transitionSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_appraisal_workflow_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen`,
      name: `${service}_appraisal_task_visibility_listing`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/task-visibility/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_task_visibilities_appraisal'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: 'eye',
        title: 'Lista widoczności zleceń',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:taskVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'post_task_visibilities_appraisal' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen/edycja`,
      name: `${service}_appraisal_task_visibility_edit`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/task-visibility/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'post_task_visibilities_appraisal'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_task_visibility_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'post_task_visibilities_appraisal' }
          },
          {
            event: { name: `${service}:appraisal:taskVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'post_task_visibilities_appraisal' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_appraisal_task_visibility_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_task_visibilities_appraisal' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-pol`,
      name: `${service}_appraisal_field_access_listing`,
      component: () => import('../../../../components/bundles/intvig/admin/field-access/Listing.vue'),
      meta: {
        workflow,
        acl: {
          service: service,
          action: 'get_appraisal_fields_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: 'check-square',
        title: 'Lista widoczności pól',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:fieldVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_appraisal_fields_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-pol/edycja`,
      name: `${service}_appraisal_field_access_edit`,
      component: () => import('../../../../components/bundles/intvig/admin/field-access/Edit.vue'),
      meta: {
        workflow,
        acl: {
          service: service,
          action: 'put_appraisal_fields_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_field_access_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_appraisal_fields_visibilities' }
          },
          {
            event: { name: `${service}:appraisal:fieldVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_appraisal_fields_visibilities' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_appraisal_field_access_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_appraisal_fields_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}wymagalnosc-kategorii`,
      name: `${service}_appraisal_attachment_category_requirement_listing`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/attachment-requirement/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_appraisal_attachment_category_requirements'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: 'image',
        title: 'Lista wymagalności kategorii',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:attachmentCategoryRequirementEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_appraisal_attachment_category_requirements' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}wymagalnosc-kategorii/edycja`,
      name: `${service}_appraisal_attachment_category_requirement_edit`,
      component: () => import('../../../../components/bundles/intvig/appraisal/admin/attachment-requirement/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_appraisal_attachment_category_requirements'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs.generali,
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_attachment_category_requirement_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_appraisal_attachment_category_requirements' }
          },
          {
            event: { name: `${service}:appraisal:attachmentCategoryRequirementSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_appraisal_attachment_category_requirements' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_appraisal_attachment_category_requirement_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_appraisal_attachment_category_requirements' }
          }
        ]
      }
    }
  ]
}
