import {SET_DOWNLOADING_ATTACHMENTS} from '@/store/attachmentsState/utils/constants.js'

export default {
  SET_ATTACHMENTS_STATE (state, payload) {
  state.attachments = payload
},
  REMOVE_ATTACHMENTS (state, {uuids}) {
    let attachments = state.attachments
    let newAttachments = {}
    Object.entries(attachments).filter(([key,value], index) => !uuids.includes(key)).forEach(([key, value], index) => newAttachments[key] = value)
    state.attachments = newAttachments
  },
  SET_CATEGORIES (state, payload) {
    state.categories = payload
  },
  SET_REQUIRED_CATEGORIES (state, payload) {
    state.requiredCategories = payload
  },
  SET_DOWNLOADED_FILES (state, payload) {
    state.downloadedFiles = payload
  },
  ADD_DOWNLOADED_FILE (state, {uuid, data}) {
    state.downloadedFiles.push(data)
  },
  ADD_CURRENTLY_DOWNLOADED_FILE (state, payload) {
    state.currentlyDownloadedFile.push(payload)
  },
  SET_CURRENTLY_DOWNLOADED_FILE (state, payload) {
    state.currentlyDownloadedFile = payload
  },
  SET_LOADING_ATTACHMENTS (state, payload) {
    state.loadingAttachments = payload
  },
  [SET_DOWNLOADING_ATTACHMENTS]: (state, payload) => {
    state.downloadingAttachments = payload
  },
  SET_NUMBER_OF_FILES_TO_DOWNLOAD (state, payload) {
    state.numberOfFilesToDownload = payload
  },
  SET_ALL_ATTACHMENTS_CACHE (state, attachment) {
    if (state.attachmentsCache[attachment[0]]) {
      return
    }
    attachment[1]['timestamp'] = Date.now()
    state.attachmentsCache[attachment[0]] = attachment[1]
  },
  REMOVE_ALL_ATTACHMENTS_CACHE (state) {
  state.attachmentsCache = {}
},
  SET_ALL_ATTACHMENTS_CACHE_FULLSIZE (state, {id, value, fullSize}) {
    if (state.attachmentsCache[id] && state.attachmentsCache[id].fullSize) {
      return
    }
    let attachment = state.attachments[id]
    attachment['timestamp'] = Date.now()
    attachment['fullSize'] = fullSize
    attachment['value'] = value
    state.attachmentsCache[id] = attachment
  },
  UPDATE_ATTACHMENT_FULLSIZE (state, {id, value, fullSize}) {
    let attachment = state.attachments[id]
    attachment['timestamp'] = Date.now()
    attachment['fullSize'] = fullSize
    attachment['value'] = value
    state.attachments[id] = attachment
  },
  SET_ATTACHMENT_CATEGORY (state, {attachmentId, categoryId}) {
    let attachment = state.attachments[attachmentId]
    attachment.category = categoryId
    state.attachments[attachmentId] = attachment
  },
  SET_ASSIGNED_CATEGORIES (state, payload) {
    let attachments = state.attachments
    Object.entries(payload).forEach(([key, value], index) => {
      attachments[key].category = value[0]
    })
    state.attachments = attachments
  },
  SET_UPLOADING_ATTACHMENTS (state, payload) {
    state.uploadingAttachments = payload
  },
  SET_CHANGING_CATEGORY (state, payload) {
    state.changingCategory = payload
  },
  SET_SENDING_TO_AUDANET (state, payload) {
    state.sendingToAudanet = payload
  },
  SET_PROCESSING_IMAGES (state, payload) {
    state.processingImages = payload
  },
  ADD_PROCESSING_IMAGES_PROGRESS (state, uuid) {
    state.processingImagesCount.push(uuid)
  },
  CLEAR_PROCESSING_IMAGES_PROGRESS (state, uuid) {
    state.processingImagesCount = []
  },
  SET_IMAGES_TO_PROCESS (state, payload) {
    state.imagesToProcess = payload
  },
  SET_SENDING_COUNT (state, payload) {
    state.sendingCount = payload
  },
  INCREASE_SENDING_COUNT (state, payload) {
    state.sendingCount = state.sendingCount + 1
  },
  ADD_SENT_SUCCESFULLY (state, payload) {
    state.sentSuccesfully.push(payload)
  },
  ADD_SENT_FAILED (state, payload) {
    state.sentFailed.push(payload)
  },
  CLEAR_SENT_SUMMARIES (state) {
    state.sentSuccesfully = []
    state.sentFailed = []
  },
  SET_UPDATING_ATTACHMENTS (state, payload) {
    state.updating = payload
  },
  SET_MOVING_ATTACHMENTS (state, payload) {
    state.movingAttachments = payload
  },
  MOVED_ATTACHMENTS (state, payload) {
    state.movedAttachments.push(payload)
  },
  SET_LOADING_FINANCE_IMPORT (state, payload) {
    state.loadingFinanceImport = payload
  },
}