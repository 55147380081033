import api from '../../../../api'
import worker from '@/web-worker/statistics/inspectionOther'
import {setupTotalCount} from '../setupTotalCount'

const GET_INSPECTION_OTHER_STATISTICS = 'GET_INSPECTION_OTHER_STATISTICS'

export default {
  data () {
    return {
      inspectionOtherServices: ['intbls', 'intgen', 'intvig', 'intvhs'],
      inspectionOtherAcl: ['get_inspection_other_appraisal_statistics'],
      inspectionOtherStatisticLoaded: false,
      inspectionOtherStatisticSelected: [],
      inspectionOtherStatistic: {
        title: 'Podzlecenia pozostałe',
        total: '',
        type: 'gauge',
        data: {
          columns: []
        },
        otherArgs: {
          gauge: {
            label: {
              format: function (value, ratio) {
                return value
              }
            },
            max: 25,
            width: 50
          },
          size: {
            height: 300
          }
        }
      }
    }
  },
  methods: {
    getAllowedInspectionOtherServices(services) {
      return this.$auth.getAllowed({ services: services, actions: this.inspectionOtherAcl, allowOneOfActions: true })
    },
    getInspectionOtherStatisticRequestPromises (services) {
      return services.map(service => api.request(service, 'get', `statistics/appraisal/inspection-other?user=${this.$store.state.base.user.uuid}`))
    },
    loadInspectionOtherStatistic (services) {
      services = this.getAllowedInspectionOtherServices(services)
      let promises = this.getInspectionOtherStatisticRequestPromises(services)
      Promise.all(promises).then(results => {
        results = results.map(result => result.data)

        worker.postMessage({message: GET_INSPECTION_OTHER_STATISTICS, data: { results: results }}).then(message => {
          this.addInspectionOtherStatisticMaxArg(message)
          this.addInspectionOtherStatisticToCharts(message)
        }).catch(error =>
          console.error(error)
        )
      })
    },
    addInspectionOtherStatisticMaxArg (chartData) {
      let max = this.inspectionOtherStatistic.otherArgs.gauge.max
      chartData.forEach((data) => {
        if (max < data[1]) {
          let end = data[1] + 25
          let tresholds = this.rangeArray(max, end, 25)
          tresholds = tresholds.filter((treshold) => {return (data[1] <= treshold)})
          max = tresholds.shift();
        }
      })
      this.inspectionOtherStatistic.otherArgs.gauge.max = max
    },
    rangeArray(start, end, step) {
      let arrayLength = Math.floor(((end - start) / step)) + 1
      let range = [...Array(arrayLength).keys()].map(x => (x * step) + start)
      return range
    },
    addInspectionOtherStatisticToCharts (chartData) {
      this.inspectionOtherStatistic.total = setupTotalCount(chartData);

      this.inspectionOtherStatistic.data.columns = chartData
      this.inspectionOtherStatisticLoaded = true
    }
  }
}
