import clients from './clients'
import role from './role'
import person from './person'
import mainProperty from './main-property'
import mainVehicle from './main-vehicle'
import appraisal from './appraisal'

export default function (service, name) {
  return [
    ...clients(service, name),
    ...role(service, name),
    ...person(service, name),
    ...mainProperty(service, name),
    ...mainVehicle(service, name),
    ...appraisal(service, name),
  ]
}
