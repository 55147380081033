export default {
  data () {
    return {
      trans: {
        main_person: 'osoba',
        main_property: 'mienie',
        main_vehicle: 'pojazd',
        my_tasks: 'Moje zlecenia',
        'my-tasks': 'moje-zlecenia'
      }
    }
  }
}
