<script>
import FormModalMixin from '../../../share/modal/FormModalMixin'
import FormModal from '../form/NewCalendarEvent'
import api from '../../../../api'
import moment from 'moment'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      events: {
        deleteBookingDateEvent: 'deleteBookingDateEvent',
        editBookingDateEvent: 'editBookingDateEvent'
      },
      title: this.$t('calendar.reserveModal.title'),
      okText: this.$t('calendar.reserveModal.okText'),
    }
  },
  watch: {
    formData (newVal) {
      this.model = newVal
      if (this.model.reserved) {
        this.okText = this.$t('calendar.reserveModal.reservedOkText')
        this.title = this.$t('calendar.reserveModal.reservedTitle')
        this.cancelText = ''
      } else {
        this.okText = this.$t('calendar.reserveModal.okText')
        this.title = this.$t('calendar.reserveModal.title')
        this.cancelText = this.$t('calendar.reserveModal.cancelText')
      }
    }
  },
  mounted () {
    this.$events.on(this.events.deleteBookingDateEvent, this.handleDeleteEvent)
    this.$events.on(this.events.editBookingDateEvent, this.handleEditEvent)
  },
  computed: {
    isAllowedToSelectAppraiser () {
      if (!this.$store.state.base.securityActions.hasOwnProperty('extpko')) {
        return false
      }
      return this.$store.state.base.securityActions['extpko'].some(el => el === 'get_business_hour_free_employees')
    },
  },
  methods: {
    handleEditEvent () {
      this.$emit('close')
      this.$emit('success')
      this.$notify({
        type: 'success',
        title: '',
        text: this.$t('calendar.reserveModal.successReservationDateChanged')
      })
      this.$emit('refetchEvents')
    },
    handleDeleteEvent (event) {
      api.request(event.contractor, 'delete', `/booking-dates/${event.bookingHourId}`)
        .then((response) => {
          this.$emit('close')
          this.$emit('success')
          this.$emit('refetchEvents')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: this.$t('error.default'),
            text: this.$t('calendar.reserveModal.errorDeletingReservation')
          })
        })
    },
    async sendVictimDataToTask (service, taskId, data) {
      return await api.request(service, 'put', `/tasks/main-vehicle/${taskId}?type=collection`, data)
    },
    async setVictimPerson (service, taskId) {
      let response = await api.request(service, 'get', `/person/${this.model.chosenPerson && this.model.chosenPerson.id}`)

      let data = {
        collectionName: 'victims',
        victims: {
          id: null,
          mainTask: taskId,
          person: this.model.chosenPerson && this.model.chosenPerson.id,
          personFullname: `${response.data.person.forename} ${response.data.person.surname} [${response.data.person.id}]`,
          victimType: null,
          contactPhone: null
        },
        propertiesChanged: ['victims[i].person']
      }
      return this.sendVictimDataToTask(service, taskId, data)
    },
    postExtgenBookingDates (id, taskId) {
      api.request(this.model.service, 'post', '/booking-dates', this.createDataToSend(id, taskId))
        .then((response) => {
          this.toggleLoading()

          this.$emit('close')
          this.$emit('success')
          if (taskId !== 0) {
            this.setVictimPerson(this.model.service, taskId)
              .then(response => {
                this.$router.push({ name: `${this.model.service}_main_vehicle_task_details`, params: { id: taskId } })
              })
          } else {
            this.$emit('refetchEvents')
          }
          this.$notify({
            type: 'success',
            text: this.$t('calendar.reserveModal.reservationSuccess')
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: error.response.data.error.message
          })
        })
    },
    initSendExtgenData (id, taskId) {
      let taskData = {
        assigneeUuid: this.formData.appraiser,
        startedAt: moment(this.model.date.startDate).format('YYYY-MM-DD HH:mm')
      }
      if (!taskId && this.model.service === 'extpko' && this.isAllowedToSelectAppraiser) {
        if (this.model.selectedAvailableAppraiser) {
          taskData.assigneeUuid = this.model.selectedAvailableAppraiser.uuid
        } else {
          this.$notify({
            type: 'error',
            title: '',
            text: this.$t('calendar.reserveModal.addAppraiser')
          })
          return
        }
      }

      api.request(this.model.service, 'post', `/tasks/main-vehicle`, taskData)
        .then((response) => {
          this.toggleLoading()
          taskId = response.data.id
          this.postExtgenBookingDates(id, taskId)
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: this.$t('calendar.reserveModal.addTaskError')
          })
        })
    },
    postBookingDates (id) {
      let taskId = 0
      let services = ['extgen', 'extavi', 'extpko', 'demofokus', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp', 'extuni']
      if (services.indexOf(this.model.service) > -1) {
        this.initSendExtgenData(id, taskId)
      } else {
        api.request(this.model.service, 'post', '/booking-dates', this.createDataToSend(id, taskId))
          .then((response) => {
            this.toggleLoading()

            this.$emit('close')
            this.$emit('success')
            this.$emit('refetchEvents')
            this.$notify({
              type: 'success',
              text: this.$t('calendar.reserveModal.reservationSuccess')
            })
          })
          .catch((error) => {
            this.toggleLoading()
            this.$notify({
              type: 'error',
              text: error.response.data.error.message
            })
          })
      }
    },
    submitForm () {
      if (this.model.reserved) {
        this.$emit('close')
      } else {
        if (this.model.chosenPerson && this.model.chosenPerson.id) {
          this.postBookingDates()
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('calendar.reserveModal.addAppraiser')
          })
        }
      }
    },
    createDataToSend (id, taskId) {
      if (taskId !== 0) {
        let data =  {
          victim: this.model.chosenPerson && this.model.chosenPerson.id,
          startAt: this.model.date.startDate,
          endAt: this.model.date.endDate,
          businessHour: id || this.model.businessHourId,
          task: taskId
        }
        if (this.model.selectedAvailableAppraiser) {
          data.businessHour = this.model.selectedAvailableAppraiser.businessHourId
        }
        return data
      }
      return {
        victim: this.model.chosenPerson && this.model.chosenPerson.id,
        startAt: this.model.date.startDate,
        endAt: this.model.date.endDate,
        businessHour: id || this.model.businessHourId
      }
    }
  }
}
</script>
