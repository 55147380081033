import Vue from 'vue'
import IdleVue from 'idle-vue'
import store from '../store'
import config from '../../public'

const eventsHub = new Vue()

var idleOptions = {
  eventEmitter: eventsHub,
  store,
  events: config.app.idleVue.events,
  idleTime: config.app.idleVue.idleTime,
  keepTracking: config.app.idleVue.keepTracking,
  startAtIdle: config.app.idleVue.startAtIdle
}

export default {
  idleVue: IdleVue,
  idleOptions: idleOptions
}
