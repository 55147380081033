import RouterFrame from '../../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen

export default function (service, name) {
  return {
    path: 'przypisywanie-kategorii-zlecen',
    component: RouterFrame,
    meta: {
      title: 'Przypisywanie kategorii zleceń do ról',
      icon: 'shield',
      menuLink: true,
      acl: {
        service: service,
        action: 'put_task_categories'
      }
    },
    children: [
      {
        path: '',
        component: 'asdsa',
        name: `${service}_task_category_role_listing`,
        meta: {
          title: 'Przypisywanie kategorii zleceń do ról',
          acl: {
            service: service,
            action: 'put_task_categories'
          }
        }
      }
    ]
  }
}
