// Bundles: Internal/Gen

const pathPrefix = 'administracja-procesami-generalicrls/'
const customBreadcrumbs = {
  generali: {
    meta: {
      icon: 'generali',
      title: 'Generali CRLS'
    }
  }
}

export default [
  {
    path: `${pathPrefix}person`,
    name: 'intgen_person_listing',
    component: () => import('../../../components/bundles/intgen/admin/person/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_persons'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali
        ]
      },
      icon: 'shield',
      title: 'Lista poszkodowanych',
      secondMenu: [
        {
          event: { name: 'intgen:person:addPerson' },
          icon: 'plus',
          label: 'Dodaj osobę',
          acl: { service: 'intgen', action: 'post_person' } }
      ]
    }
  },
  {
    path: `${pathPrefix}person/:id`,
    name: 'intgen_person_details',
    component: () => import('../../../components/bundles/intgen/admin/person/Details.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_person'
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [
          customBreadcrumbs.generali,
          'intgen_person_listing'
        ]
      },
      title: 'Szczegóły poszkodowanego',
      secondMenu: [
        {
          event: { name: 'intgen:person:editPerson' },
          icon: 'edit',
          label: 'Edytuj dane',
          acl: { service: 'intgen', action: 'put_person' }
        }
      ]
    }
  }
]
