import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const workflow = 'main-vehicle'
  const pathPrefix = `/administracja-procesami-vigcrls/proces-glowny-pojazd/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  customBreadcrumbs.mainVehicleTask = {
    meta: {
      icon: 'settings',
      title: 'Proces główny - pojazd'
    }
  }

  return [
    {
      path: `${pathPrefix}stany-procesu`,
      name: `${service}_vehicle_state_listing`,
      component: () => import('../../../../components/bundles/intvig/admin/state/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_states'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask
          ]
        },
        workflow,
        icon: 'disc',
        title: 'Lista stanów',
        secondMenu: [
          {
            event: { name: `${service}:main:addState` },
            icon: 'plus',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_state' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_vehicle_state_edit`,
      component: () => import('../../../../components/bundles/intvig/admin/state/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_state'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask,
            `${service}_vehicle_state_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: `${service}:main:stateSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_vehicle_state_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_states' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_vehicle_state_edit`,
      component: () => import('../../../../components/bundles/intvig/admin/state/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_state'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask,
            `${service}_vehicle_state_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: `${service}:main:stateSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_vehicle_state_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_states' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu`,
      name: `${service}_vehicle_transition_listing`,
      component: () => import('../../../../components/bundles/intvig/admin/transition/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask
          ]
        },
        workflow,
        icon: 'git-commit',
        title: 'Lista przejść',
        secondMenu: [
          {
            event: { name: `${service}:main:transitionEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
      name: `${service}_vehicle_transition_edit`,
      component: () => import('../../../../components/bundles/intvig/admin/transition/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_role_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask,
            `${service}_vehicle_transition_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          },
          {
            event: { name: `${service}:main:transitionSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_vehicle_transition_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen`,
      name: `${service}_vehicle_task_visibility_listing`,
      component: () => import('../../../../components/bundles/intvig/admin/task-visibility/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_task_visibilities_vehicle'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask
          ]
        },
        workflow,
        icon: 'eye',
        title: 'Lista widoczności zleceń',
        secondMenu: [
          {
            event: { name: `${service}:main:taskVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'post_task_visibilities_vehicle' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen/edycja`,
      name: `${service}_vehicle_task_visibility_edit`,
      component: () => import('../../../../components/bundles/intvig/admin/task-visibility/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'post_task_visibilities_vehicle'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask,
            `${service}_vehicle_task_visibility_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'post_task_visibilities_vehicle' }
          },
          {
            event: { name: `${service}:main:taskVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'post_task_visibilities_vehicle' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_vehicle_task_visibility_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_task_visibilities_vehicle' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-pol`,
      name: `${service}_vehicle_field_access_listing`,
      component: () => import('../../../../components/bundles/intvig/admin/field-access/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_main-vehicle_fields_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask
          ]
        },
        workflow,
        icon: 'check-square',
        title: 'Lista widoczności pól',
        secondMenu: [
          {
            event: { name: `${service}:main:fieldVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_main-vehicle_fields_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-pol/edycja`,
      name: `${service}_vehicle_field_access_edit`,
      component: () => import('../../../../components/bundles/intvig/admin/field-access/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_main-vehicle_fields_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainVehicleTask,
            `${service}_vehicle_field_access_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_main-vehicle_fields_visibilities' }
          },
          {
            event: { name: `${service}:main:fieldVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_main-vehicle_fields_visibilities' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_vehicle_field_access_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_main-vehicle_fields_visibilities' }
          }
        ]
      }
    }
  ]
}
