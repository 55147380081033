<template>
  <div
    v-if="show"
    id="blocking-loader"
  >
    <div id="blocking-loader--content">
      <img
        class="blocking-loader--img"
        src="/static/img/zordon-loader.svg"
        alt="Loading"
      >
      <p><slot>Trwa ładowanie.Proszę czekać.</slot></p>
      <div
        v-if="type === 'download'"
        style="width: 30%"
      >
        <p
          v-if="type === 'download'"
          class="progress-bar-text"
        >
          {{ progress }}%
        </p>
        <div
          style=""
          :style="{width: progress + '%'}"
          class="progress-bar-loader"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, required: true },
    progress: { required: false },
    type: { required: false }
  }
}
</script>

<style scoped>
#blocking-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 42, 60, 0.8);
  text-align: center;
  z-index: 9999;
}

#blocking-loader--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 200px auto 0;

}
#blocking-loader--content  p {
  font-size: 18px;
  color: #e7e7e7;
  text-transform: uppercase;
  margin-top: 50px;
}

.blocking-loader--img {
  animation: 1s spin ease infinite;
  width: 160px;
}

@keyframes spin {
  from {
    transform: rotate(-60deg);
  }
  to {
    transform: rotate(300deg);
  }
}
.progress-bar-text {
  display: flex;
  position: relative;
  justify-content: center;
  top: 3.3rem
}
.progress-bar-loader {
  height: 20px;
  width: 0;
  background-color: #42a5ff;
}
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right   top;
  }
  100% {
    background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
  }
}
</style>
