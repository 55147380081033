// import en from './en/en'
import pl from './pl/pl'
import cs from './cs/cs'
import sk from './sk/sk'
import en from './en/en'
export default {
  pl: pl,
  cs: cs,
  sk: sk,
  en: en
}
