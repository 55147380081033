// Bundles: Internal/Gen
import statistics from './statistics.js'

const taskType = 'main-person'
const workflowName = 'main_person'
const routePrefix = `intgen_${workflowName}`
const customBreadcrumbs = {
  taskListing: {
    meta: {
      icon: 'list',
      title: 'menu.main.list.name'
    }
  }
}

export default [
  {
    path: 'zlecenia/generalicrls-osoba',
    name: `${routePrefix}_task_listing`,
    component: () => import(/* webpackChunkName: "task-listing" */ `../../components/bundles/share/tasksListing/Listing.vue`),
    meta: {
      acl: { service: 'intgen', action: 'get_main_person_tasks' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing
        ]
      },
      icon: 'generali',
      taskType: taskType,
      workflowName,
      title: 'Generali CRLS - osoba',
      secondMenu: [
        {
          event: { name: 'intgen:main:addTask' },
          icon: 'plus',
          label: 'Dodaj zlecenie',
          acl: { service: 'intgen', action: 'post_main_person_task' }
        },
        {
          event: { name: 'intgen:finance-import:spreadsheet' },
          icon: 'pocket',
          label: 'Importuj dokumenty finansowe',
          acl: { service: 'intgen', action: 'post_finance_import' }
        }
      ]
    }
  },
  {
    path: 'zlecenia/generalicrls-osoba/:id',
    component: () => import(/* webpackChunkName: "task-details" */ `../../components/bundles/intgen/MainTask/Details.vue`),
    name: `${routePrefix}_task_details`,
    meta: {
      acl: { service: 'intgen', action: 'get_main_person_task' },
      breadcrumb: {
        dynamicLabel: true,
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`
        ]
      },
      taskType: taskType,
      workflowName,
      title: 'Szczegóły zlecenia',
      secondMenu: [
        {
          event: { name: 'intgen:main:changeState' },
          icon: 'skip-forward',
          label: 'Zmień status',
          acl: { service: 'intgen', action: 'put_main-person_task_next_state' }
        },
        {
          event: { name: 'intgen:main:changeAssignee' },
          icon: 'user-plus',
          label: 'Zmień opiekuna',
          acl: { service: 'intgen', action: 'put_task' }
        },
        {
          event: { name: 'intgen:main:addComment' },
          icon: 'align-left',
          label: 'Dodaj komentarz',
          acl: {
            service: 'intgen',
            action: [
              'post_comment_main_internal',
              'post_comment_main_private',
              'post_comment_main_public'
            ],
            allowOneOfActions: true
          }
        }
      ]
    }
  },
  {
    path: 'zlecenia/generalicrls-osoba/:id/wyplaty/:paymentTaskId',
    name: `${routePrefix}_payment_task_details`,
    component: () => import('../../components/bundles/intgen/main-person/payment/Details'),
    meta: {
      acl: { service: 'intgen', action: 'get_task_main_payment' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`,
          `${routePrefix}_task_details`
        ]
      },
      workflowName,
      title: 'Szczegóły wypłaty',
      secondMenu: [
        {
          event: { name: 'dashboard:menu:redirect', value: `${routePrefix}_task_details` },
          icon: 'arrow-left',
          label: 'Wróć do zlecenia',
          acl: { service: 'intgen', action: 'put_main_person_task' }
        }
      ]
    }
  },
  {
    path: 'zlecenia/generalicrls-osoba/:id/generator-dokumentow',
    name: `${routePrefix}_document_generator`,
    component: () => import('../../components/bundles/share/generateDocument/GenerateDocumentEditor'),
    meta: {
      acl: { service: 'intgen', action: 'get_documents_templates' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`,
          `${routePrefix}_task_details`
        ]
      },
      workflowName,
      taskType: '',
      title: 'Generator Dokumentów',
      secondMenu: [
        {
          event: { name: 'intgen:main:generateDocument' },
          icon: 'filepdf',
          label: 'Zapisz dokument',
          acl: { service: 'intgen', action: 'put_main_person_task' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: `${routePrefix}_task_details` },
          icon: 'arrow-left',
          label: 'Wróć do szczegółów',
          acl: { service: 'intgen', action: 'put_main_person_task' }
        }
      ]
    }
  },
  {
    path: 'zlecenia/generalicrls-osoba/:id/zlecenia-rzeczoznawcze/:appraisalTaskId',
    component: () => import('../../components/bundles/intgen/appraisal/task/Details'),
    name: `${routePrefix}_appraisal_task_details`,
    meta: {
      acl: { service: 'intgen', action: 'get_appraisal_task' },
      breadcrumb: {
        dynamicLabel: true,
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`,
          `${routePrefix}_task_details`
        ]
      },
      workflowName,
      taskType: 'appraisal',
      title: 'Szczegóły zlecenia rzeczoznawczego',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:changeState' },
          icon: 'skip-forward',
          label: 'Zmień status',
          acl: { service: 'intgen', action: 'put_appraisal_task_next_state' }
        },
        {
          event: { name: 'intgen:appraisal:changeAssignee' },
          icon: 'user-plus',
          label: 'Zmień opiekuna',
          acl: { service: 'intgen', action: 'put_task' }
        },
        {
          event: { name: 'intgen:appraisal:addComment' },
          icon: 'align-left',
          label: 'Dodaj komentarz',
          acl: {
            service: 'intgen',
            action: [
              'post_comment_appraisal_internal',
              'post_comment_appraisal_private',
              'post_comment_appraisal_public'
            ],
            allowOneOfActions: true
          }
        }//,
        // {
        //   event: { name: 'intgen:appraisal:sendFocusSms' },
        //   icon: 'send',
        //   label: 'Fokus sms',
        //   acl: { service: 'intgen', action: 'post_focus_sms' }
        // }
        // {
        //   event: {name: 'intgen:appraisal:generateDocument'},
        //   icon: 'filepdf',
        //   label: 'Generuj dokument rzeczoznawczy',
        //   acl: {service: 'intgen', action: 'put_appraisal_task'}
        // }
      ]
    }
  },
  {
    path: 'zlecenia/generali-osoba/:id/zlecenia-rzeczoznawcze/:appraisalTaskId/generator-dokumentow-rzeczoznawczych',
    name: `${routePrefix}_appraisal_document_generator`,
    component: () => import('../../components/bundles/share/generateDocument/GenerateDocumentEditor'),
    meta: {
      acl: { service: 'intgen' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`,
          `${routePrefix}_task_details`,
          `${routePrefix}_appraisal_task_details`
        ]
      },
      workflowName,
      taskType: 'appraisal',
      title: 'Generator Dokumentów',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:generateDocument' },
          icon: 'filepdf',
          label: 'Zapisz dokument',
          acl: { service: 'intgen', action: 'put_appraisal_task' }
        },
        {
          event: {
            name: 'dashboard:menu:redirect',
            value: `${routePrefix}_appraisal_task_details`
          },
          icon: 'arrow-left',
          label: 'Wróć do szczegółów',
          acl: { service: 'intgen', action: 'put_appraisal_task' }
        }
      ]
    }
  },
  {
    path: 'zlecenia/generalicrls-osoba/import-finansowy/zlecenie-glowne',
    name: `${routePrefix}_finance_import_invoice_main`,
    component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsMain'),
    meta: {
      acl: { service: 'intgen', action: 'post_finance_import' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`
        ]
      },
      workflowName,
      title: 'Import danych finansowych',
      secondMenu: [
        {
          event: { name: 'intgen:finance-import:main' },
          icon: 'file',
          label: 'Importuj dokumenty',
          acl: { service: 'intgen', action: 'post_finance_import' }
        },
        {
          event: {
            name: 'dashboard:menu:redirect',
            value: `${routePrefix}_task_listing`
          },
          icon: 'arrow-left',
          label: 'Wróć do listy zleceń',
          acl: { service: 'intgen', action: 'get_tasks' }
        }
      ]
    },
    children: [
      {
        path: 'podsumowanie',
        name: `${routePrefix}_finance_import_invoice_main_summary`,
        component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsMain'),
        meta: {
          acl: { service: 'intgen', action: 'post_finance_import' },
          breadcrumb: {
            list: [
              customBreadcrumbs.taskListing,
              `${routePrefix}_task_listing`
            ]
          },
          title: 'Import danych finansowych - Podsumowanie',
          workflowName,
          secondMenu: [
            {
              event: {
                name: 'dashboard:menu:redirect',
                value: `${routePrefix}_task_listing`
              },
              icon: 'arrow-left',
              label: 'Wróć do listy zleceń',
              acl: { service: 'intgen', action: 'get_tasks' }
            }
          ]
        }
      }
    ]
  },
  {
    path: 'zlecenia/generalicrls-osoba/import-finansowy/zlecenie-rzeczoznawcze',
    name: `${routePrefix}_finance_import_invoice_appraisal`,
    component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsAppraisal'),
    meta: {
      acl: { service: 'intgen', action: 'post_finance_import' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`
        ]
      },
      workflowName,
      title: 'Import danych finansowych',
      secondMenu: [
        {
          event: { name: 'intgen:finance-import:appraisal' },
          icon: 'file',
          label: 'Importuj dokumenty',
          acl: { service: 'intgen', action: 'post_finance_import' }
        },
        {
          event: {
            name: 'dashboard:menu:redirect',
            value: `${routePrefix}_task_listing`
          },
          icon: 'arrow-left',
          label: 'Wróć do szczegółów',
          acl: { service: 'intgen', action: 'get_tasks' }
        }
      ]
    },
    children: [
      {
        path: 'podsumowanie',
        name: `${routePrefix}_finance_import_invoice_appraisal_summary`,
        component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsAppraisal'),
        meta: {
          acl: { service: 'intgen', action: 'post_finance_import' },
          breadcrumb: {
            list: [
              customBreadcrumbs.taskListing,
              `${routePrefix}_task_listing`
            ]
          },
          workflowName: workflowName,
          title: 'Import danych finansowych - Podsumowanie',
          secondMenu: [
            {
              event: {
                name: 'dashboard:menu:redirect',
                value: `${routePrefix}_task_listing`
              },
              icon: 'arrow-left',
              label: 'Wróć do listy zleceń',
              acl: { service: 'intgen', action: 'get_tasks' }
            }
          ]
        }
      }
    ]
  },
  ...statistics
]
