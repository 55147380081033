<script>
import BaseListing from './Listing'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      gridName: 'grid_vehicle',
      headerTitle: { title: 'Pojazdy', description: 'Lista' },
      service: 'core'
    }
  }
}
</script>
