<template>
  <div :class="`col-sm-${size}`">
    <div class="chart">
      <h3 v-text="title"/>
      <div ref="root">
        <download-report
          :with-date="downloadLinkData.withDate"
          :with-clients="downloadLinkData.withClients"
          @submitData="downloadReport"
          :clients="clients"
          :selected-client="selectedClient"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../../api'
// import {cloneDeep, defaultsDeep} from 'lodash'
import DownloadReport from '../form/DownloadReport'
import moment from 'moment'
import DownloadBlobFromBase64 from '@/components/share/mixins/DownloadBlobFromBase64.js'

export default {
  components: {
    DownloadReport
  },
  mixins: [
    DownloadBlobFromBase64
  ],
  props: {
    title: {
      type: String,
      default: 'Chart'
    },
    size: {
      type: Number,
      default: () => 12
    },
    downloadLinkData: {
      type: Object,
      default: () => (null)
    },
    withUserUuid: {
      type: Boolean,
      default: () => (false)
    },
    clients: {
      type: Array,
      default: () => []
    },
    selectedClient: {
      type: String,
      default: () => ('')
    }
  },
  computed: {},
  watch: {},
  mounted () {
  },
  methods: {
    downloadReport (dates = null) {
      let urlParts = this.downloadLinkData
      if (dates !== null) {
        Object.assign(urlParts, dates)
      }
      let urlDatePart = ''
      let filenameDatePart = moment().format('YYYY-MM-DD')
      if (urlParts.type !== 'open') {
        urlDatePart = `&dateFrom=${urlParts.dateFrom}`
        filenameDatePart = `${urlParts.dateFrom}`
        if (urlParts.dateTo !== null) {
          urlDatePart += `&dateTo=${urlParts.dateTo}`
          filenameDatePart = `from_${urlParts.dateFrom}_to_${urlParts.dateTo}`
        }
      }
      let urlClientPart = ''
      if (urlParts.withClients) {
        urlClientPart = `&client=${urlParts.client}`
      }

      let userUuidPart = `&user=${this.$store.state.base.user.uuid}`

      api.request('storage', 'get', `/download/${urlParts.service}/report?type=${urlParts.type}${urlDatePart}${urlClientPart}${this.withUserUuid ? userUuidPart : ''}`)
        .then((response) => {
          if (response.data.hasOwnProperty('message')) {
            this.$notify({
              type: 'info',
              title: 'Informacja',
              text: response.data.message
            })
          } else {
            let file = response.data
            this.downloadBlob(this.base64toBlob(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'), `${urlParts.type}_tasks_report_${filenameDatePart}.xlsx`)
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać pliku'
          })
        })
    }
  }
}
</script>
