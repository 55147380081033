<template>
  <div :class="`col-sm-${size}`">
    <div class="chart">
      <div class="header-wrapper">
        <div class="title-wrapper">
          <h3 v-text="title" />
          <h4 v-if="subTitle" v-text="subTitle" />
        </div>
        <div class="selects-wrapper">
          <chart-select
            v-if="select && select.onChange"
            :value="select.value"
            :onChange="select.onChange"
            :options="select.options"
          />
        </div>
        <div class="buttons-wrapper">
          <button
            v-if="withDownload && !(downloadLinkData.withDate || downloadLinkData.withClients)"
            class="zord-btn btn zord-btn-primary btn-primary statistics-download-btn"
            @click="downloadReport(null)"
          >
            <i class="feather-download" />
          </button>
          <!-- TODO: find better solution for visibility of both buttons (full and partial reports)... -->
          <button
            v-if="withDownload && ( downloadLinkData.withAssistants || downloadLinkData.withAssistantsOrVoivodeships || downloadLinkData.withAssistantsOrStatus || downloadLinkData.withVoivodeships )"
            class="zord-btn btn zord-btn-primary btn-primary statistics-download-btn statistics-download-withAssistantsOrVoivodeships-btn"
            @click="downloadReport">
            <i class="feather-download" />
          </button>
          <dropdown
            v-if="withDownload && (downloadLinkData.withDate || downloadLinkData.withClients || downloadLinkData.withAssistants || downloadLinkData.withAssistantsOrVoivodeships || downloadLinkData.withVoivodeships || downloadLinkData.withAssistantsOrStatus)"
            class="statistics-details-btn"
            label-type="icon"
            button-label="download"
          >
            <download-report
              :with-clients="downloadLinkData.withClients"
              :with-assistants="downloadLinkData.withAssistants"
              :with-date="downloadLinkData.withDate"
              :with-categories="downloadLinkData.withCategories"
              :with-assistants-or-voivodeships="downloadLinkData.withAssistantsOrVoivodeships"
              :with-voivodeships="downloadLinkData.withVoivodeships"
              :with-assistants-or-status="downloadLinkData.withAssistantsOrStatus"
              @submitData="downloadReport"
              :clients="clients"
              :assistants="assistants"
              :assistantsOrVoivodeships="assistantsOrVoivodeships"
              :voivodeships="voivodeships"
              :assistantsOrStatus="assistantsOrStatus"
              :selected-assistant="selectedAssistant"
              :value="select ? select.value : ''"
            />
          </dropdown>
        </div>
      </div>
      <div ref="root" />
    </div>
  </div>
</template>

<script>
import api from '../../../../../api'
import c3 from 'c3'
import * as d3 from "d3";
import { cloneDeep, defaultsDeep } from 'lodash'
import Dropdown from '../../../../share/dropdown/Dropdown'
import DownloadReport from '../form/DownloadReport'
import moment from 'moment'
import ChartSelect from './select/ChartSelect.vue'

export default {
  components: {
    Dropdown,
    DownloadReport,
    ChartSelect,
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    },
    total: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: () => 'bar'
    },
    title: {
      type: String,
      default: () => 'Chart'
    },
    otherArgs: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: Number,
      default: () => 12
    },
    groups: {
      type: Array,
      default: () => ([])
    },
    types: {
      type: Object,
      default: () => ({})
    },
    axis: {
      type: Object,
      default: () => ({})
    },
    downloadLinkData: {
      type: Object,
      default: () => (null)
    },
    partialReport: {
      type: String,
      default: () => (null)
    },
    select: {
      type: Object,
    },
    subTitle: {
      type: String,
      default: ''
    },
    withUserUuid: {
      type: Boolean,
      default: () => (false)
    },
    clients: {
      type: Array,
      default: () => []
    },
    assistants: {
      type: Array,
      default: () => []
    },
    assistantsOrVoivodeships: {
    type: Array,
    default: () => []
    },
    voivodeships: {
    type: Array,
    default: () => []
    },
    assistantsOrStatus: {
    type: Array,
    default: () => []
    },
    selectedAssistant: {
      type: String,
      default: () => ('')
    }
  },
  computed: {
    withDownload: function () {
      if (this.downloadLinkData !== null) {
        return true
      }
      return false
    }
  },
  watch: {
    data: {
      handler: 'reload',
      deep: true
    },
    axis: {
      handler: 'updateCategories',
      deep: true
    }
  },
  mounted () {
    this.loadChart()
  },
  methods: {
    loadChart() {
      const args = this.getArgs()
      this.args = args
      this.chart = c3.generate({
        bindto: this.$refs.root,
        ...args
      })
    },
    getArgs () {
      const data = this.getData()
      const config = this.getConfig()
      const otherArgs = this.getOtherArgs()
      // fix dla uciętych etykiet
      const padding = {
        right: 30,
        bottom: 8
      }
      return defaultsDeep({ data, padding }, otherArgs, config)
    },
    getData () {
      const { type } = this
      const data = this.data
      return defaultsDeep({ type }, data)
    },
    getConfig () {
      const config = cloneDeep(this.config)
      let color = {
        pattern: ['#527299', '#42a5ff', '#6cd0fc', '#f79696', '#6ac13b', '#cda735', '#5e7995', '#788195']
      }
      if (this.type === 'gauge') {
        color = {}
      }
      let axis = this.axis
      let timeseriesCharts = ['bar', 'line']
      if ((Object.keys(axis).length === 0 && axis.constructor === Object) && timeseriesCharts.includes(this.type)) {
        axis = {
          x: {
            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d',
              rotate: 60
            }
          },
          y: {
            min: 0,
            tick: {
              format: d3.format('d')
            },
            padding: { bottom: 0 }
          }
        }
      }

      return defaultsDeep({ axis, color }, config)
    },
    getOtherArgs () {
      const otherArgs = cloneDeep(this.otherArgs)
      return defaultsDeep(otherArgs)
    },
    update () {
      const data = this.getData()
      this.chart.load(data)
    },
    reload () {
      this.chart.unload()
      this.$nextTick(() => {
        this.update()
      })
    },
    updateCategories (newVal) {
      this.chart.internal.config.axis_x_categories = newVal.x.categories
    },
    downloadReport (data = null) {
      let urlParts = this.downloadLinkData
      if (data !== null) {
        Object.assign(urlParts, data)
      }
      let urlDatePart = ''
      let filenameDatePart = moment().format('YYYY-MM-DD')

      if (urlParts.withDate) {
        urlDatePart = `&dateFrom=${urlParts.dateFrom}`
        filenameDatePart = `${urlParts.dateFrom}`
        if (urlParts.dateTo !== null) {
          urlDatePart += `&dateTo=${urlParts.dateTo}`
          filenameDatePart = `from_${urlParts.dateFrom}_to_${urlParts.dateTo}`
        }
      }

      if (urlParts.reportName === 'assignee_workload' && urlParts.type === 'assignee_workload') {
        filenameDatePart = `to_${filenameDatePart}`
      }

      let urlClientPart = ''
      if (urlParts.withClients) {
        urlClientPart = `&client=${data.client}`
      }

      let urlAssistantPart = ''
      if (urlParts.withAssistants && data.assistant ) {
        urlAssistantPart = `&user=${data.assistant}`

        if (urlParts.withOrWithoutRelated && data.assistant) {
          if (data.assistant !== null && 'without_related_user') {
            urlAssistantPart= `_with_related_user&user=${data.assistant}`
          }
          if (data.assistant === 'without_related_user') {
            urlAssistantPart = `_${data.assistant}`
          }
        }
      }
      let urlAssistantOrVoivodeshipPart = ''
      if (urlParts.withAssistantsOrVoivodeships && data.assistantOrVoivodeship) {
        switch (this.select?.value) {
        case 'voivodeship':
          urlAssistantOrVoivodeshipPart = `&voivodeship=${data.assistantOrVoivodeship}`
        break
        case 'users':
          urlAssistantOrVoivodeshipPart = `&user=${data.assistantOrVoivodeship}`
        break
        }
      }

      let urlVoivodeshipPart = ''
      if (urlParts.withVoivodeships && data.voivodeship) {
        urlVoivodeshipPart = `&voivodeship=${data.voivodeship}`
      }

      let urlAssistantOrStatusPart = ''
      if (urlParts.withAssistantsOrStatus && data.assistantOrStatus) {
        switch (this.select?.value) {
        case 'status':
          urlAssistantOrStatusPart = `&status=${data.assistantOrStatus}`
        break
        case 'users':
          urlAssistantOrStatusPart = `&user=${data.assistantOrStatus}`
        break
        }
      }
      let urlPartialReport = ''
      let filenamePartialReport = ''
      if (urlParts.withCategories) {
        if (data.categories.toString() !== '0') {
          urlPartialReport += `&part=${data.categories}`
        }
        filenamePartialReport += `_${data.categories}`
      }
      let userUuidPart = `&user=${this.$store.state.base.user.uuid}`
      let reportType = urlParts.type

      // TODO please make it disappear (REFACTOR)
      if (urlParts.type === 'closed_invoice' && data.client !== 'intgen' && data.client) {
        let clientPart = data.client === 'vig' ? 'intvig' : (data.client === 'vhs' ? 'intvhs' : 'intbls')
        reportType = urlParts.type + '_' + clientPart
      }
      api.request('storage', 'get', `/download/${urlParts.service}/report?type=${reportType}${urlDatePart}${urlClientPart}${urlAssistantPart}${urlPartialReport}${this.downloadLinkData.assistantOrVoivodeship ? urlAssistantOrVoivodeshipPart : ''}${this.downloadLinkData.voivodeship ? urlVoivodeshipPart : ''}${this.downloadLinkData.assistantOrStatus ? urlAssistantOrStatusPart : ''}${this.withUserUuid ? userUuidPart : ''}`)
        .then((response) => {
          if (response.data.hasOwnProperty('message')) {
            this.$notify({
              type: 'info',
              title: 'Informacja',
              text: response.data.message
            })
          } else {
            let file = response.data
            this.downloadBlob(this.base64toBlob(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'), `${urlParts?.reportName ? urlParts.reportName : urlParts.type}${filenamePartialReport}_tasks_report_${filenameDatePart}.xlsx`)
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać pliku'
          })
        })
    },
    downloadBlob (blob, filename) {
      let a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename
      document.body.appendChild(a)
      a.click()
    },
    base64toBlob (b64Data, contentType, sliceSize) {
      contentType = contentType || ''
      sliceSize = sliceSize || 512

      let byteCharacters = atob(b64Data)
      let byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize)

        let byteNumbers = new Array(slice.length)
        for (let c = 0; c < slice.length; c++) {
          byteNumbers[c] = slice.charCodeAt(c)
        }

        let byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, { type: contentType })
    }
  }
}
</script>
<style scoped>
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    width: 100%;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
    width: 100%;
  }

  .selects-wrapper {
    width: fit-content;
    display: flex;
    justify-content: center;
    margin-right: 2.5rem;
  }

  .buttons-wrapper {
    position: relative;
    width: 50%;
  }

  .statistics-download-withAssistantsOrVoivodeships-btn {
  position: absolute;
  right: -4.5rem;
  }
</style>
