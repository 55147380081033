export default function () {
  return [
    {
      path: 'niedokonczone-zlecenia',
      name: 'incomplete-task',
      component: () => import('../../components/bundles/incomplete-task/Listing'),
      meta: {
        acl: {
          service: ['intgen', 'intbls'],
          action: ['incomplete_tasks', 'get_incomplete_tasks'],
          allowOneOfServices: true,
          allowOneOfActions: true
        },
        icon: 'list',
        title: 'Niedokończone zlecenia',
        menuLink: true
      }
    }
  ]
}
