
// eslint-disable-next-line import/no-webpack-loader-syntax
import InspectionOtherWorker from 'worker-loader!./inspectionOtherWorker.js';
import PromiseWorker from 'promise-worker';

const inspectionOtherWorker = new InspectionOtherWorker();
const inspectionOtherPromiseWorker = new PromiseWorker(inspectionOtherWorker);

const postMessage = message => inspectionOtherPromiseWorker.postMessage(message);

export default {
  postMessage
};
