export default {
  getLoadingFinanceImport: state => state.loadingFinanceImport,
  // filteredAttachments: (state) => (catId) =>{
  //   return Object.entries(state.attachments.attachments).
  //     filter(
  //       ([key, value], index) => value.category === catId).
  //     map(([key, value], index) => {
  //       value.uuid = key
  //       return value
  //     })
  // },
}
