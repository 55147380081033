<template>
  <div>
    <select-type
      v-if="withCategories"
      v-model="categories"
      label="Kategorie"
      name="categories"
      :error-message="vErrors.first(client)"
      :options="getCategories()"
    />
    <select-type
      v-if="withClients"
      v-model="client"
      label="Klient"
      name="client"
      :error-message="vErrors.first(client)"
      :options="clients"
    />
    <select-type
      v-if="withAssistants && !withVoivodeship"
      v-model="assistant"
      label="Opiekun"
      name="assistant"
      :error-message="vErrors.first(assistant)"
      :options="assistants"
    />
    <select-type
      v-if="withVoivodeships"
      v-model="voivodeship"
      label="Województwo"
      name="voivodeship"
      :error-message="vErrors.first(voivodeship)"
      :options="voivodeships"
      :emptyFirst="false"
    />
    <select-type
      v-if="withAssistantsOrVoivodeships"
      v-model="assistantOrVoivodeship"
      :label="(this.value ==='users') ? 'Opiekun': 'Województwo' "
      name="assistantOrVoivodeship"
      :error-message="vErrors.first(assistantOrVoivodeship)"
      :options="assistantsOrVoivodeships"
    />
    <select-type
      v-if="withAssistantsOrStatus"
      v-model="assistantOrStatus"
      :label="(this.value ==='users') ? 'Opiekun': 'Status' "
      name="assistantOrStatus"
      :error-message="vErrors.first(assistantOrStatus)"
      :options="assistantsOrStatus"
    />
    <date-picker-type
      v-if="withDate"
      v-model="dateFrom"
      v-validate="{ rules: { required: true, date_format: 'yyyy-MM-dd' }}"
      class="datepicker"
      name="dateFrom"
      label="Od"
      :required="true"
      :error-message="vErrors.first('dateForm')"
      data-vv-as="data"
    />
    <date-picker-type
      v-if="withDate"
      v-model="dateTo"
      v-validate="{ rules: { required: true, date_format: 'yyyy-MM-dd' }}"
      class="datepicker"
      name="dateTo"
      label="Do"
      :required="true"
      :error-message="vErrors.first('dateTo')"
      data-vv-as="data"
    />
    <button
      class="btn btn-primary vert-form-btn"
      @click="downloadReport"
    >
      Pobierz
    </button>
  </div>
</template>

<script>
import DatePickerType from '../../../../share/form/type/DatePickerType'
import SelectType from '../../../../share/form/type/SelectType'
import moment from 'moment'

export default {
  components: {
    DatePickerType,
    SelectType
  },
  props: {
    withClients: {
      type: Boolean,
      default: () => false
    },
    withAssistants: {
      type: Boolean,
      default: () => false
    },
    withVoivodeship: {
      type: Boolean,
      default: () => false
    },
    withDate: {
      type: Boolean,
      default: () => true
    },
    withCategories: {
      type: Boolean,
      default: () => false
    },
    withAssistantsOrVoivodeships: {
      type: Boolean,
      default: () => false
    },
    withVoivodeships: {
      type: Boolean,
      default: () => false
    },
    withAssistantsOrStatus: {
      type: Boolean,
      default: () => false
    },
    clients: {
      type: Array,
      default: () => []
    },
    assistants: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: () => ('')
    },
    assistantsOrVoivodeships:{
      type: Array,
      default: () => []
    },
    voivodeships:{
      type: Array,
      default: () => []
    },
    assistantsOrStatus:{
      type: Array,
      default: () => []
    },
    selectedAssistant: {
      type: String,
      default: () => ('')
    },
    selectedClient: {
      type: String,
      default: () => ('')
    }
  },
  data () {
    return {
      client: null,
      dateFrom: null,
      dateTo: null,
      categories: 0,
      assistant: null,
      assistantOrVoivodeship: null,
      voivodeship: null,
      assistantOrStatus: null
    }
  },
  watch: {
    selectedAssistantTemp (newVal) {
      this.assistant = newVal
    }
  },
  computed: {
    selectedAssistantTemp: {
      get: function () {
        return this.selectedAssistant
      }
    }
  },
  methods: {
    downloadReport () {
      if (this.withDate && (this.dateFrom === null || this.dateTo === null)) {
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Obie daty muszą być podane.'
        })
        return
      }
      if (this.client === null && this.selectedClient !== '') {
        this.client = this.selectedClient
      }
      if (this.withCategories && this.withClients) {
        if (this.client === null) {
          this.client = 'all'
        }
      }
      if (this.withClients && !this.withCategories && (this.client === null)) {
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Proszę wybrać klienta.'
        })
        return
      }
      if (this.withDate && moment(this.dateTo).isBefore(this.dateFrom)) {
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Data "Do" musi być taka sama lub późniejsza niż "Od".'
        })
        return
      }
      let data = {}
      if (this.withDate) {
        data.dateFrom = this.dateFrom
        data.dateTo = this.dateTo
      }
      if (this.withClients) {
        data.client = this.client
      }
      if (this.withCategories) {
        data.categories = this.categories
      }
    if (this.withAssistants) {
        data.assistant = this.assistant
      }
    if (this.withVoivodeships) {
        data.voivodeship = this.voivodeship
      }
    if (this.withAssistantsOrVoivodeships) {
        data.assistantOrVoivodeship = this.assistantOrVoivodeship
      }
    if (this.withAssistantsOrStatus) {
        data.assistantOrStatus = this.assistantOrStatus
      }
      this.$emit('submitData', data)
    },
    getClientLabel (name) {
      return name.replace('_', ' ').toUpperCase()
    },
    getCategories () {
      return this.$store.state.base.inspectionCategories
    }
  }
}
</script>
