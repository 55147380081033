<script>
import { Validator } from 'vee-validate'

export default {
  data () {
    return {
      schema: [
        { name: 'label', label: 'Nazwa', rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńÓŚŁŻŹĆ\s]{1,}$/ } },
        { name: 'city', label: 'Miejscowość', rules: { required: true } },
        { name: 'postcode', label: 'Kod pocztowy', rules: { required: true, postCode: 'code', max: 6 } },
        { name: 'street', label: 'Adres', rules: { required: true } },
        { name: 'email', label: 'Kontakt (e-mail)', rules: { required: true, email: true } },
        {
          name: 'phone',
          label: 'Numer telefonu (format: +00 123456789 lub +00 11 1234567, +00 opcjonalne)',
          label2: 'numer telefonu',
          rules: { required: true, regex: /^(\++\d{2}\s)?((\d{2}\s)+\d{7}|\d{9})$/ }
        }
      ]
    }
  },
  created () {
    Validator.extend('postCode', {
      getMessage: () => ' Pole Kod pocztowy musi być poprawnym kodem pocztowym.',
      validate: (value) => {
        const postCodeRegex = /^(\d{2}-\d{3})$/
        if (value === '00-000') {
          return false
        } else if (!postCodeRegex.test(value)) {
          return false
        } else if (postCodeRegex.test(value)) {
          return true
        }
      }
    })
  }
}
</script>
