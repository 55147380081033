<template>
  <menu-link
    v-if="isMenuLink"
    :model="model"
    :level="level"
    @childOpened="modifyMenu"
  />
  <li
    v-else-if="isMenuTree"
    ref="item"
    class="menu-tree menu-element"
  >
    <a
      ref="link"
      :href="model.path"
      :class="levelClass"
      :title="model.meta.title"
      @click.prevent="toggle"
    >
      <i
        v-if="model.meta.icon"
        class=""
        :class="`feather-${model.meta.icon}`"
      /><span class="menu-element--label">{{ $t(model.meta.title) }}</span>
      <i
        class="feather-chevron-right pull-left"
        :class="{open: open}"
      />
    </a>
    <ul
      v-if="hasChildren"
      ref="subMenu"
      class="menu-tree--list overflow-hidden menu-height-0 menu-closed"
    >
      <menu-element
        v-for="(item, index) in model.children"
        :key="`${item.name}-${index}`"
        :model="item"
        :level="level + 1"
        :opened-element="currentEl"
        @childOpened="modifyMenu"
        @childClosed="modifyMenu"
      />
    </ul>
  </li>
</template>

<script>
import MenuElement from './Element'
import MenuLink from './Link'

export default {
  name: 'MenuElement',
  components: {
    MenuElement,
    MenuLink
  },
  props: {
    model: { type: Object, required: true },
    level: { type: Number, required: true },
    openedElement: { type: Object, required: false }
  },
  data () {
    return {
      open: false,
      active: false,
      toggled: false,
      currentEl: null
    }
  },
  computed: {
    isMenuLink () {
      return this.model.meta !== undefined && this.model.meta.menuLink === true
    },
    isMenuTree () {
      return this.model.meta !== undefined && this.model.meta.menuTree === true
    },
    hasChildren () {
      return this.model.children !== undefined && this.model.children.length > 0
    },
    levelClass () {
      return 'link-level-' + this.level
    }
  },
  watch: {
    openedElement: function (el) {
      if (el !== this && this.isMenuTree) {
        this.modifyMenu(el, false)
        this.closeMenu()
      }
    }
  },
  methods: {
    toggle () {
      this.open = !this.open
      if (this.open) {
        this.openMenu()
      } else {
        this.toggled = true
        this.closeMenu()
      }
    },
    openMenu () {
      this.open = true
      this.replaceClass('menu-closed', 'menu-opened', true)
      this.slideMenu(!this.open, this.model.children.length)

      this.activate()

      this.$emit('childOpened', this, true)
    },
    closeMenu (withoutEmit) {
      this.open = false
      this.slideMenu(!this.open, this.model.children.length, () => {
        this.replaceClass('menu-opened', 'menu-closed', true)
      })
      if (this.toggled) {
        this.$emit('childClosed', this)
        this.toggled = false
      }
      this.deactivate()
    },
    activate () {
      let sidebar = document.querySelector('.sidebar')
      let activeEl = sidebar.querySelector('.active')
      if (activeEl !== null) {
        activeEl.classList.remove('active')
      }
      this.$refs.item.classList.add('active')
      this.active = true
    },
    deactivate () {
      this.$refs.item.classList.remove('active')
      this.active = false
    },
    modifyMenu (el, isChild) {
      let numberOfGrandchildren = 0
      if (el.active) {
        this.currentEl = el // close children
      }
      if (isChild && el.model.children) {
        numberOfGrandchildren = el.model.children.length
      }
      if (this.isMenuTree) {
        this.resizeMenu(numberOfGrandchildren)
      }
    },
    resizeMenu (numberOfGrandchildren) {
      let numberOfChildren = this.model.children.length
      let className = 'menu-height-' + (numberOfChildren + numberOfGrandchildren)
      this.replaceClass('menu-height-', className)
    },
    replaceClass (fromClass, toClass, strict) {
      if (strict) {
        this.$refs.subMenu.classList.remove(fromClass)
        this.$refs.subMenu.classList.add(toClass)
      } else {
        let classes = this.$refs.subMenu.className.split(' ')
        for (let i = 0; i < classes.length; i++) {
          if (classes[i].indexOf(fromClass) !== -1) {
            this.$refs.subMenu.classList.remove(classes[i])
          }
        }
        this.$refs.subMenu.classList.add(toClass)
      }
    },
    slideMenu (up, targetSize, callback) {
      let sizeFromClass = 'menu-height-0'
      let sizeToClass = 'menu-height-' + targetSize
      if (up) {
        sizeFromClass = 'menu-height-' + targetSize
        sizeToClass = 'menu-height-0'
      }

      this.replaceClass('overflow-visible', 'overflow-hidden', true)
      this.replaceClass(sizeFromClass, sizeToClass)

      this.replaceClass('overflow-hidden', 'overflow-visible', true)
      if (callback) {
        callback()
      }
    }
  }
}
</script>
