// Bundles: Internal/Gen
const pathPrefix = 'administracja-procesami-generalicrls/'
const customBreadcrumbs = {
  generali: {
    meta: {
      icon: 'generali',
      title: 'Generali CRLS'
    }
  }
}

export default [
  {
    path: `${pathPrefix}group_person_assign`,
    name: 'intgen_group_person_assign',
    component: () => import('../../../components/bundles/share/groupUserAssign/GroupUserAssign'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_task_type_group_user'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali
        ]
      },
      icon: 'shield',
      title: 'Grupy użytkowników',
      secondMenu: [
        {
          event: { name: 'intgen:group-person:save' },
          icon: 'plus',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_task_type_group_user' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'dashboard' },
          icon: 'arrow-left',
          label: 'Wróć do pulpitu',
          acl: { security: false }
        }
      ]
    }
  }
]
