import RouterFrame from '../../../components/share/RouterFrame.vue'
import getProperServiceIcon from '../../utils/getProperServiceIcon'
import clients from './clients'
import role from './role'
import processStatus from './process-status'
import processMap from './process-map'
import visibilityFinance from './visibility-finance'

export default function (service, name) {
  return {
    path: `administracja-procesami-${name.toLowerCase()}`,
    component: RouterFrame,
    meta: {
      title: name,
      icon: getProperServiceIcon(service),
      menuTree: true,
      acl: {
        service: service,
        action: ''
      }
    },
    children: [
      clients(service, name),
      role(service, name),
      processStatus(service, name),
      processMap(service, name),
      visibilityFinance(service, name),
    ]
  }
}
