const toNumber = (val) => {
  return !isNaN(Number(val)) ? Number(val) : 0
}

export const setupTotalCount = (data) => {
	let totalCount = 0;

	for (let item in data) {
		Object
			.values(data[item])
			.forEach(el => {
				toNumber(el) ? totalCount += Number(el) : false
			})
	}
	return `Łącznie: ${totalCount}`
}