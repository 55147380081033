const workflow = 'main-property'
const pathPrefix = 'administracja-procesami-generalicrls/proces-glowny-mienie/'
const customBreadcrumbs = {
  generali: {
    meta: {
      icon: 'generali',
      title: 'Generali CRLS'
    }
  },
  mainPropertyTask: {
    meta: {
      icon: 'settings',
      title: 'Proces główny - mienie'
    }
  }
}

export default [
  {
    path: `${pathPrefix}stany-procesu`,
    name: 'intgen_property_state_listing',
    component: () => import('../../../components/bundles/intgen/admin/state/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_workflow_states'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask
        ]
      },
      workflow,
      icon: 'disc',
      title: 'Lista stanów',
      secondMenu: [
        {
          event: { name: 'intgen:main:addState' },
          icon: 'plus',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_workflow_state' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}stany-procesu/edycja`,
    name: 'intgen_property_state_edit',
    component: () => import('../../../components/bundles/intgen/admin/state/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_workflow_state'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask,
          'intgen_property_state_listing'
        ]
      },
      workflow,
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_workflow_state' }
        },
        {
          event: { name: 'intgen:main:stateSubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_workflow_state' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_property_state_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_workflow_states' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}mapa-przejsc-procesu`,
    name: 'intgen_property_transition_listing',
    component: () => import('../../../components/bundles/intgen/admin/transition/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_workflow_transitions'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask
        ]
      },
      workflow,
      icon: 'git-commit',
      title: 'Lista przejść',
      secondMenu: [
        {
          event: { name: 'intgen:main:transitionEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_workflow_role_transitions' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
    name: 'intgen_property_transition_edit',
    component: () => import('../../../components/bundles/intgen/admin/transition/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_workflow_role_transitions'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask,
          'intgen_property_transition_listing'
        ]
      },
      workflow,
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_workflow_role_transitions' }
        },
        {
          event: { name: 'intgen:main:transitionSubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_workflow_role_transitions' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_property_transition_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_workflow_transitions' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-zlecen`,
    name: 'intgen_property_task_visibility_listing',
    component: () => import('../../../components/bundles/intgen/admin/task-visibility/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_main-property_task_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask
        ]
      },
      workflow,
      icon: 'eye',
      title: 'Lista widoczności zleceń',
      secondMenu: [
        {
          event: { name: 'intgen:main:taskVisibilityEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'post_main-property_task_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-zlecen/edycja`,
    name: 'intgen_property_task_visibility_edit',
    component: () => import('../../../components/bundles/intgen/admin/task-visibility/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'post_main-property_task_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask,
          'intgen_property_task_visibility_listing'
        ]
      },
      workflow,
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'post_main-property_task_visibilities' }
        },
        {
          event: { name: 'intgen:main:taskVisibilitySubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'post_main-property_task_visibilities' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_property_task_visibility_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_main-property_task_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-pol`,
    name: 'intgen_property_field_access_listing',
    component: () => import('../../../components/bundles/intgen/admin/field-access/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_main-property_fields_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask
        ]
      },
      workflow,
      icon: 'check-square',
      title: 'Lista widoczności pól',
      secondMenu: [
        {
          event: { name: 'intgen:main:fieldVisibilityEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_main-property_fields_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-pol/edycja`,
    name: 'intgen_property_field_access_edit',
    component: () => import('../../../components/bundles/intgen/admin/field-access/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_main-property_fields_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.mainPropertyTask,
          'intgen_property_field_access_listing'
        ]
      },
      workflow,
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_main-property_fields_visibilities' }
        },
        {
          event: { name: 'intgen:main:fieldVisibilitySubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_main-property_fields_visibilities' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_property_field_access_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_main-property_fields_visibilities' }
        }
      ]
    }
  }
]
