import api from '@/api/index.js'
import { isObject } from 'lodash'

export const ANALYST_VERIFICATION_STATISTIC_SELECT_EVENT = 'analyst-verification-select-event'
export const ANALYST_VERIFICATION_STATISTIC_TYPE_WITH_RELATED_USER = 'with_related_user'
export const ANALYST_VERIFICATION_STATISTIC_TYPE_WITHOUT_RELATED_USER = 'without_related_user'

export default {
  data () {
    return {
      analystVerificationKey: 0,
      analystVerification: {
          title: 'Weryfikacja analityka',
          total: '',
          axis: {
            x: {
              type: 'category',
              tick: {
                rotate: 60,
                multiline: false
              }
            }
          },
          data: {
            json: [],
            keys: {
              x: 'label',
              value: []
            },
            type: 'bar',
            groups: [],
            selection: {},
            onselected: (part, node) => {
            },
            onunselected: (slice, node) => {
            }
          },
        downloadLinkData: {
          service: 'intmil',
          type: '',
          withDate: false,
          withAssistants: true
        },
        select: {
          type: {
            options: [
              {value: ANALYST_VERIFICATION_STATISTIC_TYPE_WITH_RELATED_USER, label: 'Z osobą powiązaną'},
              {value: ANALYST_VERIFICATION_STATISTIC_TYPE_WITHOUT_RELATED_USER, label: 'Bez osoby powiązanej'}
            ],
            value: ANALYST_VERIFICATION_STATISTIC_TYPE_WITH_RELATED_USER,
            event: ANALYST_VERIFICATION_STATISTIC_SELECT_EVENT
          },
          report: {
            options: [],
            value: ''
          }
        },
      }
    }
  },
  methods: {
    handleChangeAnalystVerificationStatisticType (type = ANALYST_VERIFICATION_STATISTIC_TYPE_WITH_RELATED_USER) {
      this.loadTasksAnalystVerification(type)
      this.analystVerification.select.type.value = type
      this.analystVerification.downloadLinkData.type = 'analyst_verification_' + type
      this.analystVerification.select.report.value = ''
    },
    loadTasksAnalystVerification (type) {
      api.request('intmil', 'get', `/statistics/tasks/task-analyst-verification?type=${type}`)
        .then((responseData) => {
          let data = responseData.data

          if (isObject(data)) {
            this.processTasksAnalystVerification(data)
          }
        })
        .catch((e) => {
          console.log('error', e)
        })
      },
      processTasksAnalystVerification (data) {
      this.analystVerificationKey = Math.random() * 100

      const preparedData = Object.entries(data).map(([key,item]) => {
        return {
          label: `${key} (${item.count})`,
          id: item.uuid,
          ...item.assignees
        }
      })
      const values = this.getAnalystVerificationValues(preparedData)

      this.analystVerification.data.json = preparedData
      this.analystVerification.data.keys.value = values
      this.analystVerification.data.groups = [values]

      const totalCount = Object.values(data).reduce((acc, curr) => acc + Number(curr.count), 0)
      this.analystVerification.total = `Łącznie: ${totalCount}`

      let reportOptions = Object.entries(data).map(([key,item]) => ({label: key, value: item.uuid}))
      this.analystVerification.select.report.options = reportOptions
    },
    getAnalystVerificationValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label' && key !== 'id').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
  }
}
