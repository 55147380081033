import api from '@/api/index.js'

export default {
  data() {
    return {
      taskWithoutAppointmentKey: 0,
      tasksWithoutAppointment:{
        title: 'Zlecenia bez daty wizji',
        total: '',
        data: {
          columns: [],
        },
        type: 'pie',
        otherArgs: {
          pie: {
            label: {
              format: function (value, ratio, id) {
                return value
              }
            }
          },
          tooltip: {
            format: {
              value: function (value, ratio, id, index) {
                return value;
              }
            }
          },
        },
        downloadLinkData: {
          service: 'intmil',
          type: 'appraisal_task_without_appointment',
          withDate: false,
          withAssistants: true,
        }
      },
    }
  },
  methods: {
    loadTasksWithoutAppointment () {
      api.request('intmil', 'get', `statistics/tasks/user-with-task-without-appointment`)
        .then((response) => {
        let data = response.data.data
        
        this.taskWithoutAppointmentKey = Math.random() * 100

        let assistants = data.map(user => ({label: user.label, value: user.user_id} ))
        let chartValues = data.map(user => ([ user.label, parseInt(user.task_count) ]))
        this.tasksWithoutAppointment.data.columns = chartValues
        this.bwnAssistantsSelectOptions = assistants

        const totalCount = data.reduce((acc, curr) => acc + Number(curr.task_count), 0)
        this.tasksWithoutAppointment.total = `Łącznie: ${totalCount}`
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
  },
}
