import api from '../../../../api'

export const ACTIVE_TASK_STATISTIC_TYPE_USER = 'users'
export const ACTIVE_TASK_STATISTIC_TYPE_VOIVODESHIP = 'voivodeship'
export default {
  data () {
    return {
      activeTasksBWNStatisticKey: 0,
      activeTasksBWNStatisticLoaded: false,
      activeTasksBWNStatisticSelectedType: ACTIVE_TASK_STATISTIC_TYPE_USER,
      activeTasksSelectOptions: [],
      activeTasksBWNStatisticTypeOptions: [
        {value: ACTIVE_TASK_STATISTIC_TYPE_USER, label: 'Opiekun zlecenia'},
        {value: ACTIVE_TASK_STATISTIC_TYPE_VOIVODESHIP, label: 'Województwo'}
      ],
      activeTasksBWNStatistic: {
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        title: 'W trakcie realizacji',
        total: '',
        data: {
          json: [],
          keys: {
            x: 'label',
            value: []
          },
          type: 'bar',
          groups: [],
          selection: {},
          onselected: (part, node) => {
          },
          onunselected: (slice, node) => {
          }
        },
        partialReport: null,
        downloadLinkData: {
          service: 'intmil',
          type: 'task_in_progress',
          withAssistantsOrVoivodeships: true,
          withDate: false
        }
      }
    }
  },

  methods: {
    getLoadActiveTaskUrl() {
      return `statistics/tasks/get-user-with-task-in-progress`
    },
    loadActiveTaskStatistic (type = ACTIVE_TASK_STATISTIC_TYPE_USER) {
      (new Promise((resolve => setTimeout(() =>
      api.request('intmil', 'get', this.getLoadActiveTaskUrl())
      .then((response) => {
        let data = response.data
      resolve({data}), 200})))).then(({data}) => {
        const dataByType = data[type]
        const preparedData = this.getActiveTaskStatistics(dataByType, true)

        const values = this.getActiveTasksStatisticsValues(preparedData)
        const tempActiveTasks = this.activeTasksBWNStatistic

        const totalCount = data.users.reduce((acc, curr) => acc + Number(curr.totalCount), 0)
        tempActiveTasks.total = `Łącznie: ${totalCount}`

        tempActiveTasks.data.json = preparedData
        tempActiveTasks.data.keys.value = values
        tempActiveTasks.data.groups = [values]
        this.activeTasksBWNStatistic = tempActiveTasks

        this.activeTasksBWNStatisticKey = Math.random() * 100
        switch (type) {
          case ACTIVE_TASK_STATISTIC_TYPE_USER:
            this.activeTasksSelectOptions = dataByType.map(user => ({label: `${user.label} (${user.totalCount})`, value: user.uuid} ))
          break
          case ACTIVE_TASK_STATISTIC_TYPE_VOIVODESHIP:
            this.activeTasksSelectOptions = dataByType.map( voivodeship => ({label: `${voivodeship.label} (${voivodeship.totalCount})`, value: voivodeship.label}))
          break
        }
      }).catch((e) => {
        console.error(e)
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Nie można załadować danych do wykresu W trakcie realizacji'
        })
      })
      )},
    getActiveTaskStatistics (backendData, addTotal = false) {
      return backendData.map(item => ({
        label: addTotal ? `${item.label} (${item.totalCount})` : item.label,
        ...item.states[0]
      }))
    },
    getActiveTasksStatisticsValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label' && key !== 'id' && key !== 'uuid').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
    handleChangeActiveTasksStatisticType (value) {
      this.loadActiveTaskStatistic(value)
      this.activeTasksBWNStatisticSelectedType = value
    },
  }
}
