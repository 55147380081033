import jquery from 'jquery'
import Vue from 'vue'
import vueFilter from 'vue-filter'
import VueEvents from 'vue-events'
import IdleManager from './idleVue'
import App from './App.vue'
import Auth from './Auth'
import EventsUnsubscribing from './plugins/EventsUnsubscribing'
import isWithClients from './plugins/isWithClients'
import router from './router'
import store from './store'
import validator from './validator'
import VueVirtualScroller from 'vue-virtual-scroller'
import './registerServiceWorker'
import validateRoute from './router/utils/validateRoute'
import workflowTranslator from './plugins/workflowTranslator'
import internationalisation from './translations/internationalisation'
import axios from 'axios'
import handleCheckAccessMethod from '@/plugins/handleCheckAccessMethod.js'

window.$http = Vue.prototype.$http = axios

Vue.use(VueVirtualScroller)

const i18n = internationalisation
Vue.config.productionTip = false
validator.veeValidateOptions.i18nRootKey = 'validations'
validator.veeValidateOptions.i18n = i18n
Vue.use(validator.veeValidate, validator.veeValidateOptions)
Vue.use(IdleManager.idleVue, IdleManager.idleOptions)
Vue.use(vueFilter)
Vue.use(VueEvents)
Vue.use(EventsUnsubscribing)
Vue.use(isWithClients)
Vue.use(workflowTranslator)

Vue.use(Auth)
Vue.use(handleCheckAccessMethod)

router.beforeEach((to, from, next) => validateRoute(to, from, next, 'beforeEach'))

new Vue({
  el: '#app',
  store,
  router,
  jquery,
  i18n,
  render: h => h(App)
}).$mount('#app')
