<template>
  <div class="wrapper">
    <main-header />
    <sidebar />
    <div class="content-wrapper">
      <notifications width="470" />
      <div class="row">
        <div class="col-sm-12">
          <router-view :key="'route-' + routeKey" />
        </div>
      </div>
    </div>
    <idle />
    <PresenceModal
      v-if="showPresenceConfirm"
      :show="showPresenceConfirm"
      @close="showPresenceConfirm = false"
    >
    </PresenceModal>
  </div>
</template>

<script>
import api from '../../api'
import Notifications from 'vue-notification'
import MainHeader from './MainHeader'
import Idle from './modal/Idle'
import Sidebar from './sidebar/Sidebar'
import WebStorage from '../share/WebStorage'
// import ConfirmModal from '../share/modal/BaseConfirmModal'
import Vue from 'vue'
import {mapActions} from 'vuex'
import PresenceModal from '@/components/bundles/share/modal/presenceModal/PresenceModal.vue'

Vue.use(Notifications)

export default {
  components: {
    PresenceModal,
    Idle,
    MainHeader,
    Sidebar,
  },
  data () {
    return {
      events: {
        loginSuccess: 'dashboard:notification:loginSuccess',
        changeState: 'task:changedState',
        settlementChangeState: 'settlement:refresh'
      },
      securityActions: [],
      intervalId: null,
      routeKey: '',
      showPresenceConfirm: false
    }
  },
  watch: {
    $route: function (newVal) {
      this.routeKey = newVal.fullPath
    }
  },
  beforeMount () {
    this.$store.dispatch('saveGridStateFromLocalStorage', this.$store.state.base.user.uuid)
  },
  mounted () {
    this.securityActions = WebStorage.getSecurityActions()
    this.$store.commit('SET_SECURITY_ACTIONS', this.securityActions)
    if (this.securityActions.hasOwnProperty('finance') && Array.isArray(this.securityActions['finance'])) {
      this.getFinanceOwnership()
    }
    this.intervalId = setInterval(() => {
      this.$store.commit('REMOVE_OLD_CACHED_ATTACHMENTS')
      this.$store.commit('REMOVE_ALL_ATTACHMENTS_CACHE')
    }, 300000)
    this.$events.on(this.events.loginSuccess, () => {
      this.$notify({
        type: 'success',
        text: this.$t('login.success')
      })
    })
    this.$events.on(this.events.changeState, this.handleChangeState)
    this.$events.on(this.events.settlementChangeState, this.handleChangeStateSettlements)
    this.loadPresence()
    this.loadAvatars()
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions({
      getFinanceOwnership: 'getOwnership'
    }),
    handleChangeState () {
      this.routeKey = `${Math.random() * 100}`
    },
    handleChangeStateSettlements () {
      this.routeKey = `${Math.random() * 100}`
    },
    loadAvatars () {
      let avatars = WebStorage.getAvatars()
      if (avatars === null) {
        api.request('core', 'get', 'contractors/avatars')
          .then((response) => {
            WebStorage.setAvatars(response.data)
          })
          .catch((e) => {
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można pobrać avatarów'
            })
          })
      }
    },
    loadPresence() {
      let user = this.$store.state.base.user
      api.request('core', 'get', `/users/${user.uuid}/presence`)
        .then((response) => {
          if (!response.data.presence) {
            this.showPresenceConfirm = true
          }
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można sprawdzić obecności'
          })
        })
    },
  }
}
</script>

<style scoped>
.version-text {
    font-size: 0.5em;
}
</style>
