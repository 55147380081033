import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import getters from './getters'
import gridState from './ListingState/state'
import gridActions from './ListingState/actions'
import gridMutations from './ListingState/mutations'
import gridGetters from './ListingState/getters'
import settlementsState from './SettlementsState/state'
import settlementsMutations from './SettlementsState/mutations'
import settlementsGetters from './SettlementsState/getters'
import settlementsActions from './SettlementsState/actions'
import informationState from './InformationState/state'
import informationActions from './InformationState/actions'
import informationMutations from './InformationState/mutations'
import informationGetters from './InformationState/getters'
import attachmentsState from './attachmentsState/state'
import attachmentsActions from './attachmentsState/actions'
import attachmentsGetters from './attachmentsState/getters'
import attachmentsMutations from './attachmentsState/mutations'
import incompleteTasksState from './incompleteTasksState/state'
import incompleteTasksActions from './incompleteTasksState/actions'
import incompleteTasksGetters from './incompleteTasksState/getters'
import incompleteTasksMutations from './incompleteTasksState/mutations'

Vue.use(Vuex)
const settlements = {
  actions: settlementsActions,
  state: settlementsState,
  mutations: settlementsMutations,
  getters: settlementsGetters
}
const base = {
  state: state,
  mutations: mutations,
  getters: getters
}
const grid = {
  state: gridState,
  actions: gridActions,
  mutations: gridMutations,
  getters: gridGetters
}
const information = {
  state: informationState,
  actions: informationActions,
  mutations: informationMutations,
  getters: informationGetters
}
const attachments = {
  state: attachmentsState,
  actions: attachmentsActions,
  mutations: attachmentsMutations,
  getters: attachmentsGetters
}
const incompleteTasks = {
  state: incompleteTasksState,
  actions: incompleteTasksActions,
  mutations: incompleteTasksMutations,
  getters: incompleteTasksGetters
}
export default new Vuex.Store({
  modules: {
    base: base,
    gridStates: grid,
    settlementsState: settlements,
    information: information,
    attachments: attachments,
    incompleteTasks: incompleteTasks
  }
})
