export default function (service) {

  switch (service) {
    case 'extpko':
      return ' - online'
    case 'extgcz':
      return ' - trial'
    case 'extshp':
      return ' - online'
    case 'extemn':
      return ' - online'
    case 'extacn':
      return ' - online'
    case 'extgsl':
      return ' - vozidlo'
    case 'extuni':
      return ' - online'
    default:
      return ' - pojazd'
  }
  // return service === 'extpko' || service === 'extemn' ? ' - online' : ' - pojazd'
}
