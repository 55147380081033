// Bundles: Internal/Gen
const pathPrefix = 'administracja-procesami-generalicrls/'
const customBreadcrumbs = {
  generali: {
    meta: {
      icon: 'generali',
      title: 'Generali CRLS'
    }
  }
}

export default [
  {
    path: `${pathPrefix}role`,
    name: 'intgen_role_listing',
    component: () => import('../../../components/bundles/intgen/admin/role/Listing.vue'),
    meta: {
      columns: [
        { id: 0, data: 'label', title: 'Opis' },
        { id: 1, data: 'name', title: 'Nazwa' },
        { id: 2, data: 'businessRole', title: 'Rola biznesowa', searchable: false }
      ],
      acl: {
        service: 'intgen',
        action: 'get_roles'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali
        ]
      },
      icon: 'shield',
      title: 'Lista ról',
      secondMenu: [
        {
          event: { name: 'intgen:role:addRole' },
          icon: 'plus',
          label: 'Dodaj rolę',
          acl: { service: 'intgen', action: 'post_role' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}role/:id`,
    name: 'intgen_role_details',
    component: () => import('../../../components/bundles/intgen/admin/role/Details.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_roles'
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [
          customBreadcrumbs.generali,
          'intgen_role_listing'
        ]
      },
      title: 'Szczegóły roli',
      secondMenu: [
        {
          event: { name: 'intgen:role:editRole' },
          icon: 'edit',
          label: 'Edytuj dane',
          acl: { service: 'intgen', action: 'put_role' }
        },
        {
          event: { name: 'intgen:role:editActionsAccess' },
          icon: 'unlock',
          label: 'Edytuj uprawnienia',
          acl: { service: 'intgen', action: 'put_role_access_tree' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}role/:id/edycja-poziomow-dostepu`,
    name: 'intgen_role_details_acl_edition',
    component: () => import('../../../components/bundles/share/role/details/AclEditionCheckboxes.vue'),
    props: { service: 'intgen' },
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_role_access_tree'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          'intgen_role_listing',
          'intgen_role_details'
        ]
      },
      title: 'Edycja poziomów dostępu',
      thirdMenu: [
        {
          event: { name: 'intgen:acl:submitData' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_role_access_tree' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_role_details' },
          icon: 'arrow-left',
          label: 'Wróć do szczegółów',
          acl: { service: 'intgen', action: 'get_role' }
        }
      ]
    }
  }
]
