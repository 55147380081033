import api from '../../api'

export default {
  getIncompleteTasks: async ({state, commit}, {services}) => {
    commit('SET_LOADING_STATE', 'inProgress')
    commit('CLEAR_INCOMPLETE_TASKS')
    let servicesArr = Object.keys(services)
    for (let index = 0; index < servicesArr.length; index++) {
      try {
        let response = await api.request(servicesArr[index], 'get',
          `/tasks/incomplete?format=overview`)
       commit('SET_INCOMPLETE_TASKS', response.data)
      } catch (e) {
        console.error(e)
      }
    }
    commit('SET_LOADING_STATE', 'after')
  },
}
