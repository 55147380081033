<template>
  <form>
    <input-type
      v-for="field in schema"
      :key="field.name"
      v-model="value[field.name]"
      v-validate="{rules: field.rules}"
      :data-vv-value-path="'value'"
      :data-vv-as="field.label2 ? field.label2 : field.label"
      :error-message="vErrors.first(field.name)"
      :label="field.label"
      :name="field.name"
      :required="field.rules.required"
    />
    <div
      class="modal-body form-group required-field"
      :class="[{ 'has-error': vErrors.first('unitIds'), 'has-success' : !vErrors.first('unitIds') && selectHasBeenChanged }]"
    >
      <label>Jednostki:</label>
      <select
        v-validate="{rules: { required: false }}"
        name="unitIds"
        class="form-control"
        multiple
        :value="value['unitIds']"
        data-vv-value-path="value"
        data-vv-as="unit"
        @change="multiselectValues($event.target.selectedOptions)"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.id"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'
import api from '../../../../../../api'

export default {
  components: {
    InputType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      schema: [
        { name: 'symbol', label: 'Symbol', rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńÓŚŁŻŹĆ]{1,}$/ } },
        { name: 'label', label: 'Nazwa', rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńÓŚŁŻŹĆ0-9()\s]{3,}$/ } },
        { name: 'description', label: 'Opis', rules: { required: false } },
        {
          name: 'phone',
          label: 'Numer telefonu (format: +00 123456789 lub +00 11 1234567, +00 opcjonalne)',
          label2: 'numer telefonu',
          rules: { regex: /^(\++\d{2}\s)?((\d{2}\s)+\d{7}|\d{9})$/ }
        },
        { name: 'email', label: 'Kontakt (e-mail)', rules: { required: false, email: true } },
        { name: 'avatar', label: 'Awatar', rules: { required: false } },
        { name: 'userGuide', label: 'Instrukcja', rules: { required: false } }
      ],
      selectHasBeenChanged: false,
      options: []
    }
  },
  mounted () {
    this.loadUnits()
  },
  methods: {
    loadUnits () {
      api.request('core', 'get', '/units')
        .then((response) => {
          this.options = response.data
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    multiselectValues (selectedOptions) {
      this.$events.$emit('core:contractor:newContractorModal', selectedOptions)
      this.selectHasBeenChanged = true
    }
  }
}
</script>
