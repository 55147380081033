import moment from 'moment'

export default {
  methods: {
    prepareSelectDates (responseData, workflows, dates) {
      Object.entries(responseData).forEach(row => {
        let data = row[1]

        workflows.forEach(w => {
          if (data.hasOwnProperty(w)) {
            Object.keys(data[w]).length !== 0 && dates.push(...Object.keys(data[w]))
          }
        })

        let set = new Set(dates)
        dates = [...set]
        this.handleDates(dates)
        let sortedDates = function (a, b) {
          return moment(b).valueOf() - moment(a).valueOf()
        }
        dates = dates.sort(sortedDates)
      })
    },
    handleDates (dates) {
      if (dates.length === 1) {
        if (dates[0] === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
          dates.push(moment().format('YYYY-MM-DD'))
        } else {
          dates.push(moment().subtract(1, 'days').format('YYYY-MM-DD'))
        }
      } else if (dates.length === 0) {
        dates.push(moment().format('YYYY-MM-DD'))
        dates.push(moment().subtract(1, 'days').format('YYYY-MM-DD'))
      }
    },
    getClientsOptions(services, clients, contractorApps, addAllOption = true, optionsFilter = []) {
      let allowedOptions = []

      services.forEach(service => {
        if (this.$isWithClients(service) === false) {
          allowedOptions.push({ value: service, label: contractorApps[service] })
        } else {
          clients[service].forEach(element => {
            allowedOptions.push({ value: element.name, label: element.label })
          })
        }
      })

      let options = []
      let useFilter = optionsFilter.length > 0
      if (useFilter) {
        allowedOptions.forEach(option => {
          if (optionsFilter.includes(option.value)) {
            options.push(option)
          }
        })
      } else {
        options = allowedOptions
      }

      if (addAllOption && options.length > 0) {
        options.unshift({ value: 'all', label: '' })
      }

      return options
    }
  }
}
