export default {
  methods: {
    async getResponses (requests) {
      try {
        const responses = await Promise.all(requests)
        let result = this.groupByBaseUrl(responses)
        return result
      } catch (error) {
        return error
      }
    },
    groupByBaseUrl (responses) {
      let result = {};
      responses.forEach((resp) => {
        let baseUrl = resp.config.baseURL.replace(/[^\w ]/, '')
        result[baseUrl] = resp.data
      })
      return result
    },
    filterBy (responses, property) {
      let data = {};
      Object.entries(responses).forEach(item => {
        let key = item[0]
        let value = item[1]
        data[key] = value[property]
      })
      return data
    }
  }
}
