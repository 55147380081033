<template>
  <ul
    v-if="items"
    class="sidebar--menu-list"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      class="menu-element"
    >
      <a @click="emitEvent(item.event)">
        <i
          v-if="item.icon"
          class=""
          :class="`feather-${item.icon}`"
        /><span class="menu-element--label">{{ $t(item.label) }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import SubMenu from './SubMenu'

export default {
  name: 'ThirdMenuMenu',
  mixins: [
    SubMenu
  ],
  data () {
    return {
      events: {
        redirect: 'dashboard:menu:redirect',
        taskTab: 'dashboard:menu:taskTab',
        settlementTab: 'dashboard:menu:settlementTab'
      }
    }
  },
  mounted () {
    this.$events.on(this.events.redirect, (route) => {
      this.$router.push({ name: route })
    })
    this.$events.on(this.events.taskTab, this.updateItems)
    this.$events.on(this.events.settlementTab, this.updateItems)
  },
  methods: {
    getItems (route) {
      let items = []
      if (route.meta.thirdMenu && route.meta.thirdMenu.length) {
        items = this.getAllowedMenuItems(route.meta.thirdMenu)
      }
      this.$emit('thirdMenuUpdate', items)
      return items
    },
    updateItems (tabItems) {
      let items = this.getItems(this.$route)
      if (tabItems) {
        items = items.concat(this.getAllowedMenuItems(tabItems))
      }

      this.items = items
      this.$emit('thirdMenuUpdate', this.items)
    }
  }
}
</script>
