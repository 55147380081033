export default {
  CLEAR_INCOMPLETE_TASKS (state) {
    state.tasks = []
  },
  SET_INCOMPLETE_TASKS (state, payload) {
    state.tasks = state.tasks.concat(payload)
  },
  SET_LOADING_STATE (state, payload) {
    state.loading = payload
  }
}
