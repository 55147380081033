import RouterFrame from '../../../../components/share/RouterFrame.vue'
import FinanceWorkflowListing from '../../../../components/bundles/finance/admin/transition/Listing.vue'

export default function (service, name) {
  return {
    path: 'mapa-przejsc-procesu',
    component: RouterFrame,
    meta: {
      title: 'Mapa procesu',
      icon: 'git-commit',
      menuLink: true,
      acl: {
        service: service,
        action: 'get_workflow_transitions'
      }
    },
    children: [
      {
        path: '',
        component: FinanceWorkflowListing,
        name: `${service}_transition_listing`,
        meta: {
          title: 'Lista przejść',
          acl: {
            service: service,
            action: 'get_workflow_transitions'
          }
        }
      }
    ]
  }
}
