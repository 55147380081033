// NOTE: common configuration for modules intmil and intbwn

import getProperServiceIcon from '../../../utils/getProperServiceIcon'
import acl from './acl'
import {beforeRouteValidateAclPrevalidation} from '@/router/utils/beforeEnterAclPreValidation.js'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-podzlecenia-${name.toLowerCase()}/:service/:client/:taskCategoryId/`
  const customBreadcrumbs = {}

  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  customBreadcrumbs.appraisalTask = {
    meta: {
      icon: `settings`,
      title: `Proces rzeczoznawczy`
    }
  }

  let routes = [
    {
      path: `${pathPrefix}stany-procesu`,
      name: `${service}_appraisal_state_listing`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/state/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: `disc`,
        title: `Lista stanów`,
        secondMenu: [
          {
            event: {name: `${service}:appraisal:addState`},
            icon: `plus`,
            label: `Tryb edycji`,
            acl: {service: service, action: `put_workflow_state`}
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_appraisal_state_edit`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/state/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_state_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: {name: `share:list:addItem`},
            icon: `plus`,
            label: `Dodaj`,
            acl: {service: service, action: `put_workflow_state`}
          },
          {
            event: {name: `${service}:appraisal:stateSubmit`},
            icon: `save`,
            label: `Zapisz`,
            acl: {service: service, action: `put_workflow_state`}
          },
          {
            event: {name: `dashboard:menu:redirect`, value: `${service}_appraisal_state_listing`},
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: {service: service, action: `get_workflow_states`}
          }
        ]
      },
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu`,
      name: `${service}_appraisal_workflow_listing`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/transition/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: `git-commit`,
        title: `Lista przejść`,
        secondMenu: [
          {
            event: {name: `${service}:appraisal:transitionEdit`},
            icon: `edit`,
            label: `Tryb edycji`,
            acl: {service: service, action: `put_workflow_transitions`}
          }
        ]
      },
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
      name: `${service}_appraisal_workflow_edit`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/transition/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_workflow_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: {name: `share:list:addItem`},
            icon: `plus`,
            label: `Dodaj`,
            acl: {service: service, action: `put_workflow_transitions`}
          },
          {
            event: {name: `${service}:appraisal:transitionSubmit`},
            icon: `save`,
            label: `Zapisz`,
            acl: {service: service, action: `put_workflow_transitions`}
          },
          {
            event: {name: `dashboard:menu:redirect`, value: `${service}_appraisal_workflow_listing`},
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: {service: service, action: `get_workflow_transitions`}
          }
        ]
      },
    },
    {
      path: `${pathPrefix}widocznosc-zlecen`,
      name: `${service}_appraisal_task_visibility_listing`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/task-visibility/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: `eye`,
        title: `Lista widoczności zleceń`,
        secondMenu: [
          {
            event: {name: `${service}:appraisal:taskVisibilityEdit`},
            icon: `edit`,
            label: `Tryb edycji`,
            // acl: { service: service, action: ``post_task_visibilities_@@taskCategory@@`` }
            acl: {service: service, action: `post_task_category_task_visibilities`}
          }
        ]
      },
    },
    {
      path: `${pathPrefix}widocznosc-zlecen/edycja`,
      name: `${service}_appraisal_task_visibility_edit`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/task-visibility/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_task_visibility_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: {name: `share:list:addItem`},
            icon: `plus`,
            label: `Dodaj`,
            // acl: { service: service, action: ``post_task_visibilities_@@taskCategory@@`` }
            acl: {service: service, action: `post_task_category_task_visibilities`}
          },
          {
            event: {name: `${service}:appraisal:taskVisibilitySubmit`},
            icon: `save`,
            label: `Zapisz`,
            // acl: { service: service, action: ``post_task_visibilities_@@taskCategory@@`` }
            acl: {service: service, action: `post_task_category_task_visibilities`}
          },
          {
            event: {name: `dashboard:menu:redirect`, value: `${service}_appraisal_task_visibility_listing`},
            icon: `arrow-left`,
            label: `Wróć do listy`,
            // acl: { service: service, action: ``get_task_visibilities_@@taskCategory@@`` }
            acl: {service: service, action: `get_task_category_task_visibilities`}
          }
        ]
      },
    },
    {
      path: `${pathPrefix}wymagalnosc-kategorii`,
      name: `${service}_appraisal_attachment_category_requirement_listing`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/attachment-requirement/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask
          ]
        },
        icon: `image`,
        title: `Lista wymagalności kategorii`,
        secondMenu: [
          {
            event: {name: `${service}:appraisal:attachmentCategoryRequirementEdit`},
            icon: `edit`,
            label: `Tryb edycji`,
            // acl: { service: service, action: ``put_@@taskCategory@@_attachment_category_requirements`` }
            acl: {service: service, action: `put_attachment_category_requirements`}
          }
        ]
      },
    },
    {
      path: `${pathPrefix}wymagalnosc-kategorii/edycja`,
      name: `${service}_appraisal_attachment_category_requirement_edit`,
      component: () => import(`../../../../components/bundles/${service}/appraisal/admin/attachment-requirement/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.appraisalTask,
            `${service}_appraisal_attachment_category_requirement_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: {name: `share:list:addItem`},
            icon: `plus`,
            label: `Dodaj`,
            // acl: { service: service, action: ``put_@@taskCategory@@_attachment_category_requirements`` }
            acl: {service: service, action: `put_attachment_category_requirements`}
          },
          {
            event: {name: `${service}:appraisal:attachmentCategoryRequirementSubmit`},
            icon: `save`,
            label: `Zapisz`,
            // acl: { service: service, action: ``put_@@taskCategory@@_attachment_category_requirements`` }
            acl: {service: service, action: `put_attachment_category_requirements`}
          },
          {
            event: {
              name: `dashboard:menu:redirect`,
              value: `${service}_appraisal_attachment_category_requirement_listing`
            },
            icon: `arrow-left`,
            label: `Wróć do listy`,
            // acl: { service: service, action: ``get_@@taskCategory@@_attachment_category_requirements`` }
            acl: {service: service, action: `get_role_attachment_category_requirements`}
          }
        ]
      },
    }
  ]

  return routes.map(route => {
    route.props = true
    route.meta.securityDynamic = true
    route['beforeEnter'] = beforeRouteValidateAclPrevalidation(acl)
    return route
  })
}
