import RouterFrame from '../../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen
import IntgenPersonListing from '../../../../components/bundles/intgen/admin/person/Listing.vue'

export default {
  path: 'person',
  component: RouterFrame,
  meta: {
    title: 'Baza poszkodowanych',
    icon: 'users',
    menuLink: true,
    acl: {
      service: 'intgen',
      action: 'get_persons'
    }
  },
  children: [
    {
      path: '',
      component: IntgenPersonListing,
      name: 'intgen_person_listing',
      meta: {
        title: 'Lista poszkodowanych',
        acl: {
          service: 'intgen',
          action: 'get_persons'
        }
      }
    }
  ]
}
