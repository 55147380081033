// NOTE: common configuration for modules intmil and intbwn

import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  return [
    {
      path: `${pathPrefix}role`,
      name: `${service}_role_listing`,
      component: () => import(`../../../../components/bundles/${service}/admin/role/Listing.vue`),
      meta: {
        columns: [
          { id: 0, data: `label`, title: `Opis` },
          { id: 1, data: `name`, title: `Nazwa` },
          { id: 2, data: `businessRole`, title: `Rola biznesowa`, searchable: false }
        ],
        acl: {
          service: service,
          action: `get_roles`
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service]
          ]
        },
        icon: `shield`,
        title: `Lista ról`,
        secondMenu: [
          {
            event: { name: `${service}:role:addRole` },
            icon: `plus`,
            label: `Dodaj rolę`,
            acl: { service: service, action: `post_role` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}role/:id`,
      name: `${service}_role_details`,
      component: () => import(`../../../../components/bundles/${service}/admin/role/Details.vue`),
      meta: {
        acl: {
          service: service,
          action: `get_roles`
        },
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs[service],
            `${service}_role_listing`
          ]
        },
        title: `Szczegóły roli`,
        secondMenu: [
          {
            event: { name: `${service}:role:editRole` },
            icon: `edit`,
            label: `Edytuj dane`,
            acl: { service: service, action: `put_role` }
          },
          {
            event: { name: `${service}:role:editActionsAccess` },
            icon: `unlock`,
            label: `Edytuj uprawnienia`,
            acl: { service: service, action: `put_role_access_config` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}role/:id/edycja-poziomow-dostepu`,
      name: `${service}_role_details_acl_edition`,
      component: () => import(`../../../../components/bundles/${service}/admin/role/details/AclEditionCheckboxes.vue`),
      props: { service: service },
      meta: {
        acl: {
          service: service,
          action: `get_role_access_tree`
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_role_listing`,
            `${service}_role_details`
          ]
        },
        title: `Edycja poziomów dostępu`,
        thirdMenu: [
          {
            event: { name: `${service}:acl:submitData` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `put_role_access_config` }
          },
          {
            event: { name: `dashboard:menu:redirect`, value: `${service}_role_details` },
            icon: `arrow-left`,
            label: `Wróć do szczegółów`,
            acl: { service: service, action: `get_role` }
          }
        ]
      }
    }
  ]
}
