<template>
  <div class="row">
    <input
      v-if="value.id"
      :id="'id_' + value.id"
      type="hidden"
      :value="value.id"
    >
    <div class="col-sm-2">
      <div>
        <label
          for="employee"
          :class="{'required-label': !value.employee }"
        >Pracownik:</label>
        <select
          id="employee"
          class="form-control"
          :value="value.employee"
          :class="{'required-select': !value.employee }"
          @input="updateEmployee($event.target.value)"
        >
          <option
            v-for="option in employees"
            :value="option.value"
            :key="option.value"
            :selected="option.value == value.employee"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <!--<vue-form-generator-->
    <!--class="col-sm-6"-->
    <!--:schema="{groups: [{fields: [datepickerStartSchema, datepickerEndSchema]}]}"-->
    <!--:model="value"-->
    <!--&gt;</vue-form-generator>-->
    <vue-form-generator
      class="col-sm-2"
      :schema="{groups: [{fields: [datepickerStartSchema]}]}"
      :model="startDate"
    />
    <div class="col-sm-2">
      <label for="timestart">Godzina rozpoczęcia:</label>
      <select
        id="timestart"
        class="form-control"
        :value="start"
        :disabled="!startDate.hasOwnProperty('date')"
        @input="updateStartValue"
      >
        <option
          v-for="option in workingHours"
          :value="option.value"
          :key="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="col-sm-1">
      <label for="timeEnd">Interwały:</label>
      <select
        id="timeEnd"
        v-model="value.intervals"
        class="form-control"
        :disabled="!startDate.hasOwnProperty('date')"
        @input="updateEndValue"
      >
        <option
          v-for="option in intervalsArray"
          :value="option.value"
          :key="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div v-if="service !== 'extpko'" class="col-sm-1">
      <label for="priority">Priorytet:</label>
      <select
        id="priority"
        v-model="value.priority"
        class="form-control"
        :disabled="!startDate.hasOwnProperty('date')"
        @input="updatePriorities"
      >
        <option
          v-for="option in priorities"
          :value="option.value"
          :key="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div
      class="col-md-8 col-lg-4 days-checkbox-list"
    >
      <div
        v-for="(day, index) in days"
        :key="index"
        class="day-checkbox-container"
      >
        <label
          :for="`chkbox-${day.label}-${index}-${value.id}`"
          style="position: relative;"
        >{{ day.label }}</label>
        <input
          :id="`chkbox-${day.label}-${index}-${value.id}`"
          v-model="value['cyclicity'][index]"
          type="checkbox"
          :disabled="false"
        >
        <label :for="`chkbox-${day.label}-${index}-${value.id}`" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import moment from 'moment'

Vue.use(VueFormGenerator)

export default {
  components: {
  },
  props: {
    value: { type: Object, required: true },
    employees: { type: Array, required: true },
    service: { required: true }
  },
  data () {
    return {
      selectedDays: [],
      start: '',
      end: '',
      intervals: '',
      startDate: {},
      datepickerStartSchema: {
        'type': 'dateTimePicker',
        'label': 'Od:',
        'inputName': 'startDate',
        'disabled': false,
        'required': false,
        'relatedFields': [],
        'model': 'date',
        'readonly': false,
        'dateTimePickerOptions': { 'format': 'YYYY-MM-DD', 'locale': 'pl' }
      },
      datepickerEndSchema: {
        'type': 'dateTimePicker',
        'label': 'Do',
        'inputName': 'endAt',
        'disabled': false,
        'required': false,
        'relatedFields': [],
        'model': 'endAt',
        'readonly': false,
        'dateTimePickerOptions': { 'format': 'YYYY-MM-DD', 'locale': 'pl' }
      }
    }
  },
  computed: {
    startDateComp: {
      get () {
        return [this.startDate.date, this.start].join(' ')
      },
      set (newVal) {
        this.value.startAt = newVal
      }
    },
    workingHours: function () {
      let intervalLength = 45
      if (this.service === 'extpko') {
        intervalLength = 30
      }
      return [...Array(30).keys()].map((x, index) => {
        return {
          label: moment(new Date(2011, 2, 12, 9, 0, 0)).add(index * intervalLength, 'm').toISOString().split('T')[1].split('').filter((letter, index) => index < 5).join(''),
          value: moment(new Date(2011, 2, 12, 9, 0, 0)).add(index * intervalLength, 'm').toISOString().split('T')[1].split('.')[0]
        }
      })
    },
    intervalsArray: function () {
      let intervalCount = 12
      if (this.service === 'extpko') {
        intervalCount = 22
      }
      return [...Array(intervalCount).keys()].map((x, index) => {
        return {
          label: index + 1,
          value: index + 1
        }
      })
    },
    priorities: function () {
      return [...Array(10).keys()].map((x, index) => {
        return {
          label: index + 1,
          value: index + 1
        }
      })
    },
    days () {
      return [...Array(7).keys()].map((x, index) => {
        return {
          label: moment([2019, 2, 11]).weekday(index).format('dd'),
          value: index + 1
        }
      })
    }
  },
  watch: {
    'selectedDays': function (newVal) {
      this.updateCyclicity()
    },
    'startDate.date': function () {
      this.updateStartValue()
    },
    'value.startAt': function (newVal) {
      this.updateBusinessHours()
    },
    'value.endAt': function (newVal) {
      this.updateBusinessHours()
    }
  },
  mounted () {
    this.start = this.value && this.value.startAt && this.value.startAt.split(' ')[1]
    this.startDate = { date: this.value && this.value.startAt && this.value.startAt.split(' ')[0] }
  },
  methods: {
    updateCyclicity () {
      this.value.cyclicity = this.selectedDays
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateEndValue (event) {
      let intervalLength = 45
      if (this.service === 'extpko') {
        intervalLength = 30
      }
      if (event) {
        this.end = event.target.value
        this.emitModificationSignal()
      }
      this.value.intervals = this.end
      let end = moment(this.value.startAt).add(this.value.intervals * intervalLength, 'm')
      this.value.endAt = end.format('YYYY-MM-DD HH:mm:ss')
      this.$emit('input', this.value)
    },
    updateStartValue (event) {
      let intervalLength = 45
      if (this.service === 'extpko') {
        intervalLength = 30
      }
      if (event) {
        this.start = event.target.value
        this.emitModificationSignal()
      }
      this.value.startAt = this.startDateComp
      if (this.value.hasOwnProperty('intervals')) {
        let end = moment(this.value.startAt).add(this.value.intervals * intervalLength, 'm')
        this.value.endAt = end.format('YYYY-MM-DD HH:mm:ss')
      }
      this.$emit('input', this.value)
    },
    updatePriorities (event) {
      if (event) {
        this.emitModificationSignal()
      }
    },
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateEmployee (value) {
      this.value.employee = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateBusinessHours () {
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
    .day-checkbox-container {
      display: flex; flex-direction: column;  width: 30px;
    }
    .days-checkbox-list {
      display: flex; flex-direction: row;
    }

    .input-group {
        width: 100%;
    }
    .required-select {
        border: red 1px solid;
    }
    .required-label:before {
        color: red;
        content: '*'
    }
</style>
