import RouterFrame from '../../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen
import IntgenRoleListing from '../../../../components/bundles/intgen/admin/role/Listing.vue'

export default {
  path: 'role',
  component: RouterFrame,
  meta: {
    title: 'Role',
    icon: 'shield',
    menuLink: true,
    acl: {
      service: 'intgen',
      action: 'get_roles'
    }
  },
  children: [
    {
      path: '',
      component: IntgenRoleListing,
      name: 'intgen_role_listing',
      meta: {
        title: 'Lista ról',
        acl: {
          service: 'intgen',
          action: 'get_roles'
        }
      }
    }
  ]
}
