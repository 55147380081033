import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/Person'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    service: { type: String, required: true }
  },
  methods: {
    submitForm () {},
    createDataToSend () {
      let data = {
        company: this.model.company ? this.model.company : false,
        name: this.model.name && this.model.company ? this.model.name : '',
        forename: this.model.forename && !this.model.company ? this.model.forename : '',
        surname: this.model.surname && !this.model.company ? this.model.surname : '',
        pesel: this.model.pesel ? this.model.pesel : '',
        nip: this.model.nip ? this.model.nip : '',
        phone: this.model.phone ? this.model.phone : '',
        email: this.model.email ? this.model.email : '',
        address: null
      }
      if (this.$isWithClients(this.service)) {
        data['clientUuid'] = this.$route.meta.client ? this.$store.state.base.user.serviceClients[this.service].find((client) => client.id === this.$route.meta.client).uuid : this.client
      }

      const address = {
        street: this.model.street ? this.model.street : '',
        city: this.model.city ? this.model.city : '',
        postcode: this.model.postcode ? this.model.postcode : '',
        voivodeship: this.model.voivodeship ? this.model.voivodeship : null,
        country: this.model.country && parseInt(this.model.voivodeship) === 17 ? this.model.country : ''
      }

      const isAddressFilled = Object.keys(address).some(item => address[item])

      if (isAddressFilled) {
        data.address = address
      }

      return data
    }
  }
}
