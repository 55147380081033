import role from './role'
import taskHeaders from './task-header'
import person from './person'
import online from './calendar/online'
import visibility from './calendar/visibility'
import RouterFrame from '../../../components/share/RouterFrame.vue'
// import mainPerson from './main-person'
// import mainProperty from './main-property'
import main from './main'
import complaint from './complaint'
import appraisal from './appraisal'
import clients from './clients'
import taskCategory from './taskCategoryRole'
import getProperServiceIcon from '../../utils/getProperServiceIcon'

// const getAppraisalAdminPanel = function (service, name) {
//   let services = ['extgen', 'extpko', 'extavi']
//   return (services.indexOf(service) > -1) ? [] : appraisal(service, name)
// }
const getWithClientsMenu = function (service, name, routes) {
  return process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? routes(service, name) : []
}
const checkAvailabilityMain = (group, service, serviceClient, name) => {
  if (!group.hasOwnProperty('main')) {
    return []
  }
  return group.main.map(taskCategory => main(service, name, serviceClient, taskCategory))
}
const checkAvailabilityAppraisal = (group, service, serviceClient, name) => {
  if (!group.hasOwnProperty('appraisal')) {
    return []
  }
  return group.appraisal.map(taskCategory => appraisal(service, name, serviceClient, taskCategory))
}
const checkAvailabilityComplaint = (group, service, serviceClient, name) => {
  if (!group.hasOwnProperty('complaint')) {
    return []
  }
  return group.complaint.map(taskCategory => complaint(service, name, serviceClient, taskCategory))
}
export default function (service, name, serviceClients) {

  const getClientAdminMenu = (serviceClient) => {
    return {
      path: `administracja-procesami-${serviceClient.label}`,
      component: RouterFrame,
      meta: {
        title: serviceClient.label,
        icon: getProperServiceIcon(service),
        menuTree: true,
        acl: {
          service: service,
          action: ''
        }
      },
      children: [
        ...checkAvailabilityMain(serviceClient.taskCategories, service, serviceClient, name),
        ...checkAvailabilityAppraisal(serviceClient.taskCategories, service, serviceClient, name),
        ...checkAvailabilityComplaint(serviceClient.taskCategories, service, serviceClient, name)
        // getAppraisalAdminPanel(service, name, serviceClient)
      ]
    }
  }
  return {
    path: `administracja-procesami-${name}`,
    component: RouterFrame,
    meta: {
      title: name,
      icon: getProperServiceIcon(service),
      menuTree: true,
      acl: {
        service: service,
        action: ''
      }
    },
    children: [
      getWithClientsMenu(service, name, clients),
      taskCategory(service, name),
      role(service, name),
      taskHeaders(service, name),
      person(service, name),
      online(service, name),
      // getWithClientsMenu(service, name, mainPerson),
      visibility(service, name),
      ...serviceClients.map(getClientAdminMenu)
    ]
  }
}
