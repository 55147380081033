import api from '../../../../api'
import moment from "moment";
import {setupTotalCount} from '../setupTotalCount'
import _ from 'lodash'

export default {
  data() {
    return {
      workloadPerAssigneeServices: ['intgen', 'intbls', 'intvig'],
      workloadPerAssigneeAcl: ['get_workload_per_assignee_statistics'],
      workloadPerAssigneeKey: 98760,
      workloadPerAssigneeLoaded: false,
      selectedWorkloadPerAssigneeDate: moment().format('YYYY-MM-DD'),
      selectedReportWorkloadPerAssigneeDate: '',
      workflowPerAssigneeChangeDateEvent: 'workloadPerAssigneeChangeDate',
      workloadPerAssigneeDateOptions: [
        {value: moment().format('YYYY-MM-DD'), label: 'Dzisiaj'},
        {value: 'all', label: 'Wszystkie'}
      ],
      workloadPerAssignee: {
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        title: 'Obciążenie opiekunów',
        total: '',
        data: {
          json: [],
          keys: {
            x: 'name',
            value: [],
          },
          type: 'bar',
          groups: []
        },
        partialReport: null
      },
      workloadPerAssigneeDefault: {},
      categories: [
        'no_appointment',
        'no_inspection',
        'inspection_after_time',
        'late_inspection'
      ],
    }

  },
  methods: {
    getWorkloadPerAssigneeStatisticRequests (services) {
      return services.map(service => api.request(service, 'get', `statistics/tasks/workload-per-assignee`))
    },
    handleWorkloadPerAssigneeChange (selectedOption) {
      if (this.workloadPerAssigneeLoaded) {
        this.reloadWorkloadPerAssigneeStatistic(this.workloadPerAssigneeServices, selectedOption)
      }
    },
    loadWorkloadPerAssigneeStatistic (services, selectedOption = null) {
      selectedOption = selectedOption === null ? moment().format('YYYY-MM-DD') : 'all'
      this.selectedWorkloadPerAssigneeDate = selectedOption
      this.selectedReportWorkloadPerAssigneeDate = selectedOption
      this.workloadPerAssigneeDefault = _.cloneDeep(this.workloadPerAssignee)
      this.processWorkloadPerAssigneeStatistic(services, selectedOption)
      return
    },
    reloadWorkloadPerAssigneeStatistic (services, selectedOption) {
      this.selectedWorkloadPerAssigneeDate = selectedOption
      this.selectedReportWorkloadPerAssigneeDate = selectedOption
      this.workloadPerAssignee = _.cloneDeep(this.workloadPerAssigneeDefault)
      this.processWorkloadPerAssigneeStatistic(services, selectedOption)
      return
    },
    async processWorkloadPerAssigneeStatistic (services, selectedOption, buildElements = false) {
      services = this.$auth.getAllowed({services: services, actions: this.workloadPerAssigneeAcl, allowOneOfActions: true})

      if (!_.isEmpty(services)) {
        try {
          let requests = this.getWorkloadPerAssigneeStatisticRequests(services)
          let responseData = await this.getResponses(requests)
          let chartData = {
            columns: {
              data: [],
              categories: ['x']
            }
          }

          this.prepareChartByUser(responseData, chartData, this.contractorApps, selectedOption)
          this.addWorkloadPerAssigneeTaskToCharts(chartData)
          this.workloadPerAssigneeLoaded = true
          this.workloadPerAssigneeKey = Math.random() * 100
        } catch (error) {
          console.error(error)
        }
      }
      return
    },
    addWorkloadPerAssigneeTaskToCharts(chartData) {
      let defaultChartData = _.cloneDeep(this.workloadPerAssigneeDefault)
      let statistics = this.getRebuildedChartData(chartData, defaultChartData)
      statistics.total = setupTotalCount(statistics.data.json)
      this.workloadPerAssignee = statistics
    },
    getDownloadWorkloadPerAssigneeLinkData(type, withDate = false, withClients = false) {
      return {
        type: `assignee_workload${this.selectedWorkloadPerAssigneeDate === 'all' ? '' : '&dateFrom=' + this.selectedWorkloadPerAssigneeDate}`,
        reportName: 'assignee_workload',
        service: 'all',
        withDate,
        withClients,
      }
    }
  }
}
