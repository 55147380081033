<template>
  <div>
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div class="row">
                  <select-type
                    class="col-sm-4 col-md-3"
                    name="role"
                    :label="'Rola'"
                    :required="false"
                    :options="roles"
                    :disabled="!rolesLoaded"
                    :empty-first="true"
                    :value="model.roleId"
                    @input="changeRole"
                  />
                </div>
                <div
                  v-if="model.roleId !== null"
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Poziomy widoczności zleceń</h2>
                    <list
                      :items="model.taskVisibilities"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <task-visibility-type
                          v-model="listItems.item"
                          :states="states"
                          :visibilities="visibilities"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />
    <login-modal
      :show="loginVisible"
      :back-url-name="`${service}_${workflow.split('-')[1]}_task_visibility_listing`"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../../share/content/Page'
import api from '../../../../../api'
import List from '../../../../share/form/List'
import SelectType from '../../../../share/form/type/SelectType'
import LoginModal from '../../../share/modal/LoginModal'
import TaskVisibilityType from '../../../share/form/type/TaskVisibilityType'
import SaveDataInformation from '../../../share/modal/SaveDataInformation'

export default {
  components: {
    TaskVisibilityType,
    SelectType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      events: null,
      rolesLoaded: false,
      statesLoaded: false,
      taskVisibilitiesLoaded: false,
      roles: [],
      states: [],
      visibilities: [],
      headerTitle: { title: 'Widoczność zleceń', description: 'Tryb edycji' },
      loginVisible: false,
      allowedAccess: false,
      model: {
        roleId: null,
        taskVisibilities: []
      },
      isSaved: true,
      saveDataInformationVisible: false,
      previouslySelectedRole: null,
      workflow: this.$route.meta.workflow
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.taskVisibilitySubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      this.events = {
        taskVisibilitySubmit: `${this.service}:main:taskVisibilitySubmit`
      }
    },
    init () {
      this.allowedAccess = true
      this.loadRoles()
      this.loadStates()
      this.loadVisibilities()
    },
    createEmptyElement () {
      return {
        id: null,
        state: { id: null },
        visibility: null
      }
    },
    loadRoles () {
      api.request(this.service, 'get', '/roles')
        .then((response) => {
          const roles = response.data
          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({ label: roles[c].label, value: roles[c].id })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    loadStates () {
      api.request(this.service, 'get', `/workflows/${this.workflow}/states`)
        .then((response) => {
          const states = response.data
          for (let c = 0; c < states.length; ++c) {
            this.states.push({ label: states[c].label, value: states[c].id })
          }
          this.statesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    },
    loadVisibilities () {
      this.visibilities = [
        { label: 'brak', value: 0 },
        { label: 'swoje (odpowiedzialny)', value: 1 },
        { label: 'swoje lub swojego autorstwa', value: 3 },
        { label: 'wszystkie', value: 4 }
      ]
    },
    forceToSave () {
      this.isSaved = false
    },
    changeRole (optionValue) {
      if (!this.isSaved && this.model.taskVisibilities.length > 0) {
        document.getElementById('role').value = this.previouslySelectedRole
        this.saveDataInformationVisible = true
        return
      }

      this.model.roleId = this.previouslySelectedRole = optionValue

      this.loadTaskVisibilityList()
    },
    loadTaskVisibilityList () {
      api.request(this.service, 'get', `/roles/${this.model.roleId}/task-visibility-list?type=${this.workflow.split('-')[1]}`)
        .then((response) => {
          this.model.taskVisibilities = response.data
          this.taskVisibilitiesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować widoczności zleceń'
          })
        })
    },
    submitData () {
      let submitData = { taskVisibilities: [], role: this.model.roleId }
      for (let c = 0; c < this.model.taskVisibilities.length; ++c) {
        submitData.taskVisibilities.push({
          id: this.model.taskVisibilities[c].id,
          role: this.model.roleId,
          state: this.model.taskVisibilities[c].state.id,
          visibility: this.model.taskVisibilities[c].visibility
        })
      }
      api.request(this.service, 'post', `/task-visibilities/${this.workflow}`, submitData)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Błąd zapisu danych: ${error.message}`
          })
        })
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 18px
}
</style>
