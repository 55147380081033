<template>
  <div></div>
</template>
<script>
import Logout from '../../share/mixins/Logout'
import api from '../../../api'
import WebStorage from '../../share/WebStorage'
import config from '../../../../public'

export default {
  mixins: [
    Logout
  ],
  data () {
    return {
      refreshTimer: ''
    }
  },
  created () {
    if (WebStorage.getIsCurrentSession() === '') {
      WebStorage.setIsCurrentSession('true')
    } else {
      this.refreshToken()
    }

    this.startRefreshTimer()
  },
  onIdle () {
    this.securityLogoutOnIdle()
  },
  methods: {
    refreshToken () {
      var refreshToken = this.$store.state.base.refreshToken
      api.request('core', 'post', config.app.refreshToken.url, { refresh_token: refreshToken })
        .then((response) => {
          const data = response.data
          if (data.token) {
            const token = `Bearer ${data.token}`
            data.user[`serviceClients`] = {}
            Object.keys(data.data.contractorApps).forEach(app => {
              if (this.$isWithClients(app)) {
                data.user[`serviceClients`][app] = WebStorage.getUser().serviceClients[app]
              }
            })
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)
            WebStorage.setToken(token)
          }
        })
        .catch(() => {
          this.logout()
          this.$router.push({ name: 'security_login', query: { redirect: this.$route.fullPath }, params: { notification: 'security-logout' } })
        })
    },
    startRefreshTimer () {
      this.refreshTimer = setInterval(this.refreshToken, config.app.refreshToken.expirationTime)
    },
    cancelAutoRefresh () { clearInterval(this.refreshTimer) },
    securityLogoutOnIdle () {
      this.cancelAutoRefresh()
      this.logout()
      this.$router.push({ name: 'security_login', query: { redirect: this.$route.fullPath }, params: { notification: 'security-logout' } })
    }
  }
}
</script>
