import api from '@/api/index.js'

export default {
  data() {
    return {
        inspectionOnline: {
            title: 'Podzlecenia online',
            total: '',
            type: 'pie',
            data: {
              columns: []
            },
            otherArgs: {
              pie: {
                label: {
                  format: function (value, ratio, id) {
                    return value
                  }
                }
              }
            },
            types: []
          },
    }
  },
  methods: {
    loadInspectionOnline () {
      if (!this.isAllowed(['intgen'], 'get_inspection_online_appraisal_statistics')) {
        return
      }
      api.request('intgen', 'get', `statistics/appraisal/inspection-online?user=${this.$store.state.base.user.uuid}`)
        .then((response) => {
          const data = response.data

          const totalCount = Object.values(data).reduce((acc, curr) => acc + curr, 0)
          this.inspectionOnline.total = `Łącznie: ${totalCount}`

          this.assignDataToPieces(this.inspectionOnline, data)
        })
        .catch((e) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować danych do wykresu Oględziny online'
          })
        })
    },
    assignDataToPieces (chart, data) {
      let users = Object.keys(data)
      users.forEach((user) => {
        if (chart.hasOwnProperty('users')) {
          chart.users.push(user)
        }
        chart.data.columns.push([user, data[user]])
      })
    },
  },
}
