import VeeValidate from 'vee-validate'
import pl from 'vee-validate/dist/locale/pl'
import sl from 'vee-validate/dist/locale/sl'
import cs from 'vee-validate/dist/locale/cs'

var veeValidateOptions = {
  locale: 'pl',
  errorBagName: 'vErrors',
  dictionary: {
    pl,
    sl,
    cs,
  }
}

export default {
  veeValidate: VeeValidate,
  veeValidateOptions: veeValidateOptions
}
