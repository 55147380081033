<template>
  <form>
    <input-type
      v-model="value.username"
      v-validate="{ rules: { required: true, regex: /^([a-zA-Z0-9ęóąśłżźćńĘÓĄŚŁŻŹĆŃ-]+\s*){3,}/ }}"
      label="Login/Email"
      name="username"
      :error-message="vErrors.first('username')"
      :required="true"
      data-vv-value-path="value"
      data-vv-as="login/email"
    />
    <input-type
      v-model="value.password"
      v-validate="{ rules: { required: true }}"
      label="Hasło"
      name="password"
      type="password"
      :error-message="vErrors.first('password')"
      :required="true"
      data-vv-value-path="value"
      data-vv-as="hasło"
    />
  </form>
</template>

<script>
import InputType from './type/InputType'

export default {
  components: {
    InputType
  },
  props: {
    value: {}
  }
}
</script>
