<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="dates"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
  import BaseFormModal from '../../../../../share/modal/BaseFormModal'
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from './AddSettlementForm'
  import ValidatorReset from '../../../../../share/mixins/ValidatorReset'
  import { mapActions, mapState } from 'vuex'
  import moment from 'moment'

  export default {
    components: {
      FormModal,
      BaseFormModal
    },
    mixins: [
      FormModalMixin,
      ValidatorReset
    ],
    props: {
      service: { type: String, default: '' }
    },
    data () {
      return {
        dates: {
          beginAt: null,
          endAt: moment().format('YYYY-MM-DD')
        },
        title: 'Dodaj nowe rozliczenie'
      }
    },
    computed: {
      ...mapState({
        vuexSettlement: state => state.settlementsState.settlement
      })
    },
    mounted () {
    },
    methods: {
      ...mapActions([
        'addNewSettlement'
      ]),
      async submitForm () {
        if (!this.dates.endAt || !this.dates.beginAt) {
          this.$notify({
            type: 'info',
            text: 'Daty muszą być uzupełnione'
          })
          return
        }
        if (moment(this.dates.endAt).format('YYYY-MM-DD').valueOf() > moment().format('YYYY-MM-DD').valueOf()  || moment(this.dates.beginAt).format('YYYY-MM-DD').valueOf() > moment().format('YYYY-MM-DD').valueOf() ) {
          this.$notify({
            type: 'info',
            text: 'Daty muszą być wcześniejsze niż dzień bieżący'
          })
          return
        }

        try {
          await this.addNewSettlement({
            service: this.service,
            beginAt: this.dates.beginAt,
            endAt: this.dates.endAt,
            $router: this.$router,
            notify: this.$notify,
          })
          this.dates.beginAt = null
          this.dates.endAt = null
        } catch (e) {
          console.error(e)
          this.errorNotify(e)
        }
      }
    }
  }
</script>
