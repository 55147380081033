import * as d3 from 'd3'
import api from '../../../../api'
import {setupTotalCount} from '../setupTotalCount'
import _ from 'lodash'

export default {
  data () {
    return {
      inspectionServices: ['intbls', 'intgen', 'intvig', 'intvhs'],
      inspectionAcl: ['get_inspection_appraisal_statistics'],
      selected: [],
      ready: 0,
      inspection: {
        axis: {
          x: {
            type: 'category',
            tick: {}
          },
          y: {
            tick: {
              format: d3.format('d') // ADD
            }
          }
        },
        groups: [],
        title: 'Podzlecenia główne',
        total: '',
        data: {
          x: 'x',
          columns: [],
          groups: [],
          selection: {
          },
          onselected: (part, node) => {
          },
          onunselected: (slice, node) => {
          }
        },
        partialReport: null
      },
      categoriesInspection: [
        'x',
        'Brak daty kontaktu',
        'Brak daty oględzin',
        'Oględziny po czasie',
        'Późne oględziny'
      ]
    }
  },
  methods: {
    getAllowedInspectionServices(services) {
      return this.$auth.getAllowed({ services: services, actions: this.inspectionAcl, allowOneOfActions: true })
    },
    getInspectionStatisticRequests (services) {
      return services.map(service => api.request(service, 'get', `statistics/appraisal/inspection?user=${this.$store.state.base.user.uuid}`))
    },
    loadInspectionStatistic (services) {
      this.processInspectionStatistic(services)
      return
    },
    async processInspectionStatistic (services) {
      services = this.getAllowedInspectionServices(services)

      if (!_.isEmpty(services)) {
        try {
          let requests = this.getInspectionStatisticRequests(services)
          let responseData = await this.getResponses(requests)

          let chartOptions = this.prepareChartOptions(responseData, this.$store.state.base.user.serviceClients, this.contractorApps)
          chartOptions.categories = this.categoriesInspection

          let chartData = {'columns': {}}
          chartData['categories'] = chartOptions.categories
          this.addGroup(chartData, chartOptions)
          this.prepareChartWithOtions(responseData, chartData, chartOptions)
          this.addInspectionTaskToCharts(chartData)
          this.addInspectionTaskToRefChart(!_.isEmpty(responseData), chartData)
        } catch (error) {
          console.error(error)
        }
      }
      return
    },
    addInspectionTaskToCharts (chartData) {
      this.inspection.total = setupTotalCount(chartData.columns)
      this.inspection.data.columns.push(chartData.categories)
      Object.entries(chartData.columns).forEach(([key, value]) => { this.inspection.data.columns.push(value) })

      if (this.$refs.inspection) {
        this.$store.commit('SET_INSPECTION_CATEGORIES', this.inspection.data.columns[0].map((el, index) => {
          if (el === 'x') {
            return { label: '', value: 0 }
          }
          return { label: el, value: this.categories[index - 1] }
        }))
      }
    },
    addInspectionTaskToRefChart(addToRef, chartData) {
      if (addToRef) {
        this.$refs.inspection.chart.groups([chartData.groups])
        this.$refs.inspection.chart.selection = this.inspection.data.selection
      }
    }
  }
}
