
export default {
  CLEAR_SETTLEMENT_AND_ITEMS (state) {
    state.settlement = {}
    state.items = []
  },
  SET_SETTLEMENT_STATE (state, payload) {
    let settlement = payload.data
    if (payload.data.hasOwnProperty('createdBy')) {
      settlement['createdByLabel'] = payload.data.createdBy.label
    }
    state.settlement = settlement
  },
  ADD_SETTLEMENT_ITEMS (state, payload) {
    if (state.items.find(item => item.uuid === payload.uuid)) {
      state.items.map(item => item.uuid === payload.uuid ? { uuid: payload.uuid, data: payload.data } : item)
    } else {
      state.items.push({ uuid: payload.uuid, data: payload.data })
    }
  },
  EDIT_SETTLEMENT_ITEM_COMMENT (state, payload) {
    state.items.map(item => item.data.id === payload.id ? item.data.comment = payload.comment : item)
  },
  EDIT_SETTLEMENT_ITEM_AMOUNT (state, payload) {
    state.items.map(item => item.data.id === payload.id ? item.data.amount = payload.amount : item)
  },
  EDIT_SETTLEMENT_COMMENT (state, payload) {
    state.settlement.comment = payload.comment
  },
  SET_BLOCKING_LOADER (state, payload) {
    state.loader = {loading: payload.loading, message: payload.message}
  },
  SET_DOWNLOADED_FILE (state, payload) {
    state.settlementFile = payload
  },
  SET_SETTLEMENT_OWNERSHIP_STATE (state, payload) {
    state.settlementOwnership = payload
  },
  SET_SETTLEMENT_DATATABLES_OWNERSHIP_STATE (state, payload) {
    state.settlementDatatablesOwnership = payload
  },
  SET_SETTLEMENT_PUT_OWNERSHIP_STATE (state, payload) {
    state.settlementPutOwnership = payload
  },
  SET_FILE_UUID (state, payload) {
    state.fileUuid = payload
  },
  SET_VAT_OPTIONS (state, payload) {
    state.vatOptions = payload
  },
  SET_VAT_MODEL (state, payload) {
    state.vatModel = payload
  },
  CLEAR_USER_VAT (state, payload) {
    state.vatModel = {}
  }
}
