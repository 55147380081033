<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="details-header">
        <div class="details-header--details">
          <h2>Dane podstawowe</h2>
          <ul class="list-unstyled">
            <li><label>Nazwa:</label> {{ role.label }}</li>
            <li><label>Opis:</label> {{ role.description }}</li>
          </ul>
        </div>

        <slot />

        <div
          v-if="controlModule"
          class="details-header--details"
        >
          <h2 class="box-title">
            Uprawnienia specjalne
          </h2>
          <ul class="list-unstyled">
            <li>Zezwól na zmiany w konfiguracji ACL na wszystkich serwisach:</li>
            <li>
              <span
                v-if="appConfigAccess"
                class="label label-success"
              >TAK</span>
              <span
                v-else
                class="label label-danger"
              >NIE</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../api'
import Loader from '../../../share/Loader'

export default {
  components: {
  },
  mixins: [
    Loader
  ],
  props: {
    role: { required: true },
    service: { type: String, required: true },
    controlModule: { type: Boolean, default: false }
  },
  data () {
    return {
      events: {
        changeAccessEvent: 'core:role:changeSpecialAccess'
      },
      actionChangeSpecialAccess: 'role_change_special_access',
      appConfigAccess: false
    }
  },
  watch: {
    role: function (val) {
      this.appConfigAccess = val.appConfigAccess
    }
  },
  mounted () {
    this.$events.on(this.events.changeAccessEvent, this.changeAppConfigAccess)
  },
  methods: {
    changeAppConfigAccess () {
      let changedAppConfigAccess = !this.appConfigAccess
      api.request('core', 'put', `roles/${this.$route.params.id}/app-config-access`, { appConfigAccess: changedAppConfigAccess })
        .then((response) => {
          this.toggleLoading()
          const data = response.data
          if (data.error) {
            this.getServerErrorMessage(data)
          } else {
            this.appConfigAccess = changedAppConfigAccess
          }
        })
        .catch(() => {
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.serverResponse = {
            type: 'error',
            message: 'Błąd serwera'
          }
        })
    }
  }
}
</script>

<style scoped>
table.permissions td {
    width: 50px;
    text-align: center;
}
</style>
