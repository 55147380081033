<template>
  <div>
    <div class="statistics-container col-sm-12">
      <section
        v-if="bwnSectionVisible"
        class="content statistics-section col-sm-12"
      >
        <h3>Biuro Wycen Nieruchomości</h3>
        <chart
          v-if="$auth.isAllowed({service: ['intbwn', 'intmil'], allowOneOfServices: true, action: 'get_user_with_task_in_progress'})"
          :key="'ch_1' + activeTasksBWNStatisticKey"
          :title="activeTasksBWNStatistic.title"
          :subTitle="activeTasksBWNStatistic.total"
          :data="activeTasksBWNStatistic.data"
          :type="activeTasksBWNStatistic.type"
          :axis="activeTasksBWNStatistic.axis"
          :groups="activeTasksBWNStatistic.data.groups"
          :select="{
            value: activeTasksBWNStatisticSelectedType,
            onChange: handleChangeActiveTasksStatisticType,
            options: activeTasksBWNStatisticTypeOptions
          }"
          :download-link-data="activeTasksBWNStatistic.downloadLinkData"
          :assistantsOrVoivodeships="activeTasksSelectOptions"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
        v-if="$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_user_with_task_without_appointment'})"
        :key="'ch_2' + taskWithoutAppointmentKey"
        :size="isTwoItemsPerDesktop ? 6 : 12"
        :title="tasksWithoutAppointment.title"
        :subTitle="tasksWithoutAppointment.total"
        :data="tasksWithoutAppointment.data"
        :type="tasksWithoutAppointment.type"
        :axis="tasksWithoutAppointment.axis"
        :otherArgs="tasksWithoutAppointment.otherArgs"
        :download-link-data="tasksWithoutAppointment.downloadLinkData"
        :assistants="bwnAssistantsSelectOptions"
        />
        <chart
          v-if="$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_new_task_statistics'})"
          :key="'ch_3' + bwnNewTaskStatisticKey"
          :size="isTwoItemsPerDesktop ? 6 : 12"
          :title="bwnNewTaskStatistic.title"
          :subTitle="bwnNewTaskStatistic.total"
          :data="bwnNewTaskStatistic.data"
          :type="bwnNewTaskStatistic.type"
          :axis="bwnNewTaskStatistic.axis"
          :otherArgs="bwnNewTaskStatistic.otherArgs"
          :download-link-data="bwnNewTaskStatistic.downloadLinkData"
          :assistants="bwnNewTaskSelectOptions"
        />
        <chart
          v-if="$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_tasks_for_assigments'})"
          :key="'ch_4' + tasksForAssigmentStatisticKey"
          :title="tasksForAssigments.title"
          :subTitle="tasksForAssigments.total"
          :data="tasksForAssigments.data"
          :type="tasksForAssigments.type"
          :axis="tasksForAssigments.axis"
          :otherArgs="tasksForAssigments.otherArgs"
          :download-link-data="tasksForAssigments.downloadLinkData"
          :voivodeships="forAssigmentSelectOptions"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_order_task_sent_back_statistics'})"
          :key="'ch_5' + tasksSentBackStatisticKey"
          :title="tasksSentBack.title"
          :subTitle="tasksSentBack.total"
          :data="tasksSentBack.data"
          :type="tasksSentBack.type"
          :axis="tasksSentBack.axis"
          :otherArgs="tasksSentBack.otherArgs"
          :download-link-data="tasksSentBack.downloadLinkData"
          :assistants="sentBackSelectOptions"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
         <chart
          v-if="$auth.isAllowed({service: ['intbwn', 'intmil'], allowOneOfServices: true, action: 'get_tasks_for_correction'})"
          :key="'ch_6' + activeTaskForCorrectionStatisticKey"
          :title="taskForCorrectionStatistic.title"
          :subTitle="taskForCorrectionStatistic.total"
          :data="taskForCorrectionStatistic.data"
          :type="taskForCorrectionStatistic.type"
          :axis="taskForCorrectionStatistic.axis"
          :groups="taskForCorrectionStatistic.data.groups"
          :download-link-data="taskForCorrectionStatistic.downloadLinkData"
          :assistantsOrStatus="taskForCorrectionSelectOptions"
          :size="isTwoItemsPerDesktop ? 6 : 12"
          :select="{
            value: taskForCorrectionStatisticSelectedType,
            onChange: handleChangeTaskForCorrectionStatisticType,
            options: taskForCorrectionStatisticTypeOptions
          }"
        />
        <chart
          v-if="$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_task_analyst_verification'})"
          :key="'ch_7' + analystVerificationKey"
          :title="analystVerification.title"
          :subTitle="analystVerification.total"
          :data="analystVerification.data"
          :type="analystVerification.type"
          :axis="analystVerification.axis"
          :selected-assistant="analystVerification.select.report.value"
          :download-link-data="analystVerification.downloadLinkData"
          :assistants="analystVerification.select.report.options"
          :size="isTwoItemsPerDesktop ? 6 : 12"
          :select="{
            value: analystVerification.select.type.value,
            onChange: handleChangeAnalystVerificationStatisticType,
            options: analystVerification.select.type.options
          }"
        />
        <chart
          v-if="$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_task_analyst_verification_after_deadline'})"
          :key="'ch_8' + analystVerificationLongerThanKey"
          :title="analystVerificationLongerThan.title"
          :subTitle="analystVerificationLongerThan.total"
          :data="analystVerificationLongerThan.data"
          :type="analystVerificationLongerThan.type"
          :axis="analystVerificationLongerThan.axis"
          :otherArgs="analystVerificationLongerThan.otherArgs"
          :download-link-data="analystVerificationLongerThan.downloadLinkData"
          :assistants="sentAnalystVeryficationLongerThanOptions"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <report
          v-if="isAllowed(['intmil'], 'frontend_acl_transferred_task_report')"
          :title="'Zlecenia przekazane'"
          :download-link-data="getBWNDownloadLinkData('transferred_task','intmil', true)"
          :size="isTwoItemsPerDesktop ? 3 : 6"
        />
        <report
          v-if="isAllowed(['intmil'], 'frontend_acl_new_task_report')"
          :title="'Zlecenia nowe'"
          :download-link-data="getBWNDownloadLinkData('new_task', 'intmil',true)"
          :size="isTwoItemsPerDesktop ? 3 : 6"
        />
      </section>
      <section
        v-if="blsSectionVisible"
        class="content statistics-section col-sm-12"
      >
        <h3>Biuro Likwidacji Szkód</h3>
        <chart
          v-if="isAllowed(['intgen', 'intbls'], 'get_inner_state_task_statistics')"
          :title="myTasks.title"
          :subTitle="myTasks.total"
          :data="myTasks.data"
          :axis="myTasks.axis"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(this.newTasksServices, this.newTasksAcl)"
          ref="newTasks"
          :key="'ch_9' + newTasksKey"
          :title="newTasks.title"
          :subTitle="newTasks.total"
          :type="newTasks.type"
          :data="newTasks.data"
          :axis="newTasks.axis"
          :download-link-data="getDownloadLinkData('created', true, isAllowed(this.newTasksServices, this.newTasksAcl))"
          :clients="getClientsOptions(this.getAllowedNewTasksServices(this.newTasksServices), this.$store.state.base.user.serviceClients, this.contractorApps)"
          :select="{
            value: selectedNewDate,
            onChange: handleChangeNewTasksType,
            options: selectDateOptions
          }"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(['intgen'], 'get_communication_audanet_ce_statistics')"
          ref="blsCommunicationAudanetCe"
          :key="'ch_10' + communicationAudanetCeKey"
          :title="blsCommunicationAudanetCe.title"
          :subTitle="blsCommunicationAudanetCe.total"
          :data="blsCommunicationAudanetCe.data"
          :type="blsCommunicationAudanetCe.type"
          :axis="blsCommunicationAudanetCe.axis"
          :download-link-data="blsCommunicationAudanetCe.downloadLinkData"
          @communicationAudanetCeSelectEvent="handleChangeCommunicationAudanetCeType"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(this.closedTasksServices, this.closedTasksAcl)"
          ref="closedTasks"
          :key="'ch_11' + closedTasksKey"
          :title="closedTasks.title"
          :subTitle="closedTasks.total"
          :type="closedTasks.type"
          :data="closedTasks.data"
          :axis="closedTasks.axis"
          :with-user-uuid="true"
          :clients="getClientsOptions(this.getAllowedClosedTasksServices(this.closedTasksServices), this.$store.state.base.user.serviceClients, this.contractorApps)"
          :download-link-data="getDownloadLinkData('closed_invoice', true, isAllowed(this.closedTasksServices, this.closedTasksAcl))"
          :select="{
            value: selectedClosedDate,
            onChange: handleChangeClosedTasksType,
            options: selectDateOptions
          }"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(['intgen'], 'get_open_task_statistics')"
          ref="blsOpenTasksintgen"
          :title="blsOpenTasks.intgen.title"
          :subTitle="blsOpenTasks.intgen.total"
          :data="blsOpenTasks.intgen.data"
          :type="blsOpenTasks.intgen.type"
          :axis="blsOpenTasks.intgen.axis"
          :download-link-data="getDownloadLinkData('open', false, false)"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(['extavi'], 'get_open_task_statistics')"
          ref="blsOpenTasksextavi"
          :title="blsOpenTasks.extavi.title"
          :subTitle="blsOpenTasks.extavi.total"
          :data="blsOpenTasks.extavi.data"
          :type="blsOpenTasks.extavi.type"
          :axis="blsOpenTasks.extavi.axis"
          :download-link-data="getDownloadLinkDataPerService('extavi', 'open_extavi', false, false)"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(['extuni'], 'get_open_task_statistics')"
          ref="blsOpenTasksextuni"
          :title="blsOpenTasks.extuni.title"
          :subTitle="blsOpenTasks.extuni.total"
          :data="blsOpenTasks.extuni.data"
          :type="blsOpenTasks.extuni.type"
          :axis="blsOpenTasks.extuni.axis"
          :download-link-data="getDownloadLinkDataPerService('extuni', 'open_extuni', false, false)"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <chart
          v-if="isAllowed(this.blsOpenAdvencedServices, this.blsOpenAdvencedAcl, true)"
          ref="blsOpenAdvancedTask"
          :title="blsOpenAdvancedTask.title"
          :subTitle="blsOpenAdvancedTask.total"
          :data="blsOpenAdvancedTask.data"
          :type="blsOpenAdvancedTask.type"
          :axis="blsOpenAdvancedTask.axis"
          :download-link-data="getDownloadLinkData('open_plus', false, isAllowed(this.blsOpenAdvencedServices, this.blsOpenAdvencedAcl))"
          :clients="getClientsOptions(this.getAllowedBlsOpenAdvancedServices(this.blsOpenAdvencedServices), this.$store.state.base.user.serviceClients, this.contractorApps)"
          :size="isTwoItemsPerDesktop ? 6 : 12"
        />
        <div class="col-xs-12">
          <chart
            v-if="isAllowed(this.inspectionServices, this.inspectionAcl)"
            ref="inspection"
            :title="inspection.title"
            :subTitle="inspection.total"
            :data="inspection.data"
            :type="inspection.type"
            :axis="inspection.axis"
            :partial-report="inspection.partialReport"
            :with-user-uuid="true"
            :download-link-data="getDownloadInspectionLinkData('inspection', false, true, true)"
            :clients="getClientsOptions(this.getAllowedInspectionServices(this.inspectionServices), this.$store.state.base.user.serviceClients, this.contractorApps)"
            :size="isThreeItemsPerDesktop ? 4 : 6"
          />
          <chart
            v-if="workloadPerAssigneeLoaded && $auth.isAllowed({service: this.workloadPerAssigneeServices, allowOneOfServices: true, action: this.workloadPerAssigneeAcl})"
            :key="'ch_12' + workloadPerAssigneeKey"
            :title="workloadPerAssignee.title"
            :subTitle="workloadPerAssignee.total"
            :data="workloadPerAssignee.data"
            :type="workloadPerAssignee.type"
            :axis="workloadPerAssignee.axis"
            :download-link-data="getDownloadWorkloadPerAssigneeLinkData()"
            :size="isThreeItemsPerDesktop ? 4 : 6"
            :select="{
              value: selectedWorkloadPerAssigneeDate,
              onChange: handleWorkloadPerAssigneeChange,
              options: workloadPerAssigneeDateOptions
            }"
          />
          <chart
            v-if="inspectionOtherStatisticLoaded && $auth.isAllowed({service: this.inspectionOtherServices, allowOneOfServices: true, action: this.inspectionOtherAcl})"
            ref="inspectionOtherStatisticChart"
            :title="inspectionOtherStatistic.title"
            :subTitle="inspectionOtherStatistic.total"
            :data="inspectionOtherStatistic.data"
            :type="inspectionOtherStatistic.type"
            :axis="inspectionOtherStatistic.axis"
            :other-args="inspectionOtherStatistic.otherArgs"
            :download-link-data="getDownloadLinkData('inspection_other', false, isAllowed(this.inspectionOtherServices, this.inspectionOtherAcl))"
            :clients="getClientsOptions(this.getAllowedInspectionOtherServices(this.inspectionOtherServices), this.$store.state.base.user.serviceClients, this.contractorApps)"
            :size="isThreeItemsPerDesktop ? 4 : 6"
          />
        </div>
        <div class="col-xs-12" style="float: left" >
          <chart
            v-if="isAllowed(['intgen'], 'get_inspection_online_appraisal_statistics')"
            :title="inspectionOnline.title"
            :subTitle="inspectionOnline.total"
            :data="inspectionOnline.data"
            :type="inspectionOnline.type"
            :otherArgs="inspectionOnline.otherArgs"
            :with-user-uuid="true"
            :download-link-data="getDownloadLinkData('inspection_online', false)"
            :size="isThreeItemsPerDesktop ? 4 : 6"
          />
        </div>
        <div class="col-xs-12">
          <report
            v-if="isAllowed(['intgen'], 'frontend_acl_appraisal_basic_report')"
            :title="appraisalBasicReport.title"
            :download-link-data="getDownloadLinkData('appraisal_basic')"
            :size="isTwoItemsPerDesktop ? 3 : 6"
          />
          <report
            v-if="isAllowed(['intgen'], 'frontend_acl_appraisal_complex_report')"
            :title="appraisalComplexReport.title"
            :download-link-data="getDownloadLinkData('appraisal_complex')"
            :size="isTwoItemsPerDesktop ? 3 : 6"
          />
          <report
            v-if="isAllowed(['intgen'], 'frontend_acl_billing_report')"
            :title="billingReport.title"
            :download-link-data="getDownloadLinkData('billing')"
            :size="isTwoItemsPerDesktop ? 3 : 6"
          />
          <report
            v-if="isAllowed(['intgen'], 'frontend_acl_billing_v2_report')"
            :title="billingReportV2.title"
            :download-link-data="getDownloadLinkData('billing_v2')"
            :size="isTwoItemsPerDesktop ? 3 : 6"
          />
          <report
            v-if="isAllowed(['intgen', 'intbls'], 'frontend_acl_created_appraisal_report')"
            :title="createdAppraisalReport.title"
            :download-link-data="{'type': 'created_appraisal','service': 'all', 'withDate': true, 'withClients': true}"
            :clients="getClientsOptions(this.$auth.getAllowed({ services: ['intbls', 'intgen'], actions: ['frontend_acl_created_appraisal_report'], allowOneOfActions: true }), this.$store.state.base.user.serviceClients, this.contractorApps)"
            :selected-client="'all'"
            :with-user-uuid="true"
            :size="isTwoItemsPerDesktop ? 3 : 6"
          />
          <report
          v-if="isAllowed(['intgen', 'intbls'], 'frontend_acl_monitoring_report')"
            :title="monitoringReport.title"
            :download-link-data="{'type': 'closed_v2','service': 'all', 'withDate': true, 'withClients': true}"
            :clients="getClientsOptions(this.$auth.getAllowed({ services: ['intbls', 'intgen'], actions: ['frontend_acl_monitoring_report'], allowOneOfActions: true }), this.$store.state.base.user.serviceClients, this.contractorApps, false, ['intgen', 'axa'])"
            :selected-client="'all'"
            :with-user-uuid="false"
            :size="isTwoItemsPerDesktop ? 3 : 6"
          />

          <chart
            v-if="workloadPerAppraiserLoaded && $auth.isAllowed({service: this.workloadPerAppraiserServices, allowOneOfServices: true, action: this.workloadPerAppraiserAcl})"
            :key="'ch_13' + workloadPerAppraiserKey"
            :title="workloadPerAppraiser.title"
            :subTitle="workloadPerAppraiser.total"
            :data="workloadPerAppraiser.data"
            :type="workloadPerAppraiser.type"
            :axis="workloadPerAppraiser.axis"
            :download-link-data="getDownloadWorkloadPerAppraiserLinkData()"
            :size="12"
            :select="{
              value: selectedWorkloadPerAppraiserDate,
              onChange: handleWorkloadPerAppraiserChange,
              options: workloadPerAppraiserDateOptions
            }"
          />
        </div>
      </section>
      <section
        v-if="coreSectionVisible"
        class="content statistics-section"
      >
        <h3>Ogólne</h3>
        <report
          v-if="isAllowed(['core'], 'get_presence_report')"
          :title="'Lista obecności'"
          :download-link-data="getDownloadPresenceLinkData('presence', true,false)"
          :size="3"
        />
      </section>
    </div>
  </div>
</template>

<script>
import api from '../../../api'
import Chart from '../share/statistic/chart/Chart'
import Report from '../share/statistic/report/Report'
import WebStorage from '../../share/WebStorage'
import moment from 'moment'
import inspection from './mixins/inspection'
import Trans from '../../share/translations/Translations.js'
import innerState from './mixins/innerState'
import workloadPerAssignee from './mixins/workloadPerAssignee'
import workloadPerAppraiser from '@/components/bundles/statistics/mixins/workloadPerAppraiser.js'
import activeTasksBWN from '@/components/bundles/statistics/mixins/activeTasksBWN.js'
import taskForCorrection from '@/components/bundles/statistics/mixins/taskForCorrection.js'
import tasksWithoutAppointment from '@/components/bundles/statistics/mixins/tasksWithoutAppointment.js'
import blsCommunicationAudanetCe from './mixins/blsCommunicationAudanetCe.js'
import bwnNewTaskStatistic from '@/components/bundles/statistics/mixins/bwnNewTaskStatistic.js'
import tasksSentBack from '@/components/bundles/statistics/mixins/tasksSentBack.js'
import tasksForAssigments from '@/components/bundles/statistics/mixins/tasksForAssigment.js'
import inspectionOthertatistics from '@/components/bundles/statistics/mixins/inspectionOtherStatistics.js'
import analystVerification from '@/components/bundles/statistics/mixins/analystVerification.js'
import analystVerificationLongerThan from '@/components/bundles/statistics/mixins/analystVerificationLongerThan.js'
import blsNewTask from '@/components/bundles/statistics/mixins/blsNewTask.js'
import blsClosedTask from '@/components/bundles/statistics/mixins/blsClosedTask.js'
import blsOpenTask from '@/components/bundles/statistics/mixins/blsOpenTask.js'
import blsOpenAdvancedTask from '@/components/bundles/statistics/mixins/blsOpenAdvancedTask.js'
import inspectionOnline from '@/components/bundles/statistics/mixins/inspectionOnline.js'
import chartData from '@/components/bundles/statistics/mixins/chartData.js'
import chartElement from '@/components/bundles/statistics/mixins/chartElement.js'
import response from '@/components/bundles/statistics/mixins/response.js'

const MEDIUM_BREAKPOINT = 1350
const LARGE_BREAKPOINT = 1700

export default {
  components: {
    Chart,
    Report
  },
  mixins: [
    innerState,
    Trans,
    inspection,
    workloadPerAssignee,
    workloadPerAppraiser,
    blsCommunicationAudanetCe,
    activeTasksBWN,
    taskForCorrection,
    bwnNewTaskStatistic,
    tasksSentBack,
    tasksForAssigments,
    inspectionOthertatistics,
    analystVerification,
    analystVerificationLongerThan,
    tasksWithoutAppointment,
    blsNewTask,
    blsClosedTask,
    blsOpenTask,
    blsOpenAdvancedTask,
    inspectionOnline,
    chartData,
    chartElement,
    response
  ],
  data () {
    return {
      isTwoItemsPerDesktop: window.innerWidth > MEDIUM_BREAKPOINT,
      isThreeItemsPerDesktop: window.innerWidth > LARGE_BREAKPOINT,
      workflows: ['main_vehicle', 'main_property'],
      closedDates: [],
      allClients: [],
      allClientsMap: {},
      allBlsClientsSelectOptions: [],
      blsClientsSelectOptions: [],
      bwnClientsSelectOptions: [],
      bwnTaskInProgressSelectOptions: [],
      bwnAssistantsSelectOptions: [],
      bwnNewTaskSelectOptions: [],
      sentBackSelectOptions: [],
      sentAnalystVeryficationLongerThanOptions: [],
      forAssigmentSelectOptions: [],
      selectedClosedDate: null,
      newDates: [],
      selectedNewDate: null,
      selectDateOptions: [
        {
          label: 'Dzisiaj',
          value: new Date().toISOString().split('T')[0]
        },
        {
          label: 'Wczoraj',
          value:  new Date(new Date().setDate(new Date().getDate()-1)).toISOString().split('T')[0]
        }
      ],
      appraisalBasicReport: {
        title: 'Raport podzleceń'
      },
      appraisalComplexReport: {
        title: 'Raport podzleceń kompleksowych'
      },
      billingReport: {
        title: 'Raport rozliczeń z Generali'
      },
      billingReportV2: {
        title: 'Raport rozliczeń z Generali (audanet)'
      },
      createdAppraisalReport: {
        title: 'Rzeczoznawcy wykonane'
      },
      monitoringReport: {
        title: 'Raport monitorujący'
      },
      actions: [
        'get_inner_state_task_statistics',
        'get_new_task_statistics',
        'get_closed_task_statistics',
        'get_open_task_statistics',
        'get_inspection_appraisal_statistics',
        'get_inspection_other_appraisal_statistics',
        'get_inspection_online_appraisal_statistics',
        'get_task_states_statistics',
        'get_appraisal_task_per_user_statistics',
        'get_my_completed_tasks_statistics',
        'get_tasks_total_statistics',
        'frontend_acl_appraisal_basic_report',
        'frontend_acl_appraisal_complex_report',
        'get_workload_per_appraiser_statistics',
        'frontend_acl_billing_report',
        'frontend_acl_billing_v2_report',
        'frontend_acl_get_open_statistics_plus',
        'frontend_acl_created_appraisal_report',
        'frontend_acl_monitoring_report',
        'get_clients',
        'get_main_task_statistics',
        'get_presence_report',
        'frontend_acl_transferred_task_report',
        'frontend_acl_new_task_report',
        'get_communication_audanet_ce_statistics',
        'frontend_acl_new_task_report',
        'get_user_with_task_in_progress',
        'get_tasks_for_correction',
        'get_user_with_task_without_appointment',
        'get_tasks_for_assigments',
        'get_order_task_sent_back_statistics',
        'get_task_analyst_verification_after_deadline',
        'get_task_analyst_verification'
      ],
      visibilities: {}
    }
  },
  computed: {
    contractorApps () {
      let contractorApps = WebStorage.getContractorApps()
      let emptySecurityActions = Object.entries(WebStorage.getSecurityActions()).map((key) => {
        return {
          name: key[0],
          actions: key[1]
        }
      }).filter((app) => app.actions.length === 0)
      // filter some apps
      delete contractorApps.history
      delete contractorApps.storage
      emptySecurityActions.forEach(action => {
        Object.keys(contractorApps).forEach((contractor) => contractor === action.name ? delete contractorApps[action.name] : null)
      })
      return contractorApps
    },
    blsSectionVisible () {
      let charts = [
        this.isAllowed(['intgen', 'intbls'], 'get_inner_state_task_statistics'),
        this.isAllowed(this.newTasksServices, this.newTasksAcl),
        this.isAllowed(this.closedTasksServices, this.closedTasksAcl),
        this.isAllowed(['intgen'], 'get_open_task_statistics'),
        this.isAllowed(['extavi'], 'get_open_task_statistics'),
        this.isAllowed(['extuni'], 'get_open_task_statistics'),
        this.isAllowed(this.blsOpenAdvencedServices, this.blsOpenAdvencedAcl, true),
        this.isAllowed(this.inspectionServices, this.inspectionAcl),
        this.workloadPerAssigneeLoaded && this.$auth.isAllowed({
          service: this.workloadPerAssigneeServices,
          allowOneOfServices: true,
          action: this.workloadPerAssigneeAcl
        }),
        this.isAllowed(this.inspectionOtherServices, this.inspectionOtherAcl),
        this.isAllowed(['intgen'], 'get_inspection_online_appraisal_statistics'),
        this.isAllowed(['intgen'], 'frontend_acl_appraisal_basic_report'),
        this.isAllowed(['intgen'], 'frontend_acl_appraisal_complex_report'),
        this.isAllowed(['intgen'], 'frontend_acl_billing_report'),
        this.isAllowed(['intgen'], 'frontend_acl_billing_v2_report'),
        this.isAllowed(['intgen', 'intbls'], 'frontend_acl_created_appraisal_report'),
        this.isAllowed(['intgen', 'intbls'], 'frontend_acl_monitoring_report'),
        this.workloadPerAppraiserLoaded && this.$auth.isAllowed({
          service: this.workloadPerAppraiserServices,
          allowOneOfServices: true,
          action: this.workloadPerAppraiserAcl
        }),
        this.isAllowed(['intgen'], 'get_communication_audanet_ce_statistics'),
      ]
      return charts.some(el => el)
    },
    coreSectionVisible () {
      let charts = [
        this.isAllowed(['core'], 'get_presence_report')
      ]
      return charts.some(el => el)
    },
    bwnSectionVisible () {
      let charts = [
        this.isAllowed(['intmil'], 'frontend_acl_transferred_task_report'),
        this.isAllowed(['intmil'], 'frontend_acl_new_task_report'),
        this.$auth.isAllowed({service: ['intmil', 'intbwn'], allowOneOfServices: true, action: 'get_user_with_task_in_progress'}),
        this.$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_user_with_task_without_appointment'}),
        this.$auth.isAllowed({service: ['intbwn', 'intmil'], allowOneOfServices: true, action: 'get_new_task_statistics'}),
        this.$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_order_task_sent_back_statistics'}),
        this.$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_task_analyst_verification'}),
        this.$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_task_analyst_verification_after_deadline'}),
        this.$auth.isAllowed({service: ['intmil'], allowOneOfServices: true, action: 'get_tasks_for_assigments'}),
        this.$auth.isAllowed({service: ['intbwn', 'intmil'], allowOneOfServices: true, action: 'get_tasks_for_correction'}),
      ]
      return charts.some(el => el)
    }
  },

  created () {
    this.setVisibility()
    this.getAllClients().catch(clientsError => {
      console.error(clientsError)
    })
    this.getUserBlsClients()
    this.getUserBwnClients(['intbwn', 'intmil'])
  },
  mounted () {
    this.$nextTick(() => {
      this.setStatisticsData()
    })
    window.onresize = () => {
      this.isTwoItemsPerDesktop = window.innerWidth > MEDIUM_BREAKPOINT
      this.isThreeItemsPerDesktop = window.innerWidth > LARGE_BREAKPOINT
    }
  },
  methods: {
    getAllBlsClientsSelectOptions (allClients) {
      // deprecated
      let clients = [{value: 'all', label: ''}]
      if (this.contractorApps.hasOwnProperty('intgen')) {
        clients.push({label: this.contractorApps.intgen, value: 'intgen'})
      }
      this.allBlsClientsSelectOptions = [...clients, ...allClients.map(el => ({
        label: this.allClientsMap[el],
        value: el
      }))]
    },
    getUserBlsClients () {
      // deprecated
      let clients = [{value: 'all', label: ''}]
      if (this.contractorApps.hasOwnProperty('intgen')) {
        clients.push({label: this.contractorApps.intgen, value: 'intgen'})
      }
      if (this.$store.state.base.user.hasOwnProperty('serviceClients') && this.$store.state.base.user.serviceClients.hasOwnProperty('intbls')) {
        this.$store.state.base.user.serviceClients['intbls'].forEach(c => {
          clients.push({label: c.label, value: c.name})
        })
      }
      this.blsClientsSelectOptions = clients
    },
    getUserBwnClients (bwnServices) {
      let clients = [{value: 'all', label: ''}]
      bwnServices.forEach(service => {
          if (this.$store.state.base.user.hasOwnProperty('serviceClients') && this.$store.state.base.user.serviceClients.hasOwnProperty(service) && this.$store.state.base.user.serviceClients[service] && Array.isArray(this.$store.state.base.user.serviceClients[service])) {
            this.$store.state.base.user.serviceClients[service].forEach(c => {
              clients.push({label: c.label, value: c.name})
            })
          }
        }
      )
      this.bwnClientsSelectOptions = clients
    },

    setStatisticsData () {
      this.newTasks.data.columns.push(this.getDateLabels(this.newTasks.timeLength))
      this.closedTasks.data.columns.push(this.getDateLabels(this.closedTasks.timeLength))

      setTimeout(() => {
        this.loadWorkloadPerAppraiserStatistic(this.workloadPerAppraiserServices)
        this.loadMyTasks()
        this.loadNewTasksStatistic(this.newTasksServices)
        this.loadTasksWithoutAppointment()
        this.loadbwnNewTaskStatistic()
        this.loadTasksSentBack()
        this.loadTasksForAssigments()
      }, 1000)

      setTimeout(() => {
        this.loadAnalystVerificationLongerThan()
        this.loadClosedTasksStatistic(this.closedTasksServices)
        this.loadblsOpenTasks('intgen', 'get_open_task_statistics', ['main_vehicle', 'main_property'])
        this.loadblsOpenTasks('extavi', 'get_open_task_statistics', ['main_vehicle'])
        this.loadblsOpenTasks('extuni', 'get_open_task_statistics', ['main_vehicle'])
      }, 2000)

      setTimeout(() => {
        this.loadBlsOpenAdvancedTaskStatistic(this.blsOpenAdvencedServices)
        this.loadInspectionOnline()
        this.loadInspectionStatistic(this.inspectionServices)
        this.loadInspectionOtherStatistic(this.inspectionOtherServices)
      }, 3000)

      setTimeout(() => {
        this.loadWorkloadPerAssigneeStatistic(this.workloadPerAssigneeServices)
        this.loadCommunicationAudanetCeStatistic()
        this.loadActiveTaskStatistic()
        this.loadTaskForCorrectionStatistic()
        this.handleChangeAnalystVerificationStatisticType()
      }, 4000)
    },
    handleDates (dates) {
      if (dates.length === 1) {
        if (dates[0] === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
          dates.push(moment().format('YYYY-MM-DD'))
        } else {
          dates.push(moment().subtract(1, 'days').format('YYYY-MM-DD'))
        }
      } else if (dates.length === 0) {
        dates.push(moment().format('YYYY-MM-DD'))
        dates.push(moment().subtract(1, 'days').format('YYYY-MM-DD'))
      }
    },
    getDateLabels (timeDiff) {
      let today = new Date()
      let day = 24 * 3600 * 1000
      let week = (timeDiff * day)

      let result = ['x']
      let time = today.getTime() - week

      for (let i = 0; i <= timeDiff; i++) {
        let date = new Date(time)
        result.push(date.getFullYear() + '-' + this.pad(date.getMonth() + 1) + '-' + this.pad(date.getDate()))
        time += day
      }

      return result
    },
    pad (n) {
      return n < 10 ? '0' + n : n
    },
    prepareTimeData (dateList, data) {
      let result = []
      dateList.forEach((el) => {
        if (el in data) {
          result.push(Number(data[el]))
        } else if (el !== 'x') {
          result.push(0)
        }
      })
      return result
    },
    assignDataToColumns (columns, data, taskType) {
      // TODO ustawić stany, potem przypisać wartości do stanów
      let result = []
      let dateList = columns[0]
      result.push(this.trans[taskType])
      result = result.concat(this.prepareTimeData(dateList, data))
      columns.push(result)
    },
    chartSizeByDate (chart) {
      if (chart.data.columns[0]) {
        return chart.data.columns[0].length - 1
      }
    },
    setVisibility () {
      let securityActions = WebStorage.getSecurityActions()
      let visibilities = {}
      Object.keys(this.contractorApps).forEach((service) => {
        visibilities[service] = []
        if (!securityActions.hasOwnProperty(service)) {
          return
        }
        securityActions[service].forEach((action) => {
          if (this.actions.includes(action)) {
            visibilities[service].push(action)
          }
        })
      })

      this.visibilities = visibilities
    },
    isAllowed (services, action, allowOneOfActions = false) {
      let isAllowed = false
      for (let service of services) {
        if (Array.isArray(action)) {
          if (allowOneOfActions) {
            isAllowed = this.visibilities[service] !== undefined && action.some(element => this.visibilities[service].includes(element))
          } else {
            isAllowed = this.visibilities[service] !== undefined && action.every(element => this.visibilities[service].includes(element))
          }
        } else {
          isAllowed = this.visibilities[service] !== undefined && this.visibilities[service].includes(action)
        }
        if (isAllowed) {
          return isAllowed
        }
      }
      return isAllowed
    },
    getServiceByLabel (label) {
      for (let name in this.contractorApps) {
        if (this.contractorApps[name] === label) {
          return name
        }
      }
      return null
    },
    getDownloadLinkData (type, withDate = true, withClients = false) {
      return {
        type,
        service: 'intgen',
        withDate,
        withClients
      }
    },
    getBWNDownloadLinkData (type, service, withDate = true, withClients = false) {
      return {
        type,
        service,
        withDate,
        withClients
      }
    },
    getDownloadPresenceLinkData (type, withDate = true, withClients = false) {
      return {
        type,
        service: 'core',
        withDate,
        withClients
      }
    },
    getDownloadInspectionLinkData (type, withDate = false, withClients = true, withCategories = true) {
      return {
        type,
        service: 'intgen',
        withDate,
        withClients,
        withCategories
      }
    },
    getDownloadLinkDataPerService (service, type, withDate = true, withClients = false) {
      return {
        type,
        service: service,
        withDate,
        withClients
      }
    },
    getClients (service = 'intbls') {
      let clients = []
      if (!this.contractorApps.hasOwnProperty(service)) {
        return clients
      }

      this.$store.state.base.user.serviceClients[service].forEach(c => {
        clients.push(c.name)
      })
      return clients
    },
    getAllClients () {
      return new Promise((resolve, reject) => {
        if (!this.$auth.isAllowed({service: ['intbls'], allowOneOfServices: true, action: 'get_clients'})) {
          reject({message: 'Action not allowed'})
          return
        }
        api.request('intbls', 'get', '/clients').then(({data}) => {
          this.allClientsMap = data.reduce((accumulator, nextObject) => {
            accumulator[nextObject.name] = nextObject.label
            return accumulator
          }, {})
          this.allClients = data.map(client => client.name)

          this.getAllBlsClientsSelectOptions(this.allClients)
          resolve(this.allClients)
        }).catch(e => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać klientów'
          })
          reject(e)
        })
      })

    },
    getClientLabel (name) {
      //TODO
      // tymczasowa podmiana nazw klientów w intbls dla statystyk
      // do usunięcia po pełnej zmianie nazw klientów na backendzie
      name = (name === 'aviva') ? 'allianz' : name
      name = (name === 'axa') ? 'uniqa' : name
      return name.replace('_', ' ').toUpperCase()
    }
  }
}
</script>
<style scoped>
.statistics-container {
  float: left;
  padding: 10px;
}

.statistics-section > h3 {
  width: fit-content;
  position: relative;
  top: -54px;
  background-color: #f1f3f6;
  padding: 2px;
}

.statistics-section {
  width: 100%;
  margin-top: 20px;
  border: #42a5f6 1px solid;
  border-radius: 5px;
  float: left;
}
</style>
