import role from '../common-configuration/role/index'
import person from '../common-configuration/person/index'
import online from '../common-configuration/calendar/online/index'
import visibility from '../common-configuration/calendar/visibility/index'
import main from '../common-configuration/main/index'
import appraisal from '../common-configuration/appraisal/index'
import clients from '../common-configuration/clients/index'
import taskCategoryRole from '../common-configuration/taskCategoryRole/index'
import taskHeaders from '../common-configuration/task-headers/index'

export default function (service, name) {
  return [
    ...taskCategoryRole(service, name),
    ...role(service, name),
    ...taskHeaders(service, name),
    ...person(service, name),
    ...online(service, name),
    ...visibility(service, name),
    ...main(service, name),
    ...appraisal(service, name),
    ...clients(service, name)
  ]
}
