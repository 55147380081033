import validateRoute from '@/router/utils/validateRoute.js'

const beforeRouteValidateAclPrevalidation = (acl) => (to, from, next) => {
  let newTo = {
    ...to, meta: {
      ...to.meta, acl: acl(to)[to.name]
    }
  }
  validateRoute(newTo, from, next)
}

export { beforeRouteValidateAclPrevalidation }
