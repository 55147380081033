
const deparseCategoryParamId = (param) => {
  const idRegex = /<<([0-9]+)>>/
  return param.includes('<<') && param.includes('>>') ? parseInt(idRegex.exec(param)[1]) : param
}

export default {
  install: function (Vue, options) {
    Vue.prototype.$translateMainCategory = function (Client, categoryLabel) {
      return Client.taskCategories.main.find(category => parseInt(category.id) === deparseCategoryParamId(categoryLabel)).name
    }
    Vue.prototype.$translateMainCategorySnakeCase = function (Client, categoryLabel) {
      let name = Client.taskCategories.main.find(category => parseInt(category.id) === deparseCategoryParamId(categoryLabel)).name
      return name.includes('-') ? name.split('-').join('_') : name
    }
    Vue.prototype.$translateAppraisalCategory = function (Client, categoryLabel) {
      return Client.taskCategories.appraisal.find(category => parseInt(category.id) === deparseCategoryParamId(categoryLabel)).name
    }
    Vue.prototype.$translateAppraisalCategorySnakeCase = function (Client, categoryLabel) {
      let name = Client.taskCategories.appraisal.find(category => parseInt(category.id) === deparseCategoryParamId(categoryLabel)).name
      return name.includes('-') ? name.split('-').join('_') : name
    }
    Vue.prototype.$getCategoryGroup = function (Client, categoryLabel) {
      return Object.entries(Client.taskCategories).find(([key, value])  => value.find(category => parseInt(category.id) === deparseCategoryParamId(categoryLabel)))[0]
    }
    Vue.prototype.$getCategory = function (Client, categoryLabel) {
      const group = Vue.prototype.$getCategoryGroup(Client, categoryLabel)
      return Client.taskCategories[group].find(category => parseInt(category.id) === deparseCategoryParamId(categoryLabel))

    }
    Vue.prototype.$getCategorySchemas = function (Client, categoryLabel) {
      let category = Vue.prototype.$getCategory(Client, categoryLabel)
        return category.formSchemas
    }
    Vue.prototype.$getCategoryWorkflows = function (Client, categoryLabel) {
        const category = Vue.prototype.$getCategory(Client, categoryLabel)
        return category.workflows
    }

    Vue.prototype.$getCategoryGroupNew = function (Client, categoryId) {
      return Object.entries(Client.taskCategories).find(([key, value])  => value.find(category => parseInt(category.id) === parseInt(categoryId)))[0]
    }
    Vue.prototype.$getCategoryNew = function (Client, categoryId) {
      const group = this.$getCategoryGroupNew(Client, categoryId)
      return Client.taskCategories[group].find(category => parseInt(category.id) === parseInt(categoryId))
    }

  }
}
