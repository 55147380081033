<template>
  <div>
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div class="row">
                  <select-type
                    class="col-sm-4 col-md-3"
                    name="role"
                    :label="'Rola'"
                    :required="false"
                    :options="roles"
                    :disabled="!rolesLoaded || !!model.client || selectedState !== ''"
                    :empty-first="true"
                    :value="model.roleId"
                    @input="changeRole"
                  />

                  <select-type
                    v-if="isServiceWithClients"
                    class="col-sm-4 col-md-3"
                    name="client"
                    :label="'Klient'"
                    :required="false"
                    :options="clients"
                    :disabled="selectedState !== ''"
                    :empty-first="true"
                    :value="model.client"
                    @input="changeClient"
                  />
                  <select-type
                    class="col-sm-4 col-md-3"
                    name="client"
                    :label="'Status'"
                    :required="false"
                    :options="states"
                    :empty-first="true"
                    :value="selectedState"
                    @input="changeState"
                  />
                </div>
                <div
                  v-if="model.roleId !== null"
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Poziomy dostępności do pól</h2>
                    <list
                      :items="filteredByState"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                      @addItem="handleAddItem"
                      @removeItem="handleRemoveItem"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <field-access-type
                          v-model="listItems.item"
                          :states="states"
                          :schema="schema"
                          :visibilities="visibilities"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />

    <login-modal
      :show="loginVisible"
      :back-url-name="securityRedirect"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../../share/content/Page'
import api from '../../../../../api'
import ErrorNotify from '../../../../share/mixins/ErrorNotify'
import List from '../../../../share/form/List'
import SelectType from '../../../../share/form/type/SelectType'
import LoginModal from '../../../share/modal/LoginModal'
import FieldAccessType from '../../../share/form/type/FieldAccessType'
import SaveDataInformation from '../../../share/modal/SaveDataInformation'

export default {
  components: {
    FieldAccessType,
    SelectType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page,
    ErrorNotify
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      events: null,
      headerTitle: { title: 'Dostęp do pól', description: 'Tryb edycji' },
      schemaName: this.$route.meta.workflow,
      workflowName: this.$route.meta.workflow,
      model: {
        roleId: null,
        client: null,
        fieldVisibilities: []
      },
      fieldVisibilities: [],
      isSaved: true,
      saveDataInformationVisible: false,
      previouslySelectedRole: null,
      previouslySelectedClient: null,
      previouslySelectedState: null,
      schema: {
        groups: []
      },
      roles: [],
      rolesLoaded: false,
      states: [],
      selectedState: '',
      statesLoaded: false,
      visibilities: [],
      loginVisible: false,
      allowedAccess: false,
      clients: []
    }
  },
  computed: {
    securityRedirect () {
      let workflow = this.$route.meta.workflow
      if (workflow.includes('-')) {
        workflow = this.$route.meta.workflow.split('-')[1]
      } else {
        workflow = 'appraisal'
      }
      return `${this.service}_${workflow}_field_access_listing`
    },
    isServiceWithClients () {
      if (this.service) {
        return this.$isWithClients(this.service)
      } else {
        return undefined
      }
    },
    filteredByState () {
      return this.fieldVisibilities.filter(el => parseInt(el.state.id) === parseInt(this.selectedState))
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.fieldVisibilitySubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      let workflow = 'main'
      if (!this.workflowName.includes('main')) {
        workflow = 'appraisal'
      }
      this.events = {
        fieldVisibilitySubmit: `${this.service}:${workflow}:fieldVisibilitySubmit`
      }
    },
    handleAddItem () {
      this.fieldVisibilities = this.fieldVisibilities.concat([this.createEmptyElement()])
    },
    handleRemoveItem ({element}) {
      this.fieldVisibilities = this.fieldVisibilities.filter(el => el !== element)
    },
    init () {
      this.allowedAccess = true
      this.loadRoles()
      this.loadClients()
      this.loadStates()
      this.loadVisibilities()
      if (!this.isServiceWithClients) {
        this.loadSchema()
      }
    },
    loadRoles () {
      api.request(this.service, 'get', '/roles')
        .then((response) => {
          const roles = response.data
          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({ label: roles[c].label, value: roles[c].id })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    loadStates () {
      api.request(this.service, 'get', `/workflows/${this.workflowName}/states`)
        .then((response) => {
          const states = response.data
          for (let c = 0; c < states.length; ++c) {
            this.states.push({ label: states[c].label, value: states[c].id })
          }
          this.statesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    },
    loadVisibilities () {
      this.visibilities = [
        { label: 'niewidoczne', value: 0 },
        { label: 'widoczne', value: 1 },
        { label: 'edytowalne', value: 2 },
        { label: 'obligatoryjne', value: 4 }
      ]
    },
    getLoadSchemaMethod (schemaName = this.schemaName) {
      if (this.$isWithClients(this.service)) {
        let clientId = this.$store.state.base.user.serviceClients[this.service].find(client => client.name === this.model.client).id
        return api.request(this.service, 'get', `/schemas/${schemaName}?client=${clientId}`)
      } else {
        return api.request(this.service, 'get', `/schemas/${schemaName}`)
      }
    },
    loadSchema (schemaName = this.schemaName) {
      this.fieldVisibilities = []
      this.getLoadSchemaMethod()
        .then((response) => {
          this.buildSchema(response.data)
          if (this.isServiceWithClients) {
            this.loadFieldsVisibilities(schemaName)
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Błąd ładowania listy pól formularza'
          })
        })
    },
    buildSchema (schema) {
      for (let g = 0; g < schema.groups.length; g++) {
        const group = schema.groups[g]
        let fields = []
        for (let f = 0; f < group.fields.length; f++) {
          fields.push(this.createField(group.fields[f]))
        }
        this.schema.groups.push(this.createGroup(group, fields))
      }
    },
    createGroup (group, fields) {
      let result = { fields }
      if (group.label) {
        result.label = group.label
      }
      return result
    },
    createField (fieldItem) {
      let fieldParams = {
        id: fieldItem.id,
        label: fieldItem.field.label
      }
      return fieldParams
    },
    forceToSave () {
      this.isSaved = false
    },
    changeRole (optionValue) {
      if (!this.isSaved && this.fieldVisibilities.length > 0) {
        document.getElementById('role').value = this.previouslySelectedRole
        this.saveDataInformationVisible = true
        return
      }

      this.model.roleId = this.previouslySelectedRole = optionValue

      if (!this.isServiceWithClients || this.model.client !== null) {
        this.loadFieldsVisibilities(this.retrieveSchemaName())
      }
    },
    changeState (optionValue) {
      if (!this.isSaved && this.fieldVisibilities.length > 0) {
        document.getElementById('state').value = this.previouslySelectedState
        this.saveDataInformationVisible = true
        return
      }

      this.selectedState = this.previouslySelectedState = optionValue

      // if (this.model.roleId !== null) {
      //   this.loadSchema(this.retrieveSchemaName(optionValue))
      // }
    },
    changeClient (optionValue) {
      if (!this.isSaved && this.fieldVisibilities.length > 0) {
        document.getElementById('client').value = this.previouslySelectedClient
        this.saveDataInformationVisible = true
        return
      }

      this.model.client = this.previouslySelectedClient = optionValue

      if (this.model.roleId !== null) {
        this.loadSchema(this.retrieveSchemaName(optionValue))
      }
    },
    getLoadFieldVisibilitiesMethod (schemaName) {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/schemas/${schemaName}/${this.schemaName}/roles/${this.model.roleId}/field-visibility`)
        : api.request(this.service, 'get', `/schemas/${this.schemaName}/roles/${this.model.roleId}/field-visibility`)
    },
    loadFieldsVisibilities (schemaName = this.schemaName) {
      this.getLoadFieldVisibilitiesMethod(schemaName)
        .then((response) => {
          this.fieldVisibilities = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować widoczności pól'
          })
        })
    },
    createEmptyElement () {
      return {
        id: null,
        fieldItem: { id: null },
        state: { id: this.selectedState },
        visibility: null
      }
    },
    getLoadSchemaPutMethod (schemaName) {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'put', `/schemas/${this.retrieveSchemaName()}/${this.schemaName}/field-visibility`, this.createDataToSend())
        : api.request(this.service, 'put', `/schemas/${this.schemaName}/field-visibility`, this.createDataToSend())
    },
    submitData (schemaName = this.schemaName) {
      this.getLoadSchemaPutMethod(schemaName)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    getFieldItemId (c) {
      if (this.fieldVisibilities[c].fieldItem.id !== null) {
        return this.fieldVisibilities[c].fieldItem.id
      } else {
        return null
      }
    },
    createDataToSend () {
      let submitData = { fieldsVisibilities: [], role: this.model.roleId }

      for (let c = 0; c < this.fieldVisibilities.length; ++c) {
        submitData.fieldsVisibilities.push({
          id: this.fieldVisibilities[c].id,
          role: this.model.roleId,
          state: this.fieldVisibilities[c].state.id,
          fieldItem: this.getFieldItemId(c),
          visibility: this.fieldVisibilities[c].visibility
        })
      }

      return submitData
    },
    loadClients () {
      if (!this.isServiceWithClients) {
        return
      }
      this.clients = this.$store.state.base.user.serviceClients[this.service].map(client => {
        return {
          value: client.name,
          label: client.label
        }
      })
    },
    retrieveSchemaName (client = this.model.client) {
      // TODO: delete '&& this.workflowName.includes('main')' if there are custom appraisal schemas
      if (this.isServiceWithClients && this.workflowName.includes('main')) {
        return process.env.VUE_APP_CUSTOM_FORM_SCHEMAS.includes(client) ? `${client}-${this.schemaName}` : this.schemaName
      }
      return this.schemaName
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 18px
}
</style>
