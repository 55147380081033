<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/NewContractor'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    mode: { type: String, required: true },
    service: { type: String, required: true }
  },
  data () {
    return {
      events: {
        newContractorModal: 'core:contractor:newContractorModal'
      },
      title: 'Dodanie kontrahenta',
      method: { new: 'post' },
      action: { new: '/contractors' }
    }
  },
  created () {
    this.listenForSelectChange()

    this.$on('close', () => {
      this.$refs.form.selectHasBeenChanged = false
    })
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$events.$emit('reloadGrid')
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Dodano kontrahenta'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error, 'error', 'Wystąpił błąd', 'Nie można dodać kontrahenta.')
        })
    },
    createDataToSend () {
      return {
        symbol: this.model.symbol,
        label: this.model.label,
        description: this.model.description,
        phone: this.model.phone,
        email: this.model.email,
        avatar: this.model.avatar,
        units: this.model.unitIds,
        userGuide: this.model.userGuide
      }
    },
    listenForSelectChange () {
      this.$events.on(this.events.newContractorModal, (selectedUnits) => {
        this.model.unitIds = []
        let units = [...selectedUnits]
        units.forEach((unit) => {
          this.model.unitIds.push(unit.value)
        })
      })
    }
  }
}
</script>
