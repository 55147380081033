import Grid from '../../../share/grid/Grid.vue'
import Page from '../../../../share/content/Page'
import NewBankModal from './modal/NewBank.vue'

export default {
  template: `
    <div>
        <section class="content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box box-default box-solid">
                        <div class="box-body table-responsive">
                            <grid :source="gridDataSource"
                                  :name="gridName"
                                  :columns="gridColumns"
                                  :actions="gridActions"
                                  @show="actionShow">
                            </grid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <new-bank-modal :show="modalBankVisible"
                    @close="modalBankVisible = false"
                    mode="new"
                    :service="service">
        </new-bank-modal>
    </div>`,
  components: {
    Grid,
    NewBankModal
  },
  mixins: [
    Page
  ],
  data () {
    return {
      events: {
        addBank: 'core:bank:addBank'
      },
      gridColumns: [
        { id: 0, data: 'label', title: 'Nazwa' },
        { id: 1, data: 'city', title: 'Miejscowość' },
        { id: 2, data: 'postcode', title: 'Kod pocztowy' },
        { id: 3, data: 'voivodeship', title: 'Województwo' }
      ],
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'folder-open', acl: { service: 'core', action: 'get_bank' } }
      ],
      modalBankVisible: false
    }
  },
  mounted () {
    this.$events.on(this.events.addBank, this.addBank)
  },
  computed: {
    gridDataSource () {
      const vm = this
      return { service: vm.service, url: '/bank' }
    }
  },
  methods: {
    addBank () {
      this.modalBankVisible = true
    },
    actionShow (id) {
      const vm = this
      this.$router.push({ name: vm.actionShowUrlName, params: { id } })
    }
  }
}
