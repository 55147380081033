import Grid from '../grid/Grid.vue'
import Page from '../../../share/content/Page'
import RoleModal from './modal/Role.vue'

export default {
  template: `
    <div>
        <section class="content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box box-default box-solid">
                        <div class="box-body table-responsive">
                            <grid :source="gridDataSource"
                                  :name="gridName"
                                  :columns="gridColumns"
                                  :actions="gridActions"
                                  @show="actionShow">
                            </grid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <role-modal :show="modalVisible"
                    @close="modalVisible = false"
                    :service="service">
        </role-modal>
    </div>`,
  components: {
    Grid,
    RoleModal
  },
  mixins: [
    Page
  ],
  data () {
    return {
      gridColumns: [
        { id: 0, data: 'label', title: 'Opis' },
        { id: 1, data: 'name', title: 'Nazwa' }
      ],
      gridActions: [],
      modalVisible: false
    }
  },
  mounted () {
    this.$events.on(`${this.service}:role:addRole`, this.addRole)
  },
  computed: {
    gridDataSource () {
      const vm = this
      return { service: vm.service, url: '/roles' }
    }
  },
  methods: {
    addRole () {
      this.modalVisible = true
    },
    actionShow (id) {
      const vm = this
      this.$router.push({ name: vm.actionShowUrlName, params: { id } })
    }
  }
}
