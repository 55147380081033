import Grid from "../../../share/grid/Grid.vue";
import Page from "../../../../share/content/Page";
import MakeModal from "./modal/Make.vue";
import ModelModal from "./modal/Model.vue";

export default {
  template: `
    <div v-if="showGrid">
        <section class="content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box box-default box-solid">
                        <div class="box-body table-responsive">
                            <grid :source="gridDataSource"
                                  :name="gridName"
                                  :columns="gridColumns">
                            </grid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <make-modal :show="modalMakeVisible"
                    @close="modalMakeVisible = false"
                    mode="new"
                    :service="service">
        </make-modal>
        <model-modal :show="modalModelVisible"
                    @close="modalModelVisible = false"
                    mode="new"
                    :service="service">
        </model-modal>
    </div>`,
  components: {
    Grid,
    MakeModal,
    ModelModal
  },
  mixins: [Page],
  data() {
    return {
      events: {
        addMake: "core:vehicle:addMake",
        addModel: "core:vehicle:addModel"
      },
      gridColumns: [],
      modalMakeVisible: false,
      modalModelVisible: false,
      showGrid: false
    };
  },
  mounted() {
    this.getGridColumns();
    this.$events.on(this.events.addMake, this.addMake);
    this.$events.on(this.events.addModel, this.addModel);
  },
  computed: {
    gridDataSource() {
      const vm = this;
      return { service: vm.service, url: "/vehicles" };
    }
  },
  methods: {
    addMake() {
      this.modalMakeVisible = true;
    },
    getGridColumns() {
      const vm = this;
      this.gridColumns = [
        { id: 0, data: "make", title: this.$t("pages.vehicles.make") },
        { id: 1, data: "model", title: this.$t("pages.vehicles.model") },
        {
          id: 2,
          data: "vehicleType",
          title: this.$t("pages.vehicles.vehicleType"),
          render(data) {
            const vehicleTypes = {
              1: vm.$t("pages.vehicles.car"),
              2: vm.$t("pages.vehicles.truck")
            };
            return vehicleTypes[data];
          }
        }
      ];
      this.showGrid = true;
    },
    addModel() {
      this.modalModelVisible = true;
    },
    actionShow(id) {
      const vm = this;
      this.$router.push({ name: vm.actionShowUrlName, params: { id } });
    }
  }
};
