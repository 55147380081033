import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}

  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }

  return [
    {
      path: `${pathPrefix}stany-procesu`,
      name: `${service}_state_listing`,
      component: () => import('../../../../components/bundles/finance/admin/state/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_states'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
          ]
        },
        icon: 'disc',
        title: 'Lista stanów',
        secondMenu: [
          {
            event: { name: `${service}:main:addState` },
            icon: 'plus',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_states' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_state_edit`,
      component: () => import('../../../../components/bundles/finance/admin/state/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_states'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_state_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_states' }
          },
          {
            event: { name: `${service}:main:stateSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_states' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_state_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_states' }
          }
        ]
      }
    },
  ]
}
