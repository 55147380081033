import role from './role'
import processStatus from './process-status'
import processMap from './process-map'
import visibilityFinance from './visibility-finance'
import clients from './clients'

export default function (service, name) {
  return [
    ...clients(service, name),
    ...role(service, name),
    ...processStatus(service, name),
    ...processMap(service, name),
    ...visibilityFinance(service, name),
  ]
}
