<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div
              v-if="isServiceWithClients"
              class="row"
            >
              <select-type
                class="col-sm-4 col-md-3"
                name="clients"
                :label="'Klient'"
                :required="false"
                :options="clients"
                :disabled="false"
                :empty-first="true"
                :value="client"
                @input="changeClient"
              />
            </div>
            <div
              v-if="!isServiceWithClients"
              class="box-body table-responsive"
            >
              <grid
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
              />
            </div>
            <div
              v-if="client"
              :key="gridKey"
              class="box-body table-responsive"
            >
              <grid
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                :additional-params="{clientName: client}"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SelectType from '../../../../share/form/type/SelectType'
import Grid from '../../../share/grid/Grid.vue'
import Page from '../../../../share/content/Page'

export default {
  components: {
    Grid,
    SelectType
  },
  mixins: [
    Page
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Widoczność pól', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_task_field_visibility',
      editEventName: '',
      gridActions: [],
      gridColumns: [
        { id: 0, data: 'field', title: 'Pole' },
        { id: 1, data: 'fieldGroup', title: 'Grupa' },
        { id: 2, data: 'state', title: 'Stan' },
        { id: 3, data: 'role', title: 'Rola' },
        { id: 4, data: 'visibility', title: 'Poziom dostępu' }
      ],
      clients: [],
      client: null,
      gridKey: 0
    }
  },
  computed: {
    isServiceWithClients () {
      if (this.service) {
        return this.$isWithClients(this.service)
      } else {
        return false
      }
    }
  },
  beforeMount () {
    if (this.isServiceWithClients) {
      this.getClients()
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  mounted () {
    this.$events.on(this.editEventName, this.editMode)
  },
  methods: {
    getClients () {
      this.clients = this.$store.state.base.user.serviceClients[this.service].map(client => {
        return {
          value: client.name,
          label: client.label
        }
      })
    },
    changeClient (event) {
      this.client = event
      this.gridKey = Math.random() * 100
    },
    getInitEditModeUrlName () {
      let workflow = this.$route.meta.workflow
      if (workflow.includes('-')) {
        workflow = this.$route.meta.workflow.split('-')[1]
      } else {
        workflow = 'appraisal'
      }
      this.editModeUrlName = `${this.service}_${workflow}_field_access_edit`
    },
    getInitEditEventName () {
      let workflow = 'main'
      if (!this.$route.meta.workflow.includes('main')) {
        workflow = 'appraisal'
      }
      this.editEventName = `${this.service}:${workflow}:fieldVisibilityEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/schemas/${this.$route.meta.workflow}/field-visibility` }
    },
    editMode () {
      const vm = this
      this.$router.push({ name: vm.editModeUrlName })
    }
  }
}
</script>
