<script>
import BaseLoginModal from '../../../share/modal/BaseLoginModal'
// import api from '../../../../api'

export default {
  mixins: [
    BaseLoginModal
  ],
  methods: {
    submitForm () {
      this.toggleLoading()
      this.$validator.validateAll().then(() => {
        this.$emit('success')
        // api.request('core', 'post', 'security/user-authentication', this.createDataToSend())
        //   .then(() => {
        //     this.toggleLoading()
        //     this.$emit('success')
        //     this.$notify({
        //       type: 'success',
        //       text: 'Użytkownik potwierdzony'
        //     })
        //   })
        //   .catch(() => {
        //     this.toggleLoading()
        //     this.$notify({
        //       type: 'error',
        //       text: 'Błąd logowania'
        //     })
        //     this.$emit('success')
        //     const vm = this
        //     this.$router.push({name: vm.backUrlName})
        //   })
      })
    }
  }
}
</script>
