<template>
  <div class="row">
    <section
      v-if="!value.reserved"
      class="col-xs-offset-2 col-xs-8"
    >
      <p class="appraiser-select-positioning">
        {{ $t('calendar.reserveModal.date') }}: <span style="font-weight: bold">{{ startDate }}</span>
      </p>
      <p class="appraiser-select-positioning">
        {{ $t('calendar.reserveModal.startAt') }}: <span style="font-weight: bold">{{ startHour }}</span>
      </p>
      <p class="appraiser-select-positioning">
        {{ $t('calendar.reserveModal.endAt') }}: <span style="font-weight: bold">{{ endHour }}</span>
      </p>
      <div
        class="form-group has-error calendar-person-multiselect-appraiser"
        style="margin-top: 20px;"
      >
        <label class="appraiser-select-positioning">{{ $t('calendar.reserveModal.multiselect.findVictim') }}</label>
        <CalendarPersonMultiselect
          v-if="value.service"
          :schema="personSchema"
          :model="value"
        />
      </div>
      <div
        v-if="(value.service === 'extpko' && isAllowedToSelectAppraiser)"
        class="form-group has-error calendar-multiselect-available-appraiser"
      >
        <label class="available-appraiser-select-positioning">Wybierz odpowiedzialnego *</label>
        <vue-multiselect
          :id="'input-appraiser-multiselect-new-booking-date'"
          class="available-appraiser-multiselect"
          :name="'appraiser'"
          v-model="value.selectedAvailableAppraiser"
          :label="'fullname'"
          :select-label="'Wybierz rzeczoznawcę'"
          :selected-label="'Wybrano'"
          :deselect-label="'Odznacz'"
          :options="availableAppraisers"
          :placeholder="'wybierz/szukaj'"
          @input="changeSelectedAvailableAppraiser"
        >
          <template slot="noResult">
            <p>Brak wyników</p>
          </template>
          <template slot="noOptions">
            <p>Brak wyników</p>
          </template>
        </vue-multiselect>
<!--        <span class="help-block available-appraiser-select-positioning">Pole dodaj rzeczoznawcę jest wymagane.</span>-->
      </div>
    </section>
    <div
      v-if="bookingDates && bookingDates.length > 0"
      class="appraiser-select-positioning col-xs-offset-2 col-xs-8"
      style="margin-top: 20px"
    >
      <p>{{$t('calendar.reserveModal.reservationsList')}}</p>
      <div style="display: flex; flex-direction: row; justify-content: start; margin-bottom: 20px;">
        <div style="width: 47%; margin-left: 3%;">
          {{$t('calendar.reserveModal.reservationsVictimColumn')}}
        </div>
        <div style="width: 50%;">
          {{$t('calendar.reserveModal.reservationsAppraiserColumn')}}
        </div>
      </div>
      <div
        v-for="(hour, index) in bookingDates"
        :key="`${hour.victim.forename}-${index}`"
      >
        <div>
          <div class="victim-row">
            {{ index + 1 }}.
            <div>{{ hour.victim.forename }} {{ hour.victim.surname }}</div>
            <div>{{ hour.employee.fullname }}</div>
            <button
              v-if="isDeleteButtonAvailable"
              class="zord-btn btn zord-btn-primary btn-primary btn-primary-delete-victim"
              @click="deleteReservation(hour)"
              :title="$t('calendar.reserveModal.reservationsDeleteButton')"
            >
              <i
                class="fa fa-trash-o"
              />
            </button>
            <button
              v-if="(value.service === 'extgen' || value.service === 'extavi' || value.service === 'extpko' || value.service === 'demofokus' || value.service === 'extgsl' || value.service === 'extemn' || value.service === 'extacn' || value.service === 'extgcz' || value.service === 'extshp' || value.service === 'extuni') && isDeleteButtonAvailable"
              class="zord-btn btn zord-btn-primary btn-primary btn-primary-delete-victim"
              @click="setEditedReservation(hour, 'byDate')"
              :title="$t('calendar.reserveModal.reservationsChangeDateButton')"
            >
              <i
                class="fa fa-pencil"
              />
            </button>
            <button
              v-if="(value.service === 'extgen' || value.service === 'extavi' || value.service === 'extpko' || value.service === 'demofokus' || value.service === 'extgsl' || value.service === 'extemn' || value.service === 'extacn' || value.service === 'extgcz' || value.service === 'extshp' || value.service === 'extuni') && isDeleteButtonAvailable && isAllowedToEditAppraiser"
              class="zord-btn btn zord-btn-primary btn-primary btn-primary-delete-victim"
              @click="setEditedReservation(hour, 'byAppraiser')"
              :title="$t('calendar.reserveModal.reservationsChangeAppraiserButton')"
            >
              <i
                class="fa fa-user"
              />
            </button>
          </div>

          <transition name="fade">
            <div
              v-if="(value.service === 'extgen' || value.service === 'extavi' || value.service === 'extpko' || value.service === 'demofokus' || value.service === 'extgsl' || value.service === 'extemn' || value.service === 'extacn' || value.service === 'extgcz' || value.service === 'extshp' || value.service === 'extuni') && editedBookingHour && editedBookingHour.bookingHourId === hour.bookingHourId && editMode === 'byDate'"
              class="edit-booking-date-datepicker"
            >
              <vue-form-generator
                :class="{'found-businessHour': isEditedBookingHourAvailable, 'not-found-businessHour': !isEditedBookingHourAvailable}"
                class=""
                :schema="{groups: [{fields: [datepickerSchema]}]}"
                :model="date"
              />
              <button
                class="zord-btn btn edit-booking-date-button"
                :class="{'btn-primary': isEditedBookingHourAvailable, 'btn-danger': !isEditedBookingHourAvailable, 'zord-btn-primary': isEditedBookingHourAvailable, 'zord-btn-danger': !isEditedBookingHourAvailable}"
                @click="editReservation(hour)"
              >
                <span v-if="isEditedBookingHourAvailable">{{ $t('calendar.reserveModal.change') }}</span>
                <span v-else>{{ $t('calendar.reserveModal.noFreeBusinessHours') }}</span>
              </button>
            </div>
            <div
              v-if="(value.service === 'extgen' || value.service === 'extavi' || value.service === 'extpko' || value.service === 'demofokus' || value.service === 'extgsl' || value.service === 'extemn' || value.service === 'extacn' || value.service === 'extgcz' || value.service === 'extshp' || value.service === 'extuni') && editedBookingHour && editedBookingHour.bookingHourId === hour.bookingHourId && editMode === 'byAppraiser'"
              class="edit-booking-date-datepicker"
            >
              <vue-multiselect
                :id="'input-appraiser-multiselect-new-booking-date'"
                class="appraiser-multiselect"
                :name="'appraiser'"
                :value="selectedAppraiser"
                :label="'fullname'"
                :select-label="$t('calendar.multiselect.choseAppraiser')"
                :selected-label="$t('calendar.multiselect.chosen')"
                :deselect-label="$t('calendar.multiselect.deselect')"
                :options="appraisers"
                :placeholder="''"
                @input="changeSelectedAppraiser"
              >
                <template slot="noResult">
                  <p>{{$t('calendar.multiselect.findAppraiserNoOptions')}}</p>
                </template>
                <template slot="noOptions">
                  <p>{{$t('calendar.multiselect.findAppraiserNoOptions')}}</p>
                </template>
              </vue-multiselect>
              <button
                class="zord-btn btn edit-booking-date-button"
                :class="{'btn-primary': selectedAppraiser, 'btn-danger': !selectedAppraiser, 'zord-btn-primary': selectedAppraiser, 'zord-btn-danger': !selectedAppraiser}"
                @click="editReservation(hour)"
              >
                <span v-if="appraisers.length !== 0">{{ $t('calendar.reserveModal.change') }}</span>
                <span v-else> {{$t('calendar.reserveModal.noAvailableAppraisers')}}</span>
              </button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarPersonMultiselect from './calendarPersonMultiselect'
import FormModalValidation from '../../form/FormModalValidation'
import VueFormGenerator from 'vue-form-generator'
import Vue from 'vue'
import moment from 'moment'
import api from '../../../../api'
import ErrorNotify from '../../mixins/ErrorNotify'
import VueMultiselect from 'vue-multiselect/src/Multiselect'
import WebStorage from '../../WebStorage'

Vue.use(VueFormGenerator)

export default {
  name: 'NewCalendarEvent',
  components: {
    VueMultiselect,
    CalendarPersonMultiselect
  },
  mixins: [
    FormModalValidation,
    ErrorNotify
  ],
  props: {
    value: { type: Object, required: true },
    show: { type: Boolean, required: false }
  },
  data () {
    return {
      editedBookingHour: null,
      date: { date: null },
      datepickerSchema: {
        'type': 'dateTimePicker',
        'label': this.$t('calendar.reserveModal.changeReservationDate'),
        'inputName': 'date',
        'disabled': false,
        'required': false,
        'relatedFields': [],
        'model': 'date',
        'readonly': false,
        'dateTimePickerOptions': { 'format': 'YYYY-MM-DD HH:mm', 'locale': this.$i18n.locale }
      },
      selectedAppraiser: null,
      selectedAvailableAppraiser: null,
      appraisers: [],
      editMode: '',
      availableAppraisers: []
    }
  },
  computed: {
    bookingDates () {
      return this.value.selectedAppraiser ? this.value.bookingDates.filter(hour => {
        return !this.value.selectedAppraiser || this.value.selectedAppraiser && this.value.selectedAppraiser.name === hour.employee.fullname
      }) : this.value.bookingDates
    },
    isAllowedToEditAppraiser () {
      return (WebStorage.getSecurityActions()['extgen'] && WebStorage.getSecurityActions()['extgen'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['demofokus'] && WebStorage.getSecurityActions()['demofokus'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extuni'] && WebStorage.getSecurityActions()['extuni'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extpko'] && WebStorage.getSecurityActions()['extpko'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extgcz'] && WebStorage.getSecurityActions()['extgcz'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extshp'] && WebStorage.getSecurityActions()['extshp'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extgsl'] && WebStorage.getSecurityActions()['extgsl'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extemn'] && WebStorage.getSecurityActions()['extemn'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extacn'] && WebStorage.getSecurityActions()['extacn'].includes('get_booking_date_free_employees')) ||
          (WebStorage.getSecurityActions()['extavi'] && WebStorage.getSecurityActions()['extavi'].includes('get_booking_date_free_employees'))
    },
    isAllowedToSelectAppraiser () {
      if (!this.$store.state.base.securityActions.hasOwnProperty('extpko')) {
        return false
      }
      return this.$store.state.base.securityActions['extpko'].some(el => el === 'get_business_hour_free_employees')
    },
    startDate () {
      return this.value && this.value.date && this.value.date.startDate && this.value.date.startDate.split(' ')[0]
    },
    startHour () {
      return this.value && this.value.date && this.value.date.startDate && this.value.date.startDate.split(' ')[1].slice(0, 5)
    },
    endHour () {
      return this.value && this.value.date && this.value.date.endDate && this.value.date.endDate.split(' ')[1].slice(0, 5)
    },
    personSchema () {
      return {
        'type': 'personMultiselect',
        'label': 'Imię i nazwisko poszkodowanego',
        'inputName': 'chosenPerson',
        'relatedFields': [],
        'collectionIndex': 0,
        'model': 'chosenPerson',
        'required': false,
        'params': {
          'viewOption': 'label',
          'mapOptions': { 'optionId': 'id', 'optionLabel': ['name', 'forename', 'surname'] }
        },
        'source': { 'service': this.value.service, 'method': 'get', 'url': '/person?format=multiselect' }
      }
    },
    getUserUuid () {
      return this.$store.state.base.user.uuid
    },
    getSelectedBusinessHour () {
      if (this.editedBookingHour && this.date.date) {
        return this.value.businessHours[this.value.service]
          .filter(hour => (!hour.reserved || hour.reserved === 0) &&
              moment(this.date.date).valueOf() >= moment(hour.start).valueOf() &&
              moment(this.date.date).valueOf() < moment(hour.end).valueOf() &&
              moment(this.date.date).valueOf() > moment().valueOf() &&
              moment(hour.start).valueOf() > moment().valueOf())
      } else {
        return []
      }
    },
    isEditedBookingHourAvailable () {
      return this.getSelectedBusinessHour !== 'undefined' && this.getSelectedBusinessHour.length !== 0
    },
    isDeleteButtonAvailable () {
      if (this.startDate && this.startHour) {
        return moment(`${this.startDate} ${this.startHour}`).valueOf() > moment().valueOf()
      } else {
        return false
      }
    }
  },
  watch: {
    show: function (newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          if (this.value.service === 'extpko' && this.isAllowedToSelectAppraiser) {
            this.getAvailableAppraisers()
          }
          // Use some method when modal opens
        })
      } else {
        if (this.editedBookingHour) {
          this.editedBookingHour = null
          this.date.date = null
        }
      }
    },
    'value.chosenPerson': function (newVal) {
      let multiselect = this.$el.getElementsByClassName('calendar-person-multiselect-appraiser')
      if (newVal) {
        multiselect[0].classList.remove('has-error')
        multiselect[0].classList.add('has-success')
      } else {
        multiselect[0].classList.add('has-error')
        multiselect[0].classList.remove('has-success')
      }
    },
    'value.selectedAvailableAppraiser': function (newVal) {
      let multiselect = this.$el.getElementsByClassName('calendar-multiselect-available-appraiser')
      if (newVal) {
        multiselect[0].classList.remove('has-error')
        multiselect[0].classList.add('has-success')
      } else {
        multiselect[0].classList.add('has-error')
        multiselect[0].classList.remove('has-success')
      }
    }
  },
  mounted () {
    this.$events.on('calendar:newCalendarEventClosed', this.clearForm)
  },
  methods: {
    clearForm () {
      this.editedBookingHour = null
      this.date = { date: null }
      this.selectedAppraiser = null
      this.appraisers = []
      this.editMode = ''
    },
    changeSelectedAppraiser (event) {
      this.selectedAppraiser = event
    },
    changeSelectedAvailableAppraiser (event) {
      this.selectedAvailableAppraiser = event
    },
    deleteReservation (event) {
      this.$events.emit('deleteBookingDateEvent', event)
    },
    async getAppraisers (event) {
      this.search = event
      try {
        let response = await api.request(this.value.service, 'get', `/booking-dates/${this.editedBookingHour.bookingHourId}/free-employees`)
        this.appraisers = Object.values(response.data).length !== 0 ? Object.values(response.data)[0].map(appraiser => {
          return {
            businessHourId: appraiser.parentBusinessHour.id,
            fullname: appraiser.employee.fullname
          }
        }) : []
      } catch (e) {
        this.errorNotify(e, 'error', this.$t('calendar.reserveModal.errorGetAppraisersTitle'), this.$t('calendar.reserveModal.errorGetAppraisersText'))
        console.error(e)
      }
    },
    async getAvailableAppraisers (event) {
      this.search = event
      try {
        let {data} = await api.request(this.value.service, 'get', `/business-hours/free-employees?startHour=${this.startDate}_${this.startHour}&endHour=${this.startDate}_${this.endHour}`)
        this.availableAppraisers = Object.values(data).length !== 0 ? Object.values(data)[0].map(appraiser => {
          return {
            uuid: appraiser.employee.uuid,
            businessHourId: appraiser.parentBusinessHour.id,
            fullname: appraiser.employee.fullname
          }
        }) : []
      } catch (e) {
        this.errorNotify(e, 'error', this.$t('calendar.reserveModal.errorGetAppraisersTitle'), this.$t('calendar.reserveModal.errorGetAppraisersText'))
        console.error(e)
      }
    },
    setEditedReservation (event, mode) {
      this.editMode = mode

      if (!this.editedBookingHour) {
        this.editedBookingHour = event
      } else if (this.editedBookingHour && this.editedBookingHour.bookingHourId === event.bookingHourId && this.editMode !== mode) {
        this.editedBookingHour = null
        this.date.date = null
      } else {
        this.editedBookingHour = event
      }
      if (mode === 'byAppraiser') {
        this.getAppraisers()
      }
    },
    editReservation (event) {
      let selectedBusinessHour = this.getSelectedBusinessHour
      if (this.editMode === 'byDate') {
        try {
          let dataToSend = {
            newBusinessHour: {
              id: selectedBusinessHour[0].businessHourId,
              startAt: selectedBusinessHour[0].start,
              endAt: selectedBusinessHour[0].end
            }
          }
          if (this.isEditedBookingHourAvailable) {
            api.request(this.value.service, 'put', `/booking-dates/${event.bookingHourId}/edit`, dataToSend)
              .then((response) => {
                this.$events.emit('editBookingDateEvent')
              }).catch((error) => {
                this.errorNotify(error)
              })
          }
        } catch (e) {
          this.errorNotify(e, 'error', this.$t('calendar.reserveModal.errorNodataTitle'), this.$t('calendar.reserveModal.errorNodataText'))
        }
      } else {
        try {
          let dataToSend = {
            newBusinessHour: {
              id: this.selectedAppraiser.businessHourId,
              startAt: this.editedBookingHour.start,
              endAt: this.editedBookingHour.end
            }
          }
          api.request(this.value.service, 'put', `/booking-dates/${event.bookingHourId}/edit`, dataToSend)
            .then((response) => {
              this.$events.emit('editBookingDateEvent')
            }).catch((error) => {
              this.errorNotify(error)
            })
          this.$events.emit('editBookingDateEvent')
        } catch (e) {
          this.errorNotify(e, 'error', this.$t('calendar.reserveModal.errorNodataTitle'), this.$t('calendar.reserveModal.errorNodataText'))
        }
      }
    }
  }
}
</script>

<style>
    /*.appraiser-select-positioning, .available-appraiser-select-positioning {*/
    /*    !*width: 40rem;*!*/
    /*    margin: 1px 15%;*/
    /*    display: inline-block;*/
    /*}*/

    .not-found-businessHour .field-dateTimePicker > .field-wrap > .input-group.date, .has-error .calendar-person-multiselect .multiselect, .has-error .available-appraiser-multiselect.multiselect {
        border: solid 1px red;
        border-radius: 2px;
    }

    .found-businessHour .field-dateTimePicker > .field-wrap > .input-group.date, .has-success .calendar-person-multiselect .multiselect, .has-success .available-appraiser-multiselect.multiselect  {
        border: solid 1px #6ac13b;
        border-radius: 2px;
    }

    .edit-booking-date-datepicker {
        display: flex;
        justify-content: center;
    }
    .edit-booking-date-button {
        height: 32px;
        margin-top: 1.6%;
        font-size: 12px;
    }
    .calendar-person-multiselect-appraiser .help-block {
        display: none;
    }

    .calendar-person-multiselect-appraiser.has-error .help-block {
        display: inline-block;
    }

    .appraiser-select-positioning select.form-control {
        padding: 4px 12px;
    }

    .new-event-modal .victim-row {
        display: flex;
        justify-content: space-between;
    }

    .new-event-modal .victim-row div {
        width: 90%;
        border-bottom: 1px solid #42a5f6;
    }

    .vue-form-generator .field-dateTimePicker .form-control {
        width: 100%;
    }
    .field-dateTimePicker .input-group.date {
        width: 20rem;
    }
    /*.field-dateTimePicker label[for*=date] + div .input-group.date>.bootstrap-datetimepicker-widget.dropdown-menu.usetwentyfour {*/
    /*    width: 40rem;*/
    /*    height: 29rem;*/
    /*}*/
    .fade-enter-active {
        transition: opacity .25s;
        animation: stretch .2s;
    }
    .fade-leave-active {
        transition: opacity .25s;
        animation: stretch .2s reverse;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        height: 1%;
    }
    @keyframes stretch {
        0% {
            transform: scale3d(1, 0, 1);
        }
        50% {
            transform: scale3d(1, 0.5, 1);
        }
        100% {
            transform: scale3d(1, 1, 1);
        }
    }
</style>
