import api from '@/api/index.js'
import { setupTotalCount } from '../setupTotalCount'
import _ from 'lodash'

export default {
  data() {
    return {
      newTasksServices: ['intbls', 'intgen', 'intvig'],
      newTasksAcl: ['get_new_task_statistics'],
      newTasksKey: 234231,
      newTasks: {
        title: 'Nowe zlecenia',
        total: '',
        data: {
          x: 'x',
          columns: [],
          groups: []
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60
            }
          }
        }
      },
      newTasksDefault: {},
      newTasksLoaded: false
    }
  },
  methods: {
    getAllowedNewTasksServices(services) {
      return this.$auth.getAllowed({ services: services, actions: this.newTasksAcl, allowOneOfActions: true })
    },
    getNewTasksStatisticRequests (services) {
      return services.map(service => api.request(service, 'get', `statistics/tasks/new`))
    },
    handleChangeNewTasksType (selectedDate) {
      if (this.newTasksLoaded) {
        this.reloadNewTasksStatistic(this.newTasksServices, selectedDate)
      }
    },
    loadNewTasksStatistic (services, selectedDate = null) {
      selectedDate = selectedDate === null ? new Date().toISOString().split('T')[0] : selectedDate
      this.selectedNewDate = selectedDate
      this.newTasksDefault = _.cloneDeep(this.newTasks)
      this.processNewTasksStatistic(services, selectedDate)
      return
    },
    reloadNewTasksStatistic (services, selectedDate) {
      this.newTasksKey = Math.random() * 100
      this.selectedNewDate = selectedDate
      this.newTasks = _.cloneDeep(this.newTasksDefault)
      this.processNewTasksStatistic(services, selectedDate)
      return
    },
    async processNewTasksStatistic (services, selectedDate, buildElements = false) {
      services = this.getAllowedNewTasksServices(services)

      if (!_.isEmpty(services)) {
        try {
          let requests = this.getNewTasksStatisticRequests(services)
          let responses = await this.getResponses(requests)
          let responseData = this.filterBy(responses, 'data')
          let chartData = this.getInitialChartData(this.workflows, this.trans)

          this.prepareChartByDate(responseData, chartData, this.workflows, this.contractorApps, selectedDate)
          this.addNewTaskToCharts(chartData)
          this.addNewTaskToRefChart(!_.isEmpty(responseData), chartData)

          if (buildElements) {
            this.prepareSelectDates(responseData, this.workflows, this.newDates)
          }

          this.newTasksLoaded = true
        } catch (error) {
          console.error(error)
        }
      }
      return
    },
    addNewTaskToCharts (chartData) {
      this.newTasks.total = setupTotalCount(chartData.columns);
      this.newTasks.data.columns.push(chartData.columns.categories)
      this.newTasks.data.columns.push(chartData.columns.main_vehicle)
      this.newTasks.data.columns.push(chartData.columns.main_property)
    },
    addNewTaskToRefChart(addToRef, chartData) {
      if (addToRef) {
        this.$refs.newTasks.chart.groups([chartData.groups])
      }
    }
  }
}
