import Grid from '../../../share/grid/Grid.vue'
import Page from '../../../../share/content/Page'
import NewPoliceUnitModal from './modal/NewPoliceUnit.vue'

export default {
  template: `
    <div>
        <section class="content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box box-default box-solid">
                        <div class="box-body table-responsive">
                            <grid :source="gridDataSource"
                                  :name="gridName"
                                  :columns="gridColumns"
                                  :actions="gridActions"
                                  @show="actionShow">
                            </grid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <new-policeUnit-modal :show="modalPoliceUnitVisible"
                    @close="modalPoliceUnitVisible = false"
                    mode="new"
                    :service="service">
        </new-policeUnit-modal>
    </div>`,
  components: {
    Grid,
    NewPoliceUnitModal
  },
  mixins: [
    Page
  ],
  data () {
    return {
      events: {
        addPoliceUnit: 'core:policeUnit:addPoliceUnit'
      },
      gridColumns: [
        { id: 0, data: 'label', title: 'Nazwa' },
        { id: 1, data: 'city', title: 'Miejscowość' },
        { id: 2, data: 'postcode', title: 'Kod pocztowy' },
        { id: 3, data: 'voivodeship', title: 'Województwo' }
      ],
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'folder-open', acl: { service: 'core', action: 'get_police_unit' } }
      ],
      modalPoliceUnitVisible: false
    }
  },
  mounted () {
    this.$events.on(this.events.addPoliceUnit, this.addPoliceUnit)
  },
  computed: {
    gridDataSource () {
      const vm = this
      return { service: vm.service, url: '/police' }
    }
  },
  methods: {
    addPoliceUnit () {
      this.modalPoliceUnitVisible = true
    },
    actionShow (id) {
      const vm = this
      this.$router.push({ name: vm.actionShowUrlName, params: { id } })
    }
  }
}
