<script>
import BasePersonModal from './BasePersonModal'
import api from '../../../../../../api'

export default {
  mixins: [
    BasePersonModal
  ],
  data () {
    return {
      title: this.$t('modals.newUser.personDataEdit'),
      okText:  this.$t('modals.newUser.personDataEditOkButton')
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, 'put', `/person/${this.$route.params.id}`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: this.$t('modals.newUser.personDataUpdated')
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: error.response.data.error.message
          })
        })
    }
  }
}
</script>
