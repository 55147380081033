import Loader from '../Loader'
import ErrorNotify from '../mixins/ErrorNotify'
import BaseFormModal from './BaseFormModal'
import _ from 'lodash'

export default {
  template: `
    <base-form-modal
        :show="show"
        :title="title"
        :ok-text="okText"
        :cancel-text="cancelText"

        @show="open"
        @close="$emit('close')"
        @beforeSubmit="function (bool) {beforeSubmitTrigger = bool}"
        >

        <form-modal
            :is-before-submit="beforeSubmitTrigger"
            :service="service"
            v-model="model"
            :show="show"
            ref="form"
            >
        </form-modal>
    </base-form-modal>
  `,
  components: {
    BaseFormModal
  },
  mixins: [
    Loader,
    ErrorNotify
  ],
  props: {
    show: { type: Boolean, required: true },
    formData: { default () { return {} } },
    service: { type: String },
    mode: { type: String, default: 'new' }
  },
  data () {
    return {
      title: 'Tytuł modala z formularzem',
      okText: 'zModal.okText.formModalMixin',
      cancelText: 'zModal.cancel.formModalMixin',
      model: {},
      beforeSubmitTrigger: false
    }
  },
  watch: {
    formDataTemp (newVal) {
      this.model = newVal
    }
  },
  computed: {
    formDataTemp: {
      get: function () {
        return _.cloneDeep(this.formData)
      }
    }
  },
  methods: {
    submitForm () {
      // overload this method to handle submitting form
      // it must emit a 'close' event to hide modal
      let txt = 'submitting...\nNadpisz submitForm() aby dostosować obsługę formularza'
      console.log(txt)
      alert(txt)
      this.$emit('close')
    },
    open () {
      // overload this method to add some behavior needed when modal opens
      this.toggleLoading()
    }
  }
}
