export default function () {
  const service = 'finance'

  // const customBreadcrumbs = {
  //   settlements: {
  //     meta: {
  //       icon: 'list',
  //       title: 'Lista rozliczeń'
  //     }
  //   }
  // }
  return [
    {
      path: 'rozliczenia',
      name: 'settlements',
      component: () => import('../../components/bundles/settlements/Listing'),
      meta: {
        acl: {
          action: ['get_settlements_datatables'],
          service: service,
          allowOneOfActions: true
        },
        icon: 'activity',
        title: 'Rozliczenia',
        secondMenu: [
          {
            event: { name: `${service}:add` },
            icon: 'plus',
            label: 'Dodaj rozliczenie',
            acl: { action: 'post_settlement', service: service }
          }
        ]
      }
    },
    {
      path: 'rozliczenia/:id',
      name: 'settlements_details',
      component: () => import('../../components/bundles/settlements/Details/Finance'),
      meta: {
        acl: {
          action: ['get_settlement'],
          service: service,
          allowOneOfActions: true
        },
        breadcrumb: {
          list: [
            'settlements'
          ]
        },
        icon: 'activity',
        title: 'Szczegóły rozliczenia',
        secondMenu: [
          {
            event: { name: `${service}:changeState` },
            icon: 'skip-forward',
            label: 'Przejdź dalej',
            acl: { service: service, action: `put_settlement`, allowOneOfActions: true }
          }
        ],
        thirdMenu: [
          {
            event: { name: `${service}:get:confirmation` },
            icon: 'plus',
            label: 'Pobierz raport PDF',
            acl: { service: service, action: ['get_settlement'], allowOneOfActions: true }
          },
          {
            event: { name: `${service}:get:report` },
            icon: 'plus',
            label: 'Pobierz raport XLS',
            acl: { service: service, action: ['get_settlement'], allowOneOfActions: true }
          }
        ]
      }
    }
  ]
}
