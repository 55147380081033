import api from '@/api/index.js'

export default {
  data() {
    return {
      tasksSentBackStatisticKey: 0,
      tasksSentBack:{
        title: 'Do odesłania',
        total: '',
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        data: {
          json: [],
          keys: {
            x: 'label',
            value: []
          },
          type: 'bar',
          groups: [],
          selection: {},
          onselected: (part, node) => {
          },
          onunselected: (slice, node) => {
          }
        },
        downloadLinkData: {
          service: 'intmil',
          type: 'task_sent_back',
          withDate: false,
          withAssistants: true
        }
      },
    }
  },
  methods: {
    loadTasksSentBack () {
      api.request('intmil', 'get', `/statistics/tasks/order-sent-back`)
        .then((response) => {
          let data = response.data.data

          const preparedData = this.getTaskSentBackStatistics(data)
          const values = this.getTaskSentBackValues(preparedData)

          this.tasksSentBackStatisticKey = Math.random() * 100
          
          const temptaskSentBack = this.tasksSentBack
          temptaskSentBack.data.json = preparedData
          temptaskSentBack.data.keys.value = values
          temptaskSentBack.data.groups = [values]

          this.tasksSentBack = temptaskSentBack

          const totalCount = data.reduce((acc, curr) => acc + Number(curr.count), 0)
          temptaskSentBack.total = `Łącznie: ${totalCount}`

          let assistants = preparedData.map(user => ({label: user.label, value: user.id} ))
          this.sentBackSelectOptions = assistants
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
    getTaskSentBackStatistics (backendData) {
      return backendData.map(({label, count, uuid}) => ({
        label: `${label} (${count})`,
        id: uuid,
        [label]: count
      }))
    },
    getTaskSentBackValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label' && key !== 'id').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
  }
}
