// NOTE: common configuration for modules intmil and intbwn

export default ({params: { service }}) => {
    let routes = {}
    routes[`${service}_state_listing`] = {
      service: service,
      action: `get_workflow_states`
    }
    routes[`${service}_state_edit`] = {
      service: service,
      action: `put_workflow_state`
    }
    routes[`${service}_transition_listing`] = {
      service: service,
      action: `get_workflow_transitions`
    }
    routes[`${service}_transition_edit`] = {
      service: service,
      action: `put_workflow_transitions`
    }
    routes[`${service}_task_visibility_listing`] = {
      service: service,
      action: `get_task_category_task_visibilities`
    }
    routes[`${service}_task_visibility_edit`] = {
      service: service,
      action: `post_task_category_task_visibilities`
    }
    routes[`${service}_field_access_listing`] = {
      service: service,
      action: `get_task_category_form_schema_field_visibilities`
    }
    routes[`${service}_field_access_edit`] = {
      service: service,
      action: `put_task_category_form_schema_field_visibilities`
    }
    return routes
  }
  