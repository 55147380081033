export default {
  setCredentials: function (user, token, refreshToken) {
    if (window.sessionStorage) {
      window.sessionStorage.setItem('user', JSON.stringify(user))
      window.localStorage.setItem('user', JSON.stringify(user))
      window.sessionStorage.setItem('token', token)
      window.localStorage.setItem('token', token)
      window.sessionStorage.setItem('refreshToken', refreshToken)
      window.localStorage.setItem('refreshToken', refreshToken)
    }
  },
  getToken: function () {
    let token = window.sessionStorage.getItem('token') || window.localStorage.getItem('token')
    return token || ''
  },
  getRefreshToken: function () {
    let refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken')
    return refreshToken || ''
  },
  setUser: function (user) {
    window.sessionStorage.setItem('user', JSON.stringify(user))
    window.localStorage.setItem('user', JSON.stringify(user))
  },
  getUser: function () {
    let user = window.sessionStorage.getItem('user') || window.localStorage.getItem('user')
    return user ? JSON.parse(user) : null
  },
  getIsCurrentSession: function () {
    let isCurrentSession = window.sessionStorage.getItem('isCurrentSession') || window.localStorage.getItem('isCurrentSession')
    return isCurrentSession || ''
  },
  setToken: function (token) {
    window.sessionStorage.setItem('token', token)
    window.localStorage.setItem('token', token)
  },
  setIsCurrentSession: function (isCurrentSession) {
    window.sessionStorage.setItem('isCurrentSession', isCurrentSession)
    window.localStorage.setItem('isCurrentSession', isCurrentSession)
  },
  setSecurityActions: function (securityActions) {
    window.sessionStorage.setItem('securityActions', JSON.stringify(securityActions))
    window.localStorage.setItem('securityActions', JSON.stringify(securityActions))
  },
  getSecurityActions: function () {
    let securityActions = window.sessionStorage.getItem('securityActions') || window.localStorage.getItem('securityActions')
    return securityActions ? JSON.parse(securityActions) : null
  },
  setContractorApps: function (contractorApps) {
    window.sessionStorage.setItem('contractorApps', JSON.stringify(contractorApps))
    window.localStorage.setItem('contractorApps', JSON.stringify(contractorApps))
  },
  getContractorApps: function () {
    let contractorApps = window.sessionStorage.getItem('contractorApps') || window.localStorage.getItem('contractorApps')
    return contractorApps ? JSON.parse(contractorApps) : null
  },
  removeToken: function () {
    this.removeSessionStorageItem('token')
  },
  removeRefreshToken: function () {
    this.removeSessionStorageItem('refreshToken')
  },
  removeUser: function () {
    this.removeSessionStorageItem('user')
  },
  removeIsCurrentSession: function () {
    this.removeSessionStorageItem('isCurrentSession')
  },
  removeSecurityActions: function () {
    this.removeSessionStorageItem('securityActions')
  },
  removeContractorApps: function () {
    this.removeSessionStorageItem('contractorApps')
  },
  setDocumentData: function (documentData) {
    window.sessionStorage.setItem('documentData', JSON.stringify(documentData))
    window.localStorage.setItem('documentData', JSON.stringify(documentData))
  },
  getDocumentData: function () {
    let documentData = window.sessionStorage.getItem('documentData') || window.localStorage.getItem('documentData')
    return documentData ? JSON.parse(documentData) : null
  },
  removeDocumentData: function () {
    this.removeSessionStorageItem('documentData')
  },
  setImportSpreadsheet: function (importData) {
    window.sessionStorage.setItem('importData', JSON.stringify(importData))
    window.localStorage.setItem('importData', JSON.stringify(importData))
  },
  getImportSpreadsheet: function () {
    let importData = window.sessionStorage.getItem('importData') || window.localStorage.getItem('importData')
    return importData ? JSON.parse(importData) : null
  },
  removeImportSpreadsheet: function () {
    this.removeSessionStorageItem('importData')
  },
  removeSessionStorageItem: function (item) {
    if (window.sessionStorage) {
      window.sessionStorage.removeItem(item)
    }
    if (window.localStorage) {
      window.localStorage.removeItem(item)
    }
  },
  setAvatars: function (avatars) {
    window.sessionStorage.setItem('avatars', JSON.stringify(avatars))
    window.localStorage.setItem('avatars', JSON.stringify(avatars))
  },
  getAvatars: function () {
    let avatars = window.sessionStorage.getItem('avatars') || window.localStorage.getItem('avatars')
    return avatars ? JSON.parse(avatars) : null
  }
}
