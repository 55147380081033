module.exports = {
  baseUrl: {
    core: '/core',
    storage: '/storage',
    extgen: '/extgen',
    intgen: '/intgen',
    demo: '/demo',
    history: '/history',
    intbls: '/intbls',
    extavi: '/extavi',
    extpko: '/extpko',
    finance: '/finance',
    extemn: '/extemn',
    extgsl: '/extgsl',
    extacn: '/extacn',
    extgcz: '/extgcz',
    extshp: '/extshp',
    extuni: '/extuni',
    intmil: '/intmil',
    intvig: '/intvig',
    intvhs: '/intvhs'
  },
  refreshTokenExpirationTime: 60 * 60 * 1000, // 1h
  refreshTokenUrl: 'security/refresh-token'
}
