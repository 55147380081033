<script>
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/SpreadsheetImport'
import api from '../../../../../api'
import WebStorage from '../../../../share/WebStorage'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      title: 'Importuj Plik',
      okText: 'Importuj'
    }
  },
  methods: {
    redirectProperly () {
      let typeToSendMap = {
        main: 'main',
        main_vehicle: 'main',
        main_property: 'main',
        appraisal: 'appraisal'
      }
      this.$isWithClients(this.service)
        ? this.$router.push({ name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_finance_import_invoice_${typeToSendMap[this.model.importTypeToSend]}` })
        : this.$router.push({ name: `${this.service}_${this.$route.meta.workflowName}_finance_import_invoice_${this.model.importTypeToSend}` })
    },
    submitForm () {
      this.$store.commit('SET_LOADING_FINANCE_IMPORT', true)
      WebStorage.setImportSpreadsheet({})
      api.request(this.service, 'post', `/finance-import`, this.createDataToSend(), this.prepareAdditionalHeaders())
        .then((response) => {
          WebStorage.setImportSpreadsheet(response.data)
          this.redirectProperly()
          this.$notify({
            type: 'success',
            text: 'Wczytano plik'
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
        .finally(() => {
          this.$store.commit('SET_LOADING_FINANCE_IMPORT', false)
        })
    },
    createDataToSend () {
      let formData = new FormData()
      formData.append('spreadsheetFile', this.model.file)
      formData.append('importType', this.model.importTypeToSend)
      formData.append('mainTaskWorkflowName', this.$route.meta.workflowName)

      if (this.$isWithClients(this.service)) {
        formData.append('clientId', this.$route.meta.client)
      }

      // TODO: avivaClaimNumber make common condition
      if (this.service === 'intbls' && (this.$route.meta.client === '1' || this.$route.meta.client === 1)) {
        formData.append('findByColumn', 'avivaClaimNumber')
      }

      if (this.service === 'intvig' && ['main_vehicle', 'main_property'].includes(this.model.importTypeToSend)) {
        formData.append('findByColumn', 'icClaimNumber')
      }

      return formData
    },
    prepareAdditionalHeaders () {
      return [
        { 'Content-Type': 'multipart/form-data' }
      ]
    }
  }
}
</script>
