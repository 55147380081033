import RouterFrame from '../../../../components/share/RouterFrame.vue'
import IntvigPersonListing from '../../../../components/bundles/intvig/admin/person/Listing.vue'

export default function (service, name) {
  return {
    path: 'person',
    component: RouterFrame,
    meta: {
      title: 'menu.victimsDatabase',
      icon: 'users',
      menuLink: true,
      acl: {
        service: service,
        action: 'get_persons'
      }
    },
    children: [
      {
        path: '',
        component: IntvigPersonListing,
        name: `${service}_person_listing`,
        meta: {
          title: 'Lista poszkodowanych',
          acl: {
            service: service,
            action: 'get_persons'
          }
        }
      }
    ]
  }
}
