import RouterFrame from '../../../../components/share/RouterFrame.vue'

export default {
  path: 'person',
  component: RouterFrame,
  meta: {
    title: 'Automaty - grupy',
    icon: 'users',
    menuLink: true,
    acl: {
      service: 'intgen',
      action: 'put_task_type_group_user'
    }
  },
  children: [
    {
      path: '',
      component: 'IntgenPersonListing',
      name: 'intgen_group_person_assign',
      meta: {
        title: 'Grupy użytkowników',
        acl: {
          service: 'intgen',
          action: 'put_task_type_group_user'
        }
      }
    }
  ]
}
