<template>
  <div
    style="background: white; padding: 10px;"
    class="calendar-container"
  >
    <div
      v-if="isAllowedToSearch"
      style=""
    >
      <label
        class="typo__label"
        for="input-appraiser-multiselect"
      >{{ $t('calendar.multiselect.findAppraiser') }}</label>
      <vue-multiselect
        id="input-appraiser-multiselect"
        class="appraiser-multiselect"
        :name="'appraiser'"
        :value="selectedAppraiser"
        :label="'name'"
        :select-label="$t('calendar.multiselect.choseAppraiser')"
        :selected-label="$t('calendar.multiselect.chosen')"
        :deselect-label="$t('calendar.multiselect.deselect')"
        :options="appraisers"
        :placeholder="$t('calendar.multiselect.findAppraiserPlaceholder')"
        @input="changeSelectedAppraiser"
        @search-change="getAppraisers"
      >
        <template slot="noOptions">
          <p>{{ $t('calendar.multiselect.findAppraiserNoOptions') }}</p>
        </template>
        <template slot="noResult">
          <p>{{ $t('calendar.multiselect.findAppraiserNoOptions') }}</p>
        </template>
      </vue-multiselect>
    </div>

    <div id="calendar"/>
    <NewPerson
      v-if="model.service"
      :show="newPersonVisible"
      mode="new"
      :service="model.service"
      @close="newPersonVisible = false"
      @savedModel="updateModel"
    />
    <NewCalendarEvent
      class="new-event-modal"
      :form-data="model"
      :show="newCalendarEventVisible"
      @close="handleCloseNewCalendarEventModal"
      @refetchEvents="() => initCalendar('refetch')"
    />
  </div>
</template>
<script>
import api from '../../../api'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/pl'
import timeGridPlugin from '@fullcalendar/timegrid'
import Loader from '../Loader'
import moment from 'moment'
import NewPerson from '../../bundles/share/admin/person/modal/NewPerson'
import NewCalendarEvent from './modal/NewCalendarEvent'
import ErrorNotify from '../mixins/ErrorNotify'
import WebStorage from '../WebStorage'
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'Calendar',
  components: {
    NewPerson,
    NewCalendarEvent,
    VueMultiselect
  },
  mixins: [
    Loader,
    ErrorNotify
  ],
  data () {
    return {
      textColor: 'black',
      services: [],
      colors: {
        intgen: {
          businessHoursColor: 'rgba(148, 249, 124, 0.45)',
          bookingHoursColour: 'rgba(96, 214, 80, 1)'
        },
        extgen: {
          businessHoursColor: 'rgba(124, 149, 249, 0.45)',
          bookingHoursColour: 'rgba(124, 149, 249, 1)'
        },
        demofokus: {
          businessHoursColor: 'rgba(192,117,239,0.45)',
          bookingHoursColour: 'rgb(192,117,239)'
        },
        extuni: {
          businessHoursColor: 'rgba(146,196,166,0.45)',
          bookingHoursColour: 'rgb(146,196,166)'
        },
        extavi: {
          businessHoursColor: 'rgba(149, 0, 0, 0.45)',
          bookingHoursColour: 'rgba(149, 0, 0, 1)'
        },
        extpko: {
          businessHoursColor: 'rgba(0, 149, 0, 0.45)',
          bookingHoursColour: 'rgba(0, 149, 0, 1)'
        },
        extgcz: {
          businessHoursColor: 'rgba(0, 109, 170, 0.45)',
          bookingHoursColour: 'rgba(0, 109, 170, 1)'
        },
        extshp: {
          businessHoursColor: 'rgba(100, 49, 190, 0.45)',
          bookingHoursColour: 'rgba(100, 49, 190, 1)'
        },
        extgsl: {
          businessHoursColor: 'rgba(108,227,212,0.45)',
          bookingHoursColour: 'rgba(108, 227, 212, 1)'
        },
        extemn: {
          businessHoursColor: 'rgba(32,62,248,0.45)',
          bookingHoursColour: 'rgba(32, 62, 248, 1)'
        },
        extacn: {
          businessHoursColor: 'rgba(62,92,278,0.45)',
          bookingHoursColour: 'rgba(62, 92, 278, 1)'
        }
      },
      calendarVisibility: false,
      showEventVisible: false,
      eventData: null,
      sendEventData: null,
      businessHours: {},
      bookingDates: {},
      model: {
        chosenPerson: null,
        date: '',
        selectedAppraiser: null
      },
      events: {
        calendarAddPerson: 'calendar:addPerson'
      },
      newPersonVisible: false,
      newCalendarEventVisible: false,
      selectedAppraiser: null,
      appraisers: [],
      search: '',
      timeouts: {}
    }
  },
  computed: {
    getUserUuid () {
      return this.$store.state.base.user.uuid
    },
    isAllowedToSearch () {
      return (WebStorage.getSecurityActions().hasOwnProperty('extgen') && WebStorage.getSecurityActions()['extgen'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('demofokus') && WebStorage.getSecurityActions()['demofokus'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extuni') && WebStorage.getSecurityActions()['extuni'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extpko') && WebStorage.getSecurityActions()['extpko'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extgcz') && WebStorage.getSecurityActions()['extgcz'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extshp') && WebStorage.getSecurityActions()['extshp'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extgsl') && WebStorage.getSecurityActions()['extgsl'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extemn') && WebStorage.getSecurityActions()['extemn'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extacn') && WebStorage.getSecurityActions()['extacn'].includes('post_business_hour_employees_search')) ||
        (WebStorage.getSecurityActions().hasOwnProperty('extavi') && WebStorage.getSecurityActions()['extavi'].includes('post_business_hour_employees_search'))
    }
  },
  watch: {
    model: function (newVal) {
      let model = this.model
      model.bookingDates = this.bookingDates[this.model.service].filter(hour => hour.start === this.model.date.startDate && hour.end === this.model.date.endDate)
    }
  },
  mounted () {
    this.$events.on(this.events.calendarAddPerson, this.showNewPersonModal)
    this.init()
  },
  methods: {
    handleCloseNewCalendarEventModal () {
      this.newCalendarEventVisible = false
      this.$events.emit('calendar:newCalendarEventClosed')
    },
    changeSelectedAppraiser (event) {
      this.selectedAppraiser = event
      this.initCalendar('refetch')
    },
    async getAppraisers (event) {
      this.search = event
      try {
        let response = await api.request(this.model.service, 'post', `/business-hours/employees/search`, {term: this.search})
        this.appraisers = response.data
      } catch (e) {
        console.error(e)
      }
    },
    init () {
      this.services = Object.entries(WebStorage.getSecurityActions())
        .filter(service => service[1].includes('get_booking_dates') || service[1].includes('get_business_hours'))
        .map(element => {
          return element[0]
        })
        .map((serviceName, index) => {
          return [
            serviceName,
            this.colors.hasOwnProperty(serviceName) ? this.colors[serviceName].businessHoursColor : 'rgba(255,236,15,0.87)',
            this.colors.hasOwnProperty(serviceName) ? this.colors[serviceName].bookingHoursColour : 'rgba(249,240,4,0.45)'
          ]
        })

      this.initCalendar('start')
    },
    initCalendar (job) {
      const calendarEl = document.getElementById('calendar');

      const calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'timeGridWeek',
        locale: esLocale,
        eventSources: this.getEventSourcesHandler(),
        allDaySlot: false,
        slotEventOverlap: false,
        themeSystem: 'bootstrap4',
        displayEventTime: true,
        progressiveEventRendering: true,
        height: 'auto',
        eventClick: this.eventClick,
        header: {
          start: '',
          center: '',
          end: 'prev,next title today dayGridMonth timeGridDay'
        },
      });
      if (job === 'start') {
        calendar.render();
      } else if (job === 'refetch') {
        calendar.render();
        // calendar.refetchEvents()
      }
    },
    eventClick ({ event, jsEvent, view }) {
      // Todo adds extgen temporary
      const excludedServices = {
        'extpko': true,
        'extavi': true,
        'extgen': true,
        'demofokus': true,
        'extgsl': true,
        'extemn': true,
        'extacn': true,
        'extgcz': true,
        'extshp': true,
        'extuni': true
      }
      if (event.extendedProps.appraiser.uuid === !this.getUserUuid && excludedServices.includes(event.extendedProps.contractor)) {
        return true
      } else {
        if (event.extendedProps.reserved) {
          this.model = {
            id: event.extendedProps.id,
            businessHourId: event.extendedProps.businessHourId,
            appraiser: event.extendedProps.appraiser.uuid,
            date: {
              startDate: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
              endDate: moment(event.end).format('YYYY-MM-DD HH:mm:ss')
            },
            chosenPerson: null,
            service: event.extendedProps.contractor,
            reserved: event.extendedProps.reserved,
            businessHours: this.businessHours,
            selectedAppraiser: this.selectedAppraiser
          }
          this.newCalendarEventVisible = true
        } else {
          if (moment(event.start) < moment()) {
            this.$notify({
              type: 'notify',
              title: this.$t('calendar.reservationFailedTooLate.title'),
              text: this.$t('calendar.reservationFailedTooLate.text')
            })
          } else {
            if (event.extendedProps.type === 'businessHour') {
              this.model = {
                id: event.extendedProps.id,
                businessHourId: event.extendedProps.businessHourId,
                appraiser: event.extendedProps.appraiser.uuid,
                date: {
                  startDate: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(event.end).format('YYYY-MM-DD HH:mm:ss')
                },
                chosenPerson: null,
                service: event.extendedProps.contractor,
                reserved: event.extendedProps.reserved,
                cyclicity: event.extendedProps.cyclicity,
                priority: event.extendedProps.priority,
                businessHours: this.businessHours,
                selectedAppraiser: this.selectedAppraiser
              }
              this.newCalendarEventVisible = true
            } else if (event.extendedProps.type === 'bookingHour') {
              this.showEventVisible = true
              this.eventData = {
                person: event.extendedProps.victim,
                appraiser: event.extendedProps.appraiser,
                title: event.extendedProps.title,
                date: {
                  startDate: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(event.end).format('YYYY-MM-DD HH:mm:ss')
                },
                chosenPerson: null,
                contractor: event.extendedProps.contractor,
                businessHours: this.businessHours,
                selectedAppraiser: this.selectedAppraiser
              }
            }
          }
        }
      }
    },
    updateModel (chosenPerson) {
      let model = this.model
      model.chosenPerson = chosenPerson
      this.model = model
    },
    showNewPersonModal () {
      this.newPersonVisible = true
    },
    getCalendarData (start, end, timezone, startMethod, service) {
      api.request(service, 'get', '/booking-dates?format=calendar')
        .then((response) => {
          let data = response.data.map(event => {
            return {
              bookingHourId: event.id,
              employee: event.businessHour.employee,
              start: event.startAt,
              end: event.endAt,
              victim: event.victim,
              contractor: service,
              type: 'bookingHour',
              title: `${event.victim.forename} ${event.victim.surname}`
            }
          })
          let bookingData = this.bookingDates
          bookingData[service] = data
          this.bookingDates = bookingData
          startMethod([])
        })
        .catch((error) => {
          startMethod([])
          this.errorNotify(error)
          this.toggleLoading()
        })
    },
    getBusinessHourDataMethod (start, end, timezone, callback, service) {
      let services = ['extgen', 'extavi', 'extpko', 'demofokus', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp', 'extuni']
      return this.selectedAppraiser && (services.indexOf(service) > -1)
        ? api.request(service, 'get', `/business-hours?format=calendar&startDate=${moment(start).format('YYYY-MM-DD')}&endDate=${moment(end).add(2, 'M').format('YYYY-MM-DD')}&employeeUuid=${this.selectedAppraiser.id}`)
        : api.request(service, 'get', `/business-hours?format=calendar&startDate=${moment(start).format('YYYY-MM-DD')}&endDate=${moment(end).add(2, 'M').format('YYYY-MM-DD')}`)
    },
    getBusinessHoursData (start, end, timezone, startMethod, service) {
      this.getBusinessHourDataMethod(start, end, timezone, startMethod, service)
        .then((response) => {
          let data = Object.values(response.data).map(event => {
            return {
              id: event.id,
              businessHourId: event.parentBusinessHour && event.parentBusinessHour.id,
              reserved: event.reserved,
              appraiser: {uuid: event.employee},
              start: event.startAt,
              end: event.endAt,
              contractor: service,
              type: 'businessHour',
              cyclicity: event.parentBusinessHour && event.parentBusinessHour.cyclicity,
              priority: event.parentBusinessHour && event.parentBusinessHour.priority,
              className: [service, 'businessHour', event.reserved ? 'reserved' : 'not-reserved', moment(event.startAt).valueOf() > moment().valueOf() ? 'allow-reservation' : 'prevent-reservation']
            }
          })
          let businessData = this.businessHours
          businessData[service] = data.map(event => {
            if (event.reserved) {
              event.color = 'red'
              event.textColor = 'black'
              event.title = ''
              return event
            } else {
              event.displayEventTime = true
              return event
            }
          })
          this.businessHours = businessData
          startMethod(businessData[service])
        })
        .catch((error) => {
          startMethod([])
          this.errorNotify(error)
          this.toggleLoading()
        })
    },
    flattenDeep (arr1) {
      return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val), [])
    },
    getEventSourcesHandler () {
      let vm = this
      let eventSources = function ([name, businessHoursColour, bookingHoursColour]) {
        return [
          {
            events: ({start, end, timeZone}, ...[callback, failCallback]) => {
              return vm.getCalendarData(start, end, timeZone, callback, name)
            },
            color: bookingHoursColour,
            textColor: vm.textColor
          },
          {
            events: ({start, end, timeZone}, ...[callback, failCallback]) => {
              return vm.getBusinessHoursData(start, end, timeZone, callback, name)
            },
            color: businessHoursColour,
            textColor: vm.textColor
            // textColor: 'rgba(0, 0, 0, 0)'
          }
        ]
      }
      let arr = []
      return this.flattenDeep(this.services.map(service => arr.concat(eventSources(service))))
    }
  }
}
</script>

<style>
.fc-event-main-frame .fc-event-time {
  visibility: hidden;
}
.fc-event-main-frame:hover .fc-event-time {
  visibility: visible;
}
</style>
