<template>
  <form>
    <select-type
      v-model="value['make']"
      v-validate="{ rules: { required: true }}"
      name="make"
      label="Marka"
      :required="true"
      :options="makes"
      :disabled="!makesLoaded"
      :empty-first="true"
      data-vv-as="nazwa"
      :error-message="vErrors.first('make')"
    />
    <input-type
      v-model="value['modelLabel']"
      v-validate="{ rules: { required: true }}"
      name="modelLabel"
      label="Nazwa"
      :required="true"
      data-vv-as="nazwa"
      :error-message="vErrors.first('modelLabel')"
    />
    <select-type
      v-model="value['vehicleType']"
      v-validate="{ rules: { required: true }}"
      name="vehicleType"
      label="Rodzaj pojazdu"
      :required="true"
      :options="types"
      :disabled="!makesLoaded"
      :empty-first="true"
      data-vv-as="rodzaj pojazdu"
      :error-message="vErrors.first('vehicleType')"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'
import SelectType from '../../../../../share/form/type/SelectType'
import api from '../../../../../../api'

export default {
  components: {
    InputType,
    SelectType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      events: {
        reloadMakes: 'reloadMakes'
      },
      makes: [],
      makesLoaded: false,
      typesLoaded: false,
      types: [
        { label: 'osobowy', value: 1 },
        { label: 'ciężarowy', value: 2 }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadMakes()
      // TODO uncomment after vehicle types are ready on backend side
      // this.loadTypes()
      this.$events.on(this.events.reloadMakes, () => {
        this.makes = []
        this.loadMakes()
      })
    })
  },
  methods: {
    loadMakes () {
      api.request('core', 'get', 'vehicles/makes')
        .then((response) => {
          const makes = response.data
          for (let c = 0; c < makes.length; ++c) {
            this.makes.push({ label: makes[c].label, value: makes[c].id })
          }
          this.makesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować marek'
          })
        })
    },
    loadTypes () {
      api.request('core', 'get', 'vehicles/types')
        .then((response) => {
          const types = response.data
          this.types = types.map(type => ({ label: type.label, value: type.id }))
          this.typesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować marek'
          })
        })
    }
  }
}
</script>
