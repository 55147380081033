import api from '../../../../api'
import moment from "moment";
import {setupTotalCount} from '../setupTotalCount'
import _ from 'lodash'

export default {
  data() {
    return {
      workloadPerAppraiserServices: ['intgen', 'intbls', 'intvig'],
      workloadPerAppraiserAcl: ['get_workload_per_appraiser_statistics'],
      workloadPerAppraiserKey: 54320,
      workloadPerAppraiserLoaded: false,
      selectedWorkloadPerAppraiserDate: moment().format('YYYY-MM-DD'),
      selectedReportWorkloadPerAppraiserDate: '',
      workflowPerAppraiserChangeDateEvent: 'workloadPerAppraiserChangeDate',
      workloadPerAppraiserDateOptions: [
        {value: moment().format('YYYY-MM-DD'), label: 'Dzisiaj'},
        {value: 'all', label: 'Wszystkie'}
      ],
      workloadPerAppraiser: {
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        title: 'Obciążenie rzeczoznawców wewnętrznych',
        total: '',
        data: {
          json: [],
          keys: {
            x: 'name',
            value: [],
          },
          type: 'bar',
          groups: []
        },
        partialReport: null
      },
      workloadPerAppraiserDefault: {},
      categories: [
        'no_appointment',
        'no_inspection',
        'inspection_after_time',
        'late_inspection'
      ],
    }

  },
  methods: {
    getWorkloadPerAppraiserStatisticRequests (services) {
      return services.map(service => api.request(service, 'get', `statistics/appraisal/workload-per-appraiser` + '?selected=' + this.selectedWorkloadPerAppraiserDate))
    },
    handleWorkloadPerAppraiserChange (selectedOption) {
      if (this.workloadPerAppraiserLoaded) {
        this.reloadWorkloadPerAppraiserStatistic(this.workloadPerAppraiserServices, selectedOption)
      }
    },
    loadWorkloadPerAppraiserStatistic (services, selectedOption = null) {
      selectedOption = selectedOption === null ? moment().format('YYYY-MM-DD') : 'all'
      this.selectedWorkloadPerAppraiserDate = selectedOption
      this.selectedReportWorkloadPerAppraiserDate = selectedOption
      this.workloadPerAppraiserDefault = _.cloneDeep(this.workloadPerAppraiser)
      this.processWorkloadPerAppraiserStatistic(services, selectedOption)
      return
    },
    reloadWorkloadPerAppraiserStatistic (services, selectedOption) {
      this.selectedWorkloadPerAppraiserDate = selectedOption
      this.selectedReportWorkloadPerAppraiserDate = selectedOption
      this.workloadPerAppraiser = _.cloneDeep(this.workloadPerAppraiserDefault)
      this.processWorkloadPerAppraiserStatistic(services, selectedOption)
      return
    },
    async processWorkloadPerAppraiserStatistic (services, selectedOption, buildElements = false) {
      services = this.$auth.getAllowed({services: services, actions: this.workloadPerAppraiserAcl, allowOneOfActions: true})

      if (!_.isEmpty(services)) {
        try {
          let requests = this.getWorkloadPerAppraiserStatisticRequests(services)
          let responseData = await this.getResponses(requests)
          let chartData = {
            columns: {
              data: [],
              categories: ['x']
            }
          }

          this.prepareChartByUser(responseData, chartData, this.contractorApps, selectedOption)
          this.addWorkloadPerAppraiserTaskToCharts(chartData)
          this.workloadPerAppraiserLoaded = true
          this.workloadPerAppraiserKey = Math.random() * 100
        } catch (error) {
          console.error(error)
        }
      }
      return
    },
    addWorkloadPerAppraiserTaskToCharts(chartData) {
      let defaultChartData = _.cloneDeep(this.workloadPerAppraiserDefault)
      let statistics = this.getRebuildedChartData(chartData, defaultChartData)
      statistics.total = setupTotalCount(statistics.data.json)
      this.workloadPerAppraiser = statistics
    },
    getDownloadWorkloadPerAppraiserLinkData(type, withDate = false, withClients = false) {
      return {
        type: `appraiser_workload${this.selectedWorkloadPerAppraiserDate === 'all' ? '' : '&dateFrom=' + this.selectedWorkloadPerAppraiserDate}` +  '&user=' + `${this.$store.state.base.user.uuid}`,
        reportName: 'appraiser_workload',
        service: 'all',
        withDate,
        withClients,
      }
    }
  }
}
