
export default {
  SET_CALENDAR_VISIBILITY (state, payload) {
    state.calendarVisible = payload
  },
  SET_CALENDAR_EVENTS (state, payload) {
    state.calendarEvents = payload
  },
  SET_INFORMATION_STATUSES (state, payload) {
    state.statuses = payload
  }
}
