<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body table-responsive">
              <grid
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :language="$i18n.locale"
              ></grid>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseListing from '../../../share/ListingWithEditMode'
import Grid from '../../../share/grid/Grid'

export default {
  mixins: [
    BaseListing
  ],
  components: {
    Grid
  },
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Ustawienia online', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_calendar_business_hours',
      editEventName: '',
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  mounted() {
  },
  computed: {
    days () {
      return [this.$t('uiv.datePicker.week1'),this.$t('uiv.datePicker.week2'),this.$t('uiv.datePicker.week3'), this.$t('uiv.datePicker.week4'), this.$t('uiv.datePicker.week5'), this.$t('uiv.datePicker.week6'), this.$t('uiv.datePicker.week7')]
    },
    gridColumns () {
      const vm = this

      return [
        { id: 0, data: 'employee', title: this.$t('pages.admin.calendarSettings.employee') },
        { id: 1, data: 'startAt', title: this.$t('pages.admin.calendarSettings.from') },
        { id: 2, data: 'endAt', title: this.$t('pages.admin.calendarSettings.to') },
        { id: 4, data: 'priority', title: this.$t('pages.admin.calendarSettings.priority') },
        {
          id: 5,
          data: 'cyclicity',
          title: this.$t('pages.admin.calendarSettings.cyclicity'),
          render (data) {
            let length = vm.days
            let cyclicity = data.toString(2).split('').reverse().map(e => e === '1')
            return length.filter((element, index) => {
              return cyclicity.hasOwnProperty(index) && cyclicity[index] && length[index]
            })
          },
          searchable: false,
          orderable: false
        }
      ]
    }
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_calendar_businessHours_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:calendar:businessHoursEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/business-hours` }
    }
  }
}
</script>
