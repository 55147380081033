<script>
import BaseListing from '../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Widoczność zleceń', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_task_visibility',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'state', title: 'Stan' },
        { id: 1, data: 'role', title: 'Rola' },
        { id: 2, data: 'visibility', title: 'Poziom widoczności' }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_${this.$route.meta.workflow.split('-')[1]}_task_visibility_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:main:taskVisibilityEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/task-visibilities/${this.$route.meta.workflow}` }
    }
  }
}
</script>
