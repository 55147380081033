import getProperServiceIcon from '../../utils/getProperServiceIcon'
export default function (service, name) {
  const pathPrefix = `administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }

  return [
    {
      path: `${pathPrefix}widocznosc-naglowkow`,
      name: `${service}_header-visibility`,
      component: () => import('../../../components/bundles/share/header-visibility/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_task_headers_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service]
          ]
        },
        icon: 'git-commit',
        title: 'Lista widoczności nagłówków',
        secondMenu: [
          {
            event: { name: `${service}:HeaderVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: {
              service: service,
              action: ['put_task_header_visibilities',
                'get_task_headers'],
              allowOneOfActions: false
            }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-naglowkow/edycja`,
      name: `${service}_header-edit`,
      component: () => import('../../../components/bundles/share/header-visibility/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: ['put_task_header_visibilities',
            'get_task_headers'],
          allowOneOfActions: false
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_header-visibility`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_task_header_visibilities' }
          },
          {
            event: { name: `${service}:headersSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_task_header_visibilities' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_header-visibility` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_task_headers_visibilities' }
          }
        ]
      }
    }
  ]
}
