export default {
  changingState: (state) => {
    return state.loader.loading
  },
  loadingMessage: (state) => {
    return state.loader.message
  },
  getVatToShow: (state) => {
    if (state.vatOptions.length !== 0 && state.vatModel.hasOwnProperty('selectedVat')) {
      return state.vatOptions.filter(option => option.id.toString() === state.vatModel.selectedVat.toString())[0].label
    } else {
      return ''
    }
  },
}
