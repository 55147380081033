import router from "@/router";
import getClientObject from "@/router/utils/getClientObject";

export default ({params}) => {

  return {
    complaints: {
      service: params.service,
      action: `get_tasks_datatables__default__${router.app.$getCategoryNew(getClientObject(params.service, params.client), params.taskCategoryId).name}_${params.client}`,
      type: 'complaint'
    },
    complaint: {
      service: params.service,
      action: `get_task__${router.app.$getCategoryNew(getClientObject(params.service, params.client), params.taskCategoryId).name}__${params.client}`,
    }
  }
}
