export default {
  methods: {
    validatorReset (el = null) {
      // custom validation reset because
      // currently, the validations are not cancellable in vee-validate
      // @see https://github.com/baianat/vee-validate/issues/740#issuecomment-323496394

      if (el === null) {
        el = this
      }
      el.$validator.pause()
      setTimeout(() => {
        el.$validator.resume()
      }, 1)
    }
  }
}
