const workflowName = 'main_person'
const routePrefix = `intgen_${workflowName}`
const customBreadcrumbs = {
  taskListing: {
    meta: {
      icon: 'list',
      title: 'menu.main.list.name'
    }
  }
}

export default [
  {
    path: 'zlecenia/generalicrls-osoba/moje-zlecenia',
    name: `${routePrefix}_task_listing_my_tasks`,
    component: () => import('../../components/bundles/share/tasksListing/Listing.vue'),
    props: {
      filter: {}
    },
    meta: {
      acl: { service: 'intgen', action: 'get_main_person_tasks' },
      breadcrumb: {
        list: [
          customBreadcrumbs.taskListing,
          `${routePrefix}_task_listing`
        ]
      },
      icon: 'filter',
      workflowName,
      title: 'Moje zlecenia',
      secondMenu: []
    }
  }
]
