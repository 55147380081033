<template>
  <div>
    <strong>Grupy</strong>
    <div class="actions-node">
      <div
        v-for="(group, index) in groups"
        :key="`group-${index}`"
      >
        <input
          :id="`group-${index}`"
          v-model="groups[index]['value']"
          type="checkbox"
          :disabled="disabled"
          @click="checkAll(index)"
        >
        <label :for="`group-${index}`">{{ group.group }}</label>
      </div>
    </div>
    <br>
    <hr>
    <strong>Akcje</strong>
    <div
      style="display: flex;
        flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;"
    >
      <div
        v-for="(node, nodeIndex) in actionAccessesTree"
        :key="`node-${nodeIndex}`"
        class="actions-node"
      >
        <h5>{{ node.label }}</h5>
        <div class="">
          <span v-if="!node.actions">Brak uprawnień</span>
          <div
            v-for="(action, index) in node.actions"
            :key="index"
          >
            <label :for="`chkbox-${nodeIndex}-${index}`" />
            <input
              :id="`chkbox-${nodeIndex}-${index}`"
              v-model="actionAccessesTree[nodeIndex]['actions'][index]['value']"
              type="checkbox"
              :disabled="disabled"
            >
            <label :for="`chkbox-${nodeIndex}-${index}`">{{ action.label }}</label>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groups: { type: Array },
    actions: { type: Array },
    disabled: { type: Boolean },
    actionAccessesTree: { type: Array }
  },
  methods: {
    checkAll (index) {
      if (this.groups[index].value === false || this.groups[index].value === 0) {
        this.actionAccessesTree[index].actions.forEach(action => { action.value = true })
      } else if (this.groups[index].value === true || this.groups[index].value === 1) {
        this.actionAccessesTree[index].actions.forEach(action => { action.value = false })
      }
    }
  }
}
</script>
