// Admin
import WebStorage from '@/components/share/WebStorage.js'

const customBreadcrumbs = {
  system: {
    meta: {
      icon: "settings",
      title: "menu.system.name"
    }
  }
};

export default [
  {
    path: "administracja-systemem/uzytkownicy",
    name: "admin_user_listing",
    component: () =>
      import("../../components/bundles/core/admin/user/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_users"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "users",
      title: "menu.users",
      secondMenu: [
        {
          event: { name: "core:user:addUser" },
          icon: "user-plus",
          label: "Dodaj użytkownika",
          acl: { service: "core", action: "post_user" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/uzytkownicy/:uuid",
    name: "admin_user_details",
    component: () =>
      import("../../components/bundles/core/admin/user/Details.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_user"
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [customBreadcrumbs.system, "admin_user_listing"]
      },
      title: "Szczegóły użytkownika",
      secondMenu: [
        {
          event: { name: "core:user:editUserData" },
          icon: "edit",
          label: "menu.usersEditData",
          acl: { service: "core", action: "put_user" }
        },
        {
          event: { name: "core:user:editUserAdditionalData" },
          icon: "edit",
          label: "Edytuj dodatkowe dane",
          acl: { service: "core", action: "put_user_config" }
        },
        {
          event: { name: "core:user:editPassword" },
          icon: "hash",
          label: "menu.changePassword",
          acl: { service: "core", action: "put_user_password" }
        },
        {
          event: { name: "core:user:resetPassword" },
          icon: "unlock",
          label: "Resetuj hasło",
          acl: { service: "core", action: "post_password_reset_link" }
        },
        {
          event: { name: "core:user:activateUser" },
          icon: "check",
          label: "Wyślij link aktywacyjny",
          acl: { service: "core", action: "put_user_activation" }
        },
        {
          event: { name: "core:user:activateUserToggle" },
          icon: "check",
          label: "Zmień status aktywności użytkownika",
          acl: { service: "core", action: "put_user_toggle_active" }
        },
        {
          event: { name: "core:user:editUnit" },
          icon: "users",
          label: "Edytuj jednostki",
          acl: { service: "core", action: "put_user_units" }
        },
        {
          event: { name: "core:user:editContractor" },
          icon: "briefcase",
          label: "Zmień kontrahenta",
          acl: { service: "core", action: "put_user_contractor" }
        },
        {
          event: { name: "core:user:editRoles" },
          icon: "zap",
          label: "Edytuj role",
          acl: { service: "core", action: "put_user_roles" }
        },
        {
          event: { name: "core:user:editAbsence" },
          icon: "calendar",
          label: "Nieobecność",
          acl: { service: "core", action: "put_user_absence" }
        },
        {
          event: { name: "finance:user:editPricelist" },
          icon: "dollar-sign",
          label: "Edytuj cennik",
          acl: { service: "finance", action: "put_appraiser_price_list" }
        },
        {
          event: { name: "finance:user:editVat" },
          icon: "dollar-sign",
          label: "Ustaw VAT",
          acl: { service: "finance", action: "put_user" }
        },
        {
          event: { name: "extshp:user:editCoins" },
          icon: "dollar-sign",
          label: "Zmień ilość Żetonów",
          acl: { service: "extshp", action: "put_user_coins" }
        },
        {
          event: { name: "core:user:editLanguage" },
          icon: "feather",
          label: "Zmień język",
          acl: { service: "core", action: "put_user_locale" }
        },
        {
          event: { name: "core:user:editUserIps" },
          icon: "edit",
          label: "Edytuj filtry IP",
          acl: { service: "core", action: "put_user_access_ips" }
        },
        {
          event: { name: "core:user:editUserPresence" },
          icon: "check",
          label: "Obecność",
          acl: { service: "core", action: "put_user_presence" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/uzytkownicy/:uuid/edycja-rol",
    name: "admin_user_details_roles_edition",
    component: () =>
      import(
        "../../components/bundles/core/admin/user/details/RolesEdition.vue"
      ),
    meta: {
      acl: {
        service: "core",
        action: "put_user_roles"
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.system,
          "admin_user_listing",
          "admin_user_details"
        ]
      },
      title: "Ustawienia ról użytkownika",
      thirdMenu: [
        {
          event: { name: "core:userRole:submitData" },
          icon: "save",
          label: "Zapisz",
          acl: { service: "core", action: "put_user_roles" }
        },
        {
          event: { name: "core:userRole:cancel", value: "admin_user_details" },
          icon: "arrow-left",
          label: "Wróć do szczegółów",
          acl: { service: "core", action: "get_user" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/uzytkownicy/:uuid/obecnosci",
    name: "admin_user_presences",
    component: () =>
      import(
        "../../components/bundles/core/admin/user/details/Presences.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!WebStorage.getUser()) {
        return next(false)
      }
      if (to.params.uuid !== WebStorage.getUser().uuid) {
        return next({name: 'admin_user_presences', params: {uuid: WebStorage.getUser().uuid}})
      }
      next()
    },
    meta: {
      acl: {
        service: "core",
        action: "get_user_presences"
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.system,
          "admin_user_listing",
          "admin_user_details"
        ]
      },
      title: "Twoje obecności",
      thirdMenu: []
    }
  },
  {
    path: "administracja-systemem/role",
    name: "admin_role_listing",
    component: () =>
      import("../../components/bundles/core/admin/role/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_roles"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "shield",
      title: "Lista ról",
      secondMenu: [
        {
          event: { name: "core:role:addRole" },
          icon: "plus",
          label: "Dodaj rolę",
          acl: { service: "core", action: "post_role" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/role/:id",
    name: "admin_role_details",
    component: () =>
      import("../../components/bundles/core/admin/role/Details.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_role"
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [customBreadcrumbs.system, "admin_role_listing"]
      },
      title: "Szczegóły roli",
      secondMenu: [
        {
          event: { name: "core:role:editRole" },
          icon: "edit",
          label: "Edytuj dane",
          acl: { service: "core", action: "put_role" }
        },
        {
          event: { name: "core:role:editActionsAccess" },
          icon: "unlock",
          label: "Edytuj uprawnienia",
          acl: { service: "core", action: "put_role_access_tree" }
        },
        {
          event: { name: "core:role:editUsersVisibility" },
          icon: "eye",
          label: "Edytuj widoczność",
          acl: { service: "core", action: "put_role" }
        },
        {
          event: { name: "core:role:editClientApplication" },
          icon: "edit",
          label: "Edytuj aplikacje",
          acl: { service: "core", action: "put_contractor_app_role" }
        },
        {
          event: { name: "core:role:changeSpecialAccess" },
          icon: "star",
          label: "Zmień uprawnienia specjalne",
          acl: { service: "core", action: "put_role" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/role/:id/edycja-poziomow-dostepu",
    name: "admin_role_details_acl_edition",
    component: () =>
      import(
        "../../components/bundles/share/role/details/AclEditionCheckboxes.vue"
      ),
    props: { service: "core" },
    meta: {
      acl: {
        service: "core",
        action: "get_role_access_tree"
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.system,
          "admin_role_listing",
          "admin_role_details"
        ]
      },
      title: "Edycja poziomów dostępu",
      thirdMenu: [
        {
          event: { name: "core:acl:submitData" },
          icon: "save",
          label: "Zapisz",
          acl: { service: "core", action: "put_role_access_tree" }
        },
        {
          event: {
            name: "dashboard:menu:redirect",
            value: "admin_role_details"
          },
          icon: "arrow-left",
          label: "Wróć do szczegółów",
          acl: { service: "core", action: "get_role" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/kontrahenci",
    name: "admin_contractor_listing",
    component: () =>
      import("../../components/bundles/core/admin/contractor/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_contractors"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "briefcase",
      title: "Lista kontrahentów",
      secondMenu: [
        {
          event: { name: "core:contractor:addContractor" },
          icon: "plus",
          label: "Dodaj kontrahenta",
          acl: { service: "core", action: "post_contractor" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/kontrahenci/:id",
    name: "admin_contractor_details",
    component: () =>
      import("../../components/bundles/core/admin/contractor/Details.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_contractor"
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [customBreadcrumbs.system, "admin_contractor_listing"]
      },
      title: "Szczegóły kontrahenta",
      secondMenu: [
        {
          event: { name: "core:contractor:editContractor" },
          icon: "edit",
          label: "Edytuj dane",
          acl: { service: "core", action: "put_contractor" }
        },
        {
          event: { name: "core:contractor:editContractorIps" },
          icon: "edit",
          label: "Edytuj filtry IP",
          acl: { service: "core", action: "put_contractor_access_ips" }
        },
        {
          event: { name: "core:contractor:editLanguage" },
          icon: "feather",
          label: "Zmień język",
          acl: { service: "core", action: "put_contractor_locale" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/baza-pojazdow",
    name: "admin_vehicle_listing",
    component: () =>
      import("../../components/bundles/core/admin/vehicle/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_vehicles"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "truck",
      title: "menu.vehicleBase",
      secondMenu: [
        {
          event: { name: "core:vehicle:addMake" },
          icon: "plus",
          label: "Dodaj markę",
          acl: { service: "core", action: "post_make" }
        },
        {
          event: { name: "core:vehicle:addModel" },
          icon: "plus",
          label: "Dodaj model",
          acl: { service: "core", action: "post_model" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/banki",
    name: "admin_bank_listing",
    component: () =>
      import("../../components/bundles/core/admin/bank/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_banks"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "dollar-sign",
      title: "Lista banków",
      secondMenu: [
        {
          event: { name: "core:bank:addBank" },
          icon: "plus",
          label: "Dodaj bank",
          acl: { service: "core", action: "post_bank" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/banki/:id",
    name: "admin_bank_details",
    component: () =>
      import("../../components/bundles/core/admin/bank/Details.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_bank"
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [customBreadcrumbs.system, "admin_bank_listing"]
      },
      title: "Szczegóły banku",
      secondMenu: [
        {
          event: { name: "core:bank:editBank" },
          icon: "edit",
          label: "Edytuj dane",
          acl: { service: "core", action: "put_bank" }
        },
        {
          event: {
            name: "dashboard:menu:redirect",
            value: "admin_bank_listing"
          },
          icon: "arrow-left",
          label: "Wróć do listy",
          acl: { service: "core", action: "get_bank" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/jednostki-policji",
    name: "admin_policeUnit_listing",
    component: () =>
      import("../../components/bundles/core/admin/PoliceUnit/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_police_units"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "crosshair",
      title: "Lista jednostek policji",
      secondMenu: [
        {
          event: { name: "core:policeUnit:addPoliceUnit" },
          icon: "plus",
          label: "Dodaj jednostkę policji",
          acl: { service: "core", action: "post_police_unit" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/jednostki-policji/:id",
    name: "admin_policeUnit_details",
    component: () =>
      import("../../components/bundles/core/admin/PoliceUnit/Details.vue"),
    meta: {
      acl: {
        service: "core",
        action: "get_police_unit"
      },
      breadcrumb: {
        dynamicLabel: true,
        list: [customBreadcrumbs.system, "admin_policeUnit_listing"]
      },
      title: "Szczegóły jednostki policji",
      secondMenu: [
        {
          event: { name: "core:policeUnit:editPoliceUnit" },
          icon: "edit",
          label: "Edytuj dane",
          acl: { service: "core", action: "put_police_unit" }
        },
        {
          event: {
            name: "dashboard:menu:redirect",
            value: "admin_policeUnit_listing"
          },
          icon: "arrow-left",
          label: "Wróć do listy",
          acl: { service: "core", action: "get_police_unit" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/komunikaty-systemowe",
    name: "admin_maintenance_info_listing",
    component: () =>
      import("../../components/bundles/core/admin/maintenance/Listing.vue"),
    meta: {
      acl: {
        service: "core",
        action: "post_maintenance_info"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system]
      },
      icon: "settings",
      title: "Komunikaty systemowe",
      secondMenu: [
        {
          event: { name: "core:maintenanceInfo:addMaintenanceInfo" },
          icon: "plus",
          label: "Dodaj komunikat administracyjny",
          acl: { service: "core", action: "post_maintenance_info" }
        }
      ]
    }
  },
  {
    path: "administracja-systemem/komunikaty-systemowe/:id",
    name: "admin_maintenance_info",
    component: () =>
      import("../../components/bundles/core/admin/maintenance/Details.vue"),
    meta: {
      acl: {
        service: "core",
        action: "put_maintenance_info"
      },
      breadcrumb: {
        list: [customBreadcrumbs.system, "admin_maintenance_info_listing"]
      },
      icon: "settings",
      title: "Komunikat systemowy",
      secondMenu: [
        {
          event: { name: "core:maintenanceInfo:editMaintenanceInfo" },
          icon: "plus",
          label: "Edytuj",
          acl: { service: "core", action: "put_maintenance_info" }
        },
        {
          event: {
            name: "dashboard:menu:redirect",
            value: "admin_maintenance_info_listing"
          },
          icon: "arrow-left",
          label: "Wróć do listy",
          acl: { service: "core", action: "post_maintenance_info" }
        }
      ]
    }
  }
];
