<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('comment'), 'has-success' : !vErrors.first('comment') && value['comment'] }]"
    >
      <!--            <label for="message">-->
      <!--                Treść-->
      <!--            </label>-->
      <vue-form-generator
        class=""
        :schema="{groups: [{fields: [datepickerStartSchema, datepickerEndSchema]}]}"
        :model="value"
      />
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >{{ vErrors.first('comment') }}</span>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import VueFormGenerator from 'vue-form-generator'
import moment from 'moment'

Vue.use(VueFormGenerator)

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      datepickerStartSchema: {
        'type': 'dateTimePicker',
        'label': 'Od:',
        'inputName': 'beginAt',
        'disabled': false,
        'required': false,
        'relatedFields': [],
        'model': 'beginAt',
        'readonly': false,
        'dateTimePickerOptions': { 'format': 'YYYY-MM-DD', 'locale': 'pl' }
      },
      datepickerEndSchema: {
        'type': 'dateTimePicker',
        'label': 'Do',
        'inputName': 'endAt',
        'disabled': false,
        'required': false,
        'relatedFields': [],
        'model': 'endAt',
        'readonly': false,
        'dateTimePickerOptions': { 'format': 'YYYY-MM-DD', 'locale': 'pl', 'maxDate': moment().format('YYYY-MM-DD') }
      }
    }
  }
}
</script>
