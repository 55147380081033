<template>
  <div
    class="form-group"
    :class="[{ 'has-error': errorMessage, 'has-success' : !errorMessage && value }, { 'required-field': required }]"
  >
    <label :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      class="form-control"
      :name="name"
      :type="type"
      :value="value"
      :disabled="disabled"
      @input="updateValue($event.target)"
    >
    <span
      v-show="errorMessage"
      class="help-block"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    /** Komunikat wyświetlana podczas błędów wykrytych przez walidator */
    errorMessage: { type: null, required: true },
    /** Etykieta pola wyświetlana w widoku */
    label: { type: String, required: true },
    /** Określa atrybuty 'id' oraz 'name' tagu html: <input id="" name=""> */
    name: { type: String, required: true },
    /** Ustawia atrybut 'required-field' dla walidatora */
    required: { type: Boolean, default: false },
    /** Określa typ pola */
    type: { type: String, default: 'text' },
    disabled: {type: Boolean, default: false},
    /** Przechowuje wartość pola formularza */
    value: null
  },
  computed: {
    id: function () {
      return `${this.name}-${this._uid}`
    }
  },
  methods: {
    /** Aktualizuje wartość parametru 'value' i emituje zdarzenie 'input' */
    updateValue (target) {
      this.$emit('input', target.value)
      this.$emit('output', target)
    }
  }
}
</script>
