<script>
import api from '../../../../api'

export default {
  computed: {
    options () {
      return this.$store.state.base.voivodeships
    }
  },
  mounted () {
    this.loadVoivodeships()
  },
  methods: {
    loadVoivodeships () {
      if (!this.$store.state.base.loadedVoivodeships) {
        this.loadApiVoivodeships()
      }
    },
    loadApiVoivodeships () {
      this.$store.commit('SET_LOADED_VOIVODESHIPS', true)

      api.request(this.service, 'get', '/voivodeship')
        .then((response) => {
          const responseData = response.data
          let options = []

          for (let c = 0; c < responseData.length; ++c) {
            options.push({ label: responseData[c].label, value: responseData[c].id })
          }
          this.$store.commit('SET_VOIVODESHIPS', options)
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    }
  }
}
</script>
