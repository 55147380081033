export default function (service, name) {
  const workflowName = 'main_vehicle'
  const routePrefix = `${service}_${workflowName}`
  const routeServiceName = name.toLowerCase()
  const customBreadcrumbs = {
    taskListing: {
      meta: {
        icon: 'list',
        title: 'menu.main.list.name'
      }
    }
  }

  return [
    {
      path: `zlecenia/${routeServiceName}-osoba/moje-zlecenia`,
      name: `${routePrefix}_task_listing_my_tasks`,
      component: () => import('../../components/bundles/share/tasksListing/Listing.vue'),
      props: {
        filter: {}
      },
      meta: {
        acl: { service: service, action: 'get_tasks' },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`
          ]
        },
        icon: 'filter',
        workflowName,
        title: 'Moje zlecenia',
        secondMenu: []
      }
    }
  ]
}
