<template>
  <div>
    <!-- Nav tabs -->
    <ul
      class="nav nav-tabs sticky-tabs"
      :style="{top: `${topDistance}px`}"
      role="tablist"
    >
      <template v-for="(tabData, index) in tabsData">
        <li
          :id="`tab-header-${tabData['0']}`"
          v-if="tabsVisibilities[index]"
          :key="index"
          role="presentation"
          :class="{active: tabData['2']}"
          class="tab-title-container"
        >
          <a
            :href="'#' + tabData['0']"
            aria-controls="home"
            role="tab"
            @click.prevent="emitClickedTab(tabData['0'])"
          >
            {{ tabData['1'] }}
          </a>
          <button v-if="allowDelete && tabData['4']" @click="(e) => handleClickDelete(e, tabData, index)"
                  class="btn btn-primary zord-btn zord-btn-primary delete-tab-button"><i class="feather-trash"></i>
          </button>
        </li>
      </template>
    </ul>
    <!-- Tab panels -->
    <div class="tab-content">
      <template v-for="(tabData, index) in tabsData">
        <div
          v-if="tabsVisibilities[index]"
          :id="tabData['0']"
          :key="index"
          role="tabpanel"
          :class="{'tab-pane': true, active: tabData['2']}"
          @highlightTab="(e) => highlightTab(`tab-header-${tabData['0']}`, e)"
        >
          <div class="box box-default box-solid form-background-color row">
            <div class="box-body col-xs-12">
              <slot :name="tabData['0']"/>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import WebStorage from '../WebStorage'

export default {
  props: {
    tabsData: {type: Array, required: true},
    allowDelete: {type: Boolean, required: false, default: () => false},
    topDistance: {type: Number, default: () => 150}
  },
  data () {
    return {
      tabsVisibilities: [],
      timeouts: {}
    }
  },
  computed: {},
  mounted () {
    this.getAllSecurtyActions()
  },
  methods: {
    highlightTab (headerId, e) {
      if (e && e.detail && e.detail.class) {
        let tab = this.$el.querySelector(`#${headerId}`)
        const origClass = `${tab.className}`
        if (!tab.className.includes(e.detail.class)) {
          this.$el.querySelector(`#${headerId}`).className = this.$el.querySelector(`#${headerId}`).className.replace(` ${e.detail.class}`, ` ${e.detail.class}`)
          clearTimeout(this.timeouts[headerId])
          this.$set(this.timeouts, headerId, undefined)
        }
        this.$set(this.timeouts, headerId, setTimeout(() => {
          this.$el.querySelector(`#${headerId}`).className = this.$el.querySelector(`#${headerId}`).className.replace(` ${e.detail.class}`, '')
          this.$set(this.timeouts, headerId, undefined)
        }, 5000))
        this.$el.querySelector(`#${headerId}`).className = [origClass, e.detail.class].join(' ')
      }
    },
    handleClickDelete (e, tabData, tabIndex) {
      e.preventDefault()
      this.$emit('tabs-delete-item', {tabData, tabIndex})
    },
    emitClickedTab (tab) {
      this.$emit('clickedTab', tab)
      this.$events.$emit('clickedTab', tab)
    },
    getAllSecurtyActions () {
      let actions = WebStorage.getSecurityActions()
      let allSecurityActions = []

      Object.keys(actions).forEach((contractorApp) => {
        if (Array.isArray(actions[contractorApp])) {
          actions[contractorApp].forEach((action) => {
            allSecurityActions.push(contractorApp + '.' + action)
          })
        }
      })

      this.tabsVisibilities = this.tabsData.map(tab => allSecurityActions.filter(action => tab[3].find(tabAcl => action === tabAcl)).length !== 0)
    }
  }
}
</script>
<style scoped>
.tab-title-container {
  display: flex;
  align-items: center;
}

.tab-title-container > a {
  padding-right: 25px;
}

button.btn.delete-tab-button {
  position: absolute;
  right: 4px;
  width: 16px;
  top: 4px;
  height: 38px;
}

.sticky-tabs {
  position: sticky;
  z-index: 999;
}
</style>
