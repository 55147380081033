import RouterFrame from '../../../../components/share/RouterFrame.vue'

export default function (service, name) {
  return {
    path: 'widocznosc-naglowkow',
    component: RouterFrame,
    meta: {
      title: 'Widoczność nagłówków',
      icon: 'eye',
      menuLink: true,
      acl: {
        service: service,
        action: 'get_task_header_visibilities'
      }
    },
    children: [
      {
        path: '',
        component: '',
        name: `${service}_header-visibility`,
        meta: {
          title: 'Lista widoczności widoczności nagłówków',
          acl: {
            service: service,
            action: ['put_task_header_visibilities',
              'get_task_headers'],
            allowOneOfActions: false
          }
        }
      }
    ]
  }
}
