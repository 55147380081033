import api from '../../../../api'

export const ACTIVE_TASK_STATISTIC_TYPE_USER = 'users'
export const ACTIVE_TASK_STATISTIC_TYPE_STATUS = 'status'
export default {
  data () {
    return {
      activeTaskForCorrectionStatisticKey: 0,
      taskForCorrectionStatisticLoaded: false,
      taskForCorrectionStatisticSelectedType: ACTIVE_TASK_STATISTIC_TYPE_USER,
      taskForCorrectionSelectOptions: [],
      taskForCorrectionStatisticTypeOptions: [
        {value: ACTIVE_TASK_STATISTIC_TYPE_USER, label: 'Opiekun zlecenia'},
        {value: ACTIVE_TASK_STATISTIC_TYPE_STATUS, label: 'Status'}
      ],
      taskForCorrectionStatistic: {
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        title: 'Do poprawy',
        total: '',
        data: {
          json: [],
          keys: {
            x: 'label',
            value: []
          },
          type: 'bar',
          groups: [],
          selection: {},
          onselected: (part, node) => {
          },
          onunselected: (slice, node) => {
          }
        },
        partialReport: null,
        downloadLinkData: {
          service: 'intmil',
          type: 'task_for_correction',
          withAssistantsOrStatus: true,
          withDate: false
        }
      }
    }
  },
  methods: {
    getLoadTaskForCorrectionUrl() {
      return `statistics/tasks/for-correction`
    },
    loadTaskForCorrectionStatistic (type = ACTIVE_TASK_STATISTIC_TYPE_USER) {
      (new Promise((resolve => setTimeout(() =>
      api.request('intmil', 'get', this.getLoadTaskForCorrectionUrl())
      .then((response) => {
        let data = response.data
      resolve({data}), 200})))).then(({data}) => {
        let preparedData;
        let assistants = []
        let status = []

        switch (type) {
          case ACTIVE_TASK_STATISTIC_TYPE_USER:
            preparedData = this.getTaskForUser(data[type])
            assistants = preparedData.map(user => ({label: user.label, value: user.id} ))
            this.taskForCorrectionSelectOptions = assistants
          break
          case ACTIVE_TASK_STATISTIC_TYPE_STATUS:
            preparedData = this.getTaskForStatus(data[type])
            status = preparedData.map( ({label}) => ({label: label, value: label}))
            this.taskForCorrectionSelectOptions = status
          break
        }

        const values = this.getActiveTasksStatisticsValues(preparedData)
        const totalCount = data.users.reduce((acc, curr) => acc + Number(curr.totalCount), 0)
        const tempForCorrection = this.taskForCorrectionStatistic
        tempForCorrection.data.json = preparedData
        tempForCorrection.data.keys.value = values
        tempForCorrection.data.groups = [values]
        tempForCorrection.total = `Łącznie: ${totalCount}`
        this.taskForCorrectionStatistic = tempForCorrection

        this.activeTaskForCorrectionStatisticKey = Math.random() * 100

      }).catch((e) => {
        console.error(e)
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Nie można załadować danych do wykresu Do poprawy'
        })
      })
    )},

    getTaskForUser (backendData) {
      return backendData.map(({label, uuid, state, totalCount}) => ({
        label:`${label} (${totalCount})`,
        id: uuid,
        ...state
      }))
      },
    getTaskForStatus (backendData) {
      return backendData.map(({label, totalCount}) => ({
        label: `${label} (${totalCount})`,
        [label]: totalCount
      }))
      },
    getActiveTasksStatisticsValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label' && key !== 'id').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
    handleChangeTaskForCorrectionStatisticType (value) {
      this.loadTaskForCorrectionStatistic(value)
      this.taskForCorrectionStatisticSelectedType = value
    },
  }
}
