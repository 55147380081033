import getProperServiceIcon from '../../../utils/getProperServiceIcon'
import validateRoute from '@/router/utils/validateRoute.js'
import acl from './acl'

export default function (service, name) {

  let customBreadcrumbs = {
    taskListing: {
      meta: {
        icon: 'list',
        title: 'menu.main.list.name'
      }
    }
  }
  customBreadcrumbs[`${service}_subtask_category`] = {
    name: `${service}_subtask_category`,
    meta: {
      icon: 'list',
      title: 'Podzlecenie',
      breadcrumb: {
        dynamicLabel: true,
      },
      acl: {security: false}
    }
  }

  return [
    {
      path: `zlecenia-bwn/:service/:client/:taskCategoryId`,
      name: `${service}_task_listing`,
      props: true,
      component: () => import(/* webpackChunkName: "custom-bwn-like-task-listing" */ `../../../../components/bundles/${service}/taskListing/Listing`),
      meta: {
        securityDynamic: true,
        title: name,
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing
          ]
        },
        icon: getProperServiceIcon(service),
      },
      beforeEnter: (to, from, next) => {
        let newTo = {...to, meta: {...to.meta, acl: acl(to)[to.name]}}

        validateRoute(newTo, from, next)
      }
    },
    {
      path: `zlecenia-bwn/:service/:client/:taskCategoryId/:id`,
      component: () => import(/* webpackChunkName: "custom-bwn-like-task-details" */ `../../../../components/bundles/${service}/MainTask/Details.vue`),
      props: true,
      name: `${service}_task_details`,
      meta: {
        securityDynamic: true,
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing,
            `${service}_task_listing`
          ]
        }
      },
      beforeEnter: (to, from, next) => {
        let newTo = {...to, meta: {...to.meta, acl: acl(to)[to.name]}}
        validateRoute(newTo, from, next)
      }
    },
    {
      path: `zlecenia-bwn/:service/:client/:taskCategoryId/:id/:appraisalTaskCategory/:appraisalTaskId`,
      component: () => import(/* webpackChunkName: "custom-bwn-like-appraisal-task-details" */ `../../../../components/bundles/${service}/appraisal/task/Details`),
      props: true,
      name: `${service}_appraisal_task_details`,
      meta: {
        securityDynamic: true,
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing,
            `${service}_task_listing`,
            `${service}_task_details`,
            customBreadcrumbs[`${service}_subtask_category`]
          ]
        },
        taskType: 'appraisal',
        title: 'Szczegóły zlecenia rzeczoznawczego'
      },
      beforeEnter: (to, from, next) => {
        let newTo = {...to, meta: {...to.meta, acl: acl(to)[to.name]}}
        validateRoute(newTo, from, next)
      }
    },
    // // ...statistics(service, name)
  ]
}
