// Common
import RouterFrame from '../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen
import IntgenVehicleTaskListing from '../../../components/bundles/share/tasksListing/Listing.vue'
import getProperServiceIcon from '../../utils/getProperServiceIcon'

export default function (service, name, taskType, clientName = null, clientUuid = null, clientSymbol = null) {
  let workflowName = taskType.split('-').join('_')
  const routePrefix = process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? `${service}_${clientSymbol}_${workflowName}` : `${service}_${workflowName}`
  const aclAction = process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? `get_tasks_datatables__${taskType}__${clientSymbol}` : `get_tasks_${workflowName}`
  return {
    path: `${name.toLowerCase()}-zycie`,
    component: RouterFrame,
    meta: {
      title: `${name} - życie`,
      icon: getProperServiceIcon(service),
      menuLink: true,
      meta: {
        acl: {
          service: service,
          action: aclAction,
          client: clientName,
          clientId: clientUuid,
          clientLabel: name,
          clientSymbol: clientSymbol
        }
      }
    },
    children: [
      {
        path: '',
        component: IntgenVehicleTaskListing,
        name: `${routePrefix}_task_listing`,
        meta: {
          title: '',
          acl: {
            service: service,
            action: aclAction,
            client: clientName,
            clientId: clientUuid,
            clientLabel: name,
            clientSymbol: clientSymbol
          }
        }
      }
    ]
  }
}
