import getWorkflowRouteSuffix from '../../utils/getWorkflowRouteSuffix'

export default function (service, name, workflowName, client, taskType) {
  const routePrefix = `${service}_${client}_${workflowName}`
  const customBreadcrumbs = {
    taskListing: {
      meta: {
        icon: 'list',
        title: 'menu.main.list.name'
      }
    }
  }
  let IntgenPersonTaskListing = () => import(`../../../components/bundles/share/tasksListing/Listing.vue`)

  return [
    {
      path: `/${name}/${client}/zlecenia/${getWorkflowRouteSuffix(service, workflowName)}/moje-zlecenia`,
      name: `${routePrefix}_task_listing_my_tasks`,
      component: IntgenPersonTaskListing,
      meta: {
        acl: { service: service, action: `get_tasks_datatables__${taskType}__${client}` },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`
          ]
        },
        client,
        icon: 'filter',
        taskType: taskType,
        workflowName,
        title: 'Moje zlecenia',
        secondMenu: []
      }
    }
  ]
}
