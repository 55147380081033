export default function (service, workflow) {
  let trans = {
    main_vehicle: '-pojazd',
    main_person: '-osoba',
    main_property: '-mienie'
  }
  let clientsTrans = {
    main_vehicle: 'pojazd',
    main_person: 'osoba',
    main_property: 'mienie'
  }
  if (process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service)) {
    return clientsTrans[workflow]
  }
  switch (service) {
    case 'extgsl':
      return 'vozidlo'
    case 'extuni':
      return 'online'
    default:
      return trans[workflow]
  }
}
