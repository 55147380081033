export default {
  install: function (Vue, options) {
    Vue.mixin({
      beforeDestroy () {
        if (typeof this.events === 'object') {
          for (var eventKey in this.events) {
            // skip loop if the property is from prototype
            if (!this.events.hasOwnProperty(eventKey)) continue

            // unsubscribe component's events from global event bus
            this.$events.off(this.events[eventKey])
          }
        }
      }
    })
  }
}
