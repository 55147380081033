<script>
import BaseLisitng from '../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseLisitng
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Mapa przejść', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_transition',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'name', title: 'Nazwa', render (data) {
            return data.charAt(0).toUpperCase() + data.slice(1).replaceAll('_', " ");
          } },
        { id: 1, data: 'fromState', title: 'Status początkowy' },
        { id: 2, data: 'toState', title: 'Status końcowy' },
        { id: 3, data: 'accessRole', title: 'Rola' },
        { id: 4, data: 'metadata', title: 'Walidacje', searchable: false, sortable: true, render (data) {
            return data.validations ? 'Tak' : 'Nie'
          }
        },
        { id: 5, data: 'metadata', title: 'Powiadomienia', searchable: false, sortable: true, render (data) {
            return data && data.notifications ? 'Tak' : 'Nie'
          }
        }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_transition_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:main:transitionEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/workflows/1/transitions` }
    }
  }
}
</script>
