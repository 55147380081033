<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/NewUser'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      events: {
        newUserModal: 'core:user:NewUserModal',
        newUserModalContractor: 'core:user:NewUserModalContractor'
      },
      title: 'Rejestracja użytkownika'
    }
  },
  created () {
    this.listenForSelectChange()
    this.listenForContractorSelectChange()

    this.$on('close', () => {
      this.$refs.form.unitSelectHasBeenChanged = false
      this.$refs.form.contractorSelectHasBeenChanged = false
    })
  },
  methods: {
    submitForm () {
      api.request('core', 'post', 'users', this.createDataToSend())
        .then((response) => {
          this.$events.$emit('reloadGrid')

          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Dodano użytkownika'
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return {
        forename: this.model.forename,
        surname: this.model.surname,
        phone: this.model.phone,
        email: this.model.email,
        units: this.model.unitIds,
        contractor: this.model.contractorId,
        description: this.model.description || '',
      }
    },
    listenForSelectChange () {
      this.$events.on(this.events.newUserModal, (selectedUnits) => {
        this.model.unitIds = []
        let units = [...selectedUnits]
        units.forEach((unit) => {
          this.model.unitIds.push(unit.value)
        })
      })
    },
    listenForContractorSelectChange () {
      this.$events.on(this.events.newUserModalContractor, (selectedContractor) => {
        this.model.contractorId = selectedContractor.value
      })
    }
  }
}
</script>
