// submenus
import role from './role'
import person from './person'
import online from './calendar/online'
import visibility from './calendar/visibility'
import mainPerson from './main-person'
import mainProperty from './main-property'
import mainVehicle from './main-vehicle'
import appraisal from './appraisal'
import headers from '../share/task-headers'
import groupUser from './group-user'

export default [
  ...role,
  ...person,
  ...online('intgen', 'generali-CRLS'),
  ...visibility('intgen', 'generali-CRLS'),
  ...headers('intgen', 'generali-CRLS'),
  ...mainPerson,
  ...mainProperty,
  ...mainVehicle,
  ...appraisal,
  ...groupUser
]
