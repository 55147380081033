import * as removeActions from './actions/remove'
import * as downloadActions from './actions/download'
import * as categoriesActions from './actions/categories'
import * as uploadActions from './actions/upload'
import * as defaultActions from './actions/default'
import * as moveActions from './actions/move'


let actions = Object.assign(
  {},
  defaultActions.default,
  removeActions.default,
  downloadActions.default,
  categoriesActions.default,
  uploadActions.default,
  moveActions.default
)
export default actions
