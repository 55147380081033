import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}`
  const customBreadcrumbs = {}

  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }

  return [
    {
      path: `${pathPrefix}widocznosc-zlecen`,
      name: `${service}_task_visibility_listing`,
      component: () => import(`../../../../components/bundles/${service}/admin/task-visibility/Listing.vue`),
      meta: {
        acl: {
          service: service,
          action: 'get_settlement_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
          ]
        },
        icon: 'eye',
        title: 'Lista widoczności zleceń',
        secondMenu: [
          {
            event: { name: `${service}:main:taskVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_settlement_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen/edycja`,
      name: `${service}_task_visibility_edit`,
      component: () => import('../../../../components/bundles/finance/admin/task-visibility/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_settlement_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_task_visibility_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_settlement_visibilities' }
          },
          {
            event: { name: `${service}:main:taskVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_settlement_visibilities' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_task_visibility_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_settlement_visibilities' }
          }
        ]
      }
    },
  ]
}
