<template>
  <div class="dropdown">
    <button
      class="zord-btn btn zord-btn-secondary btn-secondary dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleShow"
    >
      <i
        v-if="labelType === 'icon'"
        :class="icon"
      />
      <span v-if="labelType === 'text'">
        {{ buttonLabel }}
      </span>
    </button>
    <div
      v-show="show"
      class="dropdown-content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonLabel: { type: String, required: true },
    labelType: { type: String, default: 'text' }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    icon: function () {
      if (this.labelType === 'icon') {
        return `feather-${this.buttonLabel}`
      }
      return ''
    }
  },
  methods: {
    toggleShow () {
      this.show = !this.show
    }
  }
}
</script>
