import AdminCalendarBusinessHoursListing from '../../../../components/bundles/intgen/admin/businessHours/Listing.vue'
import AdminCalendarBusinessHoursEdit from '../../../../components/bundles/intgen/admin/businessHours/Edit.vue'

export default function (service, name) {
  const pathPrefix = `administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: `generali`,
      title: name
    }
  }

  return [
    {
      path: `${pathPrefix}kalendarz-ustawienia-online`,
      name: `${service}_calendar_businessHours_listing`,
      component: AdminCalendarBusinessHoursListing,
      meta: {
        acl: {
          service: service,
          action: 'get_business_hours'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service]
          ]
        },
        icon: 'calendar',
        title: 'Ustawienia online',
        secondMenu: [
          {
            event: { name: `${service}:calendar:businessHoursEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_business_hours' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}kalendarz-ustawienia-online/edycja`,
      name: `${service}_calendar_businessHours_edit`,
      component: AdminCalendarBusinessHoursEdit,
      meta: {
        acl: {
          service: service,
          action: 'put_business_hours',
          businessHourTypeMode: service !== 'extpko' ? 'default' : 'extpko'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_calendar_businessHours_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_business_hours' }
          },
          {
            event: { name: `${service}:calendar:businessHoursSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_business_hours' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_calendar_businessHours_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_business_hours' }
          }
        ]
      }
    }
  ]
}
