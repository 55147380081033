// Bundles: Internal/Gen
import IntgenStateListing from '../../../components/bundles/intgen/admin/state/Listing.vue'
import IntgenStateEdit from '../../../components/bundles/intgen/admin/state/Edit.vue'
import IntgenWorkflowListing from '../../../components/bundles/intgen/admin/transition/Listing.vue'
import IntgenWorkflowEdit from '../../../components/bundles/intgen/admin/transition/Edit.vue'
import IntgenTaskVisibilityListing from '../../../components/bundles/intgen/admin/task-visibility/Listing.vue'
import IntgenTaskVisibilityEdit from '../../../components/bundles/intgen/admin/task-visibility/Edit.vue'
// field-access
import IntgenFieldAccessListing from '../../../components/bundles/intgen/admin/field-access/Listing.vue'
import IntgenFieldAccessEdit from '../../../components/bundles/intgen/admin/field-access/Edit.vue'

export default function (service, name) {
  const workflow = 'main-property'
  const pathPrefix = `administracja-procesami-${name.toLowerCase()}/proces-glowny-mienie/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: 'generali',
      title: name
    }
  }
  customBreadcrumbs.mainPropertyTask = {
    meta: {
      icon: 'settings',
      title: 'Proces główny - mienie'
    }
  }
  return [
    {
      path: `${pathPrefix}stany-procesu`,
      name: `${service}_property_state_listing`,
      component: IntgenStateListing,
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_states'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask
          ]
        },
        workflow,
        icon: 'disc',
        title: 'Lista stanów',
        secondMenu: [
          {
            event: { name: `${service}:main:addState` },
            icon: 'plus',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_state' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_property_state_edit`,
      component: IntgenStateEdit,
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_state'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask,
            `${service}_property_state_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: `${service}:main:stateSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_state' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_property_state_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_states' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu`,
      name: `${service}_property_transition_listing`,
      component: IntgenWorkflowListing,
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask
          ]
        },
        workflow,
        icon: 'git-commit',
        title: 'Lista przejść',
        secondMenu: [
          {
            event: { name: `intgen:main:transitionEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
      name: `${service}_property_transition_edit`,
      component: IntgenWorkflowEdit,
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_role_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask,
            `${service}_property_transition_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          },
          {
            event: { name: `${service}:main:transitionSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_role_transitions' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_property_transition_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen`,
      name: `${service}_property_task_visibility_listing`,
      component: IntgenTaskVisibilityListing,
      meta: {
        acl: {
          service: service,
          action: 'get_main-property_task_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask
          ]
        },
        workflow,
        icon: 'eye',
        title: 'Lista widoczności zleceń',
        secondMenu: [
          {
            event: { name: `${service}:main:taskVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'post_main-property_task_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen/edycja`,
      name: `${service}_property_task_visibility_edit`,
      component: IntgenTaskVisibilityEdit,
      meta: {
        acl: {
          service: service,
          action: 'post_main-property_task_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask,
            `${service}_property_task_visibility_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'post_main-property_task_visibilities' }
          },
          {
            event: { name: `${service}:main:taskVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'post_main-property_task_visibilities' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_property_task_visibility_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_main-property_task_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-pol`,
      name: `${service}_property_field_access_listing`,
      component: IntgenFieldAccessListing,
      meta: {
        acl: {
          service: service,
          action: 'get_main-property_fields_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask
          ]
        },
        workflow,
        icon: 'check-square',
        title: 'Lista widoczności pól',
        secondMenu: [
          {
            event: { name: `${service}:main:fieldVisibilityEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_main-property_fields_visibilities' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-pol/edycja`,
      name: `${service}_property_field_access_edit`,
      component: IntgenFieldAccessEdit,
      meta: {
        acl: {
          service: service,
          action: 'put_main-property_fields_visibilities'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainPropertyTask,
            `${service}_property_field_access_listing`
          ]
        },
        workflow,
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_main-property_fields_visibilities' }
          },
          {
            event: { name: `${service}:main:fieldVisibilitySubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_main-property_fields_visibilities' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_property_field_access_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_main-property_fields_visibilities' }
          }
        ]
      }
    }
  ]
}
