<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/Make'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    mode: { type: String, required: true },
    service: { type: String, required: true }
  },
  data () {
    return {
      title: 'Dodanie marki',
      method: { new: 'post' },
      action: { new: 'vehicles/makes' }
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$events.$emit('reloadGrid')
          this.$events.$emit('reloadMakes')
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Dodano markę'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error, 'error', 'Wystąpił błąd', 'Nie można dodać marki.')
        })
    },
    createDataToSend () {
      return {
        label: this.model.label
      }
    }
  }
}
</script>
