import api from '../../api'
import moment from 'moment'
import WebStorage from '../../components/share/WebStorage'

export default {
  clearSettlementData: ({commit}, payload) => {
    commit('CLEAR_SETTLEMENT_AND_ITEMS')
  },
  addNewSettlement: async ({ commit, state, rootState }, payload) => {
    try {
      commit('CLEAR_SETTLEMENT_AND_ITEMS')
      let response = await api.request(payload.service, 'post', `/settlements`, { beginAt: payload.beginAt, endAt: payload.endAt })
      let location = response.headers.location.split('/')
      let id = location[location.length - 1]
      commit('SET_SETTLEMENT_STATE', { data: { id: id } })
      await payload.$router.push({
        name: 'settlements_details',
        params: { id: id }
      })
      payload.notify({
        type: 'success',
        text: 'Dodano rozliczenie'
      })

    } catch (e) {
      console.error(e)
      throw e
    }
  },
  getSettlements: async ({ commit, state }, payload) => {
    commit('CLEAR_SETTLEMENT_AND_ITEMS')
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa pobieranie danych rozliczenia nr ${payload.settlementId}`})

    try {
      await api.request(payload.service, 'put', `/settlements/${payload.settlementId}`, {selfUpdate: true})

      let response = await api.request(payload.service, 'get', `/settlements/${payload.settlementId}`)

      commit('SET_SETTLEMENT_STATE', response)
      payload.reactToStateChange(state.settlement.state)
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
      console.error(e)
      await payload.$router.push({
        name: 'settlements',
      })
      throw e
    }
  },
  getSettlementItems: ({ commit }, payload) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa pobieranie danych zleceń`})
    try {

        commit('ADD_SETTLEMENT_ITEMS',
          { uuid: payload.item.taskUuid, data: payload.item })

        commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

      console.error(e)
      throw e
    }
  },
  sendSettlementItemComment: async ({ commit }, {id, comment, number, service}) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa zapisywanie komentarza do zlecenia nr: ${number}.`})

    try {
      await api.request(service, 'post', `/comments`, { settlementItem: id, comment })

      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

      console.error(e)
      throw e
    }
  },
  sendSettlementItemAmount: async ({ commit }, {id, amount, service}) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa zapisywanie kwoty do zlecenia.`})

    let amountString = amount.split(',').join('.')
    try {
      await api.request(service, 'put', `/settlement-items/${id}`, { amount: parseFloat(amountString) })

      commit('EDIT_SETTLEMENT_ITEM_AMOUNT',
        { id, amount })

      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

      console.error(e)
      throw e
    }
  },
  sendSettlementComment: async ({ commit, state }, {service, id, comment}) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa zapisywanie komentarza rozliczenia`})
    try {
      await api.request(service, 'post', `/comments`, { settlement: id, comment: comment })

      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

      console.error(e)
      throw e
    }
  },
  downloadSettlementFile: async ({commit, state}, {type, id, service}) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa pobieranie pliku.`})

    try {
      let response = await api.request(service, 'get', `/settlements/${id}?format=${type}`)

      commit('SET_DOWNLOADED_FILE', response.data)

      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

      console.error(e)
      throw e
    }
  },
  getUuid: async ({state, commit}, {id, service}) => {
    let uuidResponse = await api.request(service, 'post',
      `/settlements/${id}/attachments`)
    commit('SET_FILE_UUID', uuidResponse.data.uuid)
  },
  pushFile: async ({state, commit}, {uuid, event, id}) => {
    try {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      let formData = new FormData()

      let name = `${uuid}_${files[0].name}`
      formData.append('file[0]', files[0], name)
      formData.append('path', `${moment.unix(state.settlement.createdAt.timestamp).format('YYYYMM')}/${id}`)

      let response = await api.request('storage', 'post',
        `/upload/finance`, formData)
      return response.data
    } catch (e) {
      throw e
    }

  },
  deleteIfStorageFails: async ({commit, state}, {uuid, service, id}) => {
    await api.request(service, 'delete',
      `/settlements/${id}/attachments`, {uuid})
  },
  sendFiles: async ({ commit, state, dispatch }, {event, id, service, notify, events}) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa wysyłanie plików.`})

    try {
      await dispatch('getUuid', {id, service})

      try {
        await dispatch('pushFile', {uuid: state.fileUuid, event, id})
        commit('SET_BLOCKING_LOADER', {loading: false, message: ``})
        notify({
          type: 'success',
          text: 'Dodano plik'
        })
        events.emit('settlement:refresh')
        commit('SET_FILE_UUID', '')
      } catch (e) {
        commit('SET_BLOCKING_LOADER', {loading: false, message: ``})

        await dispatch('deleteIfStorageFails', {uuid: state.fileUuid, service, id})
        commit('SET_FILE_UUID', '')
        console.error(e)
        throw e
      }
    } catch (e) {
      notify({
        type: 'error',
        text: 'Błąd dodawania pliku'
      })
      throw e
    }
  },
  refuseSettlement: async ({ commit, state, dispatch }, {service, id, comment, ownership, settlementState}) => {
    commit('SET_BLOCKING_LOADER',
      { loading: true, message: 'Trwa odrzucanie zlecenia' })
    try {
      await dispatch('changeSettlementState', {id, settlementState, service})

      await dispatch('sendSettlementComment', {id, comment: `${comment}`, service})
      commit('SET_BLOCKING_LOADER', { loading: false, message: '' })
    } catch (e) {
      commit('SET_BLOCKING_LOADER', { loading: false, message: '' })
      throw e
    }
  },
  changeSettlementStateWithComment: async ({ commit, state, dispatch }, {service, id, comment, ownership, settlementState}) => {
    commit('SET_BLOCKING_LOADER',
      { loading: true, message: 'Trwa zmienianie statusu rozliczenia' })
    try {
      await dispatch('changeSettlementState', {id, settlementState, service})

      await dispatch('sendSettlementComment', {id, comment: `${comment}`, service})
      commit('SET_BLOCKING_LOADER', { loading: false, message: '' })
    } catch (e) {
      commit('SET_BLOCKING_LOADER', { loading: false, message: '' })
      throw e
    }
  },
  changeSettlementState: async ({ commit, state }, {settlementState, id, service}) => {
    commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa zmienianie statusu rozliczenia.`})

    try {
      await api.request(service, 'put',
        `/settlements/${id}`, { state: settlementState, ownership: state.settlementPutOwnership })

      commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa wysyłanie plików.`})
    } catch (e) {
      commit('SET_BLOCKING_LOADER', {loading: true, message: `Trwa wysyłanie plików.`})

      console.error(e)
      throw e
    }
  },
  getOwnership: ({commit, state, rootState}) => {
    let actions = WebStorage.getSecurityActions()
    const getSpecificOwnership = function (actions) {
      if(actions.some(action => action.includes('all'))) {
        return 'all'
      } else if (actions.some(action => action.includes('own')) && !actions.some(action => action.includes('all'))) {
        return 'own'
      } else if (!actions.some(action => action.includes('all')) && !actions.some(action => action.includes('own'))) {
        return ''
      }
    }
    let securityActions = rootState.base.securityActions ? rootState.base.securityActions.finance : actions.finance
    let settlementOwnershipActions = securityActions.filter(el => el.includes('get_settlement'))
    let settlementDatatablesOwnershipActions = securityActions.filter(el => el.includes('get_settlements_datatables'))
    let settlementPutOwnershipActions = securityActions.filter(el => el.includes('put_settlement'))

    let settlementOwnership = getSpecificOwnership(settlementOwnershipActions)
    let settlementDatatablesOwnership= getSpecificOwnership(settlementDatatablesOwnershipActions)
    let settlementPutOwnership = getSpecificOwnership(settlementPutOwnershipActions)
    commit('SET_SETTLEMENT_OWNERSHIP_STATE', settlementOwnership)
    commit('SET_SETTLEMENT_DATATABLES_OWNERSHIP_STATE', settlementDatatablesOwnership)
    commit('SET_SETTLEMENT_PUT_OWNERSHIP_STATE', settlementPutOwnership)
  },
  loadVatOptions: async ({commit, state, rootState}) => {
    try {
      let response = await api.request('finance', 'get', '/vat')
      commit('SET_VAT_OPTIONS', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  getUsersVat: async ({commit, state, rootState}, {userUuid}) => {
    let response = await api.request('finance', 'get', `users/${userUuid}`)
   commit('SET_VAT_MODEL', {selectedVat: response.data.vat.id})
  }
}
