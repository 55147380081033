export default {
  methods: {
    errorNotify (error, type = 'error', title = this.$t('error.default'), text = '', duration) {
      let data = this.$t('error.unnamed')

      switch (true) {
        case (error.hasOwnProperty('response')):
          data = (error.response ? (error.response.data.error ? error.response.data.error : error.response.data) : error)
          title = title + ' ' + ( title && data.code ? data.code : '')
          break

        case (error instanceof Error):
          data = error
          break
      }

      let notifOptions = {
        type: data.code === 406 ? 'info' : type,
        title: data.code === 406 ? '' : title,
        text: text !== '' ? text : (data.details ? data.details : data.message)
      }
      if (duration) {
        notifOptions['duration'] = duration
      }

      this.$notify(notifOptions)
    }
  }
}
