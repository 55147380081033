<template>
  <div class="row">
    <input
      v-if="value.id"
      :id="'id_' + value.id"
      type="hidden"
      :value="value.id"
    >
    <div class="col-sm-6">
      {{ value.label }}
    </div>
    <div class="col-sm-6">
      <div>
        <label for="inspection-visibility">Poziom widoczności</label>
        <select
          id="inspection-visibility"
          class="form-control"
          :value="value.inspectionVisibility"
          @input="updateVisibility($event.target.value)"
        >
          <option
            v-for="option in visibilities"
            :key="option.id"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    visibilities: { type: Array, required: true }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateVisibility (value) {
      this.value.inspectionVisibility = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
    width: 100%;
}
</style>
