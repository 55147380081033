<template>
  <section class="content box box-body">
    <tabs
      ref="tabs"
      :tabs-data="tabsData"
      class=""
      @clickedTab="changeTab"
      :top-distance="50"
      id="finance-listing-tabs"
    >
      <div slot="current">
        <div class="row">
          <div class="col-sm-12">
            <div class="box box-default box-solid">
              <div class="box-body table-responsive">
                <grid
                  :service="'finance'"
                  :source="gridDataSource"
                  :name="gridName"
                  :columns="gridColumns"
                  :actions="gridActions"
                  :default-order="[3, 'desc']"
                  :data-source-method="gridDataSourceMethod"
                  :additional-params="{ownership: ownership, name: gridName}"
                  @show="actionShow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="archive">
        <div class="row">
          <div class="col-sm-12">
            <div class="box box-default box-solid">
              <div class="box-body table-responsive">
                <grid
                  :service="'finance'"
                  :source="gridDataSource"
                  :name="archiveGridName"
                  :columns="gridArchivedColumns"
                  :actions="gridActions"
                  :default-order="[3, 'desc']"
                  :data-source-method="gridDataSourceMethod"
                  :additional-params="{ownership: ownership, name: gridName, type: 'archive'}"
                  @show="actionShow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="appraisers-listing">
        <AppraisersListing/>
      </div>
    </tabs>
    <AddSettlementModal
      v-if="addSettlementModalVisible"
      :show="addSettlementModalVisible"
      :service="service"
      @close="() => addSettlementModalVisible = false"
    />
  </section>
</template>

<script>
  import Tabs from '../../share/tabs/Tabs'
  import Grid from '../share/grid/Grid'
  import AddSettlementModal from './Details/modal/Settlement/AddSettlementModal'

  export default {
    name: 'Finance',
    components: {
      AppraisersListing: () => import('./AppraisersListing/AppraisersListing'),
      AddSettlementModal, Grid, Tabs
    },
    data () {
      return {
        events: {},
        addSettlementModalVisible: false,
        gridActions: [],
        gridColumns: [],
        gridArchivedColumns: [],
        gridDataSourceMethod: 'post',
        gridDataSource: {},
        gridName: 'grid_settlements',
        archiveGridName: 'grid_settlements_archive',
        service: this.$route.meta.acl.service,
        tabsData: []
      }
    },
    computed: {
      ownership () {
        return this.$store.state.settlementsState.settlementDatatablesOwnership
      }
    },
    created () {
      this.getEvents()
      this.getGridDataSource(this.service)
      this.getGridActions()
      this.getGridColumns()
      this.getGridArchivedColumns()
      this.getTabs()
    },
    mounted () {
      this.$events.on(this.events.addSettlement, this.addSettlement)
    },
    methods: {
      getTabs () {
        this.tabsData = [
          [
            'current',
            'Bieżące',
            true,
            ['finance.get_settlements_datatables']
          ],
          [
            'archive',
            'Archiwalne',
            false,
            ['finance.get_settlements_datatables']
          ]
        ]
      },
      changeTab (tabName) {
        this.tabsData = this.tabsData.map(tab => tab[0] === tabName ? (tab.map((tabProp, index) => index === 2 ? (tabProp = true) : tabProp)) : (tab.map((tabProp, index) => index === 2 ? (tabProp = false) : tabProp)))
        if(this.activeTab === 'settlement') {
          this.reactToStateChange(this.vuexSettlement.state)
        }
      },
      getEvents () {
        this.events = {
          addSettlement: `${this.service}:add`
        }
      },
      getGridActions () {
        this.gridActions = [
          {
            id: 0,
            event: 'show',
            title: 'Szczegóły',
            icon: 'eye',
            params: ['id'],
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          }
        ]
      },
      getGridDataSource (service) {
        this.gridDataSource = {
          service: 'finance',
          url: `/settlements/datatables`
        }
      },
      getGridColumns () {
        let vm = this
        this.gridColumns = [
          {
            id: 0,
            data: 'id',
            title: 'Numer rozliczenia'
          },
          {
            id: 1,
            data: 'author',
            title: 'Autor'
          },
          {
            id: 2,
            data: 'createdAt',
            title: 'Utworzone',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 3,
            data: 'statusChangedAt',
            title: 'Ostatnia modyfikacja',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 4,
            data: 'beginAt',
            title: 'Początek okresu rozliczenia',
            render (data) {
              return data ? vm.formatDateWithoutTime(data.timestamp) : ''
            }
          },
          {
            id: 5,
            data: 'endAt',
            title: 'Koniec okresu rozliczenia',
            render (data) {
              return data ? vm.formatDateWithoutTime(data.timestamp) : ''
            }
          },
          {
            id: 6,
            data: 'sentAt',
            title: 'Data wysłania',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 7,
            data: 'acceptedAt',
            title: 'Data zaakceptowania',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 8,
            data: 'state',
            title: 'Status'
          }
        ]
      },
      getGridArchivedColumns () {
        let vm = this
        this.gridArchivedColumns = [
          {
            id: 0,
            data: 'id',
            title: 'Numer rozliczenia'
          },
          {
            id: 1,
            data: 'author',
            title: 'Autor'
          },
          {
            id: 2,
            data: 'createdAt',
            title: 'Utworzone',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 3,
            data: 'statusChangedAt',
            title: 'Ostatnia modyfikacja',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 4,
            data: 'beginAt',
            title: 'Początek okresu rozliczenia',
            render (data) {
              return data ? vm.formatDateWithoutTime(data.timestamp) : ''
            }
          },
          {
            id: 5,
            data: 'endAt',
            title: 'Koniec okresu rozliczenia',
            render (data) {
              return data ? vm.formatDateWithoutTime(data.timestamp) : ''
            }
          },
          {
            id: 6,
            data: 'sentAt',
            title: 'Data wysłania',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 7,
            data: 'acceptedAt',
            title: 'Data zaakceptowania',
            render (data) {
              return data ? vm.formatDate(data.timestamp) : ''
            }
          },
          {
            id: 8,
            data: 'state',
            title: 'Status'
          },
          {
            id: 9,
            data: 'invoiceNumber',
            title: 'Numer FV'
          },
        ]
      },
      formatDate (unixTimestamp) {
        const date = new Date(unixTimestamp * 1000)
        return date.toLocaleDateString('sv') + ' ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      },
      formatDateWithoutTime (unixTimestamp) {
        const date = new Date(unixTimestamp * 1000)
        return date.toLocaleDateString('sv')
      },
      addSettlement () {
        this.addSettlementModalVisible = true
      },
      actionShow (id, params) {
        if (params && params.id) {
          return this.$router.push({
            name: `settlements_details`,
            params: { id: params.id }
          })
        }
      }
    }
  }
</script>

<style>
#finance-listing-tabs > ul {
  background: white;
}
</style>
