export default {
  renderHtml: function (data, type, row, config) {
    let params = this.renderParams(config, row)
    return `<button class="${config.cssClass !== undefined ? config.cssClass : ''} action-${config.id}" ref="${data}" params='${params}' role="button" title="${config.title}" type="button" style="display: none"><i class="feather-${config.icon}"></i></button>`
  },
  renderParams: function (config, row) {
    // let paramsObj: {[k: string]: any} = {}
    let paramsObj = {}
    if (config.params && config.params.length > 0) {
      config.params.forEach((item) => {
        if (row.hasOwnProperty(item)) { paramsObj[`${item}`] = row[item] }
      })
    }

    return JSON.stringify(paramsObj)
  }
}
