import router from '../index'

const validateRoute = (to, from, next, mode) => {
  if (!router.app.$auth.isLogged()) {
    if (to.path === '/') {
      next({name: 'security_login'})
    } else {
      if (router.app.$auth.isAclRoute(to) && router.app.$auth.isAllowed(to.meta.acl)) {
        next()
      } else {
        if (!router.app.$auth.isLogged()) {
          router.push({name: 'security_login', query: {redirect: to.fullPath}})
        } else {
          router.push({path: '/'})
        }
      }
    }
  } else {
    if (mode === 'beforeEach' && to.meta && to.meta.securityDynamic) {
      next()
      return
    }

    if (router.app.$auth.isAclRoute(to) && router.app.$auth.isAllowed(to.meta.acl)) {
      next()
    } else {
      router.push({path: '/'})
    }
  }
  if (to.meta && to.meta.acl && to.meta.acl.service && process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(to.meta.acl.service)) {
    document.title = to.meta.title && to.params.clientLabel ? `${to.params.clientLabel} ${router.app.$t(to.meta.title)}` : router.app.$t(to.meta.title) ? router.app.$t(to.meta.title) : 'Zordon'
  } else {
    document.title = to.meta.title ? (router.app.$i18n ? router.app.$t(to.meta.title) : 'Zordon') : 'Zordon'
  }
}

export default validateRoute
