// Bundles: Internal/Gen
import getFokusButton from '../utils/fokusButton'
import getProperServiceIcon from '../utils/getProperServiceIcon'

import statistics from './statistics.js'

export default function (service, name) {
  const taskType = 'main-property'
  const workflowName = 'main_property'
  const routePrefix = `${service}_${workflowName}`
  const routeServiceName = name.toLowerCase()
  const customBreadcrumbs = {
    taskListing: {
      meta: {
        icon: 'list',
        title: 'Lista zleceń'
      }
    }
  }

  return [
    {
      path: `zlecenia/${routeServiceName}-zycie`,
      name: `${routePrefix}_task_listing`,
      component: () => import(/* webpackChunkName: "task-listing" */ `../../components/bundles/share/tasksListing/Listing.vue`),
      meta: {
        acl: { service: service, action: `get_tasks_${workflowName}` },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing
          ]
        },
        icon: getProperServiceIcon(service),
        taskType: taskType,
        workflowName,
        title: `${name} - życie`,
        secondMenu: [
          {
            event: { name: `${service}:main:addTask` },
            icon: 'plus',
            label: 'Dodaj zlecenie',
            acl: { service: service, action: 'post_main_property_task' }
          },
          {
            event: { name: `${service}:finance-import:spreadsheet` },
            icon: 'pocket',
            label: 'Importuj dokumenty finansowe',
            acl: { service: service, action: 'post_finance_import' }
          }
        ]
      }
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/:id`,
      component: () => import(/* webpackChunkName: "task-details" */ `../../components/bundles/intgen/MainTask/Details.vue`),
      name: `${routePrefix}_task_details`,
      meta: {
        acl: { service: service, action: 'get_main_property_task' },
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`
          ]
        },
        taskType: taskType,
        workflowName,
        title: 'Szczegóły zlecenia',
        secondMenu: [
          {
            event: { name: `${service}:main:changeState` },
            icon: 'skip-forward',
            label: 'Zmień status',
            acl: { service: service, action: 'put_main-property_task_next_state' }
          },
          {
            event: { name: `${service}:main:changeAssignee` },
            icon: 'user-plus',
            label: 'Zmień opiekuna',
            acl: { service: service, action: 'put_task' }
          },
          {
            event: { name: `${service}:main:addComment` },
            icon: 'align-left',
            label: 'Dodaj komentarz',
            acl: {
              service: service,
              action: [
                'post_comment_main_internal',
                'post_comment_main_private',
                'post_comment_main_public'
              ],
              allowOneOfActions: true
            }
          },
          getFokusButton(service, false)
        ]
      }
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/:id/wyplaty/:paymentTaskId`,
      name: `${routePrefix}_payment_task_details`,
      component: () => import(/* webpackChunkName: "task-payments" */ '../../components/bundles/intgen/main-property/payment/Details'),
      meta: {
        acl: { service: service, action: 'get_task_main_payment' },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`,
            `${routePrefix}_task_details`
          ]
        },
        workflowName,
        title: 'Szczegóły wypłaty',
        secondMenu: [
          {
            event: { name: 'dashboard:menu:redirect', value: `${routePrefix}_task_details` },
            icon: 'arrow-left',
            label: 'Wróć do zlecenia',
            acl: { service: service, action: 'put_main_property_task' }
          }
        ]
      }
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/:id/generator-dokumentow`,
      name: `${routePrefix}_document_generator`,
      component: () => import(/* webpackChunkName: "task-documents-editor" */ '../../components/bundles/share/generateDocument/GenerateDocumentEditor'),
      meta: {
        acl: { service: service, action: 'get_documents_templates' },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`,
            `${routePrefix}_task_details`
          ]
        },
        workflowName,
        taskType: '',
        title: 'Generator Dokumentów',
        secondMenu: [
          {
            event: { name: `${service}:main:generateDocument` },
            icon: 'filepdf',
            label: 'Zapisz dokument',
            acl: { service: service, action: 'put_main_property_task' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${routePrefix}_task_details` },
            icon: 'arrow-left',
            label: 'Wróć do szczegółów',
            acl: { service: service, action: 'put_main_property_task' }
          }
        ]
      }
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/:id/zlecenia-rzeczoznawcze/:appraisalTaskId`,
      component: () => import(/* webpackChunkName: "appraisal-task-details" */  '../../components/bundles/intgen/appraisal/task/Details'),
      name: `${routePrefix}_appraisal_task_details`,
      meta: {
        acl: { service: service, action: 'get_appraisal_task' },
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`,
            `${routePrefix}_task_details`
          ]
        },
        workflowName,
        taskType: 'appraisal',
        title: 'Szczegóły zlecenia rzeczoznawczego',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:changeState` },
            icon: 'skip-forward',
            label: 'Zmień status',
            acl: { service: service, action: 'put_appraisal_task_next_state' }
          },
          {
            event: { name: `${service}:appraisal:changeAssignee` },
            icon: 'user-plus',
            label: 'Zmień opiekuna',
            acl: { service: service, action: 'put_task' }
          },
          {
            event: { name: `${service}:appraisal:addComment` },
            icon: 'align-left',
            label: 'Dodaj komentarz',
            acl: {
              service: service,
              action: [
                'post_comment_appraisal_internal',
                'post_comment_appraisal_private',
                'post_comment_appraisal_public'
              ],
              allowOneOfActions: true
            }
          },
          getFokusButton(service, true)
          // {
          //   event: {name: 'intgen:appraisal:generateDocument'},
          //   icon: 'filepdf',
          //   label: 'Generuj dokument rzeczoznawczy',
          //   acl: {service: service, action: 'put_appraisal_task'}
          // }
        ]
      }
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/:id/zlecenia-rzeczoznawcze/:appraisalTaskId/generator-dokumentow-rzeczoznawczych`,
      name: `${routePrefix}_appraisal_document_generator`,
      component: () => import(/* webpackChunkName: "task-documents-editor" */ '../../components/bundles/share/generateDocument/GenerateDocumentEditor'),
      meta: {
        acl: { service: service },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`,
            `${routePrefix}_task_details`,
            `${routePrefix}_appraisal_task_details`
          ]
        },
        workflowName,
        taskType: 'appraisal',
        title: 'Generator Dokumentów',
        secondMenu: [
          {
            event: { name: `${service}:appraisal:generateDocument` },
            icon: 'filepdf',
            label: 'Zapisz dokument',
            acl: { service: service, action: 'put_appraisal_task' }
          },
          {
            event: {
              name: 'dashboard:menu:redirect',
              value: `${routePrefix}_appraisal_task_details`
            },
            icon: 'arrow-left',
            label: 'Wróć do szczegółów',
            acl: { service: service, action: 'put_appraisal_task' }
          }
        ]
      }
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/import-finansowy/zlecenie-glowne`,
      name: `${routePrefix}_finance_import_invoice_main`,
      component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsMain'),
      meta: {
        acl: { service: service, action: 'post_finance_import' },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`
          ]
        },
        workflowName,
        title: 'Import danych finansowych',
        secondMenu: [
          {
            event: { name: `${service}:finance-import:main` },
            icon: 'file',
            label: 'Importuj dokumenty',
            acl: { service: service, action: 'post_finance_import' }
          },
          {
            event: {
              name: 'dashboard:menu:redirect',
              value: `${routePrefix}_task_listing`
            },
            icon: 'arrow-left',
            label: 'Wróć do szczegółów',
            acl: { service: service, action: 'get_tasks' }
          }
        ]
      },
      children: [
        {
          path: 'podsumowanie',
          name: `${routePrefix}_finance_import_invoice_main_summary`,
          component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsMain'),
          meta: {
            acl: { service: service, action: 'post_finance_import' },
            breadcrumb: {
              list: [
                customBreadcrumbs.taskListing,
                `${routePrefix}_task_listing`
              ]
            },
            title: 'Import danych finansowych - Podsumowanie',
            workflowName,
            secondMenu: [
              {
                event: {
                  name: 'dashboard:menu:redirect',
                  value: `${routePrefix}_task_listing`
                },
                icon: 'arrow-left',
                label: 'Wróć do listy zleceń',
                acl: { service: service, action: 'get_tasks' }
              }
            ]
          }
        }
      ]
    },
    {
      path: `zlecenia/${routeServiceName}-zycie/import-finansowy/zlecenie-rzeczoznawcze`,
      name: `${routePrefix}_finance_import_invoice_appraisal`,
      component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsAppraisal'),
      meta: {
        acl: { service: service, action: 'post_finance_import' },
        breadcrumb: {
          list: [
            customBreadcrumbs.taskListing,
            `${routePrefix}_task_listing`
          ]
        },
        workflowName,
        title: 'Import danych finansowych',
        secondMenu: [
          {
            event: { name: `${service}:finance-import:appraisal` },
            icon: 'file',
            label: 'Importuj dokumenty',
            acl: { service: service, action: 'post_finance_import' }
          },
          {
            event: {
              name: 'dashboard:menu:redirect',
              value: `${routePrefix}_task_listing`
            },
            icon: 'arrow-left',
            label: 'Wróć do szczegółów',
            acl: { service: service, action: 'get_tasks' }
          }
        ]
      },
      children: [
        {
          path: 'podsumowanie',
          name: `${routePrefix}_finance_import_invoice_appraisal_summary`,
          component: () => import('../../components/bundles/share/finance-import/InvoiceImportDetailsAppraisal'),
          meta: {
            acl: { service: service, action: 'post_finance_import' },
            breadcrumb: {
              list: [
                customBreadcrumbs.taskListing,
                `${routePrefix}_task_listing`
              ]
            },
            workflowName: workflowName,
            title: 'Import danych finansowych - Podsumowanie',
            secondMenu: [
              {
                event: {
                  name: 'dashboard:menu:redirect',
                  value: `${routePrefix}_task_listing`
                },
                icon: 'arrow-left',
                label: 'Wróć do listy zleceń',
                acl: { service: service, action: 'get_tasks' }
              }
            ]
          }
        }
      ]
    },
    ...statistics(service, name)
  ]
}
