// Common
import RouterFrame from '../../../components/share/RouterFrame.vue'
// Admin
import AdminUserListing from '../../../components/bundles/core/admin/user/Listing.vue'
import AdminRoleListing from '../../../components/bundles/core/admin/role/Listing.vue'
import AdminContractorListing from '../../../components/bundles/core/admin/contractor/Listing.vue'
import AdminBankListing from '../../../components/bundles/core/admin/bank/Listing.vue'
import AdminPoliceUnitListing from '../../../components/bundles/core/admin/PoliceUnit/Listing.vue'
import AdminVehicleListing from '../../../components/bundles/core/admin/vehicle/Listing.vue'

export default {
  path: 'administracja-systemem',
  component: RouterFrame,
  meta: {
    title: 'menu.system.name',
    icon: 'settings',
    menuTree: true,
    service: 'core'
  },
  children: [
    {
      path: 'uzytkownicy',
      component: RouterFrame,
      meta: {
        title: 'menu.users',
        icon: 'users',
        menuLink: true,
        acl: {
          service: 'core',
          action: 'get_users'
        }
      },
      children: [
        {
          path: '',
          component: AdminUserListing,
          name: 'admin_user_listing',
          meta: {
            title: 'Lista użytkowników',
            acl: {
              service: 'core',
              action: 'get_users'
            }
          }
        }
      ]
    },
    {
      path: 'role',
      component: RouterFrame,
      meta: {
        title: 'Role',
        icon: 'shield',
        menuLink: true,
        acl: {
          service: 'core',
          action: 'get_roles'
        }
      },
      children: [
        {
          path: '',
          component: AdminRoleListing,
          name: 'admin_role_listing',
          meta: {
            title: 'Lista ról',
            acl: {
              service: 'core',
              action: 'get_roles'
            }
          }
        }
      ]
    },
    {
      path: 'kontrahenci',
      component: RouterFrame,
      meta: {
        title: 'Kontrahenci',
        icon: 'briefcase',
        menuLink: true,
        acl: {
          service: 'core',
          action: 'get_contractors'
        }
      },
      children: [
        {
          path: '',
          component: AdminContractorListing,
          name: 'admin_contractor_listing',
          meta: {
            title: 'Lista kontrahentów',
            acl: {
              service: 'core',
              action: 'get_contractors'
            }
          }
        }
      ]
    },
    {
      path: 'baza-pojazdow',
      component: RouterFrame,
      meta: {
        title: 'menu.vehicleBase',
        icon: 'truck',
        menuLink: true,
        acl: {
          service: 'core',
          action: 'get_vehicles'
        }
      },
      children: [
        {
          path: '',
          component: AdminVehicleListing,
          name: 'admin_vehicle_listing',
          meta: {
            title: 'Lista pojazdów',
            acl: {
              service: 'core',
              action: 'get_vehicles'
            }
          }
        }
      ]
    },
    {
      path: 'banki',
      component: RouterFrame,
      meta: {
        title: 'Banki',
        icon: 'dollar-sign',
        menuLink: true,
        acl: {
          service: 'core',
          action: 'get_banks'
        }
      },
      children: [
        {
          path: '',
          component: AdminBankListing,
          name: 'admin_bank_listing',
          meta: {
            title: 'Lista banków',
            acl: {
              service: 'core',
              action: 'get_banks'
            }
          }
        }
      ]
    },
    {
      path: 'jednostki-policji',
      component: RouterFrame,
      meta: {
        title: 'Jednostki Policji',
        icon: 'crosshair',
        menuLink: true,
        acl: {
          service: 'core',
          action: 'get_police_units'
        }
      },
      children: [
        {
          path: '',
          component: AdminPoliceUnitListing,
          name: 'admin_policeUnit_listing',
          meta: {
            title: 'Lista jednostek policji',
            acl: {
              service: 'core',
              action: 'get_police_units'
            }
          }
        }
      ]
    },
    {
      path: 'tryby-systemu',
      component: RouterFrame,
      meta: {
        acl: {
          service: 'core',
          action: 'put_maintenance_info'
        },
        icon: 'settings',
        title: 'Komunikaty systemowe',
        menuLink: true,
        secondMenu: [
          {
            event: { name: 'core:maintenanceInfo:addMaintenanceInfo' },
            icon: 'plus',
            label: 'Dodaj komunikat administracyjny',
            acl: { service: 'core', action: 'put_maintenance_info' }
          }
        ]
      },
      children: [
        {
          path: '',
          component: AdminPoliceUnitListing,
          name: 'admin_maintenance_info_listing',
          meta: {
            title: 'Komunikaty administracyjne',
            acl: {
              service: 'core',
              action: 'put_maintenance_info'
            }
          }
        }
      ]
    }
  ]
}
