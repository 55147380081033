// Common
import RouterFrame from '../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen
import IntgenPropertyTaskListing from '../../../components/bundles/share/tasksListing/Listing.vue'

const workflowName = 'main_property'
const routePrefix = `intgen_${workflowName}`

export default {
  path: 'generalicrls-mienie',
  component: RouterFrame,
  meta: {
    title: 'Generali CRLS - mienie',
    icon: 'generali',
    menuLink: true,
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_main_property_tasks'
      }
    }
  },
  children: [
    {
      path: '',
      component: IntgenPropertyTaskListing,
      name: `${routePrefix}_task_listing`,
      meta: {
        title: 'Lista zleceń - osoba',
        acl: {
          service: 'intgen',
          action: 'get_main_property_tasks'
        }
      }
    }
  ]
}
