<template>
  <div class="row">
    <input
      v-if="value.id"
      :id="'id_' + value.id"
      type="hidden"
      :value="value.id"
    >
    <div class="col-sm-4">
      <div>
        <label for="orderType">Typ zlecenia</label>
        <select
          class="form-control"
          :value="value.orderType.id"
          @input="updateOrderType($event.target.value)"
        >
          <option
            v-for="option in orderTypes"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div>
        <label for="state">Status</label>
        <select
          class="form-control"
          :value="value.state.id"
          @input="updateState($event.target.value)"
        >
          <option
            v-for="option in states"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div>
        <label for="field">Kategoria</label>
        <select
          class="form-control"
          :value="value.category.id"
          @input="updateCategory($event.target.value)"
        >
          <option
            v-for="option in categories"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    orderTypes: { type: Array, required: true },
    states: { type: Array, required: true },
    categories: { type: Array, required: true }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateOrderType (value) {
      this.value.orderType.id = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateState (value) {
      this.value.state.id = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateCategory (value) {
      this.value.category.id = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
    width: 100%;
}
</style>
