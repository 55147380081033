const loadCategoriesConf = () => {
  return {
    intbwn: {
      method: 'get',
      url: `/attachment-categories`
    },
    intmil: {
      method: 'get',
      url: `/attachment-categories`
    },
    default: {
      method: 'get',
      url: `/attachment-categories`
    }
  }
}
const loadAssignedCategoriesConf = () => {
  return {
    intbwn: {
      method: 'post',
      url: '/attachment-categories/search'
    },
    intmil: {
      method: 'post',
      url: '/attachment-categories/search'
    },
    default: {
      method: 'post',
      url: '/attachment-categories/search'
    }
  }
}
const getCategoryRequirementsConf = ([taskType, id]) => {
  return {
    intbwn: {
      method: 'get',
      // url: `/attachment-categories/${taskType}/${taskId}/requirement`
      url: `/tasks/${id}/attachment-category-requirements
`
    },
    intmil: {
      method: 'get',
      // url: `/attachment-categories/${taskType}/${taskId}/requirement`
      url: `/tasks/${id}/attachment-category-requirements`
    },
    default: {
      method: 'get',
      url: `/attachment-categories/${taskType}/${id}/requirement`
    }
  }
}
const getSendAttachmentCategoryConf = () => {
  return {
    intbwn: {
      method: 'put',
      url: `/attachments`
    },
    intmil: {
      method: 'put',
      url: `/attachments`
    },
    default: {
      method: 'post',
      url: `/attachment-categories`
    }
  }
}
const getDeleteCategoriesConf = ([joinedUuids]) => {
  return {
    intbwn: {
      method: 'delete',
      url: `/attachments/${joinedUuids}`
    },
    intmil: {
      method: 'delete',
      url: `/attachments/${joinedUuids}`
    },
    default: {
      method: 'delete',
      url: `/attachments/${joinedUuids}`
    }
  }
}

const handleChooseFunction = (target, service, data = []) => {
  return target(data).hasOwnProperty(service) ? target(data)[service] : target(data).default
}

export default {
  getLoadCategoriesRequest (service) {
    return handleChooseFunction(loadCategoriesConf, service)
  },
  getLoadAssignedCategoriesRequest (service) {
    return handleChooseFunction(loadAssignedCategoriesConf, service)
  },
  getCategoryRequirementsRequest (service, taskType, id) {
    return handleChooseFunction(getCategoryRequirementsConf, service, [taskType, id])
  },
  getSendAttachmentCategoryRequest (service) {
    return handleChooseFunction(getSendAttachmentCategoryConf, service)
  },
  getDeleteCategoriesRequest (service, joinedUuids) {
    return handleChooseFunction(getDeleteCategoriesConf, service, [joinedUuids])
  }
}
