
export default function (service, name) {
  return {
    path: ``,
    component: '',
    meta: {
      title: 'Klienci',
      icon: 'calendar',
      menuLink: true,
      acl: {
        service: service,
        action: [`put_users`, 'get_users']
      }
    },
    children: [
      {
        path: '',
        component: '',
        name: `${service}_clients_edit`,
        meta: {
          title: 'Klienci',
          acl: {
            service: service,
            action: 'put_user'
          }
        }
      }
    ]
  }
}
