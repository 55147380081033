const pathPrefix = 'administracja-procesami-generalicrls/proces-rzeczoznawczy/'
const customBreadcrumbs = {
  generali: {
    meta: {
      icon: 'generali',
      title: 'Generali CRLS'
    }
  },
  appraisalTask: {
    meta: {
      icon: 'settings',
      title: 'Proces rzeczoznawczy'
    }
  }
}

export default [
  {
    path: `${pathPrefix}stany-procesu`,
    name: 'intgen_appraisal_state_listing',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/state/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_workflow_states'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask
        ]
      },
      icon: 'disc',
      title: 'Lista stanów',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:addState' },
          icon: 'plus',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_workflow_state' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}stany-procesu/edycja`,
    name: 'intgen_appraisal_state_edit',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/state/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_workflow_state'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask,
          'intgen_appraisal_state_listing'
        ]
      },
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_workflow_state' }
        },
        {
          event: { name: 'intgen:appraisal:stateSubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_workflow_state' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_appraisal_state_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_workflow_states' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}mapa-przejsc-procesu`,
    name: 'intgen_appraisal_workflow_listing',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/transition/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_workflow_transitions'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask
        ]
      },
      icon: 'git-commit',
      title: 'Lista przejść',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:transitionEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_workflow_role_transitions' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
    name: 'intgen_appraisal_workflow_edit',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/transition/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_workflow_role_transitions'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask,
          'intgen_appraisal_workflow_listing'
        ]
      },
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_workflow_role_transitions' }
        },
        {
          event: { name: 'intgen:appraisal:transitionSubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_workflow_role_transitions' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_appraisal_transition_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_workflow_transitions' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-zlecen`,
    name: 'intgen_appraisal_task_visibility_listing',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/task-visibility/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_appraisal_task_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask
        ]
      },
      icon: 'eye',
      title: 'Lista widoczności zleceń',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:taskVisibilityEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'post_appraisal_task_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-zlecen/edycja`,
    name: 'intgen_appraisal_task_visibility_edit',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/task-visibility/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'post_appraisal_task_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask,
          'intgen_appraisal_task_visibility_listing'
        ]
      },
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'post_appraisal_task_visibilities' }
        },
        {
          event: { name: 'intgen:appraisal:taskVisibilitySubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'post_appraisal_task_visibilities' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_appraisal_task_visibility_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_appraisal_task_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-pol`,
    name: 'intgen_appraisal_field_access_listing',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/field-access/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_appraisal_fields_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask
        ]
      },
      icon: 'check-square',
      title: 'Lista widoczności pól',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:fieldVisibilityEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_appraisal_fields_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}widocznosc-pol/edycja`,
    name: 'intgen_appraisal_field_access_edit',
    component: () => import('../../../components/bundles/intgen/admin/field-access/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_appraisal_fields_visibilities'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask,
          'intgen_appraisal_field_access_listing'
        ]
      },
      workflow: 'appraisal',
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_appraisal_fields_visibilities' }
        },
        {
          event: { name: 'intgen:appraisal:fieldVisibilitySubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_appraisal_fields_visibilities' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_appraisal_field_access_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_appraisal_fields_visibilities' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}wymagalnosc-kategorii`,
    name: 'intgen_appraisal_attachment_category_requirement_listing',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/attachment-requirement/Listing.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'get_appraisal_attachment_category_requirements'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask
        ]
      },
      icon: 'image',
      title: 'Lista wymagalności kategorii',
      secondMenu: [
        {
          event: { name: 'intgen:appraisal:attachmentCategoryRequirementEdit' },
          icon: 'edit',
          label: 'Tryb edycji',
          acl: { service: 'intgen', action: 'put_appraisal_attachment_category_requirements' }
        }
      ]
    }
  },
  {
    path: `${pathPrefix}wymagalnosc-kategorii/edycja`,
    name: 'intgen_appraisal_attachment_category_requirement_edit',
    component: () => import('../../../components/bundles/intgen/appraisal/admin/attachment-requirement/Edit.vue'),
    meta: {
      acl: {
        service: 'intgen',
        action: 'put_appraisal_attachment_category_requirements'
      },
      breadcrumb: {
        list: [
          customBreadcrumbs.generali,
          customBreadcrumbs.appraisalTask,
          'intgen_appraisal_attachment_category_requirement_listing'
        ]
      },
      title: 'Tryb edycji',
      thirdMenu: [
        {
          event: { name: 'share:list:addItem' },
          icon: 'plus',
          label: 'Dodaj',
          acl: { service: 'intgen', action: 'put_appraisal_attachment_category_requirements' }
        },
        {
          event: { name: 'intgen:appraisal:attachmentCategoryRequirementSubmit' },
          icon: 'save',
          label: 'Zapisz',
          acl: { service: 'intgen', action: 'put_appraisal_attachment_category_requirements' }
        },
        {
          event: { name: 'dashboard:menu:redirect', value: 'intgen_appraisal_attachment_category_requirement_listing' },
          icon: 'arrow-left',
          label: 'Wróć do listy',
          acl: { service: 'intgen', action: 'get_appraisal_attachment_category_requirements' }
        }
      ]
    }
  }
]
