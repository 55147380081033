import Page from '../../../../share/content/Page'

export default {
  mixins: [Page],
  data() {
    return {
      headerTitle: { title: "Baza poszkodowanych", description: "Lista" },
      service: "",
      actionShowUrlName: "",
      gridName: "grid_person",
      mainMenuButtons: [],
      gridActions: [],
      modalVisible: false
    };
  },
  computed: {
    gridDataSource() {
      const vm = this;
      return { service: vm.service, url: "/person" };
    },
    gridColumns() {
      return [
        { id: 0, data: "name", title: this.$t("modals.newUser.company") },
        { id: 1, data: "forename", title: this.$t("modals.newUser.name") },
        { id: 2, data: "surname", title: this.$t("modals.newUser.surname") },
        { id: 3, data: "pesel", title: this.$t("modals.newUser.pesel") },
        { id: 4, data: "nip", title: this.$t("modals.newUser.nip") },
        { id: 5, data: "city", title: this.$t("modals.newUser.city") },
        { id: 6, data: "phone", title: this.$t("modals.newUser.phone") },
        { id: 7, data: "email", title: this.$t("modals.newUser.email") }
      ];
    } 
  },
  methods: {
    addPerson() {
      this.modalVisible = true;
    },
    actionShow(id) {
      const vm = this;
      this.$router.push({ name: vm.actionShowUrlName, params: { id } });
    }
  }
};
