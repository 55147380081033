<template>
  <div>
    <multiselect
      v-model="multiselectValue"
      :label="viewOption"
      :options="optionsList"
      :track-by="trackBy"
      :multiple="multiple"
      :select-label="selectLabel"
      :selected-label="selectedLabel"
      :placeholder="placeholder"
      :deselect-label="deselectLabel"
      :select-group-label="selectGroupLabel"
      :deselect-group-label="deselectGroupLabel"
      @input="updateValue"
      @search-change="asyncFind"
    >
      <template slot="noResult">
        <span
          :data-deselect="$t('calendar.reserveModal.multiselect.addNew')"
          class="multiselect__option multiselect__option--highlight multiselect__option--selected"
          @click="addPerson"
        >
          <span>{{$t('calendar.reserveModal.multiselect.findVictimNoOptions')}}</span>
        </span>
      </template>
      <template slot="noOptions">
        <span
          :data-deselect="$t('calendar.reserveModal.multiselect.addNew')"
          class="multiselect__option multiselect__option--highlight multiselect__option--selected"
          @click="addPerson"
        >
          <span>{{$t('calendar.reserveModal.multiselect.findVictimNoOptions')}}</span>
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import fieldMultiselect from '../../../bundles/share/form/type/fieldMultiselect.js'

export default {
  components: {
  },
  mixins: [
    fieldMultiselect
  ],
  data () {
    return {
      // modalVisible: false
    }
  },
  computed: {
    placeholder () {
      return this.$t('calendar.reserveModal.multiselect.findVictimPlaceholder')
    },
    deselectLabel () {
      return this.$t('calendar.reserveModal.multiselect.deselect')
    },
    selectLabel () {
      return this.$t('calendar.reserveModal.multiselect.choseVictim')
    },
    selectedLabel () {
      return this.$t('calendar.reserveModal.multiselect.chosen')
    }
  },
  methods: {
    addPerson () {
      this.$events.emit('calendar:addPerson')
      // this.modalVisible = true
    },
    updateModel (savedModel) {
      let options = {
        id: savedModel.id,
        label: savedModel.forename + ' ' + savedModel.surname,
        entity: savedModel
      }

      this.updateValue(options)
    }
  }
}
</script>
