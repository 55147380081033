<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/NewPoliceUnit'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    mode: { type: String, required: true },
    service: { type: String, required: true }
  },
  data () {
    return {
      events: {
        newPoliceUnitModal: 'core:policeUnit:newPoliceUnitModal'
      },
      title: 'Dodanie jednostki policji',
      method: { new: 'post' },
      action: { new: '/police' }
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$events.$emit('reloadGrid')
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Dodano Jednostkę policji'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error, 'error', 'Wystąpił błąd', 'Nie można dodać jednostki policji.')
        })
    },
    createDataToSend () {
      return {
        label: this.model.label,
        voivodeship: this.model.voivodeship,
        city: this.model.city,
        postcode: this.model.postcode,
        street: this.model.street,
        email: this.model.email,
        phone: this.model.phone
      }
    }
  }
}
</script>
