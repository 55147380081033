<template>
  <modal
    v-model="isVisible"
    :title="title"
    size="md"
    :backdrop="false"
    @show="open"
    @hide="close"
  >
    <slot />
    <div slot="footer" />
  </modal>
</template>

<script>
import BaseModal from './BaseModal'

export default {
  mixins: [
    BaseModal
  ],
  props: {
    title: { type: String, default: 'Tytuł' },
    subject: { type: [Array, String], required: true }
  },
  methods: {
    open () {
      this.$emit('show')
    },
    close () {
      this.$parent.$emit('close')
    }
  }
}
</script>
