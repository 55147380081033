<template>
  <modal
    v-model="isVisible"
    title="Informacja"
    size="md"
    :backdrop="true"
    :footer="false"
  >
    Przed zmianą zapisz obecne ustawienia
  </modal>
</template>

<script>
import BaseModal from '../../../share/modal/BaseModal'

export default {
  mixins: [
    BaseModal
  ],
  props: {
    mode: { type: String, requred: true },
    model: { type: Object, default () { return {} } }
  },
  methods: {
    close (msg) {
      if (msg === 'ok') {
        this.$emit('closeInfoModal')
      }
    }
  }
}
</script>
