import api from '@/api/index.js'

export const ACTIVE_TASK_STATISTIC_TYPE_VOIVODESHIP = 'voivodeship'
export default {
  data() {
    return {
      tasksForAssigmentStatisticKey: 0,
      tasksForAssigments:{
        title: 'Do przydzielenia',
        total: '',
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          },
        },
        data: {
          json: [],
          keys: {
            x: 'label',
            value: []
          },
          type: 'bar',
          groups: [],
          selection: {},
          onselected: (part, node) => {
          },
          onunselected: (slice, node) => {
          }
        },
        downloadLinkData: {
          service: 'intmil',
          type: 'tasks_for_assigment',
          withDate: false,
          withAssistants: false,
          withVoivodeships: true
        }
      },
      forAssigmentSelectOptions: [],
    }
  },
  methods: {
    loadTasksForAssigments (type = ACTIVE_TASK_STATISTIC_TYPE_VOIVODESHIP) {
      api.request('intmil', 'get', `statistics/tasks/tasks-for-assigments`)
        .then((response) => {
          let data = response.data

          this.tasksForAssigmentStatisticKey = Math.random() * 100

          const preparedData = this.getTasksForAssigments(data[type])
          const values = this.getTasksForAssigmentsValues(preparedData)

          const totalCount = data.voivodeship.reduce((acc, curr) => acc + Number(curr.totalCount), 0)
          this.tasksForAssigments.total = `Łącznie: ${totalCount}`

          this.tasksForAssigments.data.json = preparedData
          this.tasksForAssigments.data.keys.value = values
          this.tasksForAssigments.data.groups = [values]
          
          let voivodeships = preparedData.map( voivodeship => ({label: voivodeship.label, value: voivodeship.label}))
          this.forAssigmentSelectOptions = voivodeships
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
    getTasksForAssigments (backendData) {
      return backendData.map(({label, states, totalCount}) => ({
          label: `${label} (${totalCount})`,
          ...states[0]
      }))
    },
    getTasksForAssigmentsValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'label').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    }
  }
}
