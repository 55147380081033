<template>
  <div class="calendar--business-hours">
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div
                  v-if="service !== null"
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Ustawienia widoczności</h2>
                    <list
                      :items="roles"
                      :empty-element="createEmptyElement()"
                      :remove-element="false"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <inspection-visibility-type
                          v-model="listItems.item"
                          :visibilities="visibilities"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />
    <login-modal
      :show="loginVisible"
      :back-url-name="`${service}_calendar_inspection_visibilities_listing`"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../../share/content/Page'
import api from '../../../../../api'
import List from '../../../../share/form/List'
import LoginModal from '../../../share/modal/LoginModal'
import InspectionVisibilityType from '../../../share/form/type/CalendarInspectionVisibilityType'
import SaveDataInformation from '../../../share/modal/SaveDataInformation'

export default {
  components: {
    InspectionVisibilityType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      events: null,
      inspectionVisibilitiesLoaded: false,
      visibilities: [],
      roles: [],
      headerTitle: { title: 'Ustawienia widoczności', description: 'Tryb edycji' },
      loginVisible: false,
      allowedAccess: false,
      isSaved: true,
      saveDataInformationVisible: false,
      previouslySelectedContractor: null,
      previouslySelectedRole: null
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.loadRoles(this.service)
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.calendarInspectionVisibilitiesSubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      this.events = {
        calendarInspectionVisibilitiesSubmit: `${this.service}:calendar:inspectionVisibilitiesSubmit`
      }
    },
    init () {
      this.allowedAccess = true
      this.loadVisibilities()
    },
    createEmptyElement () {
      return {
        label: '',
        name: '',
        actions: [],
        usersVisibility: 1,
        inspectionVisibility: 1
      }
    },
    loadVisibilities () {
      this.visibilities = [
        { label: 'brak', value: 0 },
        { label: 'swoje', value: 1 },
        { label: 'wszystkie', value: 2 }
      ]
    },
    loadRoles (contractor) {
      api.request(contractor, 'get', '/roles/inspection-visibility-list?format=admin-list')
        .then((response) => {
          this.roles = response.data
          this.inspectionVisibilitiesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    forceToSave () {
      this.isSaved = false
    },
    submitData () {
      let submitData = {
        roles: []
      }
      for (let i = 0; i < this.roles.length; ++i) {
        submitData.roles.push({
          id: this.roles[i].id,
          name: this.roles[i].name,
          description: this.roles[i].description,
          inspectionVisibility: this.roles[i].inspectionVisibility
        })
      }
      api.request(this.service, 'put', '/roles/inspection-visibilities', submitData)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Błąd zapisu danych: ${error.message}`
          })
        })
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 18px
}
</style>
