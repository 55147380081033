<script>
import BaseListing from '../../../share/role/Listing'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      headerTitle: { title: 'Role: System', description: 'Lista' },
      service: 'core',
      actionShowUrlName: 'admin_role_details',
      gridName: 'grid_role',
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'eye', acl: { service: 'core', action: 'get_role' } }
      ]
    }
  }
}
</script>
