export default {
  activeStatus: (state) => {
    if (state.statuses.length !== 0) {
      let active = 0
      state.statuses.forEach((status, index) => status.active ? active = index : null)
      return active
    } else {
      return 0
    }
  },
}
