<template>
  <div class="row">
    <div class="col-sm-4">
      <div>
        <label for="name">Nazwa</label>
        <input
          id="name"
          class="form-control"
          :value="value.label"
          @input="updateLabel($event.target.value)"
        >
      </div>
    </div>
    <div class="col-sm-4">
      <div>
        <label for="start-place">Stan początkowy</label>
        <select
          id="start-place"
          class="form-control"
          :value="value.fromStates[0].name"
          @input="updateStart($event.target.value)"
        >
          <option
            v-for="option in fromStates"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div>
        <label for="end-place">Stan końcowy</label>
        <select
          id="end-place"
          class="form-control"
          :value="value.toStates[0].name"
          @input="updateEnd($event.target.value)"
        >
          <option
            v-for="option in toStates"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <!--<vue-form-generator :schema="schema" :model="value"></vue-form-generator>-->
  </div>
</template>

<script>
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'

Vue.use(VueFormGenerator)

export default {
  props: {
    value: Object,
    fromStates: { type: Array, required: true },
    toStates: { type: Array, required: true }
  },
  data () {
    return {
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Nazwa',
            model: 'label',
            maxlength: 255,
            required: true
          },
          {
            type: 'select',
            label: 'Status początkowy',
            model: 'fromStates[0]',
            required: true,
            values: this.fromStates,
            hideNoneSelectedText: true,
            noneSelectedText: ''
          },
          {
            type: 'select',
            label: 'Status końcowy',
            model: 'toStates[0]',
            required: true,
            values: this.endStates,
            hideNoneSelectedText: true
          }
        ]
      }
    }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateLabel (value) {
      this.value.label = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateStart (value) {
      this.value.fromStates[0].name = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateEnd (value) {
      this.value.toStates[0].name = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
    width: 100%;
}
</style>
