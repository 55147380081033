export default {
  props: {
    isBeforeSubmit: { type: Boolean, required: true, default: false }
  },
  watch: {
    isBeforeSubmit () {
      if (this.isBeforeSubmit) {
        this.validateAll()
      }
    }
  },
  methods: {
    validateAll () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$parent.$emit('validation', true)
          return
        }

        this.$parent.$emit('validation', false)
      })
    }
  }
}
