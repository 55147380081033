import validateRoute from '../utils/validateRoute.js'
import acl from './acl'

export default function () {

  const customBreadcrumbs = {
    taskListing: {
      meta: {
        icon: 'list',
        title: 'menu.complaints.list.name'
      }
    }
  }

  return [
    {
      path: `reklamacje/:service/:client/:taskCategoryId`,
      name: `complaints`,
      component: () => import('../../components/bundles/intmil/complaints/Listing'),
      meta: {
        securityDynamic: true,
        icon: 'activity',
        title: `Reklamacje`,
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing
          ]
        }
      },
      beforeEnter: (to, from, next) => {
        let newTo = {...to, meta: {acl: acl(to)[to.name]}}
        validateRoute(newTo, from, next)
      }
    },
    {
      path: `reklamacje/:service/:client/:taskCategoryId/:taskId`,
      name: 'complaint',
      component: () => import('../../components/bundles/intmil/complaints/complaintTaskDetails/complaintTaskDetails.vue'),
      meta: {
        securityDynamic: true,
        icon: 'activity',
        title: `Reklamacja`,
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs.taskListing,
            'complaints'
          ]
        }
      },
      beforeEnter: (to, from, next) => {
        let newTo = {...to, meta: {acl: acl(to)[to.name]}}
        validateRoute(newTo, from, next)
      }
    },
  ]
}
