import api from '@/api/index.js'
import { setupTotalCount } from '../setupTotalCount'
import _ from 'lodash'

export default {
  data() {
    return {
      closedTasksServices: ['intbls', 'intgen', 'intvig', 'intvhs'],
      closedTasksAcl: ['get_closed_task_statistics'],
      closedTasksKey: 4560,
      closedTasks: {
        title: 'Zamknięte zlecenia',
        total: '',
        data: {
          x: 'x',
          columns: [],
          groups: []
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60
            }
          }
        }
      },
      closedTasksDefault: {},
      closedTasksLoaded: false
    }
  },
  methods: {
    getAllowedClosedTasksServices(services) {
      return this.$auth.getAllowed({services: services, actions: this.closedTasksAcl, allowOneOfActions: true})
    },
    getClosedTasksStatisticRequests (services) {
      return services.map(service => api.request(service, 'get', `statistics/tasks/closed?user=${this.$store.state.base.user.uuid}`))
    },
    handleChangeClosedTasksType (selectedDate) {
      if (this.closedTasksLoaded) {
        this.reloadClosedTasksStatistic(this.closedTasksServices, selectedDate)
      }
    },
    loadClosedTasksStatistic (services, selectedDate = null) {
      selectedDate = selectedDate === null ? new Date().toISOString().split('T')[0] : selectedDate
      this.selectedClosedDate = selectedDate
      this.closedTasksDefault = _.cloneDeep(this.closedTasks)
      this.processClosedTasksStatistic(services, selectedDate)
      return
    },
    reloadClosedTasksStatistic (services, selectedDate) {
      this.closedTasksKey = Math.random() * 100
      this.selectedClosedDate = selectedDate
      this.closedTasks = _.cloneDeep(this.closedTasksDefault)
      this.processClosedTasksStatistic(services, selectedDate)
      return
    },
    async processClosedTasksStatistic (services, selectedDate, buildElements = false) {
      services = this.getAllowedClosedTasksServices(services)

      if (!_.isEmpty(services)) {
        try {
          let requests = this.getClosedTasksStatisticRequests(services)
          let responses = await this.getResponses(requests)
          let responseData = this.filterBy(responses, 'data')
          let chartData = this.getInitialChartData(this.workflows, this.trans)

          this.prepareChartByDate(responseData, chartData, this.workflows, this.contractorApps, selectedDate)
          this.addClosedTaskToCharts(chartData)
          this.addClosedTaskToRefChart(!_.isEmpty(responseData), chartData)

          if (buildElements) {
            this.prepareSelectDates(responseData, this.workflows, this.closedDates)
          }

          this.closedTasksLoaded = true
        } catch (error) {
          console.error(error)
        }
      }
      return
    },
    addClosedTaskToCharts (chartData) {
      this.closedTasks.total = setupTotalCount(chartData.columns);
      this.closedTasks.data.columns.push(chartData.columns.categories)
      this.closedTasks.data.columns.push(chartData.columns.main_vehicle)
      this.closedTasks.data.columns.push(chartData.columns.main_property)
    },
    addClosedTaskToRefChart(addToRef, chartData) {
      if (addToRef) {
        this.$refs.closedTasks.chart.groups([chartData.groups])
      }
    }
  }
}
