import RouterFrame from '../../../../components/share/RouterFrame.vue'
import IntgenRoleListing from '../../../../components/bundles/intgen/admin/role/Listing.vue'

export default function (service, name) {
  return {
    path: 'role',
    component: RouterFrame,
    meta: {
      title: 'Role',
      icon: 'shield',
      menuLink: true,
      acl: {
        service: service,
        action: 'get_roles'
      }
    },
    children: [
      {
        path: '',
        component: IntgenRoleListing,
        name: `${service}_role_listing`,
        meta: {
          title: 'Lista ról',
          acl: {
            service: service,
            action: 'get_roles'
          }
        }
      }
    ]
  }
}
