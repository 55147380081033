// Common
import WebStorage from '../components/share/WebStorage'
import store from '../store'
// Security

// submenus
import adminCore from './admin-core'
import adminIntGen from './admin-intgen'
import finance from './finance'
import complaints from './complaints'
import tasksIntGenPerson from './tasks-intgen-person'
import tasksIntGenProperty from './tasks-intgen-property'
import tasksIntGenVehicle from './tasks-intgen-vehicle'
import tasksVehicle from './tasks-contractor-vehicle'
import tasksExtgslVehicle from './tasks-extgsl-vehicle'
import tasksExtgczVehicle from './tasks-extgcz-vehicle'
import tasksExtuniVehicle from './tasks-extuni-vehicle'
import tasksExtaviVehicle from './tasks-extavi-vehicle'
import tasksExtaviLife from './tasks-extavi-life'
import admin from './admin-contractor/index'
import adminExtuni from './admin-extuni/index'
import adminExtavi from './admin-extavi/index'
import adminClient from './service-with-clients/admin-contractor/index'
import adminClientFinance from './service-with-clients_finance/admin-contractor/index'
import adminClientType from './service-with-clients_intbwn-intmil/admin-contractor/index'
import taskClient from './service-with-clients/tasks-contractor/index'
import adminClientIntVig from './service-with-clients_intvig/admin-contractor/index'
import taskClientIntVig from './service-with-clients_intvig/tasks-contractor/index'
import taskClientIntbwn from './service-with-clients_intbwn-intmil/tasks-contractor/intbwn/index'
import taskClientIntmil from './service-with-clients_intbwn-intmil/tasks-contractor/intmil/index'
import incompleteTask from './incomplete-task'

import Dashboard from '../components/dashboard/Dashboard'
// import router from './../router'

// let intgen = 'intgen'
// let intgenName = 'Generali'
const extgen = 'extgen'
const demofokus = 'demofokus'
const extgsl = 'extgsl'
const extavi = 'extavi'
const extpko = 'extpko'
const extgenName = 'Generali'
const demofokusName = 'Fokus demo'
const demofokusNameNomalized = 'fokus-demo'
const extgslName = 'Generali Slovakia'
const extgslNameNomralized = 'generali-slovakia'
const extaviName = 'Allianz'
const extpkoName = 'Kredyt'
const intbls = 'intbls'
const intblsName = 'BLS'
const intvhs = 'intvhs'
const intvhsName = 'VHS'
const mainFinance = 'finance'
const mainFinanceName = 'Finanse'
const extemn = 'extemn'
const extemnName = 'Emmerson'
const extacn = 'extacn'
const extacnName = 'Acons'
const extgcz = 'extgcz'
const extgczName = "Generali Česká pojišťovna";
const extgczNameNomralized = "generali-ceska-pojistovna";
const extshp = 'extshp'
const extshpName = 'Fokus'
const extuni = 'extuni'
const extuniName = 'Uniqa'
const intVig = "intvig";
const intVigName = "VIG CRLS";

const clients = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
const intvigClients = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
const intbwn = 'intbwn'
const intbwnName = 'BWN'
const intmil = 'intmil'
const intmilName = 'MILLENNIUM'

let getClientsRoutes = function (workflow) {
  let routes = []
  clients.forEach(client => {
    routes.push(...taskClient(intbls, intblsName, workflow, client))
  })

  clients.forEach(client => {
    routes.push(...taskClient(intvhs, intvhsName, workflow, client))
  })

  intvigClients.forEach(client => {
    routes.push(...taskClientIntVig(intVig, intVigName, workflow, client))
  })
  return routes
}
export default [
  {
    path: '/logowanie',
    name: 'security_login',
    component: () => import('../components/security/Login'),
    meta: {
      acl: {
        security: false
      }
    }
  },
  {
    path: '/sl/:token',
    name: 'short_link_redirect',
    component: () => import('../components/ShortLink'),
    meta: {
      acl: {
        security: false
      }
    }
  },
  {
    path: '/potwierdzenie-rejestracji-konta/:token',
    name: 'security_registration_confirmation',
    component: () => import('../components/security/RegistrationConfirmation'),
    meta: {
      acl: {
        security: false
      }
    }
  },
  {
    path: '/zmiana-hasla',
    name: 'security_password_change',
    component: () => import('../components/security/PasswordChange'),
    meta: {
      acl: {
        security: false
      }
    }
  },
  {
    path: '/przywracanie-hasla',
    component: () => import('../components/security/password-reset/PasswordReset'),
    children: [
      {
        path: '',
        name: 'security_password_reset_email',
        component: () => import('../components/security/password-reset/Email'),
        meta: {
          acl: {
            security: false
          },
          title: 'Zordon'
        }
      },
      {
        path: ':token',
        name: 'security_password_reset_new_password',
        component: () => import('../components/security/password-reset/NewPassword'),
        meta: {
          acl: {
            security: false
          }
        }
      }
    ]
  },
  {
    path: '/',
    component: Dashboard,
    meta: {
      acl: {
        security: false
      }
    },
    beforeEnter (to, from, next) {
      if (store.state.base.refreshToken === null && WebStorage.getRefreshToken() !== '') {
        store.commit('SET_REFRESHTOKEN', WebStorage.getRefreshToken())
      }
      if (store.state.base.token === null && WebStorage.getToken() !== '') {
        if (WebStorage.getToken() !== '') {
          store.commit('SET_TOKEN', WebStorage.getToken())
          store.commit('SET_USER', WebStorage.getUser())
        }
      }
      next()
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('../components/dashboard/DashboardContent'),
        meta: {
          acl: {
            security: false
          },
          icon: 'pie-chart',
          title: 'menu.dashboard.name'
        }
      },
      {
        path: 'statystyki',
        name: 'statistics',
        component: () => import('../components/bundles/statistics/Statistics'),
        meta: {
          acl: {
            service: ['intgen', 'intbls', 'extavi', 'extuni', 'core', 'intmil', 'intbwn', 'intvig', 'intvhs'],
            action: [
              'get_inner_state_task_statistics',
              'get_new_task_statistics',
              'get_closed_task_statistics',
              'get_open_task_statistics',
              'get_inspection_appraisal_statistics',
              'get_inspection_other_appraisal_statistics',
              'get_inspection_online_appraisal_statistics',
              'get_task_states_statistics',
              'get_appraisal_task_per_user_statistics',
              'get_my_completed_tasks_statistics',
              'get_tasks_total_statistics',
              'frontend_acl_appraisal_basic_report',
              'frontend_acl_appraisal_complex_report',
              'get_workload_per_appraiser_statistics',
              'frontend_acl_billing_report',
              'frontend_acl_billing_v2_report',
              'frontend_acl_created_appraisal_report',
              'frontend_acl_monitoring_report',
              'get_workload_per_assignee_statistics',
              "get_presence_report",
              "get_workload_per_assignee_statistics",
              "frontend_acl_new_task_report",
              "frontend_acl_transferred_task_report",
              "frontend_acl_get_open_statistics_plus",
              'get_communication_audanet_ce_statistics',
              "get_user_with_task_in_progress",
              "get_user_with_task_without_appointment",
              "get_tasks_for_assigments",
              "get_order_task_sent_back_statistics",
              "get_task_analyst_verification",
              "get_task_analyst_verification_after_deadline",
              'get_tasks_for_correction',
            ],
            allowOneOfActions: true,
            allowOneOfServices: true
          },
          icon: 'activity',
          title: 'Statystyki'
        }
      },
      {
        path: 'kalendarz',
        name: 'calendar',
        component: () => import('../components/share/calendar/Calendar'),
        meta: {
          acl: {
            service: ['intgen', 'extgen', 'extavi', 'extpko', 'demofokus', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp', 'extuni'],
            action: [
              'get_booking_dates',
              'get_business_hours'
            ],
            allowOneOfActions: true,
            allowOneOfServices: true
          },
          icon: 'calendar',
          title: 'menu.calendar.name'
        }
      },
      ...finance(),
      ...complaints(),
      ...incompleteTask(),
      ...tasksIntGenPerson,
      ...tasksIntGenProperty,
      ...tasksIntGenVehicle,
      ...tasksVehicle(extgen, extgenName),
      ...tasksVehicle(demofokus, demofokusName, demofokusNameNomalized),
      ...tasksExtuniVehicle(extuni, extuniName),
      ...tasksExtgslVehicle(extgsl, extgslName, extgslNameNomralized),
      ...tasksExtgczVehicle(extgcz, extgczName, extgczNameNomralized),
      ...tasksExtaviLife(extavi, extaviName),
      ...tasksExtaviVehicle(extavi, extaviName),
      ...tasksVehicle(extpko, extpkoName),
      ...tasksVehicle(extemn, extemnName),
      ...tasksVehicle(extacn, extacnName),
      ...tasksVehicle(extshp, extshpName),
      ...getClientsRoutes('main-vehicle'),
      ...getClientsRoutes('main-property'),
      ...taskClientIntbwn(intbwn, intbwnName),
      ...taskClientIntmil(intmil, intmilName, 'main-valuation', '1'),
      ...adminCore,
      ...adminIntGen,
      ...admin(extgen, extgenName),
      ...admin(demofokus, demofokusNameNomalized),
      ...admin(extgsl, extgslNameNomralized),
      ...adminExtuni(extuni, extuniName),
      ...adminExtavi(extavi, extaviName),
      ...admin(extpko, extpkoName),
      ...admin(extemn, extemnName),
      ...admin(extacn, extacnName),
      ...admin(extgcz, extgczNameNomralized),
      ...admin(extshp, extshpName),
      ...adminClient(intbls, intblsName),
      ...adminClient(intvhs, intvhsName),
      ...adminClientIntVig(intVig, intVigName),
      ...adminClientFinance(mainFinance, mainFinanceName),
      ...adminClientType(intbwn, intbwnName),
      ...adminClientType(intmil, intmilName),
    ]
  },
  {
    path: '/wymagania-systemowe',
    name: 'system_requirements',
    component: () => import('../components/info/Requirements'),
    meta: {
      acl: {
        security: false
      }
    }
  }
]
