<script>
import BasePersonModal from './BasePersonModal'
import api from '../../../../../../api'

export default {
  mixins: [
    BasePersonModal
  ],
  props: {
    mode: { type: String, required: true },
    client: {}
  },
  data () {
    return {
      title: this.$t('modals.newUser.title'),
      method: { new: 'post' },
      action: { new: '/person' }
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then((response) => {
          this.toggleLoading()

          this.$events.$emit('reloadGrid')
          this.$emit('savedModel', Object.assign(this.createDataToSend(), { 'id': response.data.id }))
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: this.$t('modals.newUser.notification.success')
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: error.response.data.error.message
          })
        })
    }
  }
}
</script>
