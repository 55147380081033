<template>
  <form>
    <p class="presence-modal-content">Czy chcesz wpisać się na elektroniczną listę obecności?</p>
  </form>
</template>

<script>
export default {
  name: 'PresenceModalForm',

  props: {
    value: { type: Object, required: true }
  }
}
</script>
<style scoped>
.presence-modal-content {
  display: flex;
  justify-content: center;
}
</style>
