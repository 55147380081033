import role from './role'
import person from './person'
import online from './calendar/online'
import visibility from './calendar/visibility'
import mainPerson from './main-person'
import mainProperty from './main-property'
import mainVehicle from './main-vehicle'
import appraisal from './appraisal'

export default function (service, name) {
  return [
    ...role(service, name),
    ...person(service, name),
    ...online(service, name),
    ...visibility(service, name),
    ...mainPerson(service, name),
    ...mainProperty(service, name),
    ...mainVehicle(service, name),
    ...appraisal(service, name)
  ]
}
