// Bundles: Internal/Gen
import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  return [
    {
      path: `${pathPrefix}przypisywanie-kategorii-zlecen`,
      name: `${service}_task_category_role_listing`,
      component: () => import(`../../../../components/bundles/${service}/admin/taskCategories/Listing`),
      meta: {
        acl: {
          service: service,
          action: `put_task_categories`
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service]
          ]
        },
        icon: `shield`,
        title: `Przypisywanie kategorii zleceń do ról`,
        secondMenu: [
          {
            event: { name: `${service}:taskCategoriesRoles:save` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `put_task_categories` }
          }
        ]
      }
    }
  ]
}
