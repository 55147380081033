import api from '../../../api'
import errorReporter from '../utils/errorReporter'
import i18n from '../../../translations/internationalisation'

export default {
  actionMove: ({dispatch, commit, state}, {sourceTaskId, targetTaskId, targetTaskNumber, uuids, service, $notify}) => {
  commit('SET_MOVING_ATTACHMENTS', true)
  let payload = {
      sourceTaskId: sourceTaskId,
      targetTaskId: targetTaskId,
      attachmentsUuids: uuids}
          
    api.request(service, 'put', `/attachments/move`, payload)
    .then((response) => {
        commit('SET_MOVING_ATTACHMENTS', false)
        commit('MOVED_ATTACHMENTS', payload)
        $notify({
          type: 'success',
          title: i18n.t('success.default'),
          text: i18n.t('pages.taskDetails.attachments.fileMoveSuccess', {targetTaskNumber: targetTaskNumber})
        })
    })
    .catch((error) => {
      errorReporter.reportError(error)
      commit('SET_MOVING_ATTACHMENTS', false)
      $notify({
        type: 'error',
        text: i18n.t('pages.taskDetails.attachments.fileMoveError')
      })
    })
  },

}
