import { render, staticRenderFns } from "./FieldAccessType.vue?vue&type=template&id=641fed58&scoped=true&"
import script from "./FieldAccessType.vue?vue&type=script&lang=js&"
export * from "./FieldAccessType.vue?vue&type=script&lang=js&"
import style0 from "./FieldAccessType.vue?vue&type=style&index=0&id=641fed58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641fed58",
  null
  
)

export default component.exports