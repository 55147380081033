<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div
              v-if="isServiceWithClients"
              class="row"
            >
              <select-type
                class="col-sm-4 col-md-3"
                name="clients"
                :label="'Klient'"
                :required="false"
                :options="clients"
                :disabled="false"
                :empty-first="true"
                :value="client"
                @input="changeClient"
              />
            </div>
            <div
              v-if="!isServiceWithClients"
              class="box-body table-responsive"
            >
              <grid
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                @show="actionShow"
              />
            </div>
            <div
              v-if="client"
              :key="gridKey"
              class="box-body table-responsive"
            >
              <grid
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                :additional-params="{clientId: client}"
                @show="actionShow"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <new-person-modal
      v-if="!isServiceWithClients"
      :show="modalVisible"
      mode="new"
      :service="service"
      @close="modalVisible = false"
    />
    <new-person-modal
      v-if="isServiceWithClients && client"
      :show="modalVisible"
      mode="new"
      :service="service"
      :client="client"
      @close="modalVisible = false"
    />
  </div>
</template>

<script>
import Listing from '../../../share/admin/person/Listing.js'
import SelectType from './../../../../share/form/type/SelectType'
import Grid from '../../../share/grid/Grid.vue'
import Page from '../../../../share/content/Page'
import NewPersonModal from './../../../share/admin/person/modal/NewPerson.vue'

export default {
  components: {
    Grid,
    NewPersonModal,
    SelectType
  },
  mixins: [
    Listing,
    Page
  ],
  data () {
    return {
      events: null,
      service: this.$route.meta.acl.service,
      actionShowUrlName: '',
      gridActions: [],
      clients: [],
      client: null,
      gridKey: 0
    }
  },
  computed: {
    isServiceWithClients () {
      if (this.service) {
        return this.$isWithClients(this.service)
      } else {
        return false
      }
    }
  },
  beforeMount () {
    if (this.isServiceWithClients) {
      this.getClients()
    }
  },
  created () {
    this.getInitEvents()
    this.getInitGridActions()
    this.getInitActionShowUrlName()
  },
  mounted () {
    this.$events.on(this.events.addPerson, this.addPerson)
  },
  methods: {
    getClients () {
      this.clients = this.$store.state.base.user.serviceClients[this.service].map(client => {
        return {
          value: client.uuid,
          label: client.label
        }
      })
    },
    changeClient (event) {
      this.client = event
      this.gridKey = Math.random() * 100
    },
    getInitEvents () {
      this.events = {
        addPerson: `${this.service}:person:addPerson`
      }
    },
    getInitGridActions () {
      this.gridActions = [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'eye', acl: { service: this.service, action: 'get_person' } }
      ]
    },
    getInitActionShowUrlName () {
      this.actionShowUrlName = `${this.service}_person_details`
    }
  }
}
</script>
