import api from '../../../api'
import errorReporter from '../utils/errorReporter'
import imageAssertions from '../utils/IsImage'
import thumb from '../utils/Thumb'
import fileUtil from '../utils/file'
import i18n from '../../../translations/internationalisation'
import {SET_DOWNLOADING_ATTACHMENTS} from '@/store/attachmentsState/utils/constants.js'
export default {
  loadAttachments ({dispatch, commit, state}, {service, filePaths, canEditAttachments, $notify, savedAttachmentsArray}) {
    commit('SET_CURRENTLY_DOWNLOADED_FILE', [])

    if (canEditAttachments) {
      commit('SET_LOADING_ATTACHMENTS', true)
      api.request('storage', 'get', `/download/${service}?format=names&filePaths=${filePaths.join()}`)
      .then((response) => {
        commit('SET_DOWNLOADED_FILES', [])
        commit('SET_NUMBER_OF_FILES_TO_DOWNLOAD', response.data.length)
        if (state.numberOfFilesToDownload !== 0) {
          response.data.forEach((file, index) => dispatch('getSingleAttachment', {data: file, index, service, $notify, savedAttachmentsArray}))
        } else {
          let filesClone = state.downloadedFiles
          if (Array.isArray(filesClone)) {
            commit('SET_CURRENTLY_DOWNLOADED_FILE', [1])
            commit('SET_NUMBER_OF_FILES_TO_DOWNLOAD', 1)
          } else {
            commit('SET_CURRENTLY_DOWNLOADED_FILE', [])
          }
        }
      })
      .catch((error) => {
        errorReporter.reportError(error)

        if (error.response && error.response.status === 403) {
          return
        }
        console.error(error)
        commit('SET_LOADING_ATTACHMENTS', false)
      })
    }
  },
  getSingleAttachment: ({dispatch, commit, state}, {data, index, service, $notify, savedAttachmentsArray}) => {
    let fileData = {
      id: data.id,
      filename: data.filename,
      createdAt: data.createdAt,
      createdBy: data.createdBy,
      mimeType: data.mimeType,
      contentHash: data.contentHash,
      category: null,
      isSent: false
    }
    if (imageAssertions.isImage(fileData.mimeType)) {
      if (state.attachmentsCache[fileData.id]) {
        fileData.value = state.attachmentsCache[fileData.id].value
        fileData.fullSize = state.attachmentsCache[fileData.id].fullSize

        commit('ADD_DOWNLOADED_FILE', {uuid: fileData.id, data: fileData})
        commit('ADD_CURRENTLY_DOWNLOADED_FILE', index)
      } else {
        api.request('storage', 'get', `/download/${service}?filter=uuid&filePaths=${data.path}&fileName=${data.storageFilename}`)
        .then((response) => {
          fileData.value = response.data
          fileData.value = thumb.src(fileData)
          fileData.fullSize = false
          fileData.isSent = Boolean(savedAttachmentsArray?.find(attachment => attachment.value === fileData.id))
          commit('ADD_DOWNLOADED_FILE', {uuid: fileData.id, data: fileData})
          commit('ADD_CURRENTLY_DOWNLOADED_FILE', index)
        })
        .catch((error) => {
          errorReporter.reportError(error)
          console.error(error)
          $notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: i18n.t('pages.taskDetails.attachments.fileNotLoadedError', {name: data.filename})
          })
          commit('ADD_CURRENTLY_DOWNLOADED_FILE', index)
        })
      }
    } else {
      fileData.value = thumb.src(fileData)
      commit('ADD_DOWNLOADED_FILE', {uuid: fileData.id, data: fileData})
      commit('ADD_CURRENTLY_DOWNLOADED_FILE', index)
    }
  },
  fullImageDownloaded: ({dispatch, commit, state}, {id, value}) => {
    commit('SET_ALL_ATTACHMENTS_CACHE_FULLSIZE', {id, value, fullSize: true})
  },
  actionDownload ({dispatch, commit, state}, {uuids, service, taskNumber, $notify}) {
    commit(SET_DOWNLOADING_ATTACHMENTS, true)
    let search = {
      filesUuids: uuids
    }
    api.request('storage', 'post', `/download/${service}/search`, search)
    .then((response) => {
      let file = response.data
      fileUtil.downloadBlob(fileUtil.base64toBlob(file.value, file.mimeType), uuids.length === 1 ? `${file.name}` : `${taskNumber}_załączniki.zip`)
      commit(SET_DOWNLOADING_ATTACHMENTS, false)
    })
    .catch((error) => {
      errorReporter.reportError(error)
      commit(SET_DOWNLOADING_ATTACHMENTS, false)

      $notify({
        type: 'error',
        title: i18n.t('error.default'),
        text: i18n.t('pages.taskDetails.attachments.fileNotLoadedError', {name: ''})
      })
    })
  },
}
