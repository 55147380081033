import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}

  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }

  return [
    {
      path: `${pathPrefix}mapa-przejsc-procesu`,
      name: `${service}_transition_listing`,
      component: () => import('../../../../components/bundles/finance/admin/transition/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: 'get_workflow_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
          ]
        },
        icon: 'git-commit',
        title: 'Lista przejść',
        secondMenu: [
          {
            event: { name: `${service}:main:transitionEdit` },
            icon: 'edit',
            label: 'Tryb edycji',
            acl: { service: service, action: 'put_workflow_transitions' }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
      name: `${service}_transition_edit`,
      component: () => import('../../../../components/bundles/finance/admin/transition/Edit.vue'),
      meta: {
        acl: {
          service: service,
          action: 'put_workflow_transitions'
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_transition_listing`
          ]
        },
        title: 'Tryb edycji',
        thirdMenu: [
          {
            event: { name: 'share:list:addItem' },
            icon: 'plus',
            label: 'Dodaj',
            acl: { service: service, action: 'put_workflow_transitions' }
          },
          {
            event: { name: `${service}:main:transitionSubmit` },
            icon: 'save',
            label: 'Zapisz',
            acl: { service: service, action: 'put_workflow_transitions' }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `${service}_transition_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: service, action: 'get_workflow_transitions' }
          }
        ]
      }
    },
  ]
}
