<template>
  <transition name="fade">
    <ul
      v-if="items"
      class="sidebar--menu-list"
    >
      <template
        v-for="(item, index) in items"
      >
        <li
          v-if="item.visibility"
          :key="index"
          class="menu-element"
        >
          <a @click="emitEvent(item.event)">
            <i
              v-if="item.icon"
              class=""
              :class="`feather-${item.icon}`"
            /><span class="menu-element--label">{{ $t(item.label) }}</span>
          </a>
        </li>
      </template>
    </ul>
  </transition>
</template>

<script>
import SubMenu from './SubMenu'

export default {
  name: 'SecondMenu',
  mixins: [
    SubMenu
  ],
  data () {
    return {
      secondMenuKey: 234,
      events: {
        changeState: 'task:changedState',
        stateLabel: 'dashboard:submenu:stateLabel',
        mainTaskInInitialState: 'dashboard:submenu:mainTaskInInitialState',
        appraisalTaskInInitialState: 'dashboard:submenu:appraisalTaskInInitialState',
        taskInFinalState: 'dashboard:submenu:taskInFinalState',
        taskLoaded: 'dashboard:submenu:taskLoaded',
        stateChanged: 'task:changedState',
        showAll: 'dashboard:submenu:showAll',
        addCustomButtons: "add:custom:secondMenu:buttons"
      },
      hiddenAtInitialMainTaskState: ['addComment', 'sendFocusSms'],
      hiddenAtInitialAppraisalTaskState: ['sendFocusSms'],
      hiddenAtFinalTaskState: ['sendFocusSms', 'addComment'],
      menuReady: false
    }
  },
  mounted () {

    this.$events.on(this.events.stateLabel, this.changeStateLabel)
    this.$events.on(this.events.stateChanged, this.stateChanged)
    this.$events.on(this.events.mainTaskInInitialState, (isState) => { if (isState) this.hideItems(this.hiddenAtInitialMainTaskState) })
    this.$events.on(this.events.appraisalTaskInInitialState, (isState) => { if (isState) this.hideItems(this.hiddenAtInitialAppraisalTaskState) })
    this.$events.on(this.events.taskInFinalState, (isState) => { if (isState) this.hideItems(this.hiddenAtFinalTaskState) })
    this.$events.on(this.events.taskLoaded, this.showTaskItems)
    this.$events.on(this.events.showAll, this.showAllItems)
    this.$events.on(this.events.addCustomButtons, this.addCustomSecondMenuButtons)
  },
  methods: {
    getItems (route) {
      let items = []
      if (route.meta.secondMenu && route.meta.secondMenu.length) {
        items = this.getAllowedMenuItems(route.meta.secondMenu)
      }

      items.forEach((item, index) => {
        items[index].visibility = true
      })

      this.$emit('secondMenuUpdate', items)
      return items
    },
    addCustomSecondMenuButtons (buttons) {
      this.items = this.getAllowedMenuItems(buttons)
      if (this.$route.name.includes('_task_details')) {
        this.items = this.items.map((item) => {
          item.visibility = false
          return item
        })
      } else {
        this.items = this.items.map((item) => {
          item.visibility = true
          return item
        })
      }
      this.$emit('secondMenuUpdate', this.items)
    },
    findItemObject (eventName) {
      return this.items.find((item) => {
        let eventParts = item.event.name.split(':')
        return (eventParts[2] === eventName)
      })
    },
    changeStateLabel (label) {
      let menuItem = this.findItemObject('changeState')
      this.items = this.items.map((temporaryItem, index) => {
        if (temporaryItem.event.name === menuItem.event.name) {
          temporaryItem.label = label
        }
        return temporaryItem
      })
    },
    hideItems (elementsToHide) {
      this.items = this.items.filter(item => !elementsToHide.includes(item.event.name.split(':')[2])
      )
    },
    showAllItems () {
      this.items = this.items.map((element) => {
        let tempEl = element
        tempEl.visibility = true
        return tempEl
      })
    },
    showTaskItems () {
      if (this.$route.name.includes('_task_details')) {
        this.items = this.items.map(el => {
          el.visibility = true
          return el
        })
      }
    },
    stateChanged() {
      this.items = []
      this.items = this.getItems(this.$route)

      if (this.$route.name.includes('_task_details')) {
        this.items = this.items.map(el => {
          el.visibility = false
          return el
        })
      }
    }
  }
}
</script>
<style scoped>
  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-leave-active {
    display: none;
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
