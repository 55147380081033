// Bundles: Internal/Gen
import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  return [
    {
      path: `${pathPrefix}person`,
      name: `${service}_person_listing`,
      component: () => import('../../../../components/bundles/intgen/admin/person/Listing.vue'),
      meta: {
        acl: {
          service: service,
          action: `get_persons`
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service]
          ]
        },
        icon: `shield`,
        title: `Lista poszkodowanych`,
        secondMenu: [
          {
            event: { name: `${service}:person:addPerson` },
            icon: `plus`,
            label: `Dodaj osobę`,
            acl: { service: service, action: `post_person` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}person/:id`,
      name: `${service}_person_details`,
      component: () => import('../../../../components/bundles/intgen/admin/person/Details.vue'),
      meta: {
        acl: {
          service: service,
          action: `get_person`
        },
        breadcrumb: {
          dynamicLabel: true,
          list: [
            customBreadcrumbs[service],
            `${service}_person_listing`
          ]
        },
        title: `Szczegóły poszkodowanego`,
        secondMenu: [
          {
            event: { name: `${service}:person:editPerson` },
            icon: `edit`,
            label: `Edytuj dane`,
            acl: { service: service, action: `put_person` }
          },
          {
            event: { name: `dashboard:menu:redirect`, value: `${service}_person_listing` },
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: { service: service, action: `put_person` }
          }
        ]
      }
    }
  ]
}
