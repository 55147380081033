// Common
import RouterFrame from '../../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen
import IntgenStateListing from '../../../../components/bundles/intgen/admin/state/Listing.vue'
import IntgenWorkflowListing from '../../../../components/bundles/intgen/admin/transition/Listing.vue'
import IntgenTaskVisibilityListing from '../../../../components/bundles/intgen/admin/task-visibility/Listing.vue'
// field-access
import IntgenFieldAccessListing from '../../../../components/bundles/intgen/admin/field-access/Listing.vue'

export default {
  path: 'proces-glowny-pojazd',
  component: RouterFrame,
  meta: {
    title: 'Proces główny - pojazd',
    icon: 'settings',
    menuTree: true
  },
  children: [
    {
      path: 'stany-procesu',
      component: RouterFrame,
      meta: {
        title: 'Stany procesu',
        icon: 'disc',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_workflow_states'
        }
      },
      children: [
        {
          path: '',
          component: IntgenStateListing,
          name: 'intgen_vehicle_state_listing',
          meta: {
            title: 'Lista stanów procesu',
            acl: {
              service: 'intgen',
              action: 'get_workflow_states'
            }
          }
        }
      ]
    },
    {
      path: 'mapa-przejsc-procesu',
      component: RouterFrame,
      meta: {
        title: 'Mapa przejść',
        icon: 'git-commit',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_workflow_transitions'
        }
      },
      children: [
        {
          path: '',
          component: IntgenWorkflowListing,
          name: 'intgen_vehicle_transition_listing',
          meta: {
            title: 'Lista przejść',
            acl: {
              service: 'intgen',
              action: 'get_workflow_transitions'
            }
          }
        }
      ]
    },
    {
      path: 'widocznosc-zlecen',
      component: RouterFrame,
      meta: {
        title: 'Widoczność zleceń',
        icon: 'eye',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_main-vehicle_task_visibilities'
        }
      },
      children: [
        {
          path: '',
          component: IntgenTaskVisibilityListing,
          name: 'intgen_vehicle_task_visibility_listing',
          meta: {
            title: 'Lista widoczności zleceń',
            acl: {
              service: 'intgen',
              action: 'get_main-vehicle_task_visibilities'
            }
          }
        }
      ]
    },
    {
      path: 'widocznosc-pol',
      component: RouterFrame,
      meta: {
        title: 'Widoczność pól',
        icon: 'check-square',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_main-vehicle_fields_visibilities'
        }
      },
      children: [
        {
          path: '',
          component: IntgenFieldAccessListing,
          name: 'intgen_vehicle_field_access_listing',
          meta: {
            title: 'Lista widoczności pól',
            acl: {
              service: 'intgen',
              action: 'get_main-vehicle_fields_visibilities'
            }
          }
        }
      ]
    }
  ]
}
