import axios from 'axios'
import config from '../../public'
import store from './../store'
import internationalisation from '../translations/internationalisation'
import { uuid } from 'vue-uuid'
if (process.env.NODE_ENV !== 'testing' && process.env.NODE_ENV !== 'development') {
  var { tracing } = require('@opencensus/web-core')
}

axios.defaults.baseURL = config.app.api.baseUrl

export default {
  request (service, method, url, data = null, additionalHeaders = []) {
    if (process.env.NODE_ENV !== 'testing' && process.env.NODE_ENV !== 'development') {
      var customSpan = tracing.tracer.startChildSpan({ name: 'axios' + ' ' + service + ' ' + url });
    }
    let axiosConfig = this.setConfig(method, url, data, config.app.api.baseUrl[service], additionalHeaders)
    if(!axiosConfig.hasOwnProperty('headers')) {
      axiosConfig['headers'] = {}
    }
    if (process.env.NODE_ENV !== 'testing' && process.env.NODE_ENV !== 'development') {
      axiosConfig.headers['traceparent'] = `00-${customSpan.spanContext.traceId}-${customSpan.spanContext.spanId}-01`
      axiosConfig.headers['X-Cloud-Trace-Context'] = `00-${customSpan.spanContext.traceId}-${customSpan.spanContext.spanId}-01`
    }


    let response = axios(axiosConfig)
    if (process.env.NODE_ENV !== 'testing' && process.env.NODE_ENV !== 'development') {
      customSpan.end()
    }
    return response
  },
  setConfig (method, url, data, baseURL, additionalHeaders) {
    let config = {
      method,
      url,
      baseURL
    }
    if (method === 'get') {
      config.params = data
    } else {
      config.data = data
    }
    let token = store.state.base.token
    if (token !== null) {
      config.headers = { Authorization: token }
    }

    additionalHeaders.forEach(header => Object.assign({}, config.headers, header))
    if(!config.hasOwnProperty('headers')) {
      config['headers'] = {}
    }
    config.headers['X-Locale'] = internationalisation.locale
    config.headers['X-Correlation-Id'] = uuid.v1()
    config.headers['X-Request-Id'] = uuid.v1()

    return config
  }
}
