import RouterFrame from '../../../../components/share/RouterFrame.vue'
import StateListing from '../../../../components/bundles/intvig/admin/state/Listing.vue'
import WorkflowListing from '../../../../components/bundles/intvig/admin/transition/Listing.vue'
import TaskVisibilityListing from '../../../../components/bundles/intvig/admin/task-visibility/Listing.vue'
import FieldAccessListing from '../../../../components/bundles/intvig/admin/field-access/Listing.vue'

export default function (service, name) {
  return {
    path: 'proces-glowny',
    component: RouterFrame,
    meta: {
      title: 'Proces główny - mienie',
      icon: 'settings',
      menuTree: true
    },
    children: [
      {
        path: 'stany-procesu',
        component: RouterFrame,
        meta: {
          title: `Stany procesu`,
          icon: 'disc',
          menuLink: true,
          acl: {
            service: service,
            action: 'get_workflow_states'
          }
        },
        children: [
          {
            path: '',
            component: StateListing,
            name: `${service}_property_state_listing`,
            meta: {
              title: 'Lista stanów procesu',
              acl: {
                service: service,
                action: 'get_workflow_states'
              }
            }
          }
        ]
      },
      {
        path: 'mapa-przejsc-procesu',
        component: RouterFrame,
        meta: {
          title: 'Mapa przejść',
          icon: 'git-commit',
          menuLink: true,
          acl: {
            service: service,
            action: 'get_workflow_transitions'
          }
        },
        children: [
          {
            path: '',
            component: WorkflowListing,
            name: `${service}_property_transition_listing`,
            meta: {
              title: 'Lista przejść',
              acl: {
                service: service,
                action: 'get_workflow_transitions'
              }
            }
          }
        ]
      },
      {
        path: 'widocznosc-zlecen',
        component: RouterFrame,
        meta: {
          title: 'Widoczność zleceń',
          icon: 'eye',
          menuLink: true,
          acl: {
            service: service,
            action: process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? 'get_task_visibilities_property' : 'get_main-property_task_visibilities'
          }
        },
        children: [
          {
            path: '',
            component: TaskVisibilityListing,
            name: `${service}_property_task_visibility_listing`,
            meta: {
              title: 'Lista widoczności zleceń',
              acl: {
                service: service,
                action: process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? 'get_task_visibilities_property' : 'get_main-property_task_visibilities'
              }
            }
          }
        ]
      },
      {
        path: 'widocznosc-pol',
        component: RouterFrame,
        meta: {
          title: 'Widoczność pól',
          icon: 'check-square',
          menuLink: true,
          acl: {
            service: service,
            action: 'get_main-property_fields_visibilities'
          }
        },
        children: [
          {
            path: '',
            component: FieldAccessListing,
            name: `${service}_property_field_access_listing`,
            meta: {
              title: 'Lista widoczności pól',
              acl: {
                service: service,
                action: 'get_main-property_fields_visibilities'
              }
            }
          }
        ]
      }
    ]
  }
}
