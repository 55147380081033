<template>
  <div>
    <ul class="list-group">
      <li
        v-for="(element, index) in items"
        :key="element.id"
        class="list-group-item"
      >
        <div class="row">
          <div class="col-sm-11">
            <slot
              name="element"
              :index="index"
              :item="element"
            />
          </div>
          <div
            v-if="removeElement"
            class="col-sm-1"
          >
            <button
              type="button"
              class="zord-btn btn zord-btn-danger btn-danger zord-btn-trash btn-trash pull-right zord-btn-icon btn-icon"
              title="Usuń"
              @click="removeItem(element, index)"
            >
              <i class="feather-trash" />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    emptyElement: { type: Object, required: true },
    removeElement: { type: Boolean, default () { return true } },
    handlersByParent: {type: Boolean, default: () => false}
  },
  data () {
    return {
      events: {
        addItem: 'share:list:addItem'
      },
      nextElementId: 0
    }
  },
  mounted () {
    this.$events.on(this.events.addItem, this.addItem)
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    addItem () {
      if(!this.handlersByParent) {
        this.items.push(this.emptyElement)
      }
      this.$emit('addItem')
      this.emitModificationSignal()
    },
    removeItem (element, index) {
      this.$emit('removeItem',{element, index})
      if(!this.handlersByParent) {
        this.items.splice(index, 1)
      }
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.btn-trash {
    margin-top: 14px;
}
</style>
