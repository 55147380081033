import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'
Vue.use(VueI18n)

export default new VueI18n({
  fallbackLocale: 'pl',
  locale: 'pl', // set locale
  silentTranslationWarn: true,
  messages, // set locale messages
})
