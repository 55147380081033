// NOTE: common configuration for modules intmil and intbwn

import getProperServiceIcon from '../../../utils/getProperServiceIcon'
import acl from './acl.js'
import {beforeRouteValidateAclPrevalidation} from '@/router/utils/beforeEnterAclPreValidation.js'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-zlecenie-glowne-${name.toLowerCase()}/:service/:client/:taskCategoryId/`
  const customBreadcrumbs = {}

  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  customBreadcrumbs.mainTask = {
    meta: {
      icon: `settings`,
      title: `Proces główny`
    }
  }

  let routes = [
    {
      path: `${pathPrefix}stany-procesu`,
      name: `${service}_state_listing`,
      component: () => import(`../../../../components/bundles/${service}/admin/state/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainTask
          ]
        },
        icon: `disc`,
        title: `Lista stanów`,
        secondMenu: [
          {
            event: { name: `${service}:main:addState` },
            icon: `plus`,
            label: `Tryb edycji`,
            acl: { service: service, action: `put_workflow_state` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}stany-procesu/edycja`,
      name: `${service}_state_edit`,
      component: () => import(`../../../../components/bundles/${service}/admin/state/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainTask,
            `${service}_state_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: { name: `share:list:addItem` },
            icon: `plus`,
            label: `Dodaj`,
            acl: { service: service, action: `put_workflow_state` }
          },
          {
            event: { name: `${service}:main:stateSubmit` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `put_workflow_state` }
          },
          {
            event: { name: `dashboard:menu:redirect`, value: `${service}_state_listing` },
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: { service: service, action: `get_workflow_states` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu`,
      name: `${service}_transition_listing`,
      component: () => import(`../../../../components/bundles/${service}/admin/transition/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainTask
          ]
        },
        icon: `git-commit`,
        title: `Lista przejść`,
        secondMenu: [
          {
            event: { name: `${service}:main:transitionEdit` },
            icon: `edit`,
            label: `Tryb edycji`,
            acl: { service: service, action: `put_workflow_transitions` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}mapa-przejsc-procesu/edycja`,
      name: `${service}_transition_edit`,
      component: () => import(`../../../../components/bundles/${service}/admin/transition/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainTask,
            `${service}_transition_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: { name: `share:list:addItem` },
            icon: `plus`,
            label: `Dodaj`,
            acl: { service: service, action: `put_workflow_transitions` }
          },
          {
            event: { name: `${service}:main:transitionSubmit` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `put_workflow_transitions` }
          },
          {
            event: { name: `dashboard:menu:redirect`, value: `${service}_transition_listing` },
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: { service: service, action: `get_workflow_transitions` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen`,
      name: `${service}_task_visibility_listing`,
      component: () => import(`../../../../components/bundles/${service}/admin/task-visibility/Listing.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainTask
          ]
        },
        icon: `eye`,
        title: `Lista widoczności zleceń`,
        secondMenu: [
          {
            event: { name: `${service}:main:taskVisibilityEdit` },
            icon: `edit`,
            label: `Tryb edycji`,
            acl: { service: service, action: `post_task_category_task_visibilities` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}widocznosc-zlecen/edycja`,
      name: `${service}_task_visibility_edit`,
      component: () => import(`../../../../components/bundles/${service}/admin/task-visibility/Edit.vue`),
      meta: {
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            customBreadcrumbs.mainTask,
            `${service}_task_visibility_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: { name: `share:list:addItem` },
            icon: `plus`,
            label: `Dodaj`,
            acl: { service: service, action: `post_task_category_task_visibilities` }
          },
          {
            event: { name: `${service}:main:taskVisibilitySubmit` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `post_task_category_task_visibilities` }
          },
          {
            event: { name: `dashboard:menu:redirect`, value: `${service}_task_visibility_listing` },
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: { service: service, action: `get_task_category_task_visibilities` }
          }
        ]
      }
    },
  ]
  return routes.map(route => {
    route.props = true
    route.meta.securityDynamic = true
    route['beforeEnter'] = beforeRouteValidateAclPrevalidation(acl)
    return route
  })
}
