<script>
import BaseListing from '../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Widoczność kalendarza', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_calendar_inspection_visibilities',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'label', title: 'Opis' },
        { id: 1, data: 'name', title: 'Nazwa' },
        { id: 2, data: 'inspectionVisibility', title: 'Widoczność', searchable: false }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_calendar_inspection_visibilities_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:calendar:inspectionVisibilitiesEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/roles/inspection-visibility-list` }
    }
  }
}
</script>
