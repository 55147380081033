<template>
  <Modal
    v-model="isVisible"
    :title="''"
    :size="'sm'"
    :backdrop="false"
    @show="open"
    @hide="() => {}"
  >
    <form-modal ref="form" v-model="model" :is-before-submit="false" />
    <template v-slot:footer>
      <div class="presence-modal-buttons-container">
        <button
          type="button"
          class="presence-modal-confirm-button zord-btn btn zord-btn-primary btn-primary"
          data-action="auto-focus"
          @click="() => confirmPresence('b')"
        >
          <span>Potwierdzam</span>
        </button>
        <button
          type="button"
          class="presence-modal-confirm-remote-button zord-btn btn zord-btn-primary btn-primary"
          data-action="auto-focus"
          @click="() => confirmPresence('z')"
        >
          <span> Potwierdzam (Praca zdalna) </span>
        </button>
        <button
          type="button"
          class="presence-modal-cancel-button zord-btn btn btn-default zord-btn-default"
          @click="$emit('close')"
        >
          <span>Anuluj</span>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import FormModal from './PresenceModalForm.vue'
import api from '@/api'
import BaseModal from '@/components/share/modal/BaseModal.js'

export default {
  name: 'PresenceModal',
  components: {
    FormModal
  },
  mixins: [
    BaseModal,
  ],
  props: {
    show: {}
  },
  data () {
    return {
      model: {},
      title: 'Obecność'
    }
  },
  methods: {
    getConfirmPresenceMethod (status) {
      let user = this.$store.state.base.user
      return api.request('core', 'put', `/users/${user.uuid}/presence`, {status})
    },
    confirmPresence (status) {
      this.getConfirmPresenceMethod(status)
        .then((response) => {
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Obecność potwierdzona'
          })
        })
        .catch(() => {
          this.$emit('close')
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można potwierdzić obecności'
          })
        })
    },
    open () {
      this.$emit('show')
    },
  }
}
</script>

<style scoped>
.presence-modal-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.presence-modal-buttons-container > button.zord-btn.btn {
  margin: 2px;
}
</style>
