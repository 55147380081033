<template>
  <div
  id="custom-select"
  class="form-group select-group"
  >
    <select
      class="select form-item"
      :value="value"
      @change="event => onChange(event.target.value)"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{option.label}}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    onChange: {
      type: Function
    },
    options: {
      type: Array
    },
  },
}
</script>

<style lang="scss" scoped>
  .select-group {
    position: relative;
    z-index: 2;
  }
  .select-group > select {
    height: 3.5rem;
    padding-right: 3.5rem;
  }
</style>