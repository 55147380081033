<template>
  <ul
    v-if="menu"
    class="sidebar--menu-list"
  >
    <menu-link
      v-if="isMenuLink"
      :model="dashboard"
      :level="level"
      @childOpened="modifyMenu"
    />
    <menu-element
      v-for="item in menu"
      :key="item.name"
      :model="item"
      :level="level"
      :opened-element="currentEl"
      @childOpened="modifyMenu"
    />
  </ul>
</template>

<script>
// tutaj dodać eventa dla zamknięcia pozostałych, bo tylko najwyzszy poziom powinien zamykać pozostałe
import MenuElement from './Element'
import MenuLink from './Link'
import menuRoutes from '../../../../router/menu-routing'
// import RouterFrame from '../../../../components/share/RouterFrame.vue'
import clientsMenu from '../../../../router/menu-routing/tasks-contractor-vehicle/index'
import clientsMenuIntbwn from '../../../../router/menu-routing/tasks-contractor-intbwn/index'
import clientsMenuIntmil from '../../../../router/menu-routing/tasks-contractor-intmil/index'
import _ from 'lodash'
import adminIntbwn
  from '../../../../router/menu-routing/admin-intbwn-contractor'
import adminIntmil
  from '../../../../router/menu-routing/admin-intmil-contractor'
import complaints from '../../../../router/menu-routing/complaints'
export default {
  components: {
    MenuElement,
    MenuLink
  },
  data () {
    return {
      events: {
        userLogin: 'dashboard:menu:userLogin'
      },
      dashboard: null,
      menu: null,
      currentEl: null,
      level: 1
    }
  },
  computed: {
    isMenuLink () {
      return this.dashboard.meta !== undefined && this.dashboard.meta.menuLink === true
    }
  },
  created () {
    if (this.$auth.isLogged()) {
      this.drawMenu()
    }
  },
  beforeDestroy () {
    this.menu = null
  },
  methods: {
    seenMenuEl (val) {
      if (!val.hasOwnProperty('children')) {
        if (this.$auth.isAclRoute(val) && this.$auth.isAllowed(val.meta.acl, {params: this.$route.params})) {
          return val
        }
      } else if (val.children.length > 0) {
        let filteredChildren = val.children.filter(this.seenMenuEl)
        if (filteredChildren.length > 0) {
          val.children = filteredChildren
          return val
        }
      }
    },
    drawMenu () {
      this.dashboard = _.cloneDeep(menuRoutes).find(item => item.name === 'dashboard')
      this.menu = []
      this.$events.$emit('dashboard:submenu:reload')
      this.menu = this.dashboard.children.filter(this.seenMenuEl)
      if (Object.keys(this.$store.state.base.user.serviceClients).length !== 0) {
        Object.entries(this.$store.state.base.user.serviceClients).forEach(service => {
          if (service[0] === 'intbls' && Array.isArray(service[1])) {
            service[1].forEach(client => {
              this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenu(service[0], client.label, 'main-vehicle', client.name, client.uuid, client.id))
              this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenu(service[0], client.label, 'main-property', client.name, client.uuid, client.id))
            })
          }
          if (service[0] === 'intvig' && Array.isArray(service[1])) {
            service[1].forEach(client => {
              this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenu(service[0], client.label, 'main-vehicle', client.name, client.uuid, client.id))
              this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenu(service[0], client.label, 'main-property', client.name, client.uuid, client.id))
            })
          }
          if (service[0] === 'intvhs' && Array.isArray(service[1])) {
            service[1].forEach(client => {
              this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenu(service[0], client.label, 'main-vehicle', client.name, client.uuid, client.id))
            })
          }
          if (service[0] === 'intbwn' && Array.isArray(service[1])) {
            this.dashboard.children.push(adminIntbwn(service[0], 'BWN', service[1]))
            service[1].forEach(client => {
              if (!client.taskCategories.hasOwnProperty('main')) {
                return
              }
              client.taskCategories.main.forEach(category => {
                this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenuIntbwn(service[0], client.label, category.label, category.name, client.name, client.uuid, client.id, category.id))
              })
            })
            service[1].forEach(client => {
              if (!client.taskCategories.hasOwnProperty('complaint')) {
                return
              }
              client.taskCategories.complaint.forEach(category => {
                this.dashboard.children.find(el => el.path === 'reklamacje').children.push(complaints(service[0],client.id, client.label, category.id, category.name))
              })
            })
          }
          if (service[0] === 'intmil' && Array.isArray(service[1])) {
            this.dashboard.children.push(adminIntmil(service[0], 'MILLENNIUM', service[1]))
            service[1].forEach(client => {
              if (!client.taskCategories.hasOwnProperty('main')) {
                return
              }
              client.taskCategories.main.forEach(category => {
                this.dashboard.children.find(el => el.path === 'zlecenia').children.push(clientsMenuIntmil(service[0], client.label, category.label, category.name, client.name, client.uuid, client.id, category.id))
              })
            })
            service[1].forEach(client => {
              if (!client.taskCategories.hasOwnProperty('complaint')) {
                return
              }
              client.taskCategories.complaint.forEach(category => {
                this.dashboard.children.find(el => el.path === 'reklamacje').children.push(complaints(service[0],client.id, client.label, category.id, category.name))
              })
            })
          }
        })
      }
      this.menu = this.dashboard.children.filter(this.seenMenuEl)
      this.$events.$emit('dashboard:submenu:reload')
    },
    modifyMenu (el) {
      this.currentEl = el
    }
  }
}
</script>
