import api from '../../../../api'
import {setupTotalCount} from '../setupTotalCount'

export default {
  data () {
    return {
      myTasksColumnMap: [],
      myTasks: {
        title: 'Moje zlecenia',
        total: '',
        data: {
          columns: [],
          onclick: (d, el) => {
            let service = this.getServiceByLabel(d.id)
            let workflow = this.myTasksColumnMap[d.index]
            if (service !== 'intgen') {
              let clientsMap = this.$store.state.base.user.serviceClients['intbls']
              service = clientsMap.find(client => client.name === d.id.split(' ').join('_').toLowerCase()).id
              this.$router.push({ name: `intbls_${service}_${workflow}_task_listing_my_tasks` })
            } else {
              this.$router.push({ name: `${service}_${workflow}_task_listing_my_tasks` })
            }
          }
        },
        axis: {
          x: {
            label: '',
            type: 'category',
            categories: [],
            tick: {
              rotate: 60
            }
          }
        }
      },
    }
  },
  methods: {
    loadMyTasks () {
      let intgenData = {}
      let intgenDataWithClient = {}
      let intgenCategories = []


      if (!this.isAllowed(['intgen'], 'get_inner_state_task_statistics')) {
        this.loadMyTasksBls({intgenData, intgenCategories})
        return
      }
      api.request('intgen', 'get', `statistics/tasks/inner-state?user=${this.$store.state.base.user.uuid}`)
      .then((response) => {
        let intgenStats = response.data
        let { intgenData, intgenCategories } = this.prepareIntgenData({ intgenStats, intgenData, intgenCategories, intgenDataWithClient })

        this.loadMyTasksBls({ intgenData, intgenCategories })
      })
      .catch((e) => {
        this.loadMyTasksBls({intgenData, intgenCategories})
        console.error(e)
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Nie można załadować danych do wykresu Moje zlecenia'
        })
      })
    },
    loadMyTasksBls ({intgenData, intgenCategories}) {
      let intblsData = {}
      let intblsCategories = []
      if (!this.isAllowed(['intbls'], 'get_inner_state_task_statistics')) {
        this.assignDataToColumnsAndCategories({intgenData, intgenCategories}, {intblsData, intblsCategories})
        return
      }
      api.request('intbls', 'get', `statistics/tasks/inner-state?user=${this.$store.state.base.user.uuid}`)
      .then((response) => {
        let data = response.data
        intblsData = data.data
        intblsCategories = data.categories

        this.assignDataToColumnsAndCategories({intgenData, intgenCategories}, {intblsData, intblsCategories})
      })
      .catch((e) => {
        this.assignDataToColumnsAndCategories({intgenData, intgenCategories}, {intblsData, intblsCategories})
        console.error(e)
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Nie można załadować danych do wykresu Moje zlecenia'
        })
      })
    },
    prepareIntgenData({ intgenStats, intgenData, intgenCategories, intgenDataWithClient }) {
      if (Object.keys(intgenStats.data).length !== 0) {
        intgenData = intgenStats.data
        Object.entries(intgenData).forEach(([workflowName, value], index) => {
          intgenDataWithClient[workflowName] = {'intgen': value}
        })
        intgenData = intgenDataWithClient
        intgenCategories = intgenStats.categories
      }
      return { intgenData, intgenCategories }
    },
    assignDataToColumnsAndCategories ({intgenData, intgenCategories},{intblsData, intblsCategories}, columnMap = null, trans = true) {
      let clients = {}

      for (let workflow in intblsData) {
          for (let client in intblsData[workflow]) {
            clients[client] = client
          }
      }

      if (Object.keys(intgenData).length !== 0) {
        clients['intgen'] = 'intgen'
      }

      let categoriesToShow = [...new Set(intgenCategories.concat(intblsCategories))]

      let dataToShow = []
      for (let client in clients) {
        let row = []
        row.push(client)
        categoriesToShow.forEach(category => {
          if (client === 'intgen') {
            intgenData.hasOwnProperty(category) ? (intgenData[category].hasOwnProperty(client) ? row.push(intgenData[category][client]) : row.push(0)) : row.push(0)
          } else {
            intblsData.hasOwnProperty(category) ? (intblsData[category].hasOwnProperty(client) ? row.push(intblsData[category][client]) : row.push(0)) : row.push(0)
          }
        })
        dataToShow.push(row)
      }
      this.myTasks.total = setupTotalCount(dataToShow);
      
      let sumValuesVehicleIntgen = intgenData.main_vehicle ? Object.values(intgenData.main_vehicle) : 0
      let sumValuesVehicleBLS = intblsData.main_vehicle ? intblsData.main_vehicle : 0
      let sumValuesPropertyIntgen =  intgenData.main_property ? Object.values(intgenData.main_property) : 0
      let sumValuesPropertyBLS = intblsData.main_property ? intblsData.main_property : 0

      this.myTasks.axis.x.categories = categoriesToShow.map(category => {
        let categoriesMap = {
          main_person: 'osoba',
          main_vehicle: 'pojazd' + ' ' + `(${Number(sumValuesVehicleIntgen) + Number(sumValuesVehicleBLS)})`, 
          main_property: 'mienie' + ' ' + `(${Number(sumValuesPropertyIntgen) + Number(sumValuesPropertyBLS)})`,
        }
        this.myTasksColumnMap.push(category)
        return categoriesMap[category]
      })

      this.myTasks.data.columns = dataToShow.map(element => {
        if (this.contractorApps.hasOwnProperty(element[0])) {
          element[0] = this.contractorApps[element[0]]
          return element
        } else {
          element[0] = element[0].split('_').join(' ').toUpperCase()
          return element
        }
      })
    }
  }
}
