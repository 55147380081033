// NOTE: common configuration for modules intmil and intbwn

import getProperServiceIcon from '../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }
  return [
    {
      path: `${pathPrefix}clients`,
      name: `${service}_clients_edit`,
      component: () => import(`../../../../components/bundles/${service}/admin/clients/Clients`),
      meta: {
        acl: {
          service: service,
          action: [`put_users`, `get_users`]
        },
        breadcrumb: {
          dynamicLabel: false,
          list: [
            customBreadcrumbs[service]
          ]
        },
        title: `Edycja klientów`,
        secondMenu: [
          {
            event: { name: `${service}:clients:save` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `put_users` }
          }
        ]
      }
    }
  ]
}
