import _ from 'lodash'

export default {
  renderHtml (data, type, row, config) {
    let routeTo = _.cloneDeep(config.routeToItems[row.supportedClass && row.supportedClass.includes('Appraisal') ? 'appraisal' : 'main'])
    for (let paramsKey in routeTo.params) {
      if (routeTo.params.hasOwnProperty(paramsKey)) {
        routeTo.params[paramsKey] = row[routeTo.params[paramsKey]]
      }
    }
    const { href } = config.router().resolve(routeTo)
    let params = this.renderParams(config, row)

    return `<a href="${href}">
      <button
        class="${config.cssClass !== undefined ? config.cssClass : ''} action-${config.id} btn btn-primary zord-btn zord-btn-primary"
        ref="${data}"
        params='${params}'
        role="button"
        title="${config.title}"
        type="button"
      >
        <i class="feather-${config.icon}"></i>
      </button>
    </a>`
  },
  renderParams: function (config, row) {
    let paramsObj = {}
    if (config.params && config.params.length > 0) {
      config.params.forEach((param) => {
        if (row.hasOwnProperty(param)) { paramsObj[`${param}`] = row[param] }
      })
    }
    return JSON.stringify(paramsObj)
  }
}
