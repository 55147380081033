import api from '../../../../api'
import moment from "moment";
import {setupTotalCount} from '../setupTotalCount'

export const communicationAudanetCeSelectEvent = 'communicationAudanetCeSelectEvent'

export default {
  data () {
    return {
    communicationAudanetCeKey: 0,
    communicationAudanetLoaded: false,
    communicationAudanetCeSelectEvent: 'communicationAudanetCeSelectEvent',
    selectedCommunicationAudanetCeDate: moment().format('YYYY-MM-DD'),
    communicationAudanetCeStatisticLoaded: false,
    communicationAudanetCeStatisticSelectedType:'',
    blsCommunicationAudanetCe: {
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
            }
          }
        },
        title: 'Odpowiedzi CE',
        total: '',
        data: {
          json: [],
          keys: {
            x: 'name',
            value: []
          },
          type: 'bar',
          groups: [],
          selection: {},
        },
        downloadLinkData: {
          service: 'intgen',
          type: 'communication_audanet_ce',
          withDate: true
        }
      }
    }
  },
  methods: {
    getLoadCommunicationAudanetCekUrl() {
      return `statistics/tasks/communication-audanet-ce`
    },
    loadCommunicationAudanetCeStatistic () {
      (new Promise((resolve => setTimeout(() => 
      api.request('intgen', 'get', this.getLoadCommunicationAudanetCekUrl())
      .then((response) => {
      let data = response.data

      resolve({data}), 200})))).then(({data}) => {
        const preparedData = this.getCommunicationAudanetCEStatistic(data)
        const values = this.getCommunicationAudanetCEValues(preparedData)
        const tempCommunicationAudanet = this.blsCommunicationAudanetCe

        this.blsCommunicationAudanetCe.total = setupTotalCount(preparedData);
        
        tempCommunicationAudanet.data.json = preparedData
        tempCommunicationAudanet.data.keys.value = values
        tempCommunicationAudanet.data.groups = [values]
        this.blsCommunicationAudanetCe = tempCommunicationAudanet
        this.communicationAudanetCeKey = Math.random() * 100
      }).catch((e) => {
        console.error(e)
        this.$notify({
          type: 'error',
          title: 'Wystąpił błąd',
          text: 'Nie można załadować danych do wykresu Odpowiedzi CE'
        })
      })
      )},
  
    getCommunicationAudanetCEStatistic (backendData) {
      return backendData.map(({name, values}) => {
        let sumValues = Object.values(...values)
        .reduce((acc, curr) => {
          if (typeof curr === 'undefined') {
            return acc += 0
          } else if (typeof curr === 'number') {
            return acc += curr
          } else if ( typeof curr === 'string') {
            return acc += Number(curr)
          }
        }, 0)
        return {
          name: name + ' ' + `(${sumValues})`,
          ...values[0]
        }
    })
    },
    getCommunicationAudanetCEValues (preparedData) {
      return [...new Set(preparedData.reduce((acc, el) => {
        Object.keys(el).filter((key) => key !== 'name').forEach((key) => {
          acc.push(key)
        })
        return acc
      }, []))]
    },
    handleChangeCommunicationAudanetCeType (value) {
      this.loadCommunicationAudanetCeStatistic(value)
      this.communicationAudanetCeStatisticSelectedType = value
    },
  }
}
