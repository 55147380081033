import WebStorage from './components/share/WebStorage'

export default {
  install: function (Vue, options) {
    Vue.prototype.$auth = {}

    Vue.prototype.$auth.isAllowed = function (acl) {
      let securityActions = WebStorage.getSecurityActions()
      let handleActionType = function (service) {
        let actions = securityActions[service] || []
        switch (typeof acl.action) {
          case 'string':
            return actions.includes(acl.action)

          case 'object':
            if (Array.isArray(acl.action)) {
              if (acl.allowOneOfActions) {
                // allow to match some action
                return acl.action.some(action => actions.includes(action))
              } else {
                // by default, check all actions
                return acl.action.every(action => actions.includes(action))
              }
            }
            break
        }
      }

      let handleServiceType = function () {
        switch (typeof acl.service) {
          case 'string':
            return handleActionType(acl.service)
          case 'object':
            if (Array.isArray(acl.service)) {
              if (acl.allowOneOfServices) {
                return acl.service.some(service => {
                  return handleActionType(service)
                })
              } else {
                return acl.service.every(service => {
                  return handleActionType(service)
                })
              }
            }
            break
        }
      }

      let securityActionsIncludeServices = function (aclService) {
        if (typeof aclService === 'string') {
          return securityActions.hasOwnProperty(aclService)
        } else {
          if (acl.allowOneOfServices) {
            return aclService.some(service => securityActions.hasOwnProperty(service))
          } else {
            return aclService.every(service => securityActions.hasOwnProperty(service))
          }
        }
      }

      // actions with unrestricted access
      if (acl.hasOwnProperty('security') && acl.security === false) {
        return true
      }

      if (!acl.hasOwnProperty('service') || !securityActions || !securityActionsIncludeServices(acl.service)) {
        return false
      }
      return handleServiceType()
    }

    Vue.prototype.$auth.getAllowed = function (acl) {
      let tempServices = []
      let services = acl.services
      for (let i = 0; i < services.length; i++) {
        let currentAcl = {}
        currentAcl.service = services[i]
        currentAcl.action = acl.actions

        if (currentAcl.allowOneOfServices) {
          currentAcl.allowOneOfServices = acl.allowOneOfServices
        }

        if (currentAcl.allowOneOfActions || acl.allowOneOfActions) {
          currentAcl.allowOneOfActions = acl.allowOneOfActions
        }

        if (!Vue.prototype.$auth.isAllowed(currentAcl)) {
          continue
        }

        tempServices.push(currentAcl.service)
      }
      return tempServices
    }

    Vue.prototype.$auth.isLogged = function () {
      let token = WebStorage.getToken()
      let securityActions = WebStorage.getSecurityActions()

      if (token && securityActions) {
        return true
      }

      return false
    }

    Vue.prototype.$auth.isAclRoute = function (route) {
      if (route.hasOwnProperty('meta') && route.meta.hasOwnProperty('acl')) {
        return true
      }

      return false
    }
  }
}
