<script>
import BaseListing from './Listing'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      gridName: 'grid_police_units',
      headerTitle: { title: 'Jednoski policji', description: 'Lista' },
      service: 'core',
      actionShowUrlName: 'admin_policeUnit_details',
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'eye', acl: { service: 'core', action: 'get_police_unit' } }
      ]
    }
  }
}
</script>
