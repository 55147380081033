<template>
  <form>
    <div class="form-group checkbox">
      <div class="form-checkbox">
        <input
          :id="'companyCheckbox' + _uid"
          v-model="value['company']"
          :label="$t('modals.newUser.company')"
          type="checkbox"
          name="company"
        >
        <label :for="'companyCheckbox' + _uid" />
      </div>
      <label :for="'companyCheckbox' + _uid">
        Firma
      </label>
    </div>
    <template
      v-for="field in schemaTop"
    >
      <input-type
        v-if="!disabled(field)"
        :key="field.name"
        v-model="value[field.name]"
        v-validate="{rules: field.rules}"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : field.label"
        :error-message="vErrors.first(field.name)"
        :label="field.label"
        :name="field.name"
        :required="field.rules.required"
      />
    </template>
    <div class="form-group">
      <select-type
        v-model="value['voivodeship']"
        v-validate="{ rules: { required: false }}"
        name="voivodeship"
        :label="$t('modals.newUser.voivodeship')"
        :required="false"
        :options="options"
        :empty-first="true"
        data-vv-as="nazwa"
        :error-message="vErrors.first('voivodeship')"
      />
      <input-type
        v-if="!disabled({voivodeship: '17'})"
        v-model="value['country']"
        label="Kraj"
        name="country"
        :error-message="vErrors.first('country')"
      />
    </div>
    <input-type
      v-for="field in schemaBottom"
      :key="field.name"
      v-model="value[field.name]"
      v-validate="{rules: field.rules}"
      :data-vv-value-path="'value'"
      :data-vv-as="field.label"
      :error-message="vErrors.first(field.name)"
      :label="field.label"
      :name="field.name"
      :required="field.rules.required"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'
import SelectType from '../../../../../share/form/type/SelectType'
import Schema from './Schema'
import VoivodeshipMixin from '../../../voivodeship/VoivodeshipMixin'

export default {
  components: {
    InputType,
    SelectType
  },
  mixins: [
    FormModalValidation,
    Schema,
    VoivodeshipMixin
  ],
  props: {
    value: {},
    service: { type: String, required: true }
  },
  methods: {
    disabled (field) {
      if (field.hasOwnProperty('disabled')) {
        return this.value[field.disabled]
      }
      if (field.hasOwnProperty('enabled')) {
        return !this.value[field.enabled]
      }
      if (field.hasOwnProperty('voivodeship')) {
        return parseInt(this.value['voivodeship']) !== parseInt(field.voivodeship)
      }
    }
  }
}
</script>
