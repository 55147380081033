import RouterFrame from "@/components/share/RouterFrame";

export default (service, client, clientLabel, taskCategoryId, categoryName) => {
  return {
    path: `reklamacje/${clientLabel}/`,
    name: "complaints",
    component: RouterFrame,
    meta: {
      params: {
        service: service,
        action: `get_tasks_datatables__default__${categoryName}_${client}`,
        client: client,
        taskCategoryId: taskCategoryId
      },
      menuLink: true,
      acl: {
        action: `get_tasks_datatables__default__${categoryName}_${client}`,
        client: client,
        service: service,
        taskCategoryId: taskCategoryId,
      },
      meta: {
        acl: {
          action: `get_tasks_datatables__default__${categoryName}_${client}`,
          client: client,
          service: service,
          taskCategoryId: taskCategoryId,
        }
      },
      icon: "activity",
      title: clientLabel
    }
  }
}
