<template>
  <ol class="breadcrumb">
    <template
      v-for="(crumb, index) in crumbs"
    >
      <router-link
        v-if="!isLastCrumb(index) && crumb.isClickable"
        :key="crumb.name"
        tag="li"
        :to="crumb"
      >
        <a><i
          v-if="crumb.meta.icon"
          class=""
          :class="`feather-${crumb.meta.icon}`"
        /> <span>{{ getLabel(crumb) }}</span></a>
      </router-link>
      <li
        v-else-if="!crumb.isClickable"
        :key="crumb.name"
        :class="{'router-link-not-active': !crumb.isRoute}"
      >
        <i
          v-if="crumb.meta.icon"
          class=""
          :class="`feather-${crumb.meta.icon}`"
        /> <span>{{ getLabel(crumb) }}</span>
      </li>
      <router-link
        v-else
        :key="crumb.name"
        tag="li"
        :to="crumb"
      >
        <i
          v-if="crumb.meta.icon"
          class=""
          :class="`feather-${crumb.meta.icon}`"
        /> <span>{{ getLabel(crumb) }}</span>
      </router-link>
    </template>
  </ol>
</template>

<script>
import intmilComplaintsAcl from "@/router/complaints/acl";
import intmilTasksAcl from "@/router/service-with-clients_intbwn-intmil/tasks-contractor/intmil/acl";
import intmilAdminAcl from "@/router/service-with-clients_intbwn-intmil/admin-contractor/acl.js";

export default {
  props: {
    loadingText: { type: String, default: () => 'default.loading' },
    root: { type: String, default: 'dashboard' }
  },
  data () {
    return {
      routes: []
    }
  },
  computed: {
    crumbs () {
      return this.routes.map(route => ({
        params: route.params,
        path: route.path,
        name: route.name,
        meta: route.meta,
        isClickable: route.path !== undefined && (route.meta.securityDynamic ? this.$auth.isAllowed(this.getBwnAcl(route)) : this.$auth.isAllowed(route.meta.acl))
      }))
    }
  },
  watch: { '$route': function () { this.updateList() } },
  mounted () {
    this.updateList()
  },
  methods: {
    updateList () {
      this.routes = []
      // add root
      this.routes.push(this.$router.resolve({ name: this.root }).route)
      // find parents
      if (this.$route.meta && this.$route.meta.breadcrumb) {
        this.$route.meta.breadcrumb.list.forEach(linkedRoute => {
          this.routes.push(this.prepareRouteItem(linkedRoute))
        })
      }
      // add self
      if (this.$route.name !== this.root) {
        this.routes.push(this.$route)
      }
    },
    prepareRouteItem (linkedRoute) {
      if (typeof linkedRoute === 'object') {
        return linkedRoute
      } else {
        return this.$router.resolve({ name: linkedRoute, params: this.$route.params }).route
      }
    },
    isLastCrumb (crumbIndex) {
      return crumbIndex === this.crumbs.length - 1
    },
    getLabel (crumb) {
      if (crumb.meta.breadcrumb && crumb.meta.breadcrumb.dynamicLabel) {
        if (this.$store.state.base.breadcrumbLabels[crumb.name] !== undefined) {
          return this.$store.state.base.breadcrumbLabels[crumb.name]
        }
        return this.$t(this.loadingText)
      }
      if (crumb.meta && crumb.meta.acl && crumb.meta.acl.service && this.$isWithClients(crumb.meta.acl.service)) {
        return crumb.meta ? `${this.getClientLabel(crumb.meta.acl.service, crumb.meta.client)} ${this.$t(crumb.meta.title)}` : this.$t(crumb.name)
      } else {
        return crumb.meta ? this.$t(crumb.meta.title) : this.$t(crumb.name)
      }
    },
    getClientLabel (service, clientName) {
      if (service === undefined || clientName === undefined) {
        return ''
      }

      return this.$store.state.base.user.serviceClients[service].find(client => client.id === clientName).label
    },
    getBwnAcl (route) {
      let aclMap = {...intmilComplaintsAcl(route), ...intmilTasksAcl(route), ...intmilAdminAcl(route)}
      return aclMap[route.name]
    }
  }
}
</script>
