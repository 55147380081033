// NOTE: common configuration for modules intmil and intbwn

export default ({ params: { service } }) => {
    let routes = {}
    routes[`${service}_appraisal_state_listing`] = {
      service: service,
      action: `get_workflow_states`
    }
    routes[`${service}_appraisal_state_edit`] = {
      service: service,
      action: `put_workflow_state`
    }
    routes[`${service}_appraisal_workflow_listing`] = {
      service: service,
      action: `get_workflow_transitions`
    }
    routes[`${service}_appraisal_workflow_edit`] = {
      service: service,
      action: `put_workflow_transitions`
    }
    routes[`${service}_appraisal_task_visibility_listing`] = {
      service: service,
      action: `get_task_category_task_visibilities`
    }
    routes[`${service}_appraisal_task_visibility_edit`] = {
      service: service,
      action: `post_task_category_task_visibilities`
    }
    routes[`${service}_appraisal_field_access_listing`] = {
      service: service,
      action: `get_task_category_form_schema_field_visibilities`
    }
    routes[`${service}_appraisal_field_access_edit`] = {
      service: service,
      action: `put_task_category_form_schema_field_visibilities`
    }
    routes[`${service}_appraisal_attachment_category_requirement_listing`] = {
      service: service,
      action: `get_role_attachment_category_requirements`
    }
    routes[`${service}_appraisal_attachment_category_requirement_edit`] = {
      service: service,
      action: `put_attachment_category_requirements`
    }
    return routes
  }
  