<script>
import BaseLisitng from '../../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseLisitng
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Mapa przejść', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_transition',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'name', title: 'Nazwa' },
        { id: 1, data: 'fromState', title: 'Status początkowy' },
        { id: 2, data: 'toState', title: 'Status końcowy' },
        { id: 3, data: 'role', title: 'Rola' }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_appraisal_workflow_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:appraisal:transitionEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/workflows/appraisal/transitions` }
    }
  }
}
</script>
