<template>
  <div :class="classType">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    // Na razie typy: 'primary' , 'warning'
    infoType: { type: String, default: 'primary' }
  },
  computed: {
    classType () {
      return `infobox infobox-${this.infoType}`
    }
  }
}
</script>

<style scoped>

</style>
