// Common
import RouterFrame from "../../components/share/RouterFrame.vue";
// Dashboard
import Dashboard from "../../components/dashboard/Dashboard.vue";
import DashboardContent from "../../components/dashboard/DashboardContent.vue";
import Statistics from "../../components/bundles/statistics/Statistics";
import Calendar from "../../components/share/calendar/Calendar";
// import calculations from './calculations-contractor'

// submenus
import adminCore from "./admin-core";
import adminIntGen from "./admin-intgen";
import tasksIntGenPerson from "./tasks-intgen-person";
import tasksIntGenProperty from "./tasks-intgen-property";
import tasksIntGenVehicle from "./tasks-intgen-vehicle";
import admin from "./admin-contractor/index";
import adminIntVig from "./admin-contractor_intvig/index";
import adminFinanceContractor from "./admin-finance-contractor/index";
import adminExtavi from "./admin-extavi/index";
import tasksVehicle from "./tasks-contractor-vehicle/index";
import tasksExtuniVehicle from "./tasks-extuni-vehicle/index";
import adminExtuni from "./admin-extuni/index";
import tasksExtaviVehicle from "./tasks-extavi-vehicle/index";
import tasksExtaviLife from "./tasks-extavi-life/index";
import Finance from "../../components/bundles/settlements/Listing";

import tasksExtgczVehicle from "./tasks-extgcz-vehicle/index";
import tasksExtgslVehicle from "./tasks-extgsl-vehicle/index";

let extgen = "extgen";
let demofokus = "demofokus";
let extavi = "extavi";
let extpko = "extpko";
let extgenName = "Generali";
let demofokusName = "Fokus demo";
let extaviName = "Allianz";
let extpkoName = "Kredyt";
let intbls = "intbls";
let intvhs = "intvhs";
let intvhsName = "VHS";
let intblsName = "BLS";
let intVig = "intvig";
let intVigName = "VIG CRLS";
const extgsl = "extgsl";
const extgslName = "Generali Slovakia";
// const extgslNameNomralized = "generali-slovakia";
const extgcz = "extgcz";
const extgczName = "Generali Česká pojišťovna";
// const extgczNameNomralized = "generali-ceska-pojistovna";

const extshp = "extshp";
const extshpName = "Fokus";
const extemn = "extemn";
const extemnName = "Emmerson";
const extacn = "extacn";
const extacnName = "Acons";
const extuni = "extuni";
const extuniName = "Uniqa";
const mainFinance = 'finance'
const mainFinanceName = 'Finanse'

export default [
  {
    path: "/",
    component: Dashboard,
    name: "dashboard",
    meta: {
      title: "menu.dashboard.name",
      icon: "pie-chart",
      menuLink: true,
      acl: {
        security: false
      }
    },
    children: [
      {
        path: "",
        component: DashboardContent,
        name: "dashboard",
        meta: {
          acl: {
            security: false
          }
        }
      },
      {
        path: "statystyki",
        component: Statistics,
        name: "statistics",
        meta: {
          title: "Statystyki",
          icon: "activity",
          menuLink: true,
          acl: {
            service: ["intgen", "intbls", "extavi", "extuni", "core", "intmil", "intbwn", "intvig", "intvhs"],
            action: [
              "get_inner_state_task_statistics",
              "get_new_task_statistics",
              "get_closed_task_statistics",
              "get_open_task_statistics",
              "get_inspection_appraisal_statistics",
              "get_inspection_other_appraisal_statistics",
              "get_inspection_online_appraisal_statistics",
              "get_task_states_statistics",
              "get_appraisal_task_per_user_statistics",
              "get_my_completed_tasks_statistics",
              "get_tasks_total_statistics",
              "frontend_acl_appraisal_basic_report",
              "frontend_acl_appraisal_complex_report",
              "get_workload_per_appraiser_statistics",
              "frontend_acl_billing_report",
              "frontend_acl_billing_v2_report",
              "frontend_acl_created_appraisal_report",
              "frontend_acl_monitoring_report",
              "get_presence_report",
              "get_workload_per_assignee_statistics",
              "frontend_acl_new_task_report",
              "frontend_acl_transferred_task_report",
              "frontend_acl_get_open_statistics_plus",
              "get_communication_audanet_ce_statistics",
              "get_user_with_task_in_progress",
              "get_user_with_task_without_appointment",
              "get_tasks_for_assigments",
              "get_order_task_sent_back_statistics",
              "get_task_analyst_verification",
              "get_task_analyst_verification_after_deadline",
              "get_tasks_for_correction",
            ],
            allowOneOfActions: true,
            allowOneOfServices: true
          }
        }
      },
      {
        path: "kalendarz",
        name: "calendar",
        component: Calendar,
        meta: {
          icon: "calendar",
          title: "menu.calendar.name",
          menuLink: true,
          acl: {
            service: [
              "intgen",
              "extgen",
              "extavi",
              "extpko",
              "demofokus",
              "extgsl",
              "extemn",
              "extacn",
              "extgcz",
              "extshp",
              "extuni"
            ],
            action: ["get_booking_dates", "get_business_hours"],
            allowOneOfActions: true,
            allowOneOfServices: true
          }
        }
      },
      {
        path: "rozliczenia",
        name: "settlements",
        component: Finance,
        meta: {
          menuLink: true,
          acl: {
            service: "finance",
            action: [
              "get_settlements_datatables"
            ],
            allowOneOfActions: true
          },
          icon: "activity",
          title: "Rozliczenia"
        }
      },
      {
        path: "reklamacje",
        component: RouterFrame,
        meta: {
          title: "menu.complaints.list.name",
          icon: "list",
          menuTree: true
        },
        children: []
      },
      {
        path: "zlecenia",
        component: RouterFrame,
        meta: {
          title: "menu.main.list.name",
          icon: "list",
          menuTree: true
        },
        children: [
          tasksIntGenPerson,
          tasksIntGenProperty,
          tasksIntGenVehicle,
          tasksVehicle(extgen, extgenName, "main-vehicle"),
          tasksVehicle(demofokus, demofokusName, "main-vehicle"),
          tasksExtgslVehicle(extgsl, extgslName, "main-vehicle"),
          tasksExtgczVehicle(extgcz, extgczName, "main-vehicle"),
          tasksVehicle(extemn, extemnName, "main-vehicle"),
          tasksVehicle(extacn, extacnName, "main-vehicle"),
          tasksExtuniVehicle(extuni, extuniName, "main-vehicle"),
          tasksExtaviLife(extavi, extaviName, "main-property"),
          tasksExtaviVehicle(extavi, extaviName, "main-vehicle"),
          tasksVehicle(extpko, extpkoName, "main-vehicle"),
          tasksVehicle(extshp, extshpName, "main-vehicle")
        ]
      },
      // calculations(),
      adminCore,
      adminFinanceContractor(mainFinance, mainFinanceName),
      adminIntGen,
      admin(extgen, extgenName),
      admin(demofokus, demofokusName),
      admin(extgsl, extgslName),
      adminIntVig(intVig, intVigName),
      admin(extemn, extemnName),
      admin(extacn, extacnName),
      adminExtuni(extuni, extuniName),
      adminExtavi(extavi, extaviName),
      admin(extpko, extpkoName),
      admin(intbls, intblsName),
      admin(intvhs, intvhsName),
      admin(extgcz, extgczName),
      admin(extshp, extshpName)
    ]
  }
];
