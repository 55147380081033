// Common
import RouterFrame from '../../../components/share/RouterFrame.vue'
// Bundles: Internal/Gen
import IntgenVehicleTaskListing from '../../../components/bundles/share/tasksListing/Listing.vue'
import getProperServiceIcon from '../../utils/getProperServiceIcon'

export default function (service, name, taskCategoryLabel, taskCategoryName, clientName = null, clientUuid = null, clientSymbol = null, taskCategoryId ) {
  let taskCategoryNameAction =  taskCategoryName.includes('_') ? taskCategoryName.split('-').join('_') : taskCategoryName
  const routePrefix = process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? `${service}` : `${service}_${taskCategoryNameAction}`
  const aclAction = process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? `get_tasks_datatables__default__${taskCategoryName}_${clientSymbol}` : 'get_tasks'
  // const routeName = taskCategoryLabel + '<<' + taskCategoryId + '>>'
  return {
    path: `${name.toLowerCase()}`,
    component: RouterFrame,
    meta: {
      title: `${name} - ${taskCategoryLabel}`,
      icon: getProperServiceIcon(service),
      menuLink: true,
      meta: {
        acl: {
          service: service,
          action: aclAction,
          client: clientSymbol,
          clientLabel: name,
          clientSymbol: clientSymbol,
          taskCategoryId: taskCategoryId
        }
      }
    },
    children: [
      {
        path: '',
        component: IntgenVehicleTaskListing,
        name: `${routePrefix}_task_listing`,
        meta: {
          title: '',
          acl: {
            service: service,
            action: aclAction,
            client: clientSymbol,
            clientLabel: name,
            clientSymbol: clientSymbol,
            taskCategoryId: taskCategoryId
          }
        }
      }
    ]
  }
}
