export default {
  methods: {
    getGridColumns () {
      let vm = this
      const isAppraisal = (field) => field ? 'appraisal' : 'main'
      const setupClassesOnRow = (row, meta) => {
        meta.settings.aoData[meta.row].nTr.classList = `${isAppraisal(row.parentId)}`
      }
      let schema = {
        extgen: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'POSZKODOWANY' },
          { id: 5, data: 'orderType', title: 'TYP' },
          { id: 6, data: 'contactPhone', title: 'TELEFON' },
          { id: 7, data: 'applicatedAt', title: 'ZGŁOSZENIE' },
          { id: 8, data: 'startedAt', title: 'ROZPOCZĘCIE' },
          { id: 9, data: 'endedAt', title: 'ZAKOŃCZENIE' },
          { id: 10, data: 'icClaimNumber', title: 'NR TU' },
          { id: 11, data: 'registrationNumber', title: 'REJESTRACJA' },
        ],
        demofokus: [
          {
            id: 0,
            data: 'number',
            title: 'pages.taskListing.number',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'pages.taskListing.assignee', sortable: false },
          { id: 2, data: 'state', title: 'pages.taskListing.state' },
          {
            id: 3,
            data: 'createdAt',
            title: 'pages.taskListing.createdAt',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'pages.taskListing.victim' },
          { id: 5, data: 'orderType', title: 'pages.taskListing.orderType' },
          { id: 6, data: 'contactPhone', title: 'pages.taskListing.contactPhone' },
          { id: 7, data: 'applicatedAt', title: 'pages.taskListing.applicatedAt' },
          { id: 8, data: 'startedAt', title: 'pages.taskListing.startedAt' },
          { id: 9, data: 'endedAt', title: 'pages.taskListing.endedAt' },
          { id: 10, data: 'icClaimNumber', title: 'pages.taskListing.icClaimNumber' },
          { id: 11, data: 'registrationNumber', title: 'pages.taskListing.registrationNumber' },
        ],
        extuni: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'contactPhone', title: 'TELEFON' },
          { id: 5, data: 'icClaimNumber', title: 'NR SPRAWY' },
        ],
        extgsl: [
          {
            id: 0,
            data: 'number',
            title: 'pages.taskListing.number',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'pages.taskListing.assignee', sortable: false },
          { id: 2, data: 'state', title: 'pages.taskListing.state' },
          {
            id: 3,
            data: 'createdAt',
            title: 'pages.taskListing.createdAt',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'pages.taskListing.victim' },
          { id: 5, data: 'orderType', title: 'pages.taskListing.orderType' },
          { id: 6, data: 'contactPhone', title: 'pages.taskListing.contactPhone' },
          { id: 7, data: 'applicatedAt', title: 'pages.taskListing.applicatedAt' },
          { id: 8, data: 'startedAt', title: 'pages.taskListing.startedAt' },
          { id: 9, data: 'endedAt', title: 'pages.taskListing.endedAt' },
          { id: 10, data: 'icClaimNumber', title: 'pages.taskListing.icClaimNumber' },
          { id: 11, data: 'registrationNumber', title: 'pages.taskListing.registrationNumber' },
        ],
        extpko: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'POSZKODOWANY' },
          { id: 5, data: 'orderType', title: 'TYP' },
          { id: 6, data: 'contactPhone', title: 'TELEFON' },
          { id: 7, data: 'applicatedAt', title: 'ZGŁOSZENIE' },
          { id: 8, data: 'startedAt', title: 'ROZPOCZĘCIE' },
          { id: 9, data: 'endedAt', title: 'ZAKOŃCZENIE' },
          { id: 10, data: 'bookingDateStartAt', title: 'OGLĘDZINY' }
        ],
        extgcz: [
          {
            id: 0,
            data: 'number',
            title: 'pages.taskListing.number',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'pages.taskListing.assignee', sortable: false },
          { id: 2, data: 'state', title: 'pages.taskListing.state' },
          {
            id: 3,
            data: 'createdAt',
            title: 'pages.taskListing.createdAt',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'pages.taskListing.victim' },
          { id: 5, data: 'orderType', title: 'pages.taskListing.orderType' },
          { id: 6, data: 'contactPhone', title: 'pages.taskListing.contactPhone' },
          { id: 7, data: 'applicatedAt', title: 'pages.taskListing.applicatedAt' },
          { id: 8, data: 'startedAt', title: 'pages.taskListing.startedAt' },
          { id: 9, data: 'endedAt', title: 'pages.taskListing.endedAt' },
          { id: 10, data: 'icClaimNumber', title: 'pages.taskListing.icClaimNumber' },
          { id: 11, data: 'registrationNumber', title: 'pages.taskListing.registrationNumber' },
        ],
        extshp: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'orderType', title: 'TYP' },
          { id: 5, data: 'contactPhone', title: 'TELEFON' },
          { id: 6, data: 'applicatedAt', title: 'ZGŁOSZENIE' },
          { id: 7, data: 'startedAt', title: 'ROZPOCZĘCIE' },
          { id: 8, data: 'endedAt', title: 'ZAKOŃCZENIE' }
        ],
        extemn: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'POSZKODOWANY' },
          { id: 5, data: 'orderType', title: 'TYP' },
          { id: 6, data: 'contactPhone', title: 'TELEFON' },
          { id: 7, data: 'applicatedAt', title: 'ZGŁOSZENIE' },
          { id: 8, data: 'startedAt', title: 'ROZPOCZĘCIE' },
          { id: 9, data: 'endedAt', title: 'ZAKOŃCZENIE' },
          { id: 10, data: 'bookingDateStartAt', title: 'OGLĘDZINY' }
        ],
        extacn: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'POSZKODOWANY' },
          { id: 5, data: 'orderType', title: 'TYP' },
          { id: 6, data: 'contactPhone', title: 'TELEFON' },
          { id: 7, data: 'applicatedAt', title: 'ZGŁOSZENIE' },
          { id: 8, data: 'startedAt', title: 'ROZPOCZĘCIE' },
          { id: 9, data: 'endedAt', title: 'ZAKOŃCZENIE' },
          { id: 10, data: 'bookingDateStartAt', title: 'OGLĘDZINY' }
        ],
        extavi: [
          {
            id: 0,
            data: 'number',
            title: 'NUMER',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'victim', title: 'POSZKODOWANY' },
          { id: 5, data: 'orderType', title: 'TYP' },
          { id: 6, data: 'contactPhone', title: 'TELEFON' },
          { id: 7, data: 'registrationNumber', title: 'REJESTRACJA' },
          { id: 8, data: 'applicatedAt', title: 'ZGŁOSZENIE' },
          { id: 9, data: 'startedAt', title: 'ROZPOCZĘCIE' },
          { id: 10, data: 'endedAt', title: 'ZAKOŃCZENIE' },
          { id: 11, data: 'icClaimNumber', title: 'NR TU' }
        ],
        intbwn: [
          {
            id: 0,
            data: 'number',
            title: 'NR CRLS',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'sentAt', title: 'DATA WYSŁANIA' },
          { id: 4, data: 'submissionedAt', title: 'DATA PRZEKAZANIA' },
          { id: 5, data: 'proposalCreditNumber', title: 'WNIOSEK' },
          { id: 6, data: 'debtor', title: 'KREDYTOBIORCA' },
          { id: 8, data: 'orderType', title: 'RODZAJ ZLECENIA', sortable: false },
          { id: 10, data: 'realEstateAddress', title: 'ADRES NIERUCHOMOŚCI' },
          { id: 10, data: 'sharer', title: 'UDOSTĘPNIAJĄCY' }
        ],
        defaultGrid: [
          {
            id: 0,
            data: 'number',
            title: 'NR CRLS',
            render (data, type, row, meta) {
              setupClassesOnRow(row, meta)
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assigneeUuid', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'icClaimNumber', title: 'NR TU' },
          { id: 5, data: 'orderType', title: 'TYP' },
          { id: 6, data: 'vehicleOwner', title: 'WŁAŚCICIEL' },
          { id: 7, data: 'accidentHappendAt', title: 'ZDARZENIE' },
          { id: 8, data: 'claimPerson', title: 'LIKWIDATOR', sortable: false },
          { id: 9, data: 'registrationNumber', title: 'REJESTRACJA' },
          { id: 10, data: 'policyNumber', title: 'POLISA' }
        ]
      }
      this.gridColumns = schema.hasOwnProperty(this.service) ? schema[this.service] : schema['defaultGrid']
    }
  }
}
