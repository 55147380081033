<template>
  <li
    ref="item"
    class="menu-element"
  >
    <router-link
      :to="{ name: hasChildren ? model.children[0].name : model.name,
      params: (model.meta.meta && model.meta.meta.acl.hasOwnProperty('client'))? { ...model.meta.params, ...model.meta.meta.acl } : {...model.meta.params} }"
      class="link-title-short"
      :class="linkClass"
      :title="$t(model.meta.title)"
      @click.prevent="react"
    >
      <i
        v-if="model.meta.icon"
        class=""
        :class="`feather-${model.meta.icon}`"
      /><span class="menu-element--label">{{ titleShortener }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    model: { type: Object, required: true },
    level: { type: Number, required: true }
  },
  computed: {
    hasChildren () {
      return this.model.children !== undefined && this.model.children.length > 0
    },
    linkClass () {
      return 'link-level-' + this.level
    },
    titleShortener () {
      if (this.model.meta.title) {
        let title = this.$t(this.model.meta.title)
        if (title.includes(' ')) {
          let titleWords = title.split('-')
          return title.length > 22 ? title.split('').filter((letter, index) => index < 11).join('') + '... - ' + titleWords[titleWords.length - 1] : title
        } else {
          return title
        }
      } else {
        return ''
      }
    }
  },
  mounted () {
    if (this.model.name === 'dashboard') {
      this.activate()
    }
  },
  methods: {
    react () {
      this.activate()
      // this.redirect()
    },
    activate () {
      let sidebar = document.querySelector('.sidebar')
      let activeEl = sidebar.querySelector('.active')
      if (activeEl !== null) {
        activeEl.classList.remove('active')
      }
      this.$refs.item.classList.add('active')

      this.$emit('childOpened', this)
    }
  }
}
</script>
<style scoped>
.link-title-short {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
