import role from './role'
import person from './person'
import online from './calendar/online'
import visibility from './calendar/visibility'
import RouterFrame from '../../../components/share/RouterFrame.vue'
// import mainPerson from './main-person'
import mainProperty from './main-property'
import mainVehicle from './main-vehicle'
import clients from './clients'
import getProperServiceIcon from '../../utils/getProperServiceIcon'
import headers from './task-header'

const getWithClientsMenu = function (service, name, routes) {
  return process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service) ? routes(service, name) : []
}
export default function (service, name) {
  return {
    path: `administracja-procesami-${name}`,
    component: RouterFrame,
    meta: {
      title: name,
      icon: getProperServiceIcon(service),
      menuTree: true,
      acl: {
        service: service,
        action: '==ACL=='
      }
    },
    children: [
      getWithClientsMenu(service, name, clients),
      role(service, name),
      person(service, name),
      online(service, name),
      // getWithClientsMenu(service, name, mainPerson),
      visibility(service, name),
      headers(service, name),
      getWithClientsMenu(service, name, mainProperty),
      mainProperty(service, name),
      mainVehicle(service, name),
    ]
  }
}
