import AdminCalendarBusinessHoursListing from '../../../../../components/bundles/intgen/admin/businessHours/Listing.vue'
import RouterFrame from '../../../../../components/share/RouterFrame.vue'

export default function (service, name) {
  return {
    path: `kalendarz-${name.toLowerCase()}-ustawienia-online`,
    component: RouterFrame,
    meta: {
      title: "menu.calendarSettings",
      icon: "calendar",
      menuLink: true,
      acl: {
        service: service,
        action: "get_business_hours"
      }
    },
    children: [
      {
        path: "",
        component: AdminCalendarBusinessHoursListing,
        name: `${service}_calendar_businessHours_listing`,
        meta: {
          title: "Ustawienia online",
          acl: {
            service: service,
            action: "get_business_hours"
          }
        }
      }
    ]
  };
}
