<template>
  <aside class="sidebar">
    <section class="sidebar--menu main-menu">
      <sidebar-menu />
    </section>
    <section
      v-show="secondMenu"
      class="sidebar--menu second-menu"
    >
      <hr class="menu-separator">
      <second-menu @secondMenuUpdate="showSecondMenu" />
    </section>
    <section
      v-show="thirdMenu"
      ref="thirdMenu"
      class="sidebar--menu third-menu"
    >
      <hr class="menu-separator">
      <third-menu @thirdMenuUpdate="showThirdMenu" />
    </section>
    <section
      ref="search"
      class="sidebar-search"
    >
      <!--<div>-->
      <!--<input type="text" id="menu-search" name="menu-search" placeholder="Szukaj w systemie"/>-->
      <!--<i class="fa fa-search menu-search-icon" ref="searchIcon" @click="expand"></i>-->
      <!--</div>-->
    </section>
  </aside>
</template>

<script>
import SidebarMenu from './menu/Menu'
import SecondMenu from './menu/SecondMenu'
import ThirdMenu from './menu/ThirdMenu'

export default {
  components: {
    SidebarMenu,
    SecondMenu,
    ThirdMenu
  },
  data () {
    return {
      secondMenu: true,
      thirdMenu: true,
    }
  },
  methods: {
    expand () {
      let body = document.body
      if (body.classList.contains('sidebar-collapse')) {
        body.classList.remove('sidebar-collapse')
      }
    },
    showSecondMenu (items) {
      if (items.length > 0) {
        this.secondMenu = true
      } else {
        this.secondMenu = false
      }
    },
    showThirdMenu (items) {
      if (items.length > 0) {
        this.thirdMenu = true
        if (this.secondMenu === false) {
          this.$refs.thirdMenu.classList.add('second-menu')
          this.$refs.thirdMenu.classList.remove('third-menu')
        } else {
          this.$refs.thirdMenu.classList.add('third-menu')
          this.$refs.thirdMenu.classList.remove('second-menu')
        }
      } else {
        this.thirdMenu = false
      }
    }
  }
}
</script>
