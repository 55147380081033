<template>
  <div class="row">
    <input
      v-if="value.id"
      :id="'id_' + value.id"
      type="hidden"
      :value="value.id"
    >
    <div class="col-sm-6">
      <div>
        <label for="state">Stan</label>
        <select
          id="state"
          class="form-control"
          :value="value.state.id"
          @input="updateState($event.target.value)"
        >
          <option
            v-for="option in states"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-sm-6">
      <div>
        <label for="task-visibility">Poziom widoczności</label>
        <select
          id="task-visibility"
          class="form-control"
          :value="value.visibility"
          @input="updateVisibility($event.target.value)"
        >
          <option
            v-for="option in visibilities"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'

Vue.use(VueFormGenerator)

export default {
  props: {
    value: { type: Object, required: true },
    states: { type: Array, required: true },
    visibilities: { type: Array, required: true }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateState (value) {
      this.value.state.id = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateVisibility (value) {
      this.value.visibility = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
    width: 100%;
}
</style>
