// Common
import RouterFrame from '../../../components/share/RouterFrame.vue'
// submenus
import role from './role'
import person from './person'
import online from './calendar/online'
import visibility from './calendar/visibility'
import mainVehicle from './main-vehicle'
import mainPerson from './main-person'
import mainProperty from './main-property'
import appraisal from './appraisal'
import headers from './task-header'
import groupUser from './group-user'

export default {
  path: 'administracja-procesami-generalicrls',
  component: RouterFrame,
  meta: {
    title: 'Generali CRLS',
    icon: 'generali',
    menuTree: true,
    acl: {
      service: 'intgen',
      action: '==ACL=='
    }
  },
  children: [
    role,
    person,
    online('intgen', 'GeneraliCRLS'),
    visibility('intgen', 'GeneraliCRLS'),
    headers('intgen', 'GeneraliCRLS'),
    groupUser,
    mainPerson,
    mainProperty,
    mainVehicle,
    appraisal
  ]
}
