import api from '../../../api'
import errorReporter from '../utils/errorReporter'

export default {
  exitAttachments: ({dispatch, commit, state}) => {
    commit('SET_ATTACHMENTS_STATE', {})
    commit('SET_CATEGORIES', [])
    commit('SET_REQUIRED_CATEGORIES', [])
    commit('SET_DOWNLOADED_FILES', [])
    commit('SET_CURRENTLY_DOWNLOADED_FILE', [])
    commit('SET_NUMBER_OF_FILES_TO_DOWNLOAD', 0)
  },
  sendToAudanet: ({dispatch, commit, state}, {service, taskId, $notify, isWithClients}) => {
    commit('SET_SENDING_TO_AUDANET', true)
    let url = isWithClients ? `/tasks/${taskId}/attachments` : `/api/1.0/audanet/tasks/${taskId}/attachment`
    api.request(service, 'post', url)
    .then((response) => {
      commit('SET_SENDING_TO_AUDANET', false)
      $notify({
        type: 'success',
        text: 'Pliki zostały pomyślnie wysłane'
      })
    })
    .catch((error) => {
      errorReporter.reportError(error)
      console.log(error.response.data.message)
      commit('SET_SENDING_TO_AUDANET', false)
      if (error.response.data.message) {
        $notify({
          type: 'error',
          duration: -1,
          text: error.response.data.message
        })
        return
      }
      $notify({
        type: 'error',
        duration: -1,
        text: 'Wystąpił błąd podczas wysyłania plików'
      })
    })
  },
  generatePDF: ({dispatch, commit, state}, {service, taskId, $notify, isWithClients}) => {
    console.log('generatePDF started')
    let url = `/pdf/generate/${taskId}`
    api.request(service, 'post', url)
      .then((response) => {
        $notify({
          type: 'success',
          text: 'Plik został pomyślnie wygenerowany'
        })
        document.querySelector('#tab-header-formularz > a').click()
      })
      .catch((error) => {
        errorReporter.reportError(error)
        if (error.response.data.message) {
          $notify({
            type: 'error',
            duration: -1,
            text: error.response.data.message
          })
          return
        }
        $notify({
          type: 'error',
          duration: -1,
          text: 'Wystąpił błąd podczas generowania pliku'
        })
      })
  },
  mergePDF: ({dispatch, commit, state}, {service, taskId, $notify, isWithClients}) => {
    console.log('mergePDF started')
    let url = `/pdf/merge/${taskId}`
    api.request(service, 'post', url)
      .then((response) => {
        $notify({
          type: 'success',
          text: 'Pliki zostały pomyślnie połączone'
        })
        document.querySelector('#tab-header-formularz > a').click()
      })
      .catch((error) => {
        errorReporter.reportError(error)
        if (error.response.data.message) {
          $notify({
            type: 'error',
            duration: -1,
            text: error.response.data.message
          })
          return
        }
        $notify({
          type: 'error',
          duration: -1,
          text: 'Wystąpił błąd podczas łączenia plików'
        })
      })
  }
}
