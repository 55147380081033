export default {
  install: function (Vue, options) {
    Vue.prototype.$isWithClients = function (service) {
      return process.env.VUE_APP_SERVICES_WITH_CLIENTS.includes(service)
    }
    Vue.prototype.$getClient = function (service, clientId) {
      return this.$store.state.base.user.serviceClients[service].find(client => parseInt(client.id) === parseInt(clientId))
    }
  }
}
