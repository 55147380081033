
<script>
import BaseEdit from '../../../../share/admin/state/Edit'

export default {
  mixins: [
    BaseEdit
  ],
  data () {
    return {
      events: null,
      headerTitle: { title: 'Stany procesu', description: 'Tryb edycji' },
      service: this.$route.meta.acl.service,
      workflowName: 'appraisal',
      loginModalBackUrlName: ''
    }
  },
  created () {
    this.getInitEvents()
    this.getInitLoginModalBackUrlName()
  },
  methods: {
    getInitEvents () {
      this.events = {
        stateSubmit: `${this.service}:appraisal:stateSubmit`
      }
    },
    getInitLoginModalBackUrlName () {
      this.loginModalBackUrlName = `${this.service}_appraisal_state_listing`
    }
  }
}
</script>
