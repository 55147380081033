export default {
  computed: {
    $additionalParams () {
      if (this.$isWithClients(this.service)) {
        let client = this.$store.state.base.user.serviceClients[this.service].find(client => parseInt(client.id) === parseInt(this.client))

        let data = {
          name: this.gridName,
          client: this.client,
          clientUuid: client.uuid,
          type: this.$route.meta.taskType
        }
        data[this.$route.meta.taskType] = true
        return data
      }
      return false
    }
  },
}
