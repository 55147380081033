<script>
import BaseListing from '../../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Widoczność pól', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_appraisal_task_field_visibility',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'field', title: 'Pole' },
        { id: 1, data: 'fieldGroup', title: 'Grupa' },
        { id: 2, data: 'state', title: 'Stan' },
        { id: 3, data: 'role', title: 'Rola' },
        { id: 4, data: 'visibility', title: 'Poziom dostępu' }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_appraisal_field_access_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:appraisal:fieldVisibilityEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: '/schemas/appraisal/field-visibility' }
    }
  }
}
</script>
