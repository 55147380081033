import api from '../../../api'
import errorReporter from '../utils/errorReporter'
import urlsConfig from './urlsConfig/index'
import i18n from '../../../translations/internationalisation'

export default {
  loadCategories: async ({dispatch, commit, state}, {service, $events, $notify}) => {
    try {
      const method = urlsConfig.getLoadCategoriesRequest(service).method
      const url = urlsConfig.getLoadCategoriesRequest(service).url

      let response = await api.request(service, method, url)

      let data = [{id: null, name: 'pages.taskDetails.attachments.withoutCategory'}]
      response.data.forEach(el=> data.push(el))

      commit('SET_CATEGORIES', data)
      $events.$emit('attachment-categories-loaded')

    } catch(error) {
      errorReporter.reportError(error)
      console.error(error)
      $notify({
        type: 'error',
        title: i18n.t('error.default'),
        text: i18n.t('pages.taskDetails.attachments.categoriesLoadError')
      })
      throw error
    }
  },
  loadAssignedCategories ({dispatch, commit, state}, {service, $events, $notify}) {
    let uuids = Object.keys(state.attachments)

    let search = {
      filesUuids: uuids
    }
    const method = urlsConfig.getLoadAssignedCategoriesRequest(service).method
    const url = urlsConfig.getLoadAssignedCategoriesRequest(service).url

    api.request(service, method, url, search)
    .then((response) => {
      commit('SET_ASSIGNED_CATEGORIES', response.data)
      commit('SET_LOADING_ATTACHMENTS', false)
      commit('SET_UPDATING_ATTACHMENTS', false)
    })
    .catch((error) => {
      errorReporter.reportError(error)
      console.error(error)
      $notify({
        type: 'error',
        title: i18n.t('error.default'),
        text: i18n.t('pages.taskDetails.attachments.attachmentCategoriesLoadError')
      })
    })
  },
  getCategoryRequirements: ({dispatch, commit, state}, {service, taskType, id, $notify}) => {
    const method = urlsConfig.getCategoryRequirementsRequest(service, taskType, id).method
    const url = urlsConfig.getCategoryRequirementsRequest(service, taskType, id).url

    api.request(service, method, url)
    .then((response) => {
      commit('SET_REQUIRED_CATEGORIES', response.data)
    })
    .catch((error) => {
      errorReporter.reportError(error)
      console.error(error)
      $notify({
        type: 'error',
        title: i18n.t('error.default'),
        text: i18n.t('pages.taskDetails.attachments.attachmentCategoriesRequirementsLoadError')
      })
    })
  },
  sendAttachmentCategory: ({dispatch, commit, state}, {data, service, $notify, categoryId, task, upload}) => {
    if (!upload) commit('SET_CHANGING_CATEGORY', true)
    if(!categoryId && !upload) {
      $notify({
        type: 'info',
        text: i18n.t('pages.taskDetails.attachments.attachmentCategoryChangeNotAllowed')
      })
      commit('SET_CHANGING_CATEGORY', false)
      return
    }
    let category = []
    if (!upload) category.push(categoryId)
    let dataToSend = {
      uuid: data.id,
      task: task,
      categories: category
    }
    const method = urlsConfig.getSendAttachmentCategoryRequest(service).method
    const url = urlsConfig.getSendAttachmentCategoryRequest(service).url

    api.request(service, method, url, dataToSend)
    .then((response) => {
      if (state.attachments.hasOwnProperty(data.uuid) && !upload) {
        commit('SET_ATTACHMENT_CATEGORY', {attachmentId: data.uuid, categoryId: categoryId})
      }
      if(!upload) {
        $notify({
          type: 'success',
          text: i18n.t('pages.taskDetails.attachments.attachmentCategoryChangeSuccess')
        })
      }
      if (!upload) commit('SET_CHANGING_CATEGORY', false)
    })
    .catch((error) => {
      errorReporter.reportError(error)
      console.error(error)
      if(!upload) {
        $notify({
          type: 'error',
          text: i18n.t('pages.taskDetails.attachments.attachmentCategoryChangeError')
        })
      }
      if (!upload) commit('SET_CHANGING_CATEGORY', false)
    })
  },
}
