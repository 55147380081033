import {
  DEFAULT_WIDTH, RESIZE_QUALITY,
  TARGET_FORM
} from './constants'
import ExifRestore
  from './ExifRestorer'
import EXIF from 'exif-js'
import imageAssertions from './IsImage'
import errorReporter from './errorReporter'
import fileUtil from '../utils/file'

export default {
  readFile ({reader, file, commit, $notify, dispatch, service, filePath, filePaths, canEditAttachments, taskId, fileRef}) {
    reader = new FileReader()
    let uuid = fileUtil.uuidv4()

    reader.onload = function (event) {
      let value = ''
      if (!imageAssertions.isImage(file.type) || file.type === 'image/svg+xml' || file.type ==='image/tiff') {
        value = event.target.result
        dispatch('sendSingleToBackend', {
          uuid,
          data: {
            id: uuid,
            filename: file.name,
            mimeType: file.type,
            value: value,
            category: null
          },
          service,
          filePath,
          filePaths,
          canEditAttachments,
          $notify,
          taskId,
          fileRef
        })
      } else {
        try {
          fileUtil.processImage (event, file, {uuid, commit, dispatch, service,
            filePath,
            filePaths,
            canEditAttachments,
            $notify,
            taskId,
            fileRef })
        } catch (e) {
          commit('SET_UPLOADING_ATTACHMENTS', false)
          errorReporter.reportError(e)
          throw e
        }
      }
    }
    reader.onerror = function (event) {
      errorReporter.reportError(['error reading image to File Reader Api', event])
      commit('INCREASE_SENDING_COUNT')
      commit('ADD_SENT_FAILED', file.name)
      reader.abort()
    }

    reader.readAsDataURL(file)
  },
  uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0
      let v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  },
  b64toBlob (b64Data, contentType = '', sliceSize = 512) {
    try {
      let byteCharacters = atob(b64Data)
      let byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, { type: contentType })
    } catch (e) {
      errorReporter.reportError(['error parsing base64 data to file', e])
      throw e
    }
  },
  getImageWidth (type = 'max', categoryIds = null, allCategories) {
    let categories = {}
    if (categoryIds !== null) {
      for (let catId in allCategories) {
        if (categoryIds.includes(catId)) {
          categories[catId] = allCategories[catId]
        }
      }
    } else {
      categories = allCategories
    }

    let width = type === 'max' ? 0 : DEFAULT_WIDTH

    for (let catId in categories) {
      if (categories[catId].imageMaxWidth !== null) {
        width = Math[type](width, categories[catId].imageMaxWidth)
      }
    }

    return width
  },
  processImage (event, file, {uuid, commit, dispatch, service,
    filePath,
    filePaths,
    canEditAttachments,
    $notify,
    taskId,
    fileRef,
    rotateMode,
    edit }) {
      let context = this
      let value = ''
      let img = new Image()
      img.src = event.target.result
      img.onload = function () {
        value = context.resize(file, img, TARGET_FORM, undefined, rotateMode)
        let data = {
          uuid,
          data: {
            id: uuid,
            filename: file.name,
            mimeType: file.type,
            value: value,
            category: file.category || null
          },
          service,
          filePath,
          filePaths,
          canEditAttachments,
          $notify,
          taskId,
          fileRef,
          categoryId: file.category || null,
        }
        edit ? data.edit = edit : null
        dispatch('sendSingleToBackend', data)
        commit('ADD_PROCESSING_IMAGES_PROGRESS', uuid)
      }
      img.onerror = function (event) {
        commit('INCREASE_SENDING_COUNT')
        commit('ADD_SENT_FAILED', file.name)
        $notify({
          type: 'error',
          duration: -1,
          text: `Plik obrazu ${file.name} jest uszkodzony.`
        })
        errorReporter.reportError(['error loading image to img.src', event])
      }
  },
  resize (file, img, target, allCategories, rotateMode) {
    let imageWidth = this.getImageWidth('max', null, allCategories)
    if (target === TARGET_FORM) {
      imageWidth = this.getImageWidth('min', file.categories, allCategories)
    }

    let resizeInfo = this.getResizeInfo(img, imageWidth)

    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')

    canvas.width = resizeInfo.trgWidth
    canvas.height = resizeInfo.trgHeight

    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/tiff' ||
      file.type === 'image/x-tiff'
    ) {
      this.transform(img, canvas, ctx, resizeInfo, rotateMode)
    }

    this.drawImageIOSFix(
      ctx,
      img,
      resizeInfo.srcX != null ? resizeInfo.srcX : 0,
      resizeInfo.srcY != null ? resizeInfo.srcY : 0,
      resizeInfo.srcWidth,
      resizeInfo.srcHeight,
      resizeInfo.trgX != null ? resizeInfo.trgX : 0,
      resizeInfo.trgY != null ? resizeInfo.trgY : 0,
      resizeInfo.trgWidth, resizeInfo.trgHeight
    )

    let resizedImageValue = canvas.toDataURL(file.type, RESIZE_QUALITY)
    resizedImageValue = ExifRestore.restore(img.src, resizedImageValue)
    return resizedImageValue
  },
  transform (img, canvas, ctx, resizeInfo, rotateMode) {
      let orientation = EXIF.getTag(img, 'Orientation')
    if(rotateMode) {
      orientation = rotateMode
    }

      if (orientation > 4) {
        canvas.width = resizeInfo.trgHeight
        canvas.height = resizeInfo.trgWidth
      }

      switch (orientation) {
        case 2:
          // horizontal flip
          ctx.translate(canvas.width, 0)
          ctx.scale(-1, 1)
          break
        case 3:
          // 180° rotate left
          ctx.translate(canvas.width, canvas.height)
          ctx.rotate(Math.PI)
          break
        case 4:
          // vertical flip
          ctx.translate(0, canvas.height)
          ctx.scale(1, -1)
          break
        case 5:
          // vertical flip + 90 rotate right
          ctx.rotate(0.5 * Math.PI)
          ctx.scale(1, -1)
          break
        case 6:
          // 90° rotate right
          ctx.rotate(0.5 * Math.PI)
          ctx.translate(0, -canvas.width)
          break
        case 7:
          // horizontal flip + 90 rotate right
          ctx.rotate(0.5 * Math.PI)
          ctx.translate(canvas.height, -canvas.width)
          ctx.scale(-1, 1)
          break
        case 8:
          // 90° rotate left
          ctx.rotate(-0.5 * Math.PI)
          ctx.translate(-canvas.height, 0)
          break
      }
  },
  /**
   * Those values are going to be used by `ctx.drawImage()`.
   */
  getResizeInfo (file, width, height = null) {
    let info = {
      srcX: 0,
      srcY: 0,
      srcWidth: file.width,
      srcHeight: file.height
    }

    let srcRatio = file.width / file.height

    if (width !== null && srcRatio < 1) {
      height = width
      width = null
    }

    // Automatically calculate dimensions if not specified
    if (width == null && height == null) {
      width = info.srcWidth
      height = info.srcHeight
    } else if (width == null) {
      width = height * srcRatio
    } else if (height == null) {
      height = width / srcRatio
    }

    // Make sure images aren't upscaled
    width = Math.min(width, info.srcWidth)
    height = Math.min(height, info.srcHeight)

    var trgRatio = width / height

    if (info.srcWidth > width || info.srcHeight > height) {
      if (srcRatio > trgRatio) {
        height = width / srcRatio
      } else {
        width = height * srcRatio
      }
    }

    info.srcX = (file.width - info.srcWidth) / 2
    info.srcY = (file.height - info.srcHeight) / 2

    info.trgWidth = width
    info.trgHeight = height

    return info
  },
  detectVerticalSquash (img) {
    let ih = img.naturalHeight
    let canvas = document.createElement('canvas')
    canvas.width = 1
    canvas.height = ih
    let ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)

    let _ctx$getImageData = ctx.getImageData(1, 0, 1, ih)
    let data = _ctx$getImageData.data

    // search image edge pixel position in case it is squashed vertically.
    let sy = 0
    let ey = ih
    let py = ih
    while (py > sy) {
      let alpha = data[(py - 1) * 4 + 3]

      if (alpha === 0) {
        ey = py
      } else {
        sy = py
      }

      py = ey + sy >> 1
    }
    let ratio = py / ih

    if (ratio === 0) {
      return 1
    } else {
      return ratio
    }
  },
  // A replacement for context.drawImage
  // (args are for source and destination).
  drawImageIOSFix (ctx, img, sx, sy, sw, sh, dx, dy, dw, dh) {
    let vertSquashRatio = this.detectVerticalSquash(img)
    return ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh / vertSquashRatio)
  },
  downloadBlob (blob, filename) {
    let a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  base64toBlob (b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    let byteCharacters = atob(b64Data)
    let byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)

      let byteNumbers = new Array(slice.length)
      for (let c = 0; c < slice.length; c++) {
        byteNumbers[c] = slice.charCodeAt(c)
      }

      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }
}
