import api from '../../../../../api'
import DynamicBreadcrumb from '../../mixins/DynamicBreadcrumb'
import PersonEditModal from './modal/PersonEdit'
import Page from '../../../../share/content/Page'

export default {
  template: `
    <div>
      <section class="content">
        <div class="row">
          <div class="col-sm-12">
            <div class="box box-default box-solid box-component">
              <div class="box-body box-details-header">
                <div class="details-header">
                  <div class="details-header--avatar">
                    <div id="imgframe">
                      <img id="avatarImage" class="img-responsive img-thumbnail" />
                    </div>
                  </div>
                  <div class="details-header--details">
                    <h2>{{$t('modals.newUser.personalData')}}</h2>
                    <!--<h2>{{$t('modals.newUser.companyDetails')}}</h2>  wartość ustawiana za pomocą vue-->
                    <ul class="list-unstyled">
                      <li><label>{{$t('modals.newUser.company')}}:</label> {{ person.name }}</li>
                      <li><label>{{$t('modals.newUser.name')}}:</label> {{ person.forename }}</li>
                      <li><label>{{$t('modals.newUser.surname')}}:</label> {{ person.surname }}</li>
                      <li><label>{{$t('modals.newUser.pesel')}}:</label> {{ person.pesel }}</li>
                      <li><label>{{$t('modals.newUser.nip')}}:</label> {{ person.nip }}</li>
                    </ul>
                  </div>
                  <div class="details-header--details">
                    <h2>{{$t('modals.newUser.contactAddress')}}</h2>
                    <ul class="list-unstyled">
                      <li><label>{{$t('modals.newUser.phone')}}:</label> {{ person.phone }}</li>
                      <li><label>{{$t('modals.newUser.email')}}:</label> {{ person.email }}</li>
                      <li><label>{{$t('modals.newUser.street')}}:</label> {{ person.street }}</li>
                      <li><label>{{$t('modals.newUser.postCode')}}:</label> {{ person.postcode }}</li>
                      <li><label>{{$t('modals.newUser.city')}}:</label> {{ person.city }}</li>
                      <li><label>{{$t('modals.newUser.voivodeship')}}:</label> {{ voivodeshipLabel }}</li>
                      <li><label>{{$t('modals.newUser.country')}}:</label> {{ person.country }}</li>
                    </ul>
                  </div>
                  <div class="details-header--status">
                    <ul class="list-unstyled">
                      <li v-if="person.company"><span class="status-tag info">Firma</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Modals -->
      <person-edit-modal
        :show="personEditVisible"
        @close="personEditVisible = false"
        :formData="person"
        :service="service"
        @success="loadContent()"
        >
      </person-edit-modal>
    </div>
  `,
  components: {
    PersonEditModal
  },
  mixins: [DynamicBreadcrumb, Page],
  data() {
    return {
      headerTitle: { title: "Poszkodowani", description: "Szczegóły" },
      personEditVisible: false,
      actionEditName: "person_edit",
      urlActions: {
        getContent: { method: "get", url: `/person/${this.$route.params.id}` }
      },
      person: {
        company: false,
        name: "",
        forename: "",
        surname: "",
        pesel: "",
        nip: "",
        phone: "",
        email: "",
        street: "",
        city: "",
        postcode: "",
        voivodeship: "",
        country: ""
      },
      voivodeshipLabel: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadContent();
    });
  },
  methods: {
    editPerson() {
      this.personEditVisible = true;
    },
    loadContent() {
      this.toggleLoading();

      api
        .request(
          this.service,
          this.urlActions.getContent.method,
          this.urlActions.getContent.url
        )
        .then(response => {
          this.toggleLoading();

          this.person.company = response.data.person.company;
          this.person.name = response.data.person.name;
          this.person.forename = response.data.person.forename;
          this.person.surname = response.data.person.surname;
          this.person.pesel = response.data.person.pesel;
          this.person.nip = response.data.person.nip;
          this.person.phone = response.data.person.phone;
          this.person.email = response.data.person.email;

          if (response.data.person.address) {
            this.person.street = response.data.person.address.street;
            this.person.city = response.data.person.address.city;
            this.person.postcode = response.data.person.address.postcode;
            this.person.voivodeship =
              response.data.person.address.voivodeship.id;
            this.person.country = response.data.person.address.country;
            this.voivodeshipLabel =
              response.data.person.address.voivodeship.label;
          }

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(
            this.$route.name,
            this.person.company
              ? this.person.name
              : this.person.forename + " " + this.person.surname
          );

          this.dataLoaded = true;
        })
        .catch(() => {
          this.toggleLoading();
        });
    },
    actionRun(urlAction, params = null) {
      api
        .request(this.service, urlAction.method, urlAction.url, params)
        .then(() => {
          this.toggleLoading();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$t("error.default")
          });
        });
    }
  }
};
