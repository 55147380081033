<template>
  <form>
    <div>
      <div class="row">
        <InfoBox v-if="value['importTypeToSend']">
          <span>{{ getInfoText }}</span>
        </InfoBox>
        <select-type
          v-model="value['importTypeToSend']"
          class="col-xs-12"
          name="importType"
          label="Rodzaj zlecenia"
          :required="true"
          :options="importTypes"
          :empty-first="false"
        />
      </div>
      <div class="file-input-container">
        <button @click="clickInput" class="zord-btn btn zord-btn-primary btn-primary select-file-button" :class="{'btn-success': file.name}">Wybierz plik</button>
        <label class="file-input-label">
          <input
            style="display: none"
            id="file"
            ref="file"
            type="file"
            @change="handleFileUpload"
          >
          <span v-if="file">{{file.name}}</span>
        </label>
      </div>
      <blocking-loader
        :show="getLoadingFinanceImport"
      >
        Trwa import dokumentów finansowych
      </blocking-loader>
    </div>
  </form>
</template>

<script>
  import FormModalValidation from '../../../../share/form/FormModalValidation';
  import Loader from '../../../../share/Loader';
  import SelectType from '../../../../share/form/type/SelectType';
  import InfoBox from '../../../../share/InfoText/InfoBox';
  import BlockingLoader from '../../../../share/BlockingLoader';
  import { mapGetters } from "vuex";

  export default {
    components: {
      InfoBox,
      SelectType,
      BlockingLoader
    },
    mixins: [
      FormModalValidation,
      Loader
    ],
    props: {
      value: {}
    },
    data () {
      return {
        importTypes: [],
        info: {},
        file: {}
      }
    },
    computed: {
    ...mapGetters(['getLoadingFinanceImport']),
      getInfoText () {
        let columnsMap = {
          default: {
            main: ['C', 'D'],
            appraisal: ['B', 'J']
          },
          intbls: {
            main: ['A', 'G'],
            main_vehicle: ['C', 'G'],
            main_property: ['C', 'G'],
            appraisal: ['B', 'J']
          }
        }

        if (this.$isWithClients(this.$route.meta.acl.service)) {
          return `Zestawienie musi zawierać numer zlecenia w kolumnie "${columnsMap.intbls[this.value.importTypeToSend][0]}" oraz nową kwotę faktury w kolumnie "${columnsMap.intbls[this.value.importTypeToSend][1]}".`
        } else {
          if (this.value.importTypeToSend === 'main' ) {
            return `Zestawienie musi zawierać numer szkody w kolumnie "C", rodzaj oględzin w kolumnie "D" oraz nową kwotę faktury w kolumnie "U".`
          } else {
            return `Zestawienie musi zawierać numer zlecenia w kolumnie "${columnsMap.default[this.value.importTypeToSend][0]}" oraz nową kwotę faktury w kolumnie "${columnsMap.default[this.value.importTypeToSend][1]}".`
          }
        }
      }
    },
    created () {
      this.getImportTypes()
    },
    methods: {
      clickInput(e) {
        e.preventDefault()
        this.$refs.file.click()
      },
      getImportTypes () {
        if (this.$isWithClients(this.$route.meta.acl.service)) {
          // TODO: avivaClaimNumber make common condition
          if (['intbls', 'intvig'].includes(this.$route.meta.acl.service) && (this.$route.meta.client === '1' || this.$route.meta.client === 1)) {
            let client = {label: ''}
            if (this.$store.state.base.user.serviceClients.hasOwnProperty(this.$route.meta.acl.service)) {
              client = this.$store.state.base.user.serviceClients[this.$route.meta.acl.service].find(client => parseInt(client.id) === parseInt(this.$route.meta.client))
            }
            this.importTypes = [
              { label: `Import zlecenia ${client.label}`, value: `${this.$route.meta.workflowName}` },
              { label: 'Import zlecenia CRLS', value: 'main' },
              { label: 'Import Podzlecenia', value: 'appraisal' }
            ]
          } else {
            this.importTypes = [
              { label: 'Import zlecenia głównego', value: 'main' },
              { label: 'Import Podzlecenia', value: 'appraisal' }
            ]
          }
        } else {
          this.importTypes = [
            { label: 'Import Generali', value: 'main' },
            { label: 'Import Podzlecenia', value: 'appraisal' }
          ]
        }
      },
      handleFileUpload (e) {
        if (this.$refs.file.files[0]) {
          this.value.file = this.$refs.file.files[0]
          this.file = this.$refs.file.files[0]
        } else {
          this.value.file = {}
          this.file = {}
        }

      }
    }
  }
</script>
<style scoped>
  .select-file-button {
    width: 100px;
    height: 30px;
    margin: 5px;
  }
  .file-input-container {
    display: flex;
  }
  .file-input-label {
    padding: 10px 0 0 0;
  }
</style>
