import BaseModal from './BaseModal'
import LoginForm from '../form/Login.vue'

export default {
  template: `
    <modal title="Logowanie"
           v-model="isVisible"
           size="sm"
           :backdrop="false"
    >
      <login-form v-model="model"></login-form>
      <div slot="footer">
        <button @click="() => close('cancel')" class="btn zord-btn zord-btn-secondary btn-secondary">Anuluj</button>
        <button @click="() => close('ok')" type="primary" class="btn zord-btn zord-btn-primary btn-primary">Zaloguj</button>
      </div>
    </modal>
  `,
  components: {
    LoginForm
  },
  mixins: [
    BaseModal,
  ],
  props: {
    backUrlName: { type: String, required: true }
  },
  data () {
    return {
      model: {}
    }
  },
  methods: {
    close (msg) {
      if (msg === 'ok') {
        this.submitForm()
      } else {
        const vm = this
        this.$router.push({ name: vm.backUrlName })
      }
      this.isVisible = false
    },
    createDataToSend () {
      return {
        username: this.model.username,
        password: this.model.password
      }
    }
  }
}
