import router from "@/router";
import getClientObject from "@/router/utils/getClientObject";

export default ({params}) => {
  const service = params.service
  let routes = {}
  routes[`${service}_task_listing`] = {
    service: params.service,
    action: `get_tasks_datatables__default__${router.app.$getCategoryNew(getClientObject(params.service, params.client), params.taskCategoryId).name}_${params.client}`,
    type: 'main'
  }
  routes[`${service}_task_details`] = {
    service: params.service,
    action: `get_task__${router.app.$getCategoryNew(getClientObject(params.service, params.client), params.taskCategoryId).name}__${params.client}`,
  }
  routes[`${service}_appraisal_task_details`] = { service: params.service, action: `get_task__appraisal__${params.client}` }
  return routes
}
