export default {
  settlement: {},
  items: [],
  loader: {loading: false, message: ''},
  settlementFile: {},
  ownership: '',
  settlementOwnership: '',
  settlementDatatablesOwnership: '',
  settlementPutOwnership: '',
  fileUuid: '',
  vatOptions: [],
  vatModel: {}
}
