// Common
// Bundles: Internal/Gen
// field-access

const resolvedName = function (service, taskCategory) {
  return 'Proces główny - ' + taskCategory.label
}
export default function (service, name, serviceClient, taskCategory) {
  return {
    path: 'proces-glowny',
    component: true,
    meta: {
      title: resolvedName(service, taskCategory),
      icon: 'settings',
      menuTree: true
    },
    children: [
      {
        path: 'stany-procesu',
        component: true,
        name: `${service}_state_listing`,
        meta: {
          title: `Stany procesu`,
          icon: 'disc',
          menuLink: true,
          acl: {
            service: service,
            action: 'get_workflow_states'
          },
          meta: {
            title: '',
            acl: {
              service: service,
              client: serviceClient.id,
              clientId: serviceClient.uuid,
              clientLabel: serviceClient.label,
              clientSymbol: serviceClient.id,
              taskCategoryId: taskCategory.id
            }
          }
        },
        children: [
          {
            path: '',
            component: '',
            name: `${service}_state_listing`,
            meta: {
              title: 'Lista stanów procesu',
              acl: {
                service: service,
                action: 'get_workflow_states'
              },
              meta: {
                title: '',
                acl: {
                  service: service,
                  client: serviceClient.id,
                  clientId: serviceClient.uuid,
                  clientLabel: serviceClient.label,
                  clientSymbol: serviceClient.id,
                  taskCategoryId: taskCategory.id
                }
              }
            }
          }
        ]
      },
      {
        path: 'mapa-przejsc-procesu',
        component: true,
        meta: {
          title: 'Mapa przejść',
          icon: 'git-commit',
          menuLink: true,
          acl: {
            service: service,
            action: 'get_workflow_transitions'
          },
          meta: {
            title: '',
            acl: {
              service: service,
              client: serviceClient.id,
              clientId: serviceClient.uuid,
              clientLabel: serviceClient.label,
              clientSymbol: serviceClient.id,
              taskCategoryId: taskCategory.id
            }
          }
        },
        children: [
          {
            path: '',
            component: '',
            name: `${service}_transition_listing`,
            meta: {
              title: 'Lista przejść',
              acl: {
                service: service,
                action: 'get_workflow_transitions'
              },
              meta: {
                title: '',
                acl: {
                  service: service,
                  client: serviceClient.id,
                  clientId: serviceClient.uuid,
                  clientLabel: serviceClient.label,
                  clientSymbol: serviceClient.id,
                  taskCategoryId: taskCategory.id
                }
              }
            }
          }
        ]
      },
      {
        path: 'widocznosc-zlecen',
        component: true,
        meta: {
          title: 'Widoczność zleceń',
          icon: 'eye',
          menuLink: true,
          acl: {
            service: service,
            // action: `get_task_visibilities_${taskCategory.name}`
            action: `get_task_category_task_visibilities`
          },
          meta: {
            title: '',
            acl: {
              service: service,
              client: serviceClient.id,
              clientId: serviceClient.uuid,
              clientLabel: serviceClient.label,
              clientSymbol: serviceClient.id,
              taskCategoryId: taskCategory.id
            }
          }
        },
        children: [
          {
            path: '',
            component: true,
            name: `${service}_task_visibility_listing`,
            meta: {
              title: 'Lista widoczności zleceń',
              acl: {
                service: service,
                // action: `get_task_visibilities_${taskCategory.name}`
                action: `get_task_category_task_visibilities`
              },
              meta: {
                title: '',
                acl: {
                  service: service,
                  client: serviceClient.id,
                  clientId: serviceClient.uuid,
                  clientLabel: serviceClient.label,
                  clientSymbol: serviceClient.id,
                  taskCategoryId: taskCategory.id
                }
              }
            }
          }
        ]
      },
      {
        path: 'widocznosc-pol',
        component: true,
        meta: {
          title: 'Widoczność pól',
          icon: 'check-square',
          menuTree: true,
          acl: {
            service: service,
            // action: `get_${taskCategory.name}_fields_visibilities`
            action: `get_task_category_form_schema_field_visibilities`
          },
          meta: {
            title: '',
            acl: {
              service: service,
              client: serviceClient.id,
              clientId: serviceClient.uuid,
              clientLabel: serviceClient.label,
              clientSymbol: serviceClient.id,
              taskCategoryId: taskCategory.id
            }
          }
        },
        children: [
          ...taskCategory.formSchemas.map(formSchema => {
            return {
              path: `/${formSchema.id}`,
              component: '',
              name: `${service}_field_access_listing`,
              meta: {
                menuLink: true,
                title: `Lista widoczności pól - ${formSchema.label}`,
                acl: {
                  service: service,
                  // action: `get_${taskCategory.name}_fields_visibilities`
                  action: `get_task_category_form_schema_field_visibilities`
                },
                meta: {
                  title: '',
                  acl: {
                    service: service,
                    client: serviceClient.id,
                    clientId: serviceClient.uuid,
                    clientLabel: serviceClient.label,
                    clientSymbol: serviceClient.id,
                    taskCategoryId: taskCategory.id,
                    formSchemaId: formSchema.id
                  }
                }
              }
            }
          })
        ]
      }
    ]
  }
}
