<template>
  <div class="row">
    <input
      v-if="value.id"
      :id="'id_' + value.id"
      type="hidden"
      :value="value.id"
    >
<!--    <div class="col-sm-4">-->
<!--      <div>-->
<!--        <label for="state">Status</label>-->
<!--        <select-->
<!--          id="state"-->
<!--          class="form-control"-->
<!--          :value="value['state']['id']"-->
<!--          @input="updateState($event.target.value)"-->
<!--        >-->
<!--          <option-->
<!--            v-for="option in states"-->
<!--            :value="option.value"-->
<!--            :key="option.value"-->
<!--          >-->
<!--            {{ option.label }}-->
<!--          </option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
    <div class="col-sm-4">
      <div>
        <label :for="`field-${value['fieldItem']['id']}`">Pole</label>
        <select
          :id="`field-${value['fieldItem']['id']}`"
          class="form-control"
          :value="value['fieldItem']['id']"
          @input="updateField($event.target.value)"
        >
          <optgroup
            v-for="group in schema.groups"
            :label="group.label"
            :key="group.label"

          >
            <option
              v-for="option in group.fields"
              :value="option.id"
              :key="option.id"
            >
              {{ option.label }} ({{ group.label }})
            </option>
          </optgroup>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div>
        <label :for="`task-visibility-${value['fieldItem']['id']}`">Poziom dostępu</label>
        <select
          :id="`task-visibility-${value['fieldItem']['id']}`"
          class="form-control"
          :value="value['visibility']"
          @input="updateVisibility($event.target.value)"
        >
          <option
            v-for="option in visibilities"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    states: { type: Array, required: true },
    visibilities: { type: Array, required: true },
    schema: { type: Object, required: true }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateState (value) {
      this.value.state.id = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateVisibility (value) {
      this.value.visibility = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateField (value) {
      this.value.fieldItem.id = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
    width: 100%;
}
</style>
