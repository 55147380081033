export default function (service, workflow) {
  let trans = {
    main_vehicle: ' - pojazd',
    main_person: ' - osoba',
    main_property: ' - mienie'
  }
  // return service === 'extpko' || service === 'extemn' ? ' - online' : trans[workflow]

  switch (service) {
    case 'extpko':
      return ' - online'
    case 'extgcz':
      return '- trial'
    case 'extshp':
      return ' - online'
    case 'extemn':
      return ' - online'
    case 'extacn':
      return ' - online'
    case 'extgsl':
      return '- vozidlo'
    case 'extuni':
      return ' - online'
    default:
      return trans[workflow]
  }
}
