<script>
// import {Validator} from 'vee-validate'

export default {
  data () {
    return {
      schemaTop: [
        { name: 'name', label: this.$t('modals.newUser.companyName'), enabled: 'company', rules: { required: true } },
        { name: 'forename', label: this.$t('modals.newUser.name'), disabled: 'company', rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńŃĘĄÓŚŁŻŹĆ\s]{1,}$/ } },
        { name: 'surname', label: this.$t('modals.newUser.surname'), disabled: 'company', rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńŃĘĄÓŚŁŻŹĆ\s]{1,}$/ } }
      ],
      schemaBottom: [
        { name: 'city', label: this.$t('modals.newUser.city'), rules: { required: false } },
        { name: 'postcode', label: this.$t('modals.newUser.postCode'), rules: { required: false } },
        { name: 'street', label: this.$t('modals.newUser.street'), rules: { required: false } },
        { name: 'pesel', label: this.$t('modals.newUser.pesel'), rules: { required: false, max: 11 } },
        { name: 'nip', label: this.$t('modals.newUser.nip'), rules: { required: false, max: 10 } },
        { name: 'phone', label: this.$t('modals.newUser.phone'), rules: { required: false, max: 16 } },
        { name: 'email', label: this.$t('modals.newUser.email'), rules: { required: false, email: true } }
      ]
    }
  },
  created () {
    // turned off
    // Validator.extend('postcode', {
    //   getMessage: () => ' Pole Kod pocztowy musi być poprawnym kodem pocztowym.',
    //   validate: (value) => {
    //     const postCodeRegex = /^(\d{2}-\d{3})$/
    //     if (value === '00-000') {
    //       return false
    //     } else if (!postCodeRegex.test(value)) {
    //       return false
    //     } else if (postCodeRegex.test(value)) {
    //       return true
    //     }
    //   }
    // })
  }
}
</script>
