<template>
  <form>
    <template
      v-for="field in schema"
    >
      <input-type
        v-if="!field.hasOwnProperty('hidden') || (field.hasOwnProperty('hidden') && !field.hidden)"
        :key="field.name"
        v-model="value[field.name]"
        v-validate="{rules: field.rules}"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : field.label"
        :error-message="vErrors.first(field.name)"
        :label="field.label"
        :name="field.name"
        :required="field.rules.required"
        @output="clearMail"
      />
    </template>
    <div
      class="modal-body form-group required-field"
      :class="[{ 'has-error': vErrors.first('unitIds'), 'has-success' : !vErrors.first('unitIds') && unitSelectHasBeenChanged }]"
    >
      <label>Jednostki:</label>
      <select
        v-validate="{ rules: { required: true }}"
        name="unitIds"
        class="form-control"
        multiple
        :value="value['unitIds']"
        data-vv-value-path="value"
        data-vv-as="unit"
        @change="multiselectValues($event.target.selectedOptions)"
      >
        <option
          v-for="option in units"
          :value="option.id"
          :key="option.id"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div
      class="modal-body form-group required-field"
      :class="[{ 'has-error': vErrors.first('contractorId'), 'has-success' : !vErrors.first('contractorId') && contractorSelectHasBeenChanged }]"
    >
      <label>Kontrahent:</label>
      <select
        v-validate="{ rules: { required: true }}"
        name="contractorId"
        class="form-control"
        :value="value['contractorId']"
        data-vv-value-path="value"
        data-vv-as="contractor"
        @change="selectValue($event.target.selectedOptions)"
      >
        <option
          v-for="option in contractors"
          :value="option.id"
          :key="option.id"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
import { Validator } from 'vee-validate'
import InputType from '../../../../../share/form/type/InputType'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import api from '../../../../../../api'

export default {
  components: {
    InputType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      unitSelectHasBeenChanged: false,
      contractorSelectHasBeenChanged: false,
      units: [],
      contractors: []
    }
  },
  computed: {
    schema () {
      return [
        {name: 'email', label: 'Adres e-mail', rules: {required: true, email: true}},
        {name: 'email2', label: 'Powtórz adres e-mail', rules: {required: true, email: true, repeated_email: true}},
        {name: 'forename', label: 'Imię', rules: {required: true, regex: /^[a-zA-ZÁáČčĎďÉéěÍíŇňŘřŠšŤťÚúůÝýŽžľęóąśłżźćńÓŚŁŃŻŹĆ]{3,}$/}},
        // eslint-disable-next-line no-useless-escape
        {name: 'surname', label: 'Nazwisko', rules: {required: true, regex: /^[a-zA-ZÁáČčĎďÉéěÍíŇňŘřŠšŤťÚúůÝýŽžľęóąśłżźćńÓŚŁŃŻŹĆ\-]{3,}$/}},
        {
          name: 'phone',
          label: 'Numer telefonu (format: +00 123456789 lub +00 11 1234567, +00 opcjonalne)',
          label2: 'Numer telefonu',
          rules: {required: false, regex: /^(\++\d{2}\s)?((\d{2}\s)+\d{7}|\d{9})$/}
        },
        {name: 'description', label: 'Opis', rules: {required: false, max: 255}, hidden: !this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')},
      ]
    }
  },
  created () {
    Validator.remove('repeated_email')
    Validator.extend('repeated_email', {
      getMessage: () => 'Adresy e-mail muszą być jednakowe.',
      validate: value => value === this.value.email
    })
  },
  mounted () {
    this.loadUnits()
    this.loadContractors()
  },
  methods: {
    loadUnits () {
      api.request('core', 'get', '/units')
        .then((response) => {
          this.units = response.data
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    loadContractors () {
      api.request('core', 'get', '/contractors')
        .then((response) => {
          const sortAlphaNum = (a, b) => a.label.localeCompare(b.label, 'pl', { numeric: true })
          this.contractors = response.data.sort(sortAlphaNum)
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    multiselectValues (selectedOptions) {
      this.$events.$emit('core:user:NewUserModal', selectedOptions)
      this.unitSelectHasBeenChanged = true
    },
    selectValue (selectedOptions) {
      this.$events.$emit('core:user:NewUserModalContractor', selectedOptions[0])
      this.contractorSelectHasBeenChanged = true
    },
    clearMail (target) {
      if (target.id === 'email') {
        document.getElementById('email2').value = ''
      }
    }
  }
}
</script>
