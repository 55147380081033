import LocalStorage from '../../components/share/LocalStorage'

export default {
  SET_GRID_STATE (state, savedGridState) {
    savedGridState.data['searchModel'] = savedGridState.searchModel
    state.grid.hasOwnProperty(savedGridState.userUuid) ? state.grid[savedGridState.userUuid][savedGridState.name] = savedGridState.data
      : state.grid[savedGridState.userUuid] = { [savedGridState.name]: savedGridState.data }
    LocalStorage.setUserTaskListing(savedGridState.userUuid, state.grid)
  },
  GET_GRID_STATE_LOCALSTORAGE (state, payload) {
    state.grid = JSON.parse(LocalStorage.getUserTaskListing(payload))
  }
}
