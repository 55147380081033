export default {
  getProperFieldSelectData: (state) => (groupName, fieldName) => {
    return state.currentTaskSchema.hasOwnProperty('groups') && state.currentTaskSchema.groups
      .filter(group => group.name === groupName)[0].fields
      .filter(field => field.field.model === fieldName)[0].field.settings.values
      .filter(value => value.id === state.currentTaskModel[fieldName])[0]
  },
  getCurrentSchemaModelReference: (state) => {
    return state.currentTaskModel
  },
  isPending: state => state.pending,
  getCurrentUser: state => state.user.uuid,
  getTransitions: state => state.workflow.transitions
}
