<script>
import BaseListing from './Listing'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      gridName: 'grid_contractor',
      headerTitle: { title: 'Kontrahenci', description: 'Lista' },
      service: 'core',
      actionShowUrlName: 'admin_contractor_details',
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'eye', acl: { service: 'core', action: 'get_contractor' } }
      ],
      mainMenuButtons: [
        { event: 'addContractor', icon: 'plus', label: 'Dodaj kontrahenta', acl: { service: 'core', action: 'post_contractor' } }
      ]
    }
  }
}
</script>
