// NOTE: common configuration for modules intmil and intbwn

import getProperServiceIcon from '../../../../utils/getProperServiceIcon'

export default function (service, name) {
  const pathPrefix = `/administracja-procesami-${name.toLowerCase()}/`
  const customBreadcrumbs = {}
  customBreadcrumbs[service] = {
    meta: {
      icon: getProperServiceIcon(service),
      title: name
    }
  }

  return [
    {
      path: `${pathPrefix}kalendarz-widocznosc`,
      name: `${service}_calendar_inspection_visibilities_listing`,
      component: () => import(`../../../../../components/bundles/${service}/admin/inspection-visibility/Listing.vue`),
      meta: {
        acl: {
          service: service,
          action: `get_inspection_visibilities`
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service]
          ]
        },
        icon: `calendar`,
        title: `Ustawienia widoczności`,
        secondMenu: [
          {
            event: { name: `${service}:calendar:inspectionVisibilitiesEdit` },
            icon: `edit`,
            label: `Tryb edycji`,
            acl: { service: service, action: `put_inspection_visibilities` }
          }
        ]
      }
    },
    {
      path: `${pathPrefix}kalendarz-widocznosc/edycja`,
      name: `${service}_calendar_inspection_visibilities_edit`,
      component: () => import(`../../../../../components/bundles/${service}/admin/inspection-visibility/Edit.vue`),
      meta: {
        acl: {
          service: service,
          action: `put_inspection_visibilities`
        },
        breadcrumb: {
          list: [
            customBreadcrumbs[service],
            `${service}_calendar_inspection_visibilities_listing`
          ]
        },
        title: `Tryb edycji`,
        thirdMenu: [
          {
            event: { name: `${service}:calendar:inspectionVisibilitiesSubmit` },
            icon: `save`,
            label: `Zapisz`,
            acl: { service: service, action: `put_inspection_visibilities` }
          },
          {
            event: { name: `dashboard:menu:redirect`, value: `${service}_calendar_inspection_visibilities_listing` },
            icon: `arrow-left`,
            label: `Wróć do listy`,
            acl: { service: service, action: `get_inspection_visibilities` }
          }
        ]
      }
    }
  ]
}
