// Common
import RouterFrame from '../../../../components/share/RouterFrame.vue'
// Bundles: External/Gen
import IntgenStateListing from '../../../../components/bundles/intgen/appraisal/admin/state/Listing.vue'
import IntgenWorkflowListing from '../../../../components/bundles/intgen/appraisal/admin/transition/Listing.vue'
import IntgenTaskVisibilityListing from '../../../../components/bundles/intgen/appraisal/admin/task-visibility/Listing.vue'
import IntgenAttachmentCategoryRequirementListing from '../../../../components/bundles/intgen/appraisal/admin/attachment-requirement/Listing.vue'
// field-access
import IntgenFieldAccessListing from '../../../../components/bundles/intgen/appraisal/admin/field-access/Listing.vue'

export default {
  path: 'proces-rzeczoznawczy',
  component: RouterFrame,
  meta: {
    title: 'Proces rzeczoznawczy',
    icon: 'settings',
    menuTree: true
  },
  children: [
    {
      path: 'stany-procesu',
      component: RouterFrame,
      meta: {
        title: 'Stany procesu',
        icon: 'disc',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_workflow_states'
        }
      },
      children: [
        {
          path: '',
          component: IntgenStateListing,
          name: 'intgen_appraisal_state_listing',
          meta: {
            title: 'Lista stanów procesu',
            acl: {
              service: 'intgen',
              action: 'get_workflow_states'
            }
          }
        }
      ]
    },
    {
      path: 'mapa-przejsc-procesu',
      component: RouterFrame,
      meta: {
        title: 'Mapa przejść',
        icon: 'git-commit',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_workflow_transitions'
        }
      },
      children: [
        {
          path: '',
          component: IntgenWorkflowListing,
          name: 'intgen_appraisal_workflow_listing',
          meta: {
            title: 'Lista przejść',
            acl: {
              service: 'intgen',
              action: 'get_workflow_transitions'
            }
          }
        }
      ]
    },
    {
      path: 'widocznosc-zlecen',
      component: RouterFrame,
      meta: {
        title: 'Widoczność zleceń',
        icon: 'eye',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_appraisal_task_visibilities'
        }
      },
      children: [
        {
          path: '',
          component: IntgenTaskVisibilityListing,
          name: 'intgen_appraisal_task_visibility_listing',
          meta: {
            title: 'Lista widoczności zleceń',
            acl: {
              service: 'intgen',
              action: 'get_appraisal_task_visibilities'
            }
          }
        }
      ]
    },
    {
      path: 'widocznosc-pol',
      component: RouterFrame,
      meta: {
        title: 'Widoczność pól',
        icon: 'check-square',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_appraisal_fields_visibilities'
        }
      },
      children: [
        {
          path: '',
          component: IntgenFieldAccessListing,
          name: 'intgen_appraisal_field_access_listing',
          meta: {
            title: 'Lista widoczności pól',
            acl: {
              service: 'intgen',
              action: 'get_appraisal_fields_visibilities'
            }
          }
        }
      ]
    },
    {
      path: 'wymagalnosc-kategorii',
      component: RouterFrame,
      meta: {
        title: 'Wymagalność kategorii',
        icon: 'image',
        menuLink: true,
        acl: {
          service: 'intgen',
          action: 'get_appraisal_attachment_category_requirements'
        }
      },
      children: [
        {
          path: '',
          component: IntgenAttachmentCategoryRequirementListing,
          name: 'intgen_appraisal_attachment_category_requirement_listing',
          meta: {
            title: 'Lista wymagalności kategorii',
            acl: {
              service: 'intgen',
              action: 'get_appraisal_attachment_category_requirements'
            }
          }
        }
      ]
    }
  ]
}
